import React from 'react';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert'; 
import Loader from '../../../components/loader';
import { useGetUserInRoleQuery, useRemoveRoleMutation, useRemoveUsersFromRoleMutation } from '../../../services/roleApi';
import List from './list';
import { useParams } from 'react-router-dom';
import Errors from '../../../components/errors';
import './list-users-in-role.scss';

const ListUsersInRole = ({}:any) => {
    const { roleId } = useParams<any>();    
        
    const { data: usersInRole, error, isLoading } = useGetUserInRoleQuery({roleId});
    const [ removeRole, { isLoading: removeLoading, error: addError }] = useRemoveRoleMutation();   
    const [ removeUserFromRole, { isLoading: removeUsersFromRoleLoading, error: removeUsersFromRoleError }] = useRemoveUsersFromRoleMutation();
    
    const onRemoveRoleFromUser = (userId: string) => {
        removeRole({userId, roleId}) .unwrap()
        .then((response: any) => {
            toast.success("Successfully removed role from user");
         })
         .catch((error) => { toast.error("Error removing role from user") });
    };

    const onRemoveUserFromRole = (userIds: string[]) => {
       confirmAlert({
            title: 'Confirm Remove Users',
            message: `Are you sure you want to remove users from role?`,
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                    removeUserFromRole({userIds, roleId}).unwrap()
                    .then((response: any) => {
                        toast.success("Successfully removed users from role");
                        return true;
                     })
                     .catch((error) => { 
                        toast.error("Error removing users from role");
                        return false;
                    });
                }
              },
              {
                label: 'No',
                onClick: () => {
                    return false;
                }
              }
            ]
          });        
    };
    
    return (
        <section className="section">
            <div>
                <h1>Users in role {usersInRole?.roleName}</h1>                
                {(isLoading || removeLoading || removeUsersFromRoleLoading) && <Loader/>}           
                <Errors error={error || removeUsersFromRoleError} />
                <List users={usersInRole?.users} 
                      onRemoveRoleFromUser={onRemoveRoleFromUser}
                      removeUserFromRole={onRemoveUserFromRole}
                      canEdit={usersInRole?.canEdit} 
                      canDelete={usersInRole?.canDelete}
                      canEditRoles={usersInRole?.canEditRoles} />
            </div>
        </section>
    )    
};

export default ListUsersInRole;