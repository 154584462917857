import { useEffect } from 'react';
import { SketchPicker } from 'react-color';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../app/hooks';
import Errors from '../../../components/errors';
import Loader from '../../../components/loader';
import { getLithologyLookups, selectLithology, updateLithologyLookup } from "../../../services/lithologySlice";
import List from './list';

const ListLithologyLexicons = ({}:any) => {
  
  const dispatch = useAppDispatch();       
  useEffect(() => {        
    dispatch(getLithologyLookups());
  }, []);

  const { 
    lithologyLookups,
    loading,
    error
  } = useSelector(selectLithology); 

  const onUpdateLithologyLookup = (lithologyLookup: any) => {
    return dispatch(updateLithologyLookup(lithologyLookup));    
  }
    
    return (
        <section className="section">
            <div>
                <div className='columns'>
                  <div className="column">
                    <h1>Lithology Lookups</h1>   
                  </div>                  
                </div>                
                {(loading) && <Loader/>}
                <Errors error={error}/>                
                <List lithologyLookups={lithologyLookups} updateLithologyLookup={onUpdateLithologyLookup} />
            </div>
        </section>
    )    
};

export default ListLithologyLexicons;