import { confirmAlert } from 'react-confirm-alert'; 
import { toast } from 'react-toastify';
import Errors from '../../../components/errors';
import Loader from '../../../components/loader';
import { useGetRolesSummaryQuery, useDeleteMutation, useDeleteMultipleMutation } from '../../../services/roleApi';
import List from './list';

const ListRoles = ({}:any) => {
    
    const { data: roles, isLoading, error } = useGetRolesSummaryQuery();

    const [deleteRole, { isLoading: isLoadingDelete, error: deleteError }] = useDeleteMutation();
    const [deleteRoles, { isLoading: isLoadingDeleteMultiple, error: deleteMultipeError }] = useDeleteMultipleMutation();

    const onDelete = (roleId: string, roleName: string) => {
        confirmAlert({
            title: 'Confirm Delete Role',
            message: `Are you sure you want to delete role ${roleName}?`,
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                    deleteRole({roleId})
                    .unwrap()
                        .then((response: any) => {
                            toast.success("Successfully deleted role");
                        })
                        .catch((error) => { toast.error("Error deleting role") });;
                }
              },
              {
                label: 'No',
                onClick: () => {}
              }
            ]
          });        
    };

    const onDeleteMultiple = (roleIds: string[]) => {
      confirmAlert({
          title: 'Confirm Delete Role',
          message: `Are you sure you want to delete roles?`,
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                deleteRoles({roleIds})
                  .unwrap()
                      .then((response: any) => {
                          toast.success("Successfully deleted roles");
                      })
                      .catch((error) => { toast.error("Error deleting roles") });;
              }
            },
            {
              label: 'No',
              onClick: () => {}
            }
          ]
        });        
  };

    
    return (
        <section className="section">
            <div>
                <h1>Roles</h1>                
                {(isLoading || isLoadingDelete || isLoadingDeleteMultiple) && <Loader/>}
                <Errors error={error || deleteMultipeError}/>
                
                <List roles={roles?.roles} 
                                  onDelete={onDelete} 
                                  deleteMultiple={onDeleteMultiple}
                                  canAdd={roles?.canAdd} 
                                  canEdit={roles?.canEdit} 
                                  canDelete={roles?.canDelete} 
                                  canListUsers={roles?.canListUsers} 
                                  canView={roles?.canView}/>                
            </div>
        </section>
    )    
};

export default ListRoles;