import React from "react";
import { Route, Switch } from "react-router-dom";
import Login from './login/container';
import ChangePassword from './change-password/container';
import UpdateDetails from './update-details/container';
import ForgotPassword from './forgot-password/container';
import ResetPassword from './reset-password/container';
import EnableMfa from './enable-two-factor/container';
import DisableMfa from './disable-two-factor/container';
import ChangeMfa from './change-two-factor/container';
const AccountRoutes = () => {
  return (    
    <Switch>
      <Route exact path="/login">
        <Login />        
      </Route>                
      <Route exact path="/logout">
        <Login />
      </Route>
      <Route exact path="/change-password">
        <ChangePassword />
      </Route>     
      <Route exact path="/user">
        <UpdateDetails />
      </Route>     
      <Route exact path="/user/enable-mfa">
        <EnableMfa />
      </Route>     
      <Route exact path="/user/disable-mfa">
        <DisableMfa />
      </Route>     
      <Route exact path="/user/change-mfa">
        <ChangeMfa />
      </Route>     
      <Route exact path="/user/mfa">
        <EnableMfa />
      </Route>     
      <Route exact path="/forgot-password">
        <ForgotPassword />
      </Route>     
      <Route exact path="/reset-password">
        <ResetPassword />
      </Route>     
    </Switch>            
  );
};

export default AccountRoutes;
