import React, { useEffect, useState } from 'react';
import Errors from '../../../components/errors';
import Loader from '../../../components/loader';
import { useGetRolesQuery } from '../../../services/roleApi';
import Form from './form';

const UserContainer = ({ title, userIsLoading, userRoles, onSave, isLoading, error }:any) => {
    
    const [checkedRoles, setCheckedRoles] = useState<any[]>();
        
    const { data: roles, isLoading: rolesIsLoading } = useGetRolesQuery();

    useEffect(() => {
        if (!userIsLoading && userRoles) {
            setCheckedRoles(userRoles.roleIds);
        }
    },[userIsLoading, userRoles]);

    const onSaveUser = (e: any) => {
        e.preventDefault();

        // only select checkedRoles that aren't don't have parent that is checked
        const roleIds = checkedRoles;
        
        onSave({ roleIds });
     };

    const onSetCheckedRoles = (checked: any) => {
        setCheckedRoles(checked);        
    }

    const onChangeRoleList = (e:any) => {            
        const value = +e.target.value;

        if (checkedRoles?.includes(value)) {
            const newRoles = [...checkedRoles].filter((id) => id !== value);

            setCheckedRoles(newRoles);    

        } else {
            const newRoles = checkedRoles ? [...checkedRoles] : [];
            newRoles.push(value);

            setCheckedRoles(newRoles);    
        }
    }

    return (
        <section className="section">
            <div>
                <h1>{title}</h1>
                <Errors error={error}/>
                {(isLoading || rolesIsLoading) && <Loader/>}
                <Form 
                      userName={userRoles?.userName}
                      onSaveUser={onSaveUser}
                      roles={roles?.roles}    
                      onChangeRoleList={onChangeRoleList}                  
                      checkedRoles={checkedRoles}
                      setCheckedRoles={onSetCheckedRoles} />
            </div>
        </section>
    )    
};

export default UserContainer;