import React from 'react';
import { Link } from 'react-router-dom';

const Row = ({ trackType }:any) => {

    return (    
        <tr>
            <td>{trackType.uploadTypeDescription}</td>
            <td><Link to={`/admin/track-type/edit/${trackType.id}`}>{trackType.name}</Link></td>
        </tr>                  
    )    
};

export default Row;