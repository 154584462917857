import { confirmAlert } from 'react-confirm-alert'; 
import { toast } from 'react-toastify';
import Loader from '../../../components/loader';
import Errors from '../../../components/errors';
import { useAppSelector } from '../../../app/hooks';
import { useGetUsersQuery } from '../../../services/userApi';
import { useDeleteMutation, useRemoveFromClientMutation, useDeleteMultipleMutation, useRemoveMultipleFromClientMutation, useResetTwoFactorMutation } from '../../../services/userApi';
import { selectUser } from '../../../services/accountSlice';
import List from './list';

const ListUsers = ({}:any) => {
    const currentUser = useAppSelector(selectUser);

    const { data: users, isLoading, error } = useGetUsersQuery();
    
    const [deleteUser, { isLoading: isLoadingDelete, error: deleteError }] = useDeleteMutation();
    const [deleteUsers, { isLoading: isLoadingDeleteMultiple, error: deleteMultipleError }] = useDeleteMultipleMutation();
    const [removeUserFromClient, { isLoading: isLoadingRemovingUserFromClient, error: removeError }] = useRemoveFromClientMutation();
    const [removeUsersFromClient, { isLoading: isLoadingRemovingUsersFromClient, error: removeMultipeError }] = useRemoveMultipleFromClientMutation();
    const [resetTwoFactor, { isLoading: isLoadingRresetTwoFactor, error: resetTwoFactorError }] = useResetTwoFactorMutation();

    const onDelete = (userId: string, userName: string) => {
        confirmAlert({
            title: 'Confirm Delete User',
            message: `Are you sure you want to delete user ${userName}?`,
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                    deleteUser({userId})
                    .unwrap()
                        .then((response: any) => {
                            toast.success("Successfully deleted user");
                        })
                        .catch((error) => { toast.error("Error deleting user") });
                }
              },
              {
                label: 'No',
                onClick: () => {}
              }
            ]
          });        
    };

    const onDeleteMultiple = (userIds: string[]) => {
      confirmAlert({
          title: 'Confirm Delete Users',
          message: `Are you sure you want to delete selected users?`,
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                  deleteUsers({ids: userIds})
                  .unwrap()
                      .then((response: any) => {                        
                          toast.success("Successfully deleted users");
                          return true;
                      })
                      .catch((error) => { 
                        toast.error("Error deleting users");
                        return false;
                      });
              }
            },
            {
              label: 'No',
              onClick: () => { return false; }
            }
          ]
        });        
  };

    const onRemove = (userId: string, userName: string) => {
      confirmAlert({
          title: 'Confirm Remove User',
          message: `Are you sure you want to remove user ${userName}?`,
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                removeUserFromClient({userId})
                  .unwrap()
                      .then((response: any) => {
                          toast.success("Successfully removed user");
                      })
                      .catch((error) => { toast.error("Error removing user") });
              }
            },
            {
              label: 'No',
              onClick: () => {}
            }
          ]
        });        
    };

    const onRemoveMultiple = (userIds: string[]) => {
      confirmAlert({
          title: 'Confirm Remove Users',
          message: `Are you sure you want to remove users?`,
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                removeUsersFromClient({ids: userIds})
                  .unwrap()
                      .then((response: any) => {
                          toast.success("Successfully removed users");
                          return true;
                      })
                      .catch((error) => { 
                        toast.error("Error removing users");
                        return false;
                      });
              }
            },
            {
              label: 'No',
              onClick: () => { return false; }
            }
          ]
        });        
    };

    const onResetTwoFactor = (userId: string, userName: string) => {
      confirmAlert({
          title: 'Confirm Remove Two Factor Authentication',
          message: `Are you sure you want to reset two factor authentication for user ${userName}? This action is irreversable. Have you confirmed the user's identity?`,
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                  resetTwoFactor({ userId })
                  .unwrap()
                      .then((response: any) => {
                          toast.success("Successfully reset user two factor");
                      })
                      .catch((error) => { toast.error("Error resetting user two factor") });
              }
            },
            {
              label: 'No',
              onClick: () => {}
            }
          ]
        });        
  };
        
    return (
        <section className="section">
            <div>
                <h1>Users</h1>                
                {(isLoading || isLoadingDelete || isLoadingDeleteMultiple || isLoadingRemovingUsersFromClient) && <Loader/>}
                <Errors error={error || deleteMultipleError || removeMultipeError }/>
                
                <List users={users?.users} 
                      onDelete={onDelete} 
                      deleteMultiple={onDeleteMultiple}
                      onRemove={onRemove}
                      removeMultiple={onRemoveMultiple}
                      resetTwoFactor={onResetTwoFactor}
                      canAdd={users?.canAdd} 
                      canEdit={users?.canEdit} 
                      canDelete={users?.canDelete}
                      canRemove={users?.canRemove}
                      canEditEmailAddress={users?.canEditEmailAddress} 
                      canEditRoles={users?.canEditRoles}
                      canResetTwoFactor={users?.canResetTwoFactor} />
            </div>
        </section>
    )    
};

export default ListUsers;