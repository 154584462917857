import React from "react";
import moment from 'moment';
import './progress.scss';

const Progress = (props: any) => {
  const {
    progress
  } = props;

  return (
    <>
      {progress && (
        <div {...props}>
          <nav className="level progress-level">
            <div className="level-item progress-level-item">
              <progress
                    className={"progress " + (progress?.warning ? "is-warning" : progress?.failed ? "is-danger" : progress?.progress === 100 ? "is-success" : progress?.progress ? "is-primary" : "is-blue") }
                    value={progress?.progress}
                    max="100">
                    {progress?.progress}
                  </progress>          
            </div>
            {progress?.progress !== null &&
              <div className={`level-item progress-percent ${progress?.failed ? "is-danger" : ""}`}>
                  <span>{(Math.round(progress.progress * 10) / 10)}%</span>  
              </div>         
            }
          </nav>
          <div className="progress-message">           
            {(progress?.secondsRemaining !== null && progress?.secondsRemaining > 0) && <span>Estimated remaining time: {moment.utc(progress?.secondsRemaining*1000).format('mm:ss')}&nbsp;&nbsp;&nbsp;</span>}            
            {progress?.message && <span>{progress?.message}</span>}            
          </div>
          {progress?.subProgress > 0 && progress?.subProgress < 100 && progress?.progress != null &&
            <nav className="level progress-level">
              <div className="level-item progress-level-item">
              <progress
                      className={"progress " + (progress?.warning ? "is-warning" : progress?.failed ? "is-danger" : progress?.subProgress === 100 ? "is-success" : "is-primary") }
                      value={progress.subProgress}
                      max="100">
                      {progress.subProgress}
                    </progress>          
              </div>
              {progress?.subProgress !== null &&
                  <div className={`level-item progress-percent ${progress?.failed ? "is-danger" : ""}`}>
                      <span>{(Math.round(progress.subProgress * 10) / 10)}%</span>  
                  </div>         
                }
            </nav>
          }
       </div>    
      )}
    </>
  );
};

export default Progress;