import React, { useEffect, useState } from 'react';
import Errors from '../../../components/errors';
import Loader from '../../../components/loader';

import Form from './form';

const LexiconContainer = ({ title, lithologyLexicon, onSave, loading, saving, error, readOnly }:any) => {
    
    const [code, setCode] = useState('');
    const [description, setDescription] = useState('');
        
    useEffect(() => {
        if (!loading && lithologyLexicon) {
            setCode(lithologyLexicon.code);         
            setDescription(lithologyLexicon.description);
        }
    },[loading, lithologyLexicon]);

    const onUpdateLithologyLexicon = (e: any) => {
        e.preventDefault();
        onSave({code, description });
      };

    return (
        <section className="section">
            <div>
                <h1>{title}</h1>
                <Errors error={error}/>
                {(loading || saving) && <Loader/>}

                <Form code={code}                      
                    setCode={setCode}                       
                    description={description}
                    setDescription={setDescription}
                    onSaveLexicon={onUpdateLithologyLexicon}
                    />
                
            </div>
        </section>
    )    
};

export default LexiconContainer;