import React from 'react';

  const Modal = (props: any) => {   
    const { children, closeModal, title, handleOkModal, width, handleYes, handleNo, buttonsDisabled } = props;

    const backgroundClick = () => {
      if (!buttonsDisabled) {
        closeModal();
      }
    };

    return(
      <div className="modal is-active">
        <div className="modal-background" onClick={backgroundClick} />
        <div className="modal-card" style={{width: width }}>
          <header className="modal-card-head">
            <p className="modal-card-title">{title}</p>
            <button className="delete" onClick={closeModal} disabled={buttonsDisabled}/>
          </header>
          <section className="modal-card-body">
            <div className="content">
              {children}
            </div>
          </section>
          <footer className="modal-card-foot">
            {handleOkModal && <button className="button is-primary" onClick={handleOkModal} disabled={buttonsDisabled}>Ok</button>}
            {handleYes && <button className="button is-primary" onClick={handleYes} disabled={buttonsDisabled}>Yes</button>}
            {handleNo && <button className="button is-danger" onClick={handleNo} disabled={buttonsDisabled}>No</button>}
            <button className="button" onClick={closeModal} disabled={buttonsDisabled}>Cancel</button>
          </footer>
        </div>
      </div>
    );
  }
export default Modal;