import { useEffect, useRef, useState } from 'react'
import { useSelector } from "react-redux";
import TextareaAutosize from 'react-textarea-autosize';
import { useAppDispatch } from "../../../app/hooks";
import { getLithologyLookups, selectLithology } from "../../../services/lithologySlice";
import { confirmAlert } from 'react-confirm-alert'; 
import './text-track.scss';
import Errors from '../../errors';

const TextEdit = (props: any) => {

  const {         
        canAdd,
        clientId,
        textData,
        changeData,        
        saveData,
        deleteData,
        selectedLithologyId,
        setSelectedLithologyId,
        visible,
        trackEditMode,
        setTrackEditMode
     } = props;

  const dispatch = useAppDispatch();    
  
  const focusInput = useRef<any[]>([]);
  const [focusIndex, setFocusIndex] = useState(-1);
  const [errors, setErrors] = useState<string[]>([]);
  const [textErrors, setTextErrors] = useState<any>({});

  useEffect(() => {            
    dispatch(getLithologyLookups());
  }, []);

  useEffect(() => {    
    if (selectedLithologyId && visible && focusInput.current.length) {      
      const focusIndex = textData[0]?.data.findIndex((data: any) => data.id === selectedLithologyId);      
      if (focusIndex >= 0) {
        setFocusIndex(focusIndex);
        setSelectedLithologyId(null);
      }
    }    
  }, [selectedLithologyId, visible, focusInput.current.length]);  

  useEffect(() => {
    if (focusIndex >= 0) {    
      focusInput.current[focusIndex]?.focus();
    }
  }, [focusIndex]);
  
  const [localData, setLocalData] = useState<any>();

  const toCannotOverlapWithAnotherRange = "'To' cannot overlap with another range.";
  const toMustBeGreaterThanFrom = "'To' must be greater than 'From'.";
  const descriptionCannotBeEmpty = "'Description' cannot be empty.";
  
  useEffect(() => {
    if (textErrors) {            
      var keys = Object.keys(textErrors);
      const allErrors: any[] = [];
      keys.forEach((key: any) => {
        const textError = textErrors[key];
        if (textError.fromDepthError) {
          allErrors.push(textError.fromDepthError);          
        }
        if (textError.toDepthError) {
          allErrors.push(textError.toDepthError);          
        }
        if (textError.descriptionError) {
          allErrors.push(textError.descriptionError);          
        }
      });
      console.log("allErrors", allErrors);
      setErrors([...new Set(allErrors)].sort((a, b) => a.localeCompare(b)));
    }
  },[textErrors]);

  useEffect(() => {    
    if (textData && textData[0]?.data) {         
      setLocalData(textData[0]?.data);
    }
  },[JSON.stringify(textData)]);

  const onChangeTextValue = (id: string, value: string) => {    
    
    const copy = Array.from(localData);
    const dataIndex = copy.findIndex((d: any) => d.id === id);
    const data: any = copy[dataIndex];    
    const newData = {...data, value};  
    updateLocalData(copy, dataIndex, newData);
  }

  const onBlurTextValue = (id: string, value: string) => {        
    const localCopy: any[] = Array.from(localData);
    const currentIndex = localCopy.findIndex((d: any) => d.id === id);
       
    if (!value) {      
        updateDescriptionError(id, descriptionCannotBeEmpty);
        return;
    }
    
    updateDescriptionError(id, null);
  }


  const updateDescriptionError = (id:any, error: string | null) => {
    const newTextErrors = {...textErrors};
    let textError = newTextErrors[id];
    if (textError) {
      newTextErrors[id] = { ...textError, descriptionError: error };
    } else {
      newTextErrors[id] = { descriptionError: error };
    }

    setTextErrors(newTextErrors);
  }

  const updateFromDepthError = (id: any, error: string | null) => {
    const newTextErrors = {...textErrors};
    let textError = newTextErrors[id];
    if (textError) {
      newTextErrors[id] = { ...textError, fromDepthError: error };      
    } else {
      newTextErrors[id] = { fromDepthError: error };
    }

    setTextErrors(newTextErrors);
  }

  const setFromDepthError = (newTextErrors: any, id: any, error: string | null) => {    
    let textError = newTextErrors[id];
    if (textError) {
      newTextErrors[id] = { ...textError, fromDepthError: error };      
    } else {
      newTextErrors[id] = { fromDepthError: error };
    }
  }

  const setToDepthError = (newTextErrors: any, id: any, error: string | null) => {    
    let textError = newTextErrors[id];
    if (textError) {
      newTextErrors[id] = { ...textError, toDepthError: error };      
    } else {
      newTextErrors[id] = { toDepthError: error };
    }
  }

  const updateFromAndToDepthError = (fromDepthId: any, fromDepthError: string | null, toDepthId: any, toDepthError: string | null) => {
    const newTextErrors = {...textErrors};
    let fromDepthTextError = newTextErrors[fromDepthId];
    if (fromDepthTextError) {
      newTextErrors[fromDepthId] = { ...fromDepthTextError, fromDepthError: fromDepthError };      
    } else {
      newTextErrors[fromDepthId] = { fromDepthError: fromDepthError };
    }

    let toDepthTextError = newTextErrors[toDepthId];
    if (toDepthTextError) {
      newTextErrors[toDepthId] = { ...toDepthTextError, toDepthError: toDepthError };      
    } else {
      newTextErrors[toDepthId] = { toDepthError: toDepthError };
    }

    setTextErrors(newTextErrors);
  }

  const updateToDepthError = (id: any, error: string | null) => {
    let newTextErrors = {...textErrors};
    let textError = newTextErrors[id];
    if (textError) {      
      newTextErrors[id] = { ...textError, toDepthError: error };
    } else {
      newTextErrors[id] = { toDepthError: error };
    }
    
    setTextErrors(newTextErrors);
  }

  const onChangeFromDepth = (id: any, newDepth: any | null) => {
    const fromDepth = +newDepth;
    const localCopy: any[] = Array.from(localData);
    const currentIndex = localCopy.findIndex((d: any) => d.id === id);
    var current = localCopy[currentIndex];
        
    let newTextErrors = {...textErrors};
    let errored = false;
    if (fromDepth >= current.toDepth) {  
        setFromDepthError(newTextErrors, id, toMustBeGreaterThanFrom);    
        errored = true;
    } else if (currentIndex < localCopy.length - 1) {
      if (fromDepth > localCopy[currentIndex + 1].fromDepth) {        
        setFromDepthError(newTextErrors, id, toCannotOverlapWithAnotherRange);        
        errored = true;
      }
    } 

    if (currentIndex > 0) {      
      const previous = localCopy[currentIndex - 1];
      if (fromDepth < previous.toDepth) {
        setFromDepthError(newTextErrors, id, toCannotOverlapWithAnotherRange);        
        errored = true;
      } else {
        setToDepthError(newTextErrors, previous.id, null);        
      }
    } 

    if (errored) {
      setTextErrors(newTextErrors);
      return;
    }
    
    setFromDepthError(newTextErrors, id, null);
    setTextErrors(newTextErrors);
    setFocusIndex(-1);    
  }

  const onChangeToDepth = (id: any, newDepth: any) => {    
    const toDepth = +newDepth;
    const localCopy: any[] = Array.from(localData);
    const currentIndex = localCopy.findIndex((d: any) => d.id === id);
    var current = localCopy[currentIndex];
    
    let newTextErrors = {...textErrors};
    let errored = false;
    if (toDepth <= current.fromDepth) {    
        setToDepthError(newTextErrors, id, toMustBeGreaterThanFrom);
        errored = true;
    } else {
      setFromDepthError(newTextErrors, id, null);
    }
    
    if (currentIndex < localCopy.length - 1) {
      const next = localCopy[currentIndex + 1];
      if (toDepth > next.fromDepth) {        
        setToDepthError(newTextErrors, id, toCannotOverlapWithAnotherRange);        
        errored = true;
      } else {                
        setFromDepthError(newTextErrors, next.id, null);
        setToDepthError(newTextErrors, id, null);
      }
    }

    if (errored) {
      setTextErrors(newTextErrors);
      return;
    }
    
    setToDepthError(newTextErrors, id, null);
    setTextErrors(newTextErrors);
        
    setFocusIndex(-1);   
  }

  const updateLocalData = (localCopy: any[], index: number, current: any) => {    
    setLocalData([
      ...localCopy.slice(0, index),
      current,
      ...localCopy.slice(index + 1)
    ]);
  };

  const onDeleteData = (id: any, depth: any) => {
    confirmAlert({ 
      title: 'Confirm Delete Data',
      message: `Are you sure you want to remove data at depth ${depth} m?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            deleteData(id);
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }

  const checkForErrors = () => {
    
    var keys = Object.keys(textErrors);    
    
    for (const key of keys) {      
      const textError = textErrors[key];
      if (textError.fromDepthError) {
        return true;
      }
      if (textError.toDepthError) {
        return true;
      }
      if (textError.descriptionError) {
        return true;
      }
    }
    return false;
  };

  const onSaveData = () => {
    if (checkForErrors()) {
      return;
    }
    const localCopy: any[] = Array.from(localData);
    localCopy.sort((a: any, b: any) => a.fromDepth - b.fromDepth);
    setLocalData(localCopy);

    changeData(localData);

    saveData(localData);
  };

  const onFromChange = (id: string, value: any) => {
    
    const validated = value.match(/^(\d*[.,]{0,1}\d{0,2}$)/);
    if (!validated) {
       return;
    }

    const copy = Array.from(localData);
    const dataIndex = copy.findIndex((d: any) => d.id === id);
    const data: any = copy[dataIndex];    
    const newData = {...data, fromDepth: +value};  
    updateLocalData(copy, dataIndex, newData);    
  };

  const onToChange = (id: string, value: any) => {
    const validated = value.match(/^(\d*[.,]{0,1}\d{0,2}$)/);
    if (!validated) {
       return;
    }

    const copy = Array.from(localData);
    const dataIndex = copy.findIndex((d: any) => d.id === id);
    const data: any = copy[dataIndex];    
    const newData = {...data, toDepth: +value};  
    updateLocalData(copy, dataIndex, newData);
  };

  const onSetEditMode = (editMode: boolean) => {
    setTrackEditMode(editMode);
  };

  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,          
      backgroundColor: state.isSelected ? '#AAAAAA' : '#FFFFFF',
      padding: 5,
      paddingLeft: "12px"
    }),
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 })        
  }

const anyError = checkForErrors();

  return (  
          <div>
             {/* <div className='mouse-wheel-control-text'>
                  Mouse click track:
              </div>
            <div className='mouse-wheel-control-control'>
                  <label className="mr-1" onClick={(e: any) => onSetEditMode(true)}>Edit</label>                  
                  <input type="checkbox" className="switch is-rounded is-outlined" checked={!trackEditMode} />
                  <label className="mouse-wheel-control-switch" onClick={(e: any) => onSetEditMode(!trackEditMode)}></label>

                  <label onClick={(e: any) => onSetEditMode(false)}>Add</label>
             </div> */}
            {errors.length > 0 && <Errors error={{ errors: {"": errors }}} />}
            <table className='text-edit-table'>
              <thead className='lithology-edit-header'>
                <tr>
                  <th>From (m)</th>
                  <th>To (m)</th>
                  <th>Description</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {(localData || []).map((description: any, index: number) => {
                 
                  return (
                  <tr key={description.id}>
                    <td>
                      <input type="number"
                             ref={(el) => (focusInput.current[index] = el)} 
                             className={`input ${textErrors[description.id]?.fromDepthError ? "is-danger" : ""}`} 
                             style={{maxWidth: "100px"}} 
                             value={description.fromDepth}                                 
                             onChange={(e) => onFromChange(description.id, e.target.value)}
                             onBlur={(e) => onChangeFromDepth(description.id, e.target.value)} />
                    </td> 
                    <td>
                      <input type="number"
                             ref={(el) => (focusInput.current[index] = el)} 
                             className={`input ${textErrors[description.id]?.toDepthError ? "is-danger" : ""}`} 
                             style={{maxWidth: "100px"}} 
                             value={description.toDepth}                              
                             onChange={(e) => onToChange(description.id, e.target.value)}
                             onBlur={(e) => onChangeToDepth(description.id, e.target.value)} />
                    </td> 
                    <td>
                      <TextareaAutosize
                        className={`text-track-teaxarea textarea ${textErrors[description.id]?.descriptionError ? "is-danger" : ""}`}
                        maxRows={10}
                        defaultValue={""}
                        value={description.value}
                        onChange={ev => onChangeTextValue(description.id, ev.target.value)}
                        onBlur={(e) => onBlurTextValue(description.id, e.target.value)}
                        />    
                    </td>
                    <td><button onClick={() => onDeleteData(description.id, description.startDepth)} className="button is-small" style={{ paddingLeft: "2px", paddingRight: "2px", paddingTop: 0, paddingBottom: 0, minWidth: "20px"}}><img src={"/images/icons/Atlas Delete Icon.svg"} className="" style={{fill: "black", width: "20px" }} title="Delete"/></button></td>
                  </tr>
                )})}
              </tbody>
            </table>            
            <button className='button is-primary' onClick={onSaveData} disabled={!localData?.length || anyError}>Save Data</button>
          </div>       
)}

export default TextEdit;