import React, { useEffect, useState } from "react";
import Select from "react-select";
import Slider from "./slider";
import './image-filter.scss';
import './panel.scss';
import TrackImageFilter from "./image-filter/track-image-filter";

const DEFAULT_OPTIONS = [
  {
    name: "Brightness",
    property: "brightness",
    value: 100,
    initalValue: 100,
    range: {
      min: 0,
      max: 600
    },
    unit: "%"
  },
  {
    name: "Contrast",
    property: "contrast",
    value: 100,
    initalValue: 100,
    range: {
      min: 0,
      max: 200
    },
    unit: "%"
  },
  {
    name: "Saturation",
    property: "saturate",
    value: 100,
    initalValue: 100,
    range: {
      min: 0,
      max: 200
    },
    unit: "%"
  },
  {
    name: "Grayscale",
    property: "grayscale",
    value: 0,
    initalValue: 0,
    range: {
      min: 0,
      max: 100
    },
    unit: "%"
  }
];

const ImageFilter = ({setFilter, onClose, tracks, selectedTrack, setSelectedTrack, setTrackCollapsed } : any) => {  
  const adjustableTracks = tracks?.filter((track: any) => track.canAdjustImage);
  const tracksOptions = adjustableTracks?.map((track: any) => { return { label: track.label, value: track.id } });

  const selectedTrackOption = tracksOptions?.find((track: any) => track.value === selectedTrack?.id);
  const onSetTrackCollapsed = (trackId: string, collapsed: boolean) => {
    setTrackCollapsed(trackId, collapsed);
  };
  
  return (
    <div className="right-panel-container">      
      <div className="popout-panel-heading">Adjustments <i className="fas fa-sliders-h" title="Adjustments" aria-hidden="true"></i><i className="fas fa-pencil-alt" title="Adjustments" aria-hidden="true"></i></div>
      <div></div>
      <div className="image-filter">
            {(adjustableTracks || []).map((track: any, index: number) => (
              <TrackImageFilter 
                key={index}
                track={track}
                setFilter={setFilter} 
                tracks={tracks} 
                setSelectedTrack={setSelectedTrack}
                setCollapsed={onSetTrackCollapsed}
                isSelected={track.id === selectedTrack?.id} />
            ))}            
       </div>
    </div>
  );
}

export default ImageFilter;
