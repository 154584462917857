import * as React from 'react'
import { useEffect, useState } from 'react';
import { DefaultHttpClient, HttpRequest, HttpResponse, HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { Link, useParams } from 'react-router-dom';
import Errors from '../../components/errors';
import Loader from '../../components/loader';
import { getBoreholes,         
         updateViewStyle,
         updateSortColumn,
         updateSortAscending,
         updateProjectName,
         updateBoreholeName,
         updateDepth,
         updateCreatedDate,
         updateLastEdited,
         updateCheckedEquipment,
         updateCheckedBoreholeStatuses,
         exportBoreholes,
         setClearCache,
         clearFilters,
         hubStatus,
        setError } from "../../services/boreholesSlice";
import { getClientTemplates
      } from './templateSlice';
import BoreholesList from './BoreholeListView';
import BoreholesCards from './BoreholeCardView';
import auth from '../../components/auth';
import { RootState } from '../../app/store';
import { useAppDispatch } from '../../app/hooks';
import { useSelector } from 'react-redux';
import BoreholeFilters from './filters/borehole-filters';
import BoreholeFilterLeftSideMenu from '../../components/side-menu/borehole-filter-left-side-menu';
import { useEquipmentQuery } from '../../services/lookupApi';
import { useBoreholeStatusesQuery } from '../../services/enumApi';
import BoreholesExport from './filters/boreholes-export';
import ExportPanel from '../../components/panels/log-viewer/export-panel';
import getConfig from '../../app/config';
import fetchProgress from 'fetch-progress';
import SelectField from '../../components/Input/select-field';
import ExportPanelMultiBorehole from '../../components/panels/log-viewer/export-panel-multi-borehole';

export default function Boreholes({setBorehole}: any) {    
    let { clientId, projectId } = useParams<any>();

    if (!clientId) {
      clientId = auth.getUserClientId();
    }
    
    const dispatch = useAppDispatch();

    const selectBorehole = (state: RootState) => state.borehole;
    const { 
      boreholes,      
      loading,
      error,
      continuationToken,
      nextPage,
      canAdd, 
      canEdit,
      canAccessFiles,
      viewStyle,
      sortColumn,
      sortAscending,
      projectName,
      boreholeName,
      minimumBoreholeDepth,
      maximumBoreholeDepth,
      minimumDepth,
      maximumDepth,
      dateCreatedStart,
      dateCreatedEnd,
      lastEditedStart,
      lastEditedEnd,
      checkedEquipment,
      checkedBoreholeStatuses,
      clearCache,
      hubConnected
    } = useSelector(selectBorehole);

    useEffect(() => {      
      dispatch(getClientTemplates({ templateType: 0, clientId, projectId }));      
    }, [clientId]);

    const selectAccount = (state: RootState) => state.account;
    
    const hasFilters = projectName || boreholeName || minimumDepth > minimumBoreholeDepth || maximumDepth < maximumBoreholeDepth || dateCreatedStart || dateCreatedEnd || lastEditedStart || lastEditedEnd || checkedEquipment.length > 0 || checkedBoreholeStatuses.length > 0;
    const [showLeftSidePanel, setShowLeftSidePanel] = useState<boolean>(hasFilters);

    const { data: equipments, isLoading: equipmentsIsLoading } = useEquipmentQuery();
    const { data: boreholeStatuses, isLoading: boreholeStatusesIsLoading } = useBoreholeStatusesQuery();

    const [showExportPanel, setShowExportPanel] = useState<boolean>(false);
    const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false);
    const [isExporting, setIsExporting] = useState<boolean>(false);
    const [exportCompleted, setExportCompleted] = useState<boolean>(false);
    const [exportUrl, setExportUrl] = useState<string>();
    const [exportProgress, setExportProgress] = useState<any>();
    const [exportFileName, setExportFileName] = useState<string>();
    const [connection, setConnection] = useState<any>();      
    const [selectedBoreholeIds, setSelectedBoreholeIds] = useState<any>({});
    const [pageSize, setPageSize] = useState<any>(25);
    
    // useEffect(() => {        
    //   dispatch(clearFilters());
    // }, [clientId]);

    useEffect(() => {      
      dispatch(getBoreholes({ clientId, sortColumn, sortAscending, continuationToken, nextPage, pageSize, initial: true, projectName, boreholeName, minimumDepth, maximumDepth, pageLoad: true }));      
    }, [clientId]);

    useEffect(() => {
      setBorehole(null);
    }, [])

    const startSignalRConnection = async (connection: HubConnection) =>
    {
        setConnection(connection);
        await connection
        .start()
        .then(() => {                     
            console.info("Borehole List SignalR Connected");                 
            dispatch(hubStatus({ connected: true }));
          })
        .catch((err: any) => console.error("Borehole List SignalR Connection Error: ", err))
    };

    useEffect(() => { 
      connectHubAsync();
    },[]);

    const apiUrl = getConfig().apiUrl;

    const downloadFile = (url: any, fileName: any, fileType: number = 3, updateProgress: any, completed: any) => 
    {      
      //const fileUrl = `${apiUrl}client/${clientId}/project/${projectId}/borehole/${boreholeId}/download?fileName=${encodeURIComponent(url)}&fileType=${fileType}&access_token=${auth.getTokenForImages()}`;
      const fileUrl = `${apiUrl}client/download/ClientFile/${encodeURIComponent(url)}?access_token=${auth.getTokenForImages()}`;
      //const fileUrl = `${apiUrl}client/${clientId}/project/${projectId}/borehole/${boreholeId}/download?fileName=F2bis.zip&fileType=${fileType}&access_token=${auth.getTokenForImages()}`;
      
      fetch(fileUrl, {
        method: 'GET',
        headers: new Headers({
          Authorization: "Bearer " + auth.getTokenForImages()
        })        
      })
      .then(
        fetchProgress({
          onProgress(progress: any) {
            updateProgress(progress.percentage);            
          },
          onError(err) {
            console.log(err);
          },
        })
      )      
      .then((response: any) => {
        return response.blob(); })
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link: any = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          fileName
        );
    
        // Append to html link element page
        document.body.appendChild(link);
    
        // Start download
        link.click();
    
        // Clean up and remove the link
        link.parentNode.removeChild(link);
        completed();
        
      })
      .catch((response) => {
        console.log(response);
        debugger;});

        return fileUrl;
    };

    useEffect(() => {

      const subscribeToProgress = async () => {
        if (!connection) {
          return;
        }  
        
        connection.off("ExportMultipleBoreholesProgress");
        connection.off("DownloadExportMultipleBoreholes");        
  
        connection.on("ExportMultipleBoreholesProgress", (percent: any, message: any, failed: any) => {                
          setExportProgress({ progress: percent, message, failed});
          ///dispatch(updateDataFileProgress({ percent, message, failed}));      
        });
  
        connection.on("DownloadExportMultipleBoreholes", (url: any, fileName: string) => {                  
          const mininmumPercentage = 90;
          const maxinmumPercentage = 100;
          
          setExportFileName(fileName);
          const fileUrl = downloadFile(url, fileName, 6, (percentage: number) => {                
            var progressPercentage = mininmumPercentage + ((maxinmumPercentage - mininmumPercentage) * percentage / 100);
            setExportProgress({progress: progressPercentage, message: "Completed exporting. Downloading...", failed: false});
          }, () => {
            setIsExporting(false);
            setExportCompleted(true);
           })
          
          setExportUrl(fileUrl);

          //dispatch(updateDataFileSubProgress({uploadType, percent, fileName}));        
        });
                
        //connection.invoke("SubscribeToClient", clientId);      
      }
  
      if (hubConnected) {      
        if (connection?.state === "Connected") {
          subscribeToProgress();                
        }
  
        return function cleanUp() {          
          dispatch(hubStatus({ connected: false })); 
          if (connection?.state === "Connected") {          
            //connection?.invoke("UnsubscribeFromClient", clientId);
            connection.off("ExportMultipleBoreholesProgress");
            connection.off("DownloadExportMultipleBoreholes");       
          }
        }
      }      
    }, [hubConnected, clientId]);

    const connectHubAsync = async () => {
      if (hubConnected) {
        return;
      }

      const connection = new HubConnectionBuilder()
        .withUrl(getConfig().boreholeListHubUrl, { accessTokenFactory: () => auth.getTokenForSignalR() })
        .withAutomaticReconnect({
          nextRetryDelayInMilliseconds: retryContext => {
              if (retryContext.elapsedMilliseconds < 600000) {
                  return Math.random() * 10000;
              } else {                  
                  return null;
              }
            }
        })
        .configureLogging(LogLevel.Information)
        .build();
      connection.onreconnecting(() => {         
        dispatch(hubStatus({ connected: false })); 
      });
      connection.onreconnected(() => { 
        dispatch(hubStatus({ connected: true })); 
      });
      connection.onclose(async () => {
          dispatch(hubStatus({ connected: false })); 
          console.error("Borehole List SignalR connection closed");     
          //console.error("SignalR connection closed. Retrying to connect in 5 seconds.");     
          //dispatch(reconnectingHub());
          setTimeout(async () => await startSignalRConnection(connection), 2000);
        }
      );

      await startSignalRConnection(connection);

      return connection;
    };

    const onSwitchViewList = () => {
      dispatch(updateViewStyle(1));
    };

    const onSwitchViewCard = () => {
      dispatch(updateViewStyle(0));
    };

    const tableSort = (column: string) => {
      let localSortAscending = true;
      if (sortColumn === column) {
        localSortAscending = !sortAscending; 
        dispatch(updateSortAscending({sortAscending: localSortAscending}));
      } else {
        dispatch(updateSortAscending({sortAscending: true}));
        dispatch(updateSortColumn({sortColumn: column}));
      }            

      dispatch(getBoreholes({ clientId, sortColumn: column, sortAscending: localSortAscending, continuationToken, nextPage, pageSize, initial: true, projectName, boreholeName, minimumDepth, maximumDepth, pageLoad: false }));
    };

    const loadMore = () => {
      dispatch(getBoreholes({ clientId, sortColumn, sortAscending, continuationToken, nextPage, pageSize, initial: false, projectName, boreholeName, minimumDepth, maximumDepth, pageLoad: false }));
    }

    const applyFilters = () => {      
      dispatch(getBoreholes({ clientId, sortColumn, sortAscending, continuationToken, nextPage, pageSize, initial: true, projectName, boreholeName, minimumDepth, maximumDepth, pageLoad: false }));
    };

    const clearFilters = () => {            
      dispatch(updateProjectName(""));
      dispatch(updateBoreholeName(""));
      dispatch(updateDepth({min: minimumBoreholeDepth, max: maximumBoreholeDepth}));
      dispatch(updateCreatedDate({start: null, end: null}));
      dispatch(updateLastEdited({start: null, end: null}));
      dispatch(updateCheckedEquipment([]));
      dispatch(updateCheckedBoreholeStatuses([]));
      dispatch(getBoreholes({ clientId, sortColumn, sortAscending, continuationToken, nextPage, pageSize, initial: true, projectName: null, boreholeName: null, minimumDepth: minimumBoreholeDepth, maximumDepth: maximumBoreholeDepth, pageLoad: false }));      
    };

    const onUpdateProjectName = (projectName: string) => {
      dispatch(updateProjectName(projectName));
    };

    const onUpdateBoreholeName = (boreholeName: string) => {
      dispatch(updateBoreholeName(boreholeName));
    };

    const onShowHideLeftSidePanel = () => {    
      if (showLeftSidePanel)  {        
        setShowExportPanel(false);
        setShowFilterPanel(false);
      } else {
        setShowFilterPanel(true);
      }

      setShowLeftSidePanel(!showLeftSidePanel);
    };

    const onShowFilterPanel = () => {            
      if (!showLeftSidePanel) {
        setShowLeftSidePanel(true);
      }    
      
      setShowExportPanel(false);
      setShowFilterPanel(true);
    };

    const onShowExportPanel = () => {      
      if (!showLeftSidePanel) {
        setShowLeftSidePanel(true);
      }    
      
      setIsExporting(false);
      setShowExportPanel(true);
      setShowFilterPanel(false);
    };

    const onSetDepth = (value : any) => {      
      dispatch(updateDepth(value));
    };
    
    const onSetCreatedDate = (value : any) => {
      dispatch(updateCreatedDate(value));
    };    

    const onSetLastEdited = (value : any) => {
      dispatch(updateLastEdited(value));
    };

    const onSetCheckedEquipment = (value : any) => {
      dispatch(updateCheckedEquipment(value))
    };

    const onSetCheckedBoreholeStatuses = (value : any) => {
      dispatch(updateCheckedBoreholeStatuses(value))
    };
    
    if (clientId === "list" || clientId === "add") {
      return null;
    }

    const onExportFile = (headerType: any, deliminator: any, selectedTemplateId: any, dataExportType: any, selectedLithologyTrackTypeId: number, perMeter: number, includeTo: boolean) => {                  
      if (!selectedTemplateId) {
        dispatch(setError({ error: {message: "Please select a template." }}));
        return;
      }
      const boreholeIds = getSelectedBoreholeIds();
      
      if (boreholeIds.length === 0) {
        dispatch(setError({ error: {message: "Please select at least one borehole." }}));
        return;
      }

      if (dataExportType === 1 && !perMeter) {
        dispatch(setError({ error: {message: "Please enter meters." }}));
        return;
      }

      if (dataExportType === 2 && !selectedLithologyTrackTypeId) {
        dispatch(setError({ error: {message: "Please select a lithology." }}));
        return;
      }

      dispatch(setError({ error: null}));

      setIsExporting(true);
      setExportCompleted(false);
      setExportProgress({progress: null, message: "Pending", failed: false })
      dispatch(exportBoreholes({ clientId, boreholeIds, templateId: selectedTemplateId, exportFileName, headerType, deliminator, orderByBoreholeDepth: true, dataExportType, lithologyTrackTypeId: selectedLithologyTrackTypeId, perMeter, connectionId: connection.connectionId, includeTo }));
    };

    const getSelectedBoreholeIds = () => {
      const keys = Object.keys(selectedBoreholeIds);
              
      let boreholeIds = [];
      for (var i = 0; i < keys.length; i ++)  {
          if (selectedBoreholeIds[keys[i]]) {                
            boreholeIds.push(keys[i]);
          }
      }       

      return boreholeIds;
  };

  const onChangePageSize = (value: number) => {
    setPageSize(value);
    dispatch(getBoreholes({ clientId, sortColumn, sortAscending, continuationToken, nextPage, pageSize: value, initial: true, projectName, boreholeName, minimumDepth, maximumDepth, pageLoad: false }));
  }

const leftPanelOffset = 300;

const pageSizes = [{name: "25", value: 25}, {name: "50", value: 50}, {name: "100", value: 100}];

    return (   
      <section className="section">
        {loading && <Loader/>}
        <div>       
            <div className='mb-4'>                
                  <nav className="level">
                    <div className="level-left">
                      {/* <div className="level-item">
                        <nav className="breadcrumb" aria-label="breadcrumbs">
                          <ul>
                            <li></li>
                            <li><a>{data?.clientName}</a></li>
                            <li><Link to={`/client/${clientId}/project`} >{data?.projectName}</Link></li>
                            <li className="is-active"><a href="#" aria-current="page">Boreholes</a></li>
                          </ul>                
                        </nav>     
                      </div>
                      <div className="level-item">
                        View
                      </div>
                      <div className="level-item">
                        <div className="field">
                          <div className="select">
                            <select>
                              <option value={1}>All Boreholes</option>
                            </select>
                          </div>
                        </div>
                      </div> */}
                              
                    </div>
                    <div className="level-right">
                      <div className="level-item">
                        {canAdd && <Link to={`/client/${clientId}/project/${projectId}/borehole/add`} className="button mr-4">Add Borehole</Link>}
                      </div>
                      <div className="level-item">
                        {canAdd && <Link to={`/client/${clientId}/project/add`} className="button mr-4">Add Project</Link>}
                      </div>
                      <div className="level-item">
                        Page Size
                      </div>
                      <div className="level-item">
                        <SelectField values={pageSizes} value={pageSize} onChange={onChangePageSize}/>
                      </div>
                      <div className="level-item">
                        Layout
                      </div>
                      <div className="level-item">
                        <div className={`${viewStyle === 1 ? 'view-icon-background' : ''}`}>
                          <img src='/images/icons/List view icon.svg' width={24} className="view-icon" alt="List View" onClick={onSwitchViewList}/>                    
                        </div>
                      </div>
                      <div className="level-item">
                        <div className={`${viewStyle === 0 ? 'view-icon-background' : ''}`}>
                          <img src='/images/icons/Card view.svg' className="view-icon" alt="Card View" onClick={onSwitchViewCard}/>                    
                        </div>
                      </div>        
                    </div>
                  </nav>                 
            </div>                
            
            
            <div className="left-side-panel" style={{width: showLeftSidePanel ? 300 : 0 }}>
            {showFilterPanel && <BoreholeFilters 
                showLeftSidePanel={showLeftSidePanel} 
                setShowLeftSidePanel={setShowLeftSidePanel} 
                applyFilters={applyFilters}
                clearFilters={clearFilters}
                projectName={projectName} 
                setProjectName={onUpdateProjectName} 
                boreholeName={boreholeName} 
                setBoreholeName={onUpdateBoreholeName}
                minimumBoreholeDepth={minimumBoreholeDepth}
                maximumBoreholeDepth={maximumBoreholeDepth}
                minimumDepth={minimumDepth}
                maximumDepth={maximumDepth}
                setDepth={onSetDepth}
                setCreatedDate={onSetCreatedDate}
                dateCreatedStart={dateCreatedStart}
                dateCreatedEnd={dateCreatedEnd}
                setLastEditedDate={onSetLastEdited}
                lastEditedStart={lastEditedStart}
                lastEditedEnd={lastEditedEnd}
                hasFilters={hasFilters}
                leftPanelOffset={leftPanelOffset}
                equipments={equipments?.equipments}
                checkedEquipment={checkedEquipment}
                setCheckedEquipment={onSetCheckedEquipment}
                boreholeStatuses={boreholeStatuses}
                checkedBoreholeStatuses={checkedBoreholeStatuses}
                setCheckedBoreholeStatuses={onSetCheckedBoreholeStatuses}
                />}
              {showExportPanel && <ExportPanelMultiBorehole 
                exportFile={onExportFile} 
                isExporting={isExporting} 
                setIsExporting={setIsExporting}
                progress={exportProgress}
                exportFileName={exportFileName} 
                setExportFileName={setExportFileName}
                downloaded={exportCompleted}
                url={exportUrl}
                showTemplates={true}
                 />}
              </div>     
              <BoreholeFilterLeftSideMenu 
                showLeftSidePanel={showLeftSidePanel} 
                onShowHideLeftSidePanel={onShowHideLeftSidePanel} 
                leftPanelOffset={leftPanelOffset} 
                onShowFilterPanel={onShowFilterPanel}
                onShowExportPanel={onShowExportPanel}
                showExportPanel={showExportPanel}
                showFilterPanel={showFilterPanel}
                />
              <div style={{ marginLeft: showLeftSidePanel ? leftPanelOffset + 20 : 20}} className="sidebar-main">
              <Errors error={error}/>       
                {viewStyle === 0 &&
                    <BoreholesCards boreholes={boreholes} 
                                    canEdit={canEdit} 
                                    canAccessFiles={canAccessFiles}
                                    clientId={clientId}
                                    moreToLoad={nextPage != null}
                                    loadMore={loadMore} />             
                }          
                {viewStyle === 1 && <BoreholesList boreholes={boreholes} 
                                            canEdit={canEdit} 
                                            canAccessFiles={canAccessFiles}
                                            clientId={clientId}
                                            tableSort={tableSort}
                                            sortColumn={sortColumn} 
                                            sortAscending={sortAscending}
                                            moreToLoad={nextPage != null}
                                            loadMore={loadMore}
                                            showSelection={showExportPanel}
                                            selectedBoreholeIds={selectedBoreholeIds} 
                                            setSelectedBoreholeIds={setSelectedBoreholeIds}
                                            loading={loading} />}
              </div>                 
        </div>
        </section>     
    )
  }