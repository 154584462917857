import React from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import Progress from '../../../components/progress';

const Row = ({ job, onDelete, canDelete, canCancel, onCancel }:any) => {

    return (    
        <>
            <tr>
                <td><Link to={`/client/${job.clientId}/project/${job.projectId}/borehole/${job.boreholeId}/files`}>{job.boreholeName}</Link></td>
                <td>{job.name}</td>
                <td>{job.type}</td>
                <td>{job.stateDescription}</td>
                <td>{job.priorityDescription}</td>
                <td>{job.createdBy}</td>
                <td>{format(new Date(job.createdDate), 'dd/MM/yyyy HH:mm:ss')}</td>                
                <td>{format(new Date(job.startedDate), 'dd/MM/yyyy HH:mm:ss')}</td>
                <td>{format(new Date(job.completedDate), 'dd/MM/yyyy HH:mm:ss')}</td>
            </tr>           
        </>        
    )    
};

export default Row;