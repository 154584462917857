import React from 'react';
import { Link } from 'react-router-dom';

const EditUserRoles = ({
    userName,
    onSaveUser,
    roles,
    checkedRoles,
    onChangeRoleList
    }:any) => {

    const selectedRoles = roles?.filter((role: any) => checkedRoles?.includes(role.id));
    
    var flattedSpecificResources = selectedRoles?.reduce((previousValue: any, currentValue: any) => { previousValue.push(...currentValue.projectSpecificResources); return previousValue; }, []);
    
    const groupBy = (arr : any[], criteria: string) => {
        if (!arr) {
            return null;
        }

        const newObj = arr.reduce(function (acc, currentValue) {
          if (!acc[currentValue[criteria]]) {
            acc[currentValue[criteria]] = [];
          }
          acc[currentValue[criteria]].push(currentValue);
          return acc;
        }, {});
        return newObj;
      }

    const projectResourcessGroupoedById = groupBy(flattedSpecificResources, "id");
    const projectKeys = projectResourcessGroupoedById ? Object.keys(projectResourcessGroupoedById) : [];

    var flattedResources = selectedRoles?.reduce((previousValue: any, currentValue: any) => { previousValue.push(...currentValue.resources); return previousValue; }, []);
    const uniqueResources = flattedResources?.filter(onlyUnique);    

    let finalResources: any[] = [];
    uniqueResources?.forEach((resource: any) => {
        let controller = finalResources.find((r: any) => r.name === resource.controller);
        if (controller)
        {
            controller.resources.push(resource.name);
        } else {
            finalResources.push({name: resource.controller, resources: [resource.name]});
        }
    });

    finalResources.sort((a: any, b: any) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });

    function onlyUnique(value: any, index: any, self: any) {
        return self.findIndex((v: any) => value.id === v.id) === index;
    }
    
    const clientRoles = roles;    
    

    return (
            <div className="columns">
              <div className="column is-one-quarter">              

                    <div className="field">        
                        <label className="label">{userName} Roles</label>                        
                        <div className="control">                            
                            <div className="track-type-list">
                                {(clientRoles || []).map((role: any, index: number) => (
                                    <div key={index}>
                                        <label>
                                            <input type="checkbox" value={role.id} onChange={(e) => onChangeRoleList(e)}  checked={(checkedRoles || []).includes(role.id) ? true : false}></input>&nbsp; {role.name}
                                        </label>
                                    </div>
                                ))}
                            </div>                                 
                        </div>                                  
                    </div>

                <div className="field is-grouped">
                    <div className="control">
                        <Link className='button' to={`/admin/user/list`}>Cancel</Link>                        
                    </div>
                    <div className="control">
                        <button className="button is-primary" type="submit" onClick={onSaveUser}>Save User Roles</button>
                    </div>
                </div>

              </div>
              <div className="column is-three-quarters">
                  <div className="is-size-4">Resources</div>
                       <div className="columns is-multiline">
                            {(finalResources || []).map((controller: any, controllerIndex: number) => (
                                <div key={controllerIndex} className="card">
                                    <div className="card-content">
                                        <div className="is-size-5">{controller.name}</div>
                                            {(controller.resources || []).map((resource: any, resourceIndex: number) => (
                                                <div key={resourceIndex}>
                                                    {resource}
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            ))}                             
                        </div>                   

                    <div className="is-size-4">Specific Resources</div>
                        {projectKeys?.length === 0 && <div>None</div>}
                        {projectKeys?.length > 0 &&
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>Project</th>
                                        <th className="has-text-centered">View</th>                                    
                                        <th className="has-text-centered">Update</th>
                                  </tr>
                                </thead>
                                <tbody>
                                    {((projectResourcessGroupoedById && projectKeys) || []).map((projectKey: any) => {
                                        const project = projectResourcessGroupoedById[projectKey];
                                        const projectName = project[0].specificName;
                                        const view = project.every((p: any) => p.view);
                                        const update = project.every((p: any) => p.update);
                                    return (
                                        <tr key={projectKey}>
                                            <td>{projectName}</td>                                        
                                            <td className="has-text-centered">{view ? "Allow" : "Deny"}</td>                                            
                                            <td className="has-text-centered">{update ? "Allow" : "Deny"}</td>                                            
                                        </tr>
                                    )})}
                                </tbody>
                            </table>
                        }
                   </div>
            </div>
    )    
};

export default EditUserRoles;