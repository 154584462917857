import { useState } from "react";
import rgbHex from 'rgb-hex';
import { SketchPicker } from "react-color";

const ColorPicker = (props: any) => {

    const { 
        color, 
        onChange,
        disabled,
        className
        } = props;

    const [displayColorPicker, setDisplayColorPicker] = useState<boolean>(false);

    const handleColorClick = () => {
        if (disabled) {
            return;
        }

        setDisplayColorPicker(!displayColorPicker);
    };

    const handleColorClose = () => {
        setDisplayColorPicker(false);
    };

    const convertToHex = (value: any) => {      
        return "#" + rgbHex(value.rgb.r, value.rgb.g, value.rgb.b, value.rgb.a);
      };

    const onChangeHex = (value: any) => {
        onChange(convertToHex(value));
    };

    const popover: any = {
        position: 'absolute',
        zIndex: '100',        
        right: '0px'
    }

    const cover: any = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    }

    return (
        <>
            <div className={`${className} color-picker`} style={{backgroundColor: color}} onClick={handleColorClick}></div>
                { displayColorPicker ? <div style={ popover }>
                    <div style={ cover } onClick={ handleColorClose }/>
                    {!disabled && <SketchPicker color={ color } onChangeComplete={onChangeHex} />}
                </div> : null }
        </>
    )
}
            
export default ColorPicker;