export default (props: any) =>
<svg width="100%" height="100%" viewBox="0 0 24 24" version="1.1"  {...props} >
    <title>Log Viewer</title>
    <g transform="matrix(3,0,0,1,0,0)">
        <path d="M1,0.75C1,0.336 0.888,0 0.75,0L0.25,0C0.112,0 0,0.336 0,0.75L0,23.25C0,23.664 0.112,24 0.25,24L0.75,24C0.888,24 1,23.664 1,23.25L1,0.75Z" />
    </g>
    <g transform="matrix(1.83697e-16,-3,1,6.12323e-17,-2.6967e-15,24)">
        <path d="M1,0.75C1,0.336 0.888,0 0.75,0L0.25,0C0.112,0 0,0.336 0,0.75L0,23.25C0,23.664 0.112,24 0.25,24L0.75,24C0.888,24 1,23.664 1,23.25L1,0.75Z" />
    </g>
    <g transform="matrix(1.116,0,0,1.116,-0.464553,-4.32004)">
        <path d="M5.938,19.811C5.988,19.861 6.057,19.889 6.128,19.889C6.199,19.889 6.268,19.861 6.318,19.81C6.943,19.185 8.92,17.203 9.392,16.73C9.456,16.667 9.547,16.639 9.635,16.657C10.368,16.803 13.706,17.471 13.706,17.471C14.104,17.551 14.518,17.465 14.852,17.234C15.186,17.004 15.413,16.647 15.48,16.247C15.48,16.247 16.121,12.398 16.272,11.493C16.29,11.382 16.376,11.294 16.487,11.273C17.239,11.148 20.094,10.672 20.982,10.524C21.128,10.499 21.227,10.361 21.202,10.214C21.109,9.653 20.891,8.347 20.798,7.786C20.773,7.639 20.635,7.54 20.488,7.565C19.328,7.758 14.753,8.52 14.753,8.52C14.121,8.626 13.626,9.121 13.52,9.753C13.52,9.753 12.983,12.98 12.821,13.951C12.809,14.023 12.768,14.087 12.708,14.129C12.648,14.17 12.574,14.185 12.503,14.171C11.683,14.007 9.294,13.529 9.294,13.529C8.802,13.431 8.293,13.585 7.938,13.94C7.938,13.94 4.981,16.904 4.195,17.691C4.09,17.796 4.09,17.967 4.195,18.071C4.598,18.474 5.535,19.408 5.938,19.811Z" />
    </g>
</svg>
