import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectTrackType, addTrackType, clearTrackType } from '../../../services/trackTypeSlice';

import Container from './container';

const EditTrackType = ({}:any) => {    
    const dispatch = useAppDispatch();    
    let history = useHistory();  

    const { 
        trackType,
        loading,
        error
       } = useAppSelector(selectTrackType);

    useEffect(() => { 
        dispatch(clearTrackType())}, 
    []);

    const onSave = (trackType: any) => {
        dispatch(addTrackType(trackType)).then((response: any) => {
            if (response.payload.success) {
                history.push(`/admin/track-type/list`)
            } 
           })
           .catch((response: any) => { 
           });
    };    

    return (
       <Container title="Add Track Type" 
                  trackType={trackType}                   
                  isLoading={loading}
                  onSave={onSave}
                  error={error}  />
    )    
};

export default EditTrackType;