import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../app/hooks";
import Errors from "../../../components/errors";
import Loader from "../../../components/loader";
import { getImageTypes, selectImageType } from "../../../services/imageTypeSlice";
import List from "./list";

export default function TrackTypeList() {

    const dispatch = useAppDispatch();       
    useEffect(() => {        
       dispatch(getImageTypes());                  
     }, []);

     const { 
      imageTypes,
      error,
      loading
     } = useSelector(selectImageType);
  
    return (
      <section className="section">
            <div>
                <h1>Image Types</h1>
                {loading && <Loader />}
                <Errors error={error}/>
                <List imageTypes={imageTypes} canAdd={true} />
            </div>
      </section>
    )
}
  