import React, { useState, createRef } from 'react'
import UploadProgress from '../UploadProgress/UploadProgress'
import { modifyFiles } from '../../redux/uploadFile/uploadFile.utils';
import { useImagesUploadMutation } from '../../services/uploadApi';
import Dropzone from 'react-dropzone';
import './Upload.scss';
import Progress from '../progress';

function Upload(props: any) {

    const { boreholeId, clientId, projectId, setError, fileUploaded, setIsUploading } = props;
    const [ fileProgress, setFileProgress ] = useState<any>({});    
    const [ totalFiles, setTotalFiles ] = useState<any>();    
    const [ remainingFiles, setRemainingFiles ] = useState<number>(0);    

    const inputRef = createRef();

    const acceptedImageFileTypes = ".zip,.jpg,.tif,.xml,.csv,.png";

    const setUploadFile = (data: any) => {
        setFileProgress((prevState: any) => ({
            ...modifyFiles(prevState, data),
            ...prevState
        }));
        //inputRef.current.value = '';
    };

    const setUploadProgress = (fileId: any, percentageProgress: any) => {
        setFileProgress((prevState: any) => ({
            ...prevState,
            [fileId]: {
                ...prevState[fileId],
                progress: percentageProgress,
            }
        }))
    };

    const successUploadFile = (fileId: any, response: any) => {      
        setRemainingFiles((previousState : any) => {
            return previousState - 1;
          });
        fileUploaded(response);
        setFileProgress((prevState: any) => ({
            ...prevState,
            [fileId]: {
                ...prevState[fileId],
                status: 1,
                progress: 100
            }
        }))
    };

    const failureUploadFile = (fileId: any, error: any) => {
        setRemainingFiles((previousState : any) => {
            return previousState - 1;
          });

        setError(error);
        setFileProgress((prevState: any) => ({
            ...prevState,
            [fileId]: {
                ...prevState[fileId],
                status: 2,
                progress: 1
            }
        }))
    };

    const handleAttachFile = (e: any) => {
        // could do some validation for the attached file here
        setUploadFile(e.target.files)
        //e.target.value = '' // to clear the current file
    }

    const onUploadImages = (formData: any) => {
        //uploadImages(formData);
        setUploadFile(formData);
      }

    return (
        <div>
            {remainingFiles === 0 &&
              <Dropzone                
                multiple={true}
                onDrop={(acceptedFiles, rejectedFiles) => {
                if (acceptedFiles && acceptedFiles.length > 0) {
                    // const formData = new FormData();
                    // formData.append("model", JSON.stringify({ boreholeId: boreholeId }));

                    // for (let i = 0; i < acceptedFiles.length; i++) {
                    //     formData.append("files", acceptedFiles[i])
                    // }
                    const maxFiles = 500;
                    if (acceptedFiles.length <= maxFiles) {
                        setError(null);
                        setTotalFiles(acceptedFiles.length);
                        setRemainingFiles(acceptedFiles.length);
                        onUploadImages(acceptedFiles);
                    } else {
                        setError({response : {message: `Cannot upload more than ${maxFiles} files at a time. You tried ${acceptedFiles.length}.` }});
                    }
                }

                if (rejectedFiles && rejectedFiles.length > 0) {
                    const fileName = rejectedFiles[0].file.name;
                    const lastDot = fileName.lastIndexOf(".");
                    const extention = fileName.substring(lastDot);
                    //  setUploadError("File type " + extention + " is not supported");
                }
                }}>
                {({ getRootProps, getInputProps, isDragActive }) => (
                <section className={`image-drop-area ${isDragActive ? "track-active" : ""}`}>
                    <div {...getRootProps({ className: "image-drop-area-inner" })}>
                    <input {...getInputProps()} />
                    <button className="button">Choose files…</button>
                    <div>Or drop files here</div>
                    </div>
                </section>
                )}
            </Dropzone>
            }
            {totalFiles && remainingFiles > 0 && <div>Total files {totalFiles}. Remaining files {remainingFiles}
                <Progress progress={{progress : Math.round(((totalFiles - remainingFiles) / totalFiles) * 1000) / 10 }} />
            </div>}
            <UploadProgress 
                    boreholeId={boreholeId} 
                    projectId={projectId} 
                    clientId={clientId} 
                    setUploadProgress={setUploadProgress} 
                    fileProgress={fileProgress} 
                    successUploadFile={successUploadFile} 
                    failureUploadFile={failureUploadFile}
                    setIsUploading={setIsUploading}
                    setError={setError} />
        </div>
    )
}

export default Upload;
