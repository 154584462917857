import { DefaultHttpClient, HttpRequest, HttpResponse, HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert'; 
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useAppDispatch } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import Errors from '../../../components/errors';
import Loader from '../../../components/loader';
import { getAllClientJobs, 
         deleteJob,
         cancelJob,
         updateJobProgress,
         updateJobSubProgress,
         updateJobstatus,
         addJob,
         jobHubStatus } from '../../../services/jobSlice';
import List from './list';

const ListJobs = (props: any) => {
    
  const dispatch = useAppDispatch();

  const [connection, setConnection] = useState<any>();  

  useEffect(() => {
    dispatch(getAllClientJobs());
  },[]);  

  const selectAccount = (state: RootState) => state.job;
  const { 
    allClientJobs,
    jobsLoading,
    canDelete,
    canCancel,
    error
  } = useSelector(selectAccount); 



    const onDelete = (jobId: string, jobName: string) => {
        confirmAlert({
            title: 'Confirm Delete Job',
            message: `Are you sure you want to delete job ${jobName}?`,
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                  dispatch(deleteJob({jobId}));
                }
              },
              {
                label: 'No',
                onClick: () => {}
              }
            ]
          });        
    };

    const onCancel = (jobId: string, jobName: string) => {
      confirmAlert({
          title: 'Confirm Cancel Job',
          message: `Are you sure you want to cancel job ${jobName}?`,
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                dispatch(cancelJob({jobId}));
              }
            },
            {
              label: 'No',
              onClick: () => {}
            }
          ]
        });        
  };

    
    return (
        <section className="section">
            <div>
                <div className='columns'>
                  <div className="column">
                    <h1>All Clients Jobs</h1>   
                  </div>
                  <div className="column">
                  
                  </div>
                </div>                
                
                {(jobsLoading) && <Loader/>}
                <Errors error={error}/>
                
                <List jobs={allClientJobs} 
                      canDelete={canDelete}
                      onDelete={onDelete} 
                      onCancel={onCancel}
                      canCancel={canCancel}
                          />                
            </div>
        </section>
    )    
};

export default ListJobs;