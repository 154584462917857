import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import GroupTrack from "./group-track";
import { useState } from "react";

const GroupTracks = (props: any) => {

    const {                   
      tracks,
      deleteTrack,
      deleteTracks,
      deleteAllTracks    
    } = props;    

    const [selectedTrack, setSelectedTrack] = useState<any>();
    const [selectedDeletedTracks, setSelectedDeletedTracks] = useState<any[]>([]);

    const onDeleteTrack = (trackId: any, boreholeName: any, trackName: any) => {
      deleteTrack(trackId, boreholeName, trackName);
    };

    const onDeleteTracks = () => {      
      if (selectedDeletedTracks.length) {
        deleteTracks(selectedDeletedTracks);
      } else {
        deleteAllTracks();
      }
    };    

    const onSetSelectedTrack = (groupId: any) => {
      setSelectedTrack(groupId);
    };

    const changeMarkDelete = (trackId: any, checked: boolean) => {
      const newSelectedDeletedTracks = Array.from(selectedDeletedTracks);
      const foundIndex = newSelectedDeletedTracks.findIndex((fileId: string) => fileId === trackId);
      if (foundIndex >= 0) {
        newSelectedDeletedTracks.splice(foundIndex, 1);
      } else {
        newSelectedDeletedTracks.push(trackId);
      }

      setSelectedDeletedTracks(newSelectedDeletedTracks);
    };

return(    
      <div>
        <button className="button mt-3" onClick={onDeleteTracks}><img src="/images/icons/Atlas Delete Icon.svg" title="Delete" width={20} className="mr-1" />{selectedDeletedTracks.length ? "Selected" : "All"} Tracks</button>
            {(tracks || []).map((track: any, index: number) => (
              <GroupTrack 
                key={track.trackId}
                track={track}
                isSelected={selectedTrack === track.trackId}
                setSelectedTrack={onSetSelectedTrack}
                changeMarkDelete={changeMarkDelete}
                markDelete={selectedDeletedTracks.findIndex((id:any) => id === track.trackId) >= 0}
                 />
            ))}            
    </div>  
  )};

export default GroupTracks;