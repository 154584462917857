import React from 'react';
import './meta-data.scss';
import './panel.scss';

const MetaData = (props: any) => {
    const {metaData} = props;
    return (
        <div className="meta-data-container">
            <div className="popout-panel-heading">Metadata</div>
            <div className="meta-data">
                {metaData?.depth && <div className="meta-data-item">Depth: {metaData?.depth}m</div>}
                {metaData?.boreholeDepth != null && <div className="meta-data-item">Borehole Depth: {metaData?.boreholeDepth}m</div>}
                {metaData?.loggedDepth != null && <div className="meta-data-item">Logged Depth: {metaData?.loggedDepth}m</div>}                  
                {metaData?.geotekImageType && <div className="meta-data-item">Image Type: {metaData?.geotekImageType}</div>}
                {metaData?.section && <div className="meta-data-item">Section Number: {metaData?.section}</div>}
                {metaData?.boxNumber > 0 && <div className="meta-data-item">Box Number: {metaData?.boxNumber}</div>}
                {metaData?.trackNumber > 0 && <div className="meta-data-item">Track Number: {metaData?.trackNumber}</div>}                
                {metaData?.exposureTimeMs > 0 && <div className="meta-data-item">Exposure Time Ms: {metaData?.exposureTimeMs}</div>}
                {metaData?.imageAperture > 0 && <div className="meta-data-item">Image Aperture: {metaData?.imageAperture}</div>}                  
                {metaData?.calibrationAperture > 0 && <div className="meta-data-item">Calibration Aperture: {metaData?.calibrationAperture}</div>}
                {metaData?.fileName && <div className="meta-data-item">File Name: {metaData?.fileName}</div>}
                {metaData?.height && <div className="meta-data-item">Height: {metaData?.height}cm</div>}
                {metaData?.width && <div className="meta-data-item">Width: {metaData?.width}cm</div>}
                {metaData?.pixelHeight && <div className="meta-data-item">Pixel Height: {metaData?.pixelHeight}</div>}
                {metaData?.pixelWidth && <div className="meta-data-item">Pixel Width: {metaData?.pixelWidth}</div>}
                {metaData?.verticalResolution && <div className="meta-data-item">Vertical Resolution: {metaData?.verticalResolution}</div>}
                {metaData?.horizontalResolution && <div className="meta-data-item">Horizontal Resolution: {metaData?.horizontalResolution}</div>}
            </div>
            {metaData?.hasLegend && <div><img src={metaData?.legendUrl} /></div>}            
        </div>
    )
};

export default MetaData;