import { createSlice, createAsyncThunk, current, PayloadAction } from "@reduxjs/toolkit";
import { format } from 'date-fns';
import getConfig from "../app/config";
import fetchWithRefresh from "./fetchWithRefresh";

export interface BoreholesState {
    loading: boolean,    
    boreholes: any[],    
    boreholesByProject: any[],
    continuationToken: string | null,
    nextPage: number | null;
    canAdd: boolean,
    canEdit: boolean,
    canAccessFiles: boolean,
    error: any,
    viewStyle: number,
    sortColumn: string,
    sortAscending: boolean,
    projectName: string | null,
    boreholeName: string | null,
    minimumBoreholeDepth: number | null,
    maximumBoreholeDepth: number | null,
    minimumDepth: number | null,
    maximumDepth: number | null,
    dateCreatedStart: Date | null,
    dateCreatedEnd: Date | null,
    lastEditedStart: Date | null,
    lastEditedEnd: Date | null,
    checkedEquipment: any[],
    checkedBoreholeStatuses: any[],
    clearCache: boolean,
    hubConnected: boolean
  }
  
  const initialState: BoreholesState = {
    loading: false,    
    boreholes: [],   
    boreholesByProject: [],
    continuationToken: null,
    nextPage: null,
    canAdd: false,
    canEdit: false, 
    canAccessFiles: false, 
    error: null,
    viewStyle: 1,
    sortColumn: "DateCreated",
    sortAscending: false,
    projectName: null,
    boreholeName: null,
    minimumBoreholeDepth: null,
    maximumBoreholeDepth: null,
    minimumDepth: null,
    maximumDepth: null,
    dateCreatedStart: null,
    dateCreatedEnd: null,
    lastEditedStart: null,
    lastEditedEnd: null,
    checkedEquipment: [],
    checkedBoreholeStatuses: [],
    clearCache: false, 
    hubConnected: false
  }

  
export const exportBoreholes = createAsyncThunk<any, any>(
  "boreholes/export",
  async (arg, thunkApi) => {        
    return await fetchWithRefresh(getConfig().apiUrl + `client/${arg.clientId}/borehole/export`, thunkApi, {
      method: 'POST',
      headers: {       
        'Content-Type': 'application/json',
        Accept: 'application/json',        
      },
      body: JSON.stringify(arg)
    });
  }
);

export const getBoreholes = createAsyncThunk<any, { clientId: string, 
                                                    sortColumn: string, 
                                                    sortAscending: boolean, 
                                                    continuationToken: string | null, 
                                                    nextPage: number | null,
                                                    pageSize: number, 
                                                    initial: boolean, 
                                                    pageLoad: boolean | null,
                                                    projectName: string | null, 
                                                    boreholeName: string | null,
                                                    minimumDepth: number,
                                                    maximumDepth: number}>(
    "borehole/list",
    async (arg, { getState }) => {
        const state :any = getState();
        const boreholeState = state.borehole;
        const { clientId, sortColumn, sortAscending, nextPage, continuationToken, pageSize, initial, projectName, boreholeName, minimumDepth, maximumDepth, pageLoad } = arg;
        let continuationTokenQuery = "";
        if (!initial && nextPage) {
          continuationTokenQuery = `&page=${nextPage}`;
        }
        
        let projectNameQuery = "";
        if (projectName) {
          projectNameQuery = `&projectName=${projectName}`;
        }

        let boreholeNameQuery = "";
        if (boreholeName) {
          boreholeNameQuery = `&boreholeName=${boreholeName}`;
        }
        
        let minimumDepthQuery = "";
        if (minimumDepth && boreholeState.minimumBoreholeDepth && minimumDepth !== boreholeState.minimumBoreholeDepth) {
          minimumDepthQuery = `&minimumDepth=${minimumDepth}`;
        }

        let maximumDepthQuery = "";
        if (maximumDepth && boreholeState.maximumBoreholeDepth && maximumDepth !== boreholeState.maximumBoreholeDepth) {          
          maximumDepthQuery = `&maximumDepth=${maximumDepth}`;
        }

        const dateCreatedStart = boreholeState.dateCreatedStart;
        let dateCreatedStartQuery = "";
        if (dateCreatedStart) {
          dateCreatedStartQuery = `&dateCreatedStart=${format(dateCreatedStart, "yyyy-MM-dd")}`;
        }
        
        const dateCreatedEnd = boreholeState.dateCreatedEnd;
        let dateCreatedEndQuery = "";
        if (dateCreatedEnd) {
          dateCreatedEndQuery = `&dateCreatedEnd=${format(dateCreatedEnd, "yyyy-MM-dd")}`;
        }

        const lastEditedStart = boreholeState.lastEditedStart;
        let lastEditedStartQuery = "";
        if (lastEditedStart) {
          lastEditedStartQuery = `&lastEditedStart=${format(lastEditedStart, "yyyy-MM-dd")}`;
        }
        
        const lastEditedEnd = boreholeState.lastEditedEnd;
        let lastEditedEndQuery = "";
        if (lastEditedEnd) {
          lastEditedEndQuery = `&lastEditedEnd=${format(lastEditedEnd, "yyyy-MM-dd")}`;
        }

        const equipmentIds = boreholeState.checkedEquipment;
        let equipmentIdsQuery = "";
        if (equipmentIds && equipmentIds.length > 0) {
          equipmentIdsQuery = `&equipmentIds=${equipmentIds.join()}`;
        }

        const boreholeStatuses = boreholeState.checkedBoreholeStatuses;
        let boreholeStatusesQuery = "";
        if (boreholeStatuses && boreholeStatuses.length > 0) {
          boreholeStatusesQuery = `&boreholeStatuses=${boreholeStatuses.join()}`;
        }

      return await fetchWithRefresh(getConfig().apiUrl + `client/${clientId}/borehole/${sortColumn}/${sortAscending}?pageSize=${pageSize}${continuationTokenQuery}${projectNameQuery}${boreholeNameQuery}${minimumDepthQuery}${maximumDepthQuery}${dateCreatedStartQuery}${dateCreatedEndQuery}${lastEditedStartQuery}${lastEditedEndQuery}${equipmentIdsQuery}${boreholeStatusesQuery}`);
    }
);

export const getBoreholesByProject = createAsyncThunk<any, void>(
"borehole/getBoreholesByProject",
async () => {
  return await fetchWithRefresh(getConfig().apiUrl + `borehole/ByProject`);
  }
);

const templateSlice = createSlice({
    name: 'borehole',
    initialState,
    reducers: {      
        updateViewStyle: (state, action: PayloadAction<any>) => {          
          state.viewStyle =  action.payload;
        },
        updateSortColumn: (state, action: PayloadAction<any>) => {        
            const { sortColumn } = action.payload;
            state.sortColumn =  sortColumn;
        },
        updateSortAscending: (state, action: PayloadAction<any>) => {        
            const { sortAscending } = action.payload;
            state.sortAscending =  sortAscending;
        },
        updateProjectName: (state, action: PayloadAction<any>) => {        
          const projectName = action.payload;
          state.projectName = projectName;
        },
        updateBoreholeName: (state, action: PayloadAction<any>) => {                  
          const boreholeName = action.payload;
          state.boreholeName = boreholeName;
        },
        updateDepth: (state, action: PayloadAction<any>) => {             
          const min = action.payload.min;
          const max = action.payload.max;
          state.minimumDepth = min;
          state.maximumDepth = max;
        },
        updateCreatedDate: (state, action: PayloadAction<any>) => {   
          const start = action.payload.start;
          const end = action.payload.end;
          state.dateCreatedStart = start;
          state.dateCreatedEnd = end;
        },
        updateLastEdited: (state, action: PayloadAction<any>) => {   
          const start = action.payload.start;
          const end = action.payload.end;
          state.lastEditedStart = start;
          state.lastEditedEnd = end;
        },
        updateCheckedEquipment: (state, action: PayloadAction<any>) => {                
          state.checkedEquipment = action.payload;
        },
        updateCheckedBoreholeStatuses: (state, action: PayloadAction<any>) => {      
          state.checkedBoreholeStatuses = action.payload;
        },
        setClearCache: (state, action: PayloadAction<any>) => {
          state.clearCache = action.payload;
        },
        clearFilters: (state) => {          
          state.minimumDepth = null;
          state.maximumDepth = null;
          state.sortColumn = "DateCreated";
          state.sortAscending = false;
          state.projectName = "";
          state.boreholeName = "";
          state.minimumBoreholeDepth = null;
          state.maximumBoreholeDepth = null;
          state.minimumDepth = null;
          state.maximumDepth = null;
          state.dateCreatedStart = null;
          state.dateCreatedEnd = null;
          state.lastEditedStart = null;
          state.lastEditedEnd = null;
          state.checkedEquipment = [];
          state.checkedBoreholeStatuses = [];
        },
        hubStatus: (state, action: PayloadAction<any>) => {          
          state.hubConnected = action.payload.connected;
        },
        setError: (state, action: PayloadAction<any>) => {              
          state.error = action.payload.error;
        }
    },
    extraReducers:  (builder) => {
        builder.addCase(getBoreholes.pending, (state, { meta, payload }) => {
            state.loading = true;
            state.error = null;            
            if (meta.arg.initial) {
              state.boreholes = [];  
            }          
            state.canAdd = false;
            state.canEdit = false;
            state.canAccessFiles = false;
            
            if (meta.arg.pageLoad) {
              // state.minimumDepth = null;
              // state.maximumDepth = null;
              // state.sortColumn = "DateCreated";
              // state.sortAscending = false;              
              // state.projectName = "";
              // state.boreholeName = "";
              // state.minimumBoreholeDepth = null;
              // state.maximumBoreholeDepth = null;
              // state.minimumDepth = null;
              // state.maximumDepth = null;
              // state.dateCreatedStart = null;
              // state.dateCreatedEnd = null;
              // state.lastEditedStart = null;
              // state.lastEditedEnd = null;
              // state.checkedEquipment = [];
              // state.checkedBoreholeStatuses = [];           
            }
          });
        builder.addCase(getBoreholes.fulfilled, (state, { meta, payload }) => {            
          
            state.loading = false;            
            if (!payload.success) {
             state.error = payload.errors;
            } else {
                if (meta.arg.initial) {
                    state.boreholes = payload.boreholes;
                } else {
                    payload.boreholes.forEach((borehole: any) => {
                        state.boreholes.push(borehole);
                    });
                }               
              
              state.continuationToken = payload.continuationToken;
              state.nextPage = payload.nextPage;
              state.canAdd = payload.canAdd;
              state.canEdit = payload.canEdit;
              state.canAccessFiles = payload.canAccessFiles;
              state.minimumBoreholeDepth = payload.minimumBoreholeDepth;
              state.maximumBoreholeDepth = payload.maximumBoreholeDepth;              
              if (meta.arg.pageLoad) {
                if (state.minimumDepth === null) {
                  state.minimumDepth = payload.minimumBoreholeDepth;
                }
                if (state.maximumDepth === null) {                  
                  state.maximumDepth = payload.maximumBoreholeDepth;
                }
              }
            }
          });
        builder.addCase(getBoreholes.rejected, (state, action) => {
          debugger;
            state.loading = false;
            state.error = action?.payload ?? action.error;
          });

        //getBoreholesByProject
        builder.addCase(getBoreholesByProject.pending, (state, { meta, payload }) => {
          state.loading = true;
          state.error = null;                     
        });
      builder.addCase(getBoreholesByProject.fulfilled, (state, { meta, payload }) => {
          state.loading = false;    
          state.boreholesByProject = payload.projects;
        });
      builder.addCase(getBoreholesByProject.rejected, (state, action) => {
          debugger;
          state.loading = false;
          state.error = action?.payload ?? action.error;
        });
    }    
})

export const { updateViewStyle,
               updateSortColumn,
               updateSortAscending,
               updateProjectName,
               updateBoreholeName,
               updateDepth,
               updateCreatedDate,
               updateLastEdited,
               updateCheckedEquipment,
               updateCheckedBoreholeStatuses,
               setClearCache,
               clearFilters,
               hubStatus,
               setError
               } = templateSlice.actions

export default templateSlice.reducer;