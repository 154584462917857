import React, { useEffect, useState } from 'react';
import queryString from "query-string";
import ForgotPasswordForm from './form';
import { forgotPassword } from '../../../services/accountSlice';
import Loader from '../../../components/loader';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../app/store';

const ForgotPasswordContainer = () =>{
    const queryParams: any = queryString.parse(window.location.search)
    const [emailAddress, setEmailAddress] = useState<string>('');
    const [emailAddressErrors, setEmailAddressErrors] = useState<string[] | null>(null);

    const location = useLocation<any>();
    
    useEffect(() => {
        setEmailAddress(location?.state?.emailAddress);
      }, [location]);

      useEffect(() => {
        if (queryParams?.email) {
          setEmailAddress(queryParams?.email);
        }
      }, [queryParams?.email]);
    
    
    const dispatch: AppDispatch = useDispatch();

    const selectAccount = (state: RootState) => state.account;
    const { loading
          } = useSelector(selectAccount);

    let history = useHistory();

    const onHandleSubmit = () => {
        dispatch(forgotPassword({ emailAddress }))
         .then((response: any) => {
            if (response.payload.ok || response.payload.success) {
                setEmailAddressErrors(null);
                history.push(`/login`)
            } else {
                setEmailAddressErrors(response.payload.errors["EmailAddress"]);
            }
           })
           .catch((response: any) => { 
            setEmailAddressErrors(['']);
           });
    }

    return (
        <>
            {loading && <Loader/>}
            <ForgotPasswordForm  emailAddress={emailAddress}
                setEmailAddress={setEmailAddress}            
                handleSubmit={onHandleSubmit}
                emailAddressErrors={emailAddressErrors} />
        </>
    )
    }
    
export default ForgotPasswordContainer;