import { createSlice, createAsyncThunk, current, PayloadAction } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import getConfig from "../app/config";
import fetchWithRefresh from "./fetchWithRefresh";

export const getJobs = createAsyncThunk<any, number>(
    "job/getJobs",
    async (arg) => {      
      return await fetchWithRefresh(getConfig().apiUrl + `job/list/${arg}`);
    }
);

export const getAllClientJobs = createAsyncThunk<any, void>(
  "job/getAllClientJobs",
  async (arg) => {      
    return await fetchWithRefresh(getConfig().apiUrl + `job/allClients`);
  }
);

export const deleteJob = createAsyncThunk<any, { jobId: any }>(
  "job/deleteJob",
  async (arg : any, thunkApi) => {      
    const { jobId } = arg;
    return await fetchWithRefresh(getConfig().apiUrl + `job/${jobId}`, thunkApi, {
      method: 'DELETE',
      headers: {       
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }});
  }
);

export const cancelJob = createAsyncThunk<any, { jobId: any }>(
  "job/cancelJob",
  async (arg, thunkApi) => {      
    const { jobId } = arg;
    return await fetchWithRefresh(getConfig().apiUrl + `job/${jobId}/cancel`, thunkApi, {
      method: 'PUT',
      headers: {       
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }});
  }
);

export interface BoreholeFilesState {
    jobsLoading: boolean,    
    jobs: any[],  
    allClientJobs: any[];  
    canDelete: boolean,
    canCancel: boolean,
    canUpdate: boolean,
    clientId: string | null,
    otherClientImageJobsInProgress: boolean,
    otherClientDataJobsInProgress: boolean,
    showJobPriorityPoints: boolean,
    error: any,
    jobHubConnected: boolean
  }
  
  const initialState: BoreholeFilesState = {
    jobsLoading: false,
    jobs: [],    
    allClientJobs: [],
    canDelete: false,
    canCancel: false,
    canUpdate: false,
    clientId: null,
    otherClientImageJobsInProgress: false,
    otherClientDataJobsInProgress: false,
    showJobPriorityPoints: false,
    error: null,
    jobHubConnected: false
  }

const templateSlice = createSlice({
    name: 'jobs',
    initialState,
    reducers: {            
      updateJobProgress: (state, action: PayloadAction<any>) => {  
        const {jobId, overallProgress, message, failed, warning} = action.payload;
        const job = state.jobs.find((j: any) => j.id === jobId);
        if (job) {
          job.overallProgress = overallProgress;
          job.message = message;
          job.failed = failed;
          console.log("warning", warning);
          job.warning = warning;
        }
      },
      updateJobSubProgress: (state, action: PayloadAction<any>) => {  
        const {jobId, subProgress} = action.payload;
        const job = state.jobs.find((j: any) => j.id === jobId);
        if (job) {
          job.subProgress = subProgress;          
        }
      },
      updateJobstatus: (state, action: PayloadAction<any>) => {      
        const {jobId, status, statusDescription, started, completed } = action.payload;
        const job = state.jobs.find((j: any) => j.id === jobId);
        if (job) {
          job.state = status;
          job.stateDescription = statusDescription;          
          job.startedDate = started;
          job.completedDate = completed;
        }
      },
      addJob: (state, action: PayloadAction<any>) => { 
        state.jobs.unshift(action.payload);
      },
      jobHubStatus: (state, action: PayloadAction<any>) => {
        state.jobHubConnected = action.payload.connected;
      }
    },
    extraReducers:  (builder) => {
        builder.addCase(getJobs.pending, (state, { payload }) => {
            state.jobsLoading = true;
          });
        builder.addCase(getJobs.fulfilled, (state, { payload }) => {            
            state.jobsLoading = false;            
            if (!payload.success) {
             state.error = { errors: payload.errors };
            } else {              
              state.jobs = payload.jobs;
              state.canDelete = payload.canDelete;
              state.canCancel = payload.canCancel;
              state.canUpdate = payload.canUpdate;
              state.clientId = payload.clientId;
              state.otherClientImageJobsInProgress = payload.otherClientImageJobsInProgress;
              state.otherClientDataJobsInProgress = payload.otherClientDataJobsInProgress;
              state.showJobPriorityPoints = payload.showJobPriorityPoints;
            }
          });
        builder.addCase(getJobs.rejected, (state, action) => {
              state.jobsLoading = false;
            state.error = action?.payload ?? action.error;
          });
          

        builder.addCase(getAllClientJobs.pending, (state, { payload }) => {
            state.jobsLoading = true;
          });
        builder.addCase(getAllClientJobs.fulfilled, (state, { payload }) => {            
            state.jobsLoading = false;            
            if (!payload.success) {
             state.error = { errors: payload.errors };
            } else {              
              state.allClientJobs = payload.jobs;
              state.canDelete = payload.canDelete;
              state.canCancel = payload.canCancel;
              state.canUpdate = payload.canUpdate;
            }
          });
        builder.addCase(getAllClientJobs.rejected, (state, action) => {
              state.jobsLoading = false;
            state.error = action?.payload ?? action.error;
          });


        builder.addCase(deleteJob.pending, (state, { payload }) => {
            state.jobsLoading = true;
          });
        builder.addCase(deleteJob.fulfilled, (state, action) => {            
            state.jobsLoading = false;            
            const { payload } = action;
            if (!payload.success) {
             state.error = { errors: payload.errors };
             toast.error("Error deleting job");
            } else {       
              toast.success("Successfully deleted job");
              const jobId = action.meta.arg.jobId;
              var jobIndex = state.jobs.findIndex((j: any) => j.id === jobId);
              if (jobIndex >= 0) {
                state.jobs.splice(jobIndex, 1);
              }
            }
          });
        builder.addCase(deleteJob.rejected, (state, action) => {
            state.jobsLoading = false;
            toast.error("Error deleting job");
            state.error = action?.payload ?? action.error;
          });
        
    }    
})

export const { 
              updateJobProgress,
              updateJobSubProgress,
              updateJobstatus,
              addJob,
              jobHubStatus } = templateSlice.actions

export default templateSlice.reducer;