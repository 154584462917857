import React from 'react';
import './panel.scss';

const RightPanel = (props: any) => {

    const {
        title,
        icon,
        children
    } = props;    

return(
<div className="right-panel-container">
    <div className="popout-panel-heading">{icon && <img width="24" style={{position: "relative", top: "3px"}} className="white-icon" src={`/images/icons/${icon}.svg`} /> } {title}</div>
        <div className="panel-contents">
            {children}
        </div>
   </div>
)};

export default RightPanel;