import { useState } from 'react';
import TextField from '../../../components/Input/text-field'
import Errors from '../../../components/errors';

const ChangeTwoFactorForm = (props: any) => {
    const { 
            handleSubmit,
            authenticatorCode, 
            setAuthenticatorCode,
            showRecoveryMfa,
            setShowRecoveryMfa,
            recoveryAuthenticatorCode,
            setRecoveryAuthenticatorCode,
            error
          } = props;

    const onHandleSubmit = (e: any) => {
        e.preventDefault();
        handleSubmit();
    }

    const onSetAuthenticatorCode = (value: string) => {
        setAuthenticatorCode(value);    
    }
   

    return (
        <div className="container">
            <div className="columns is-centered">
                <div className="column is-5-tablet is-4-desktop is-4-widescreen">
                    <form className="box" onSubmit={onHandleSubmit}>
                        <h4>Change two factor authentication</h4>
                        <div>                        
                            <div className='mb-2'>Enter authenticator code to change. Once you have entered this code 2FA will be removed from your account so if you do not re-add 2FA now, you will be forced to on your next login.</div>
                            {!showRecoveryMfa && <div><TextField type="text" label="Authenticator Code" value={authenticatorCode} onChange={onSetAuthenticatorCode} maxLength={6}></TextField>
                            <button className='button' onClick={() => setShowRecoveryMfa(true)}>Use recovery code</button>
                            </div>}
                            {showRecoveryMfa &&
                            <div>                                
                                <TextField                             
                                    label="Recovery Code"                             
                                    value={recoveryAuthenticatorCode} 
                                    onChange={setRecoveryAuthenticatorCode}
                                    icon="fa-lock"
                                />
                            </div>}
                            <button className='button is-primary mt-3' type="submit">Submit</button>
                        </div>                    
                        <Errors error={error}/>                    
                    </form>
                </div>
            </div>
        </div>        
    )
}

export default ChangeTwoFactorForm;