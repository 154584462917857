import React from 'react';
import { Link } from 'react-router-dom';
import TextField from '../../../components/Input/text-field';

const AddUser = ({firstName,
    lastName,
    emailAddress,
    setFirstName,
    setLastName,
    setEmailAddress,
    onSaveUser, 
    emailAddressReadOnly    
    }:any) => {

    return (
            <div className="columns">
              <div className="column is-one-quarter">
                <TextField
                    type="text" 
                    label="First Name" 
                    value={firstName} 
                    onChange={setFirstName} />

                <TextField
                    type="text" 
                    label="Last Name" 
                    value={lastName} 
                    onChange={setLastName} />

                <TextField
                    type="text" 
                    label="Email Address" 
                    className='grey-email-address'
                    value={emailAddress} 
                    onChange={setEmailAddress}
                    readOnly={emailAddressReadOnly} />

                {emailAddressReadOnly && <div className='mb-3'>Please contact Atlas Admin to change email address.</div>}

                <div className="field is-grouped">
                    <div className="control">
                        <Link className='button' to={`/admin/user/list`}>Cancel</Link>                        
                    </div>
                    <div className="control">
                        <button className="button is-primary" type="submit" onClick={onSaveUser}>Save User</button>
                    </div>
                </div>

              </div>
            </div>
    )    
};

export default AddUser;