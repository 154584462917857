import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DeleteIcon from '../../../components/icons/delete-icon';
import SelectField from '../../../components/Input/select-field';
import TextField from '../../../components/Input/text-field';
import './edit-role.scss';

const EditRoleForm = ({ name,
    setName,    
    onSaveRole,    
    controllers,
    checkedResources,
    onChangeResourcesList,
    onAddResourcesList,
    onRemoveResourcesList,
    readOnly,
    projects,
    selectedProjects,
    onAddProject,
    onRemoveProject,
    setPermission,
    showSpecificProjects
     }: any) => {
    
    const [selectedProjectId, setSelectedProjectId] = useState<any>(null);

    useEffect(() => {
        if (projects?.length > 0) {
            setSelectedProjectId(projects[0].id);
        }
    }, [projects?.length]);

    const onChangeContoller = (e: any, controller: any) => {
        const checked = e.target.checked;
        const controllerResourceIds = controller.resources.map((r: any) => r.id);
        if (checked) {
            onAddResourcesList(controllerResourceIds);
        } else {
            onRemoveResourcesList(controllerResourceIds);
        }
    };

    const controllerChecked = (controller: any) => {        
        return controller.resources.every((resource: any) => {
            return checkedResources.includes(resource.id);
        });
    };

    const projectOptions = projects?.map((project: any) => {return { name: project.name, value: project.id }});

    const onProjectChange = (projectId: any) => {
        setSelectedProjectId(projectId);
    };

    const addProject = () => {
        const project = projects.find((project: any) => project.id == selectedProjectId);
        onAddProject(project);
    }

    return (
            <div >
              
                <TextField
                  type="text" 
                  label="Name" 
                  className="role-name"
                  value={name} 
                  onChange={setName}
                  readOnly={readOnly} />
   
                <div className="field mt-2">        
                    <label className="label">Resource Permissions</label>
                    <div className="control">
                        <div className="resource-list">
                        {(controllers || []).map((controller: any, controllerIndex: number) => (
                            <div key={controllerIndex} className="resource-permission">
                                <div className='resource-permission-header'>
                                    <label>
                                        <input type="checkbox" checked={controllerChecked(controller)} onChange={(e) => onChangeContoller(e, controller)} disabled={readOnly}></input> {controller.name}
                                    </label>
                                </div>
                                {(controller.resources || []).map((resource: any, index: number) => (
                                    <div key={index}>
                                        <label>
                                            <input type="checkbox" value={resource.id} onChange={(e) => onChangeResourcesList(e)} checked={checkedResources.includes(resource.id) ? true : false} disabled={readOnly}></input>&nbsp;{resource.name}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        ))}
                        </div>
                    </div>          
                </div>
             
                  {showSpecificProjects && 
                    <div>
                        
                        <label className="label">Specific Project Permissions</label>
                        {!readOnly && <div className='select-project-permissions'>
                            <SelectField values={projectOptions} onChange={onProjectChange} value={selectedProjectId} />
                            <button className='button is-primary' onClick={addProject}>Add</button>                            
                        </div>}

                        <div>                            
                            <table className='table permissions-table'>
                                <thead>
                                    <tr>
                                        <th>Project</th>
                                        <th colSpan={2} className="has-text-centered">View</th>                                    
                                        <th colSpan={2} className="has-text-centered">Update</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th className="allow-deny-column has-text-centered">Allow</th>
                                        <th className="allow-deny-column has-text-centered">Deny</th>
                                        <th className="allow-deny-column has-text-centered">Allow</th>
                                        <th className="allow-deny-column has-text-centered">Deny</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(selectedProjects || []).map((project: any) => (
                                        <tr>
                                            <td className='project-column'>{project.name}</td>                                        
                                            <td className="has-text-centered"><input type="radio" name={`${project.id}-view`} onClick={() => setPermission(project, "view", true)} checked={project.view} disabled={readOnly}/></td>
                                            <td className="has-text-centered"><input type="radio" name={`${project.id}-view`} onClick={() => setPermission(project, "view", false)} checked={!project.view} disabled={readOnly}/></td>
                                            <td className="has-text-centered"><input type="radio" name={`${project.id}-update`} onClick={() => setPermission(project, "update", true)} checked={project.update} disabled={readOnly}/></td>
                                            <td className="has-text-centered"><input type="radio" name={`${project.id}-update`} onClick={() => setPermission(project, "update", false)} checked={!project.update} disabled={readOnly}/></td>
                                            <td>{!readOnly && <button className='button is-small' onClick={() => onRemoveProject(project)}><DeleteIcon /></button>}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div> 
                   }

                <div className="field is-grouped mt-4 mb-4">
                    <div className="control">
                        <Link className='button' to={`/admin/role/list`}>Cancel</Link>
                    </div>
                    {!readOnly && 
                        <div className="control">
                            <button className="button is-primary" type="submit" onClick={onSaveRole}>Save Role</button>
                        </div>        
                    }        
                </div>
               
        </div>
    )    
};

export default EditRoleForm;