import React, { useEffect, useRef, useState } from 'react';
import ImageColumnViewer, { originalImageWidth } from './image-column-viewer';
import OpenSeaDragonViewer from './seadragon-viewer';
import auth from '../auth';
import { v4 as uuidv4 } from 'uuid';
import { AbortError } from '@microsoft/signalr';

const CombinedImageViewer = ({ trackHeight,
  id,
  label,
  moved,
  zoom,
  center,
  setCenter,
  yOffset,
  setYOffset,
  ctrlKeyPressed,
  mouseWheelZoom,
  updateDepth,
  setZoom,
  imageFilter,
  setMetaData,
  metaData,
  imageType,
  apiUrl,
  clientId,
  projectId,
  boreholeId,
  setSelectedTrack,
  scrollIntoView }: any) => {

  const metaDatRef = useRef<any>();
  const fieldRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<any>();

  useEffect(() => {
    if (scrollIntoView && fieldRef.current) {
      fieldRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "end"
      });
    }
  }, [scrollIntoView]);

  const authToken = auth.getTokenForImages();

  const onSetZoom = (zoom: number) => {
    //setZoom(imageType, zoom);
    moved({ imageType, zoom }, true);
  };

  useEffect(() => {
    if (metaData) {
      metaDatRef.current = metaData;
      setImage({
        "mpp": 0.499,
        "name": "Wet",
        "source": {
          "Image": {
            "Format": "png",
            "Overlap": 1,
            "Size": {
              "Height": metaData.pixelHeight,
              "Width": metaData.pixelWidth
            },
            "TileSize": 256,
            "Url": `${apiUrl}client/${clientId}/project/${projectId}/borehole/${boreholeId}/image/deepZoomImage?imageType=${imageType}&url=`,
            "xmlns": "http://schemas.microsoft.com/deepzoom/2008"
          }
        }
      })
    }
  }, [metaData]);

  const [showSeaDragon, setShowSeaDragon] = useState<boolean>(true);
  const [startDepth, setStartDepth] = useState<number | null>(null);
  const [shouldUpdateDepth, setShouldUpdateDepth] = useState<boolean>(false);

  function roundHalf(num: number) {
    return Math.round(num * 2) / 2;
  }

  const zoomOutPastMaximum = (currentDepth: number) => {
    if (!metaData) {
      return;
    }

    setShowSeaDragon(false);

    const previousImages = metaData.imageSizes.filter((i: any) => i.section < currentDepth);
    let offsetY = 0;
    previousImages.forEach((imageSize: any, index: number) => {
      if (index % 2 == 0) {
        offsetY += roundHalf((originalImageWidth / imageSize.pixelHeight) * imageSize.pixelWidth * 0.5) + 2;
      }
    });

    //var offsetY2 = previousImages.reduce((previousValue:any, currentValue: any) => previousValue + ((originalImageWidth / currentValue.pixelHeight) * currentValue.pixelWidth * 0.5 * 0.5) + 2.5, 0);
    //var offsetY = (currentDepth * (originalImageHeight + 2.5) * 0.5 * 0.5);// + originalImageHeight * 0.25;        
    
    //console.log("currentDepth out", currentDepth);
    //console.log("offsetY", offsetY);
    setYOffset(-offsetY);

    moved({ imageType, zoom: 0.5, yOffset: -offsetY }, true);
  };

  const zoomInPastMinimum = (currentDepth: number) => {
    setShowSeaDragon(true);
    //onSetZoom(1);
    //console.log("currentDepth in ", currentDepth);
    
    moved({ imageType, zoom: 1 });
    setStartDepth(currentDepth);    
    setShouldUpdateDepth(true);
  };

  const onMoved = (arg: any, applyToAll: boolean) => {
    moved({ ...arg, imageType }, applyToAll);
  };

  useEffect(() => {
    if (zoom < 1 && showSeaDragon) {
      setShowSeaDragon(false);
      return;
    }

    if (zoom >= 1 && !showSeaDragon) {
      setShowSeaDragon(true);
    }
  }
    , [zoom]);

  const onSetMetaData = (sectionNumber: number, xOffset: number) => {
    let data;
    if (sectionNumber && sectionNumber >= 0 && metaDatRef.current) {
      var sectionMetaData = metaDatRef.current.metaDataByTypeBySection[sectionNumber.toString()];
      if (sectionMetaData) {
        data = { ...sectionMetaData, sectionNumber };
        if (sectionMetaData.loggedDepth != null) {
          data = { ...data, loggedDepth: formatDepth(data.loggedDepth += xOffset) }
        }
        if (sectionMetaData.boreholeDepth) {
          data = { ...data, boreholeDepth: formatDepth(data.boreholeDepth += xOffset) }
        }

      } else {
        data = { depth: formatDepth((sectionNumber - 1) + xOffset) }
      }
    }

    if (data) {
      data.hasLegend = metaData?.hasLegend;
      if (data.hasLegend) {
        data.legendUrl = `${apiUrl}client/${clientId}/project/${projectId}/borehole/${boreholeId}/image/legend?imageType=${imageType}&access_token=${authToken}`;
      }
    }

    setMetaData(data);
  }

  const formatDepth = (depth: number): string => {
    return (Math.round(depth * 1000) / 1000).toFixed(3);
  }

  const scaleImageFileNames = metaData?.scaleImageFileNames.map((fileName: any) => { return `${apiUrl}client/${clientId}/project/${projectId}/borehole/${boreholeId}/image/scaledImage?imageType=${imageType}&fileName=${fileName}&access_token=${authToken}` });
  
  return (
    <div className="image-container" ref={fieldRef}>
      <div className="sea-dragon-viewer-overlay">
        <OpenSeaDragonViewer
          image={image}
          id={id}
          moved={onMoved}
          zoom={zoom}
          center={center}
          ctrlKeyPressed={ctrlKeyPressed}
          mouseWheelZoom={mouseWheelZoom}
          trackHeight={trackHeight}
          updateDepth={updateDepth}
          zoomOutPastMaximum={zoomOutPastMaximum}
          showSeaDragon={showSeaDragon}
          startDepth={startDepth}
          shouldUpdateDepth={shouldUpdateDepth}
          setShouldUpdateDepth={setShouldUpdateDepth}
          filter={imageFilter}
          setMetaData={onSetMetaData}
          metaData={metaData}
          individualImageHeight={metaData?.individualImageHeight}
          pixelWidth={metaData?.pixelWidth}
          imageType={imageType} />
      </div>
      {!showSeaDragon &&
        <div className="image-column-viewer-overlay noselect">
          <ImageColumnViewer
            zoomInPastMinimum={zoomInPastMinimum}
            trackHeight={trackHeight}
            updateDepth={updateDepth}
            moved={onMoved}
            setZoom={onSetZoom}
            zoom={zoom}
            yOffset={yOffset}
            ctrlKeyPressed={ctrlKeyPressed}
            mouseWheelZoom={mouseWheelZoom}
            filter={imageFilter}
            setMetaData={onSetMetaData}
            metaData={metaData}
            scaleImageFileNames={scaleImageFileNames}
            scaledImageHeight={metaData?.scaledImageHeight}
            apiUrl={apiUrl}
            clientId={clientId}
            projectId={projectId}
            boreholeId={boreholeId}
            imageType={imageType}
            authToken={authToken} />
        </div>
      }
    </div>
  );
};

export default CombinedImageViewer;
