import Collapsible from "../../../components/panels/collapsible";
import 'react-date-range/dist/styles.css'; // main style file
//import 'react-date-range/dist/theme/default.css'; // theme css file
import './date-filter.scss';
import { DefinedRange, DateRangePicker, DateRange  } from 'react-date-range';
import { useState } from "react";
import { useEffect } from "react";


export default function DateFilter({label, onChange, startDate, endDate } : any) {    

    const handleSelect = (ranges: any) => {
        console.log(ranges.selection); 
        setSelectionRange(ranges.selection);
        onChange({ start: ranges.selection.startDate, end: ranges.selection.endDate})    
      }

      const onReset = () => {
        onChange({ start: null, end: null })    
      };

      const [selectionRange, setSelectionRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
        color: "rgba(0, 54, 113, 1)",   
        autoFocus: true,
        showDateDisplay: true
      });

      useEffect(() => {
        setSelectionRange({...selectionRange, startDate: startDate ?? new Date(), endDate: endDate ?? new Date()})
      }, [startDate, endDate])

    return (
        <Collapsible label={label} isSelected={startDate || endDate} leftPadding={0} showReset={startDate || endDate} reset={onReset}>
             <DateRange             
                className="date-filter"    
                 ranges={[selectionRange]}
                onChange={handleSelect}
                direction="vertical"
                weekStartsOn={1}
                maxDate={new Date()}
            />
            {/* <DefinedRange

                /> */}
        </Collapsible>
    )
}