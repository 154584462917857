import { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import './image-track.scss';
import { ResizeCallbackData } from 'react-resizable';
import lodashDebounce from 'lodash.debounce';
import TrackWrapper from './track-wrapper';
import './track-header.scss';

const ImageTrack = (props: any) => {
    const { id,       
            track,
            topMargin,
            label, 
            legend,
            legendRollover,
            depthUnit,
            apiUrl, 
            clientId, 
            projectId, 
            boreholeId, 
            startDepth, 
            endDepth, 
            imageType, 
            byBoreholeDepth, 
            authToken, 
            imageHeight,            
            headerHeight,
            minHeaderHeight,
            trackWidth,
            setTrackWidth,
            scrollIntoView, 
            trackHeaderClick,
            isSelected,            
            needsRegenerating
          } = props;

    const fieldRef = useRef<HTMLInputElement>(null);

    useEffect(() => {      
      if (scrollIntoView && fieldRef.current) {
        fieldRef.current.scrollIntoView({
          behavior: "smooth",
          block:"start",
          inline: "end"
        });
      }
    }, [scrollIntoView]);

    useEffect(() => {      
      setImageLoaded(true);
    }, [startDepth, endDepth, imageType]);

    
  const [mousePosition, setMousePosition] = useState({left:0, top:0});
  const [imageLoaded, setImageLoaded] = useState(true);

      const imageOnMouseMove = (ev: any) => {
        const { offsetTop, offsetLeft } = imageRef.current;
        const newPosition = {left: ev.pageX -60, top: ev.pageY - ev.target.offsetTop - 60};        
        setMousePosition(newPosition);
      };

      const headerPadding = () => {
        
        const totalHeight = headerHeight - 12;
        const padding = minHeaderHeight - totalHeight;
        if (padding > 0) {
          return <div style={{minHeight: padding}}></div>
        }
        
        return null;
      };

      const onResizeDebounce = (e: SyntheticEvent, data: ResizeCallbackData) => {      
        if (data.size.width) {
          setTrackWidth(id, data.size.width);
        }
      };
    
      const onResize = useCallback(lodashDebounce(onResizeDebounce, 40), []);      

      //let marginTop = (legend && !legendRollover ? 72 : topMargin);
      let marginTop = topMargin - (legend && !legendRollover ? 11 : 0);

      const imageRef = useRef<any>();

      const imageError = () => {
        setImageLoaded(false);
      };

    return (
      <>{imageLoaded && 
        <TrackWrapper trackWidth={trackWidth} 
          onResize={onResize}          
          scrollIntoView={scrollIntoView}
          editMode={isSelected}
          trackClick={trackHeaderClick}>
          <>
                {headerPadding()}
                <div className={`has-text-centered track-label ${isSelected ? "highlighted-track" : ""}`} style={{backgroundColor: track.color}}>
                  <div className="track-header">{label} {needsRegenerating && <span className="material-icons warning-icon track-header-icon" title={"Need regenerating"}>warning</span>}
                  {legend && !legendRollover && <div>
                    <img className="image-legend" src={`${apiUrl}client/${clientId}/project/${projectId}/borehole/${boreholeId}/image/legend?imageType=${imageType}&access_token=${authToken}`} />
                  </div>}            
                  </div>
                  
                </div>         
                {startDepth != null && endDepth != null &&
                  <div style={{marginTop: marginTop }} className={`${legendRollover ? 'image-tooltip' : ''}`}>
                      <img src={`${apiUrl}client/${clientId}/project/${projectId}/borehole/${boreholeId}/image?startDepth=${startDepth}&endDepth=${endDepth}&imageType=${imageType}&byBoreholeDepth=${byBoreholeDepth}&depthUnit=${depthUnit}&access_token=${authToken}`} 
                      className="borehole" 
                      onError={imageError}                     
                      style={{height: imageHeight, width: trackWidth}} 
                      onMouseMove={imageOnMouseMove} 
                      ref={imageRef}/>

                      {legend && legendRollover && <span style={{left:(mousePosition.left) + 'px' , top: (mousePosition.top) + 'px'}}>
                        <img src={`${apiUrl}client/${clientId}/project/${projectId}/borehole/${boreholeId}/image/legend?imageType=${imageType}&access_token=${authToken}`} />
                      </span>
                      }
                  </div>
                }
            </>
        </TrackWrapper>
      }</>
    )
}

export default ImageTrack;