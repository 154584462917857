import React from 'react';
import LeftSideMenu from './left-side-menu';
import SideMenuButton from './side-menu-button';

const ImageViewerLeftSideMenu = ({showLeftSidePanel, 
                        onShowHideLeftSidePanel, 
                        showDepthPanel, 
                        onShowDepthPanel,                        
                        showTemplatesPanel,
                        onShowTemplatesPanel,
                        notSaved,
                        showInfoPanel,
                        onShowInfoPanel,
                        templateSelected }: any) => {                            

    return (        
            <LeftSideMenu showLeftSidePanel={showLeftSidePanel}
                          onShowHideLeftSidePanel={onShowHideLeftSidePanel}>
                {onShowDepthPanel &&
                    <SideMenuButton highlighted={showDepthPanel} onClick={onShowDepthPanel} title="Set depth axis" icon="depth" disabled={!templateSelected}/>
                }                
                {onShowTemplatesPanel && <button className={`button is-tab button-icon mt-1 ${showTemplatesPanel ? "highlighted" : ""}`} onClick={onShowTemplatesPanel} title="Templates">
                    {notSaved && <span title="Template changed" className="badge"></span>}
                    <img src={notSaved ? "/images/icons/Atlas Save Template 2 Icon.svg" : "/images/icons/Atlas Save Template 2 Icon.svg"} />
                </button>}
                {onShowInfoPanel && 
                    <SideMenuButton highlighted={showInfoPanel} onClick={onShowInfoPanel} title="Meta data" icon="Atlas Info Icon" disabled={!templateSelected} />
                }
            </LeftSideMenu>

    )
}

export default ImageViewerLeftSideMenu;