import React from 'react';
import { Link } from 'react-router-dom';
import TextField from '../../../components/Input/text-field';

const AddUser = ({name,    
    emailAddress,    
    setEmailAddress,
    onSaveUser    
    }:any) => {

    return (
            <div className="columns">
              <div className="column is-one-quarter">              
                {name}
                <TextField
                    type="text" 
                    label="Email Address" 
                    value={emailAddress} 
                    onChange={setEmailAddress} />

                <div className="field is-grouped">
                    <div className="control">
                        <Link className='button' to={`/admin/user/list`}>Cancel</Link>                        
                    </div>
                    <div className="control">
                        <button className="button is-link" type="submit" onClick={onSaveUser}>Save Email Address</button>
                    </div>
                </div>

              </div>
            </div>
    )    
};

export default AddUser;