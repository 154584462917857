import Row from './row';
import './list.scss';

const ListTable = ({ lithologyLexicons, updateLithologyLexicon, addLithologyLexicon, deleteAddingRow, deleteLithologyLexicon }:any) => {
    return (                          
        <table className="table">
            <thead>
                <tr>
                    <th>Code</th>
                    <th>Description</th>                              
                    <th></th>
                </tr>
            </thead>
            <tbody>
            {(lithologyLexicons || []).map((lexicon: any, index: number) => (
                <Row key={lexicon.id} 
                     lexicon={lexicon} 
                     updateLithologyLexicon={updateLithologyLexicon} 
                     addLithologyLexicon={addLithologyLexicon} 
                     deleteAddingRow={deleteAddingRow}
                     deleteLithologyLexicon={deleteLithologyLexicon} />
            ))}
            </tbody>
        </table>                              
    )    
};

export default ListTable;