import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import GraphAdjustmentsTab from "./graph-adjustments-tabs";

const Group = (props: any) => {
    const {
      group,
      trackTypeCategories, 
      setSelectedTrack,
      selectedTrack,
      updateCurves,
      setShowGridlines,
      setScaleType,
      deleteTrack,
      addCurve,
      lithologyData,
      clientId,
      changeDataValue,
      changeTextData,
      saveLithologyData,
      saveTextData,
      changeDepth,      
      addDataValue,
      addTextValue,                            
      deleteDataValue,
      selectedLithologyId,
      showAdjustmentsPanel,
      setSelectedLithologyId,
      setTrackEditMode,
      trackDataNotSaved,
      setTrackCollapsed,
      boreholes,
      selected
    } = props;  

    const [collapsed, setCollapsed] = useState<boolean>(true);

    useEffect(() => {
        if (selected) {            
            setCollapsed(false);
        }
    }, [selected]);

    const onSetCollabpsed = () => {
        setCollapsed(!collapsed);
      };

      const onSetTrackCollapsed = (trackId: string, collapsed: boolean) => {
        setTrackCollapsed(trackId, collapsed, group.groupId);
      };

    const onDeleteTrack = (track: any) => {        
        deleteTrack(track.trackTypeId, track.id, group.groupId);
    };

return (
    <div>
        <div className={`group-header is-size-6 `} onClick={onSetCollabpsed}>                  
            <div className="track-order-label">{group?.groupName}</div>
            <div className="collapsible-icon">
            <Icon icon="mdi:chevron-down-circle-outline" width="24" height="24" rotate={collapsed ? 0 : 2} />
            </div>
        </div>  
        <div className="collapsible-content" style={{display: collapsed ? "none" : "inline-block", overflow: "visible" }}>
            <div>
                {group?.tracks?.map((track: any, trackIndex: number) => (
                    <GraphAdjustmentsTab key={track.id} 
                        track={track} 
                        tracks={group?.tracks} 
                        trackTypeCategories={trackTypeCategories}
                        setSelectedTrack={setSelectedTrack}
                        setCollapsed={onSetTrackCollapsed}
                        isSelected={track.id === selectedTrack?.id}
                        updateCurves={updateCurves}
                        setShowGridlines={setShowGridlines} 
                        setScaleType={setScaleType}
                        deleteTrack={() => onDeleteTrack(track)}
                        addCurve={addCurve}     
                        lithologyData={lithologyData}
                        clientId={clientId}
                        changeDataValue={changeDataValue}
                        changeTextData={changeTextData}
                        saveLithologyData={saveLithologyData}
                        saveTextData={saveTextData}
                        changeDepth={changeDepth}                  
                        addDataValue={addDataValue}
                        addTextValue={addTextValue}
                        deleteDataValue={deleteDataValue}
                        selectedLithologyId={selectedLithologyId}
                        setSelectedLithologyId={setSelectedLithologyId}
                        showAdjustmentsPanel={showAdjustmentsPanel}
                        setTrackEditMode={setTrackEditMode} 
                        dataNotSaved={trackDataNotSaved}
                        groupId={group?.groupId}
                        boreholes={boreholes}
                    />                    
                ))}
            </div>
        </div>                
    </div>
)};

export default Group;