import React, { useEffect } from 'react'
import { size, toArray } from 'lodash'
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import getConfig from "../../app/config";

import UploadItem from '../UploadItem/UploadItem'
import './UploadProgress.scss'
import auth from '../auth';

const UploadProgress = (props: any) => {
  const { boreholeId, projectId, clientId, setUploadProgress, fileProgress, successUploadFile, failureUploadFile, setIsUploading, setError } = props

  const uploadedFileAmount = size(fileProgress);

  // Function that will be called to refresh authorization
const refreshAuthLogic = (failedRequest: any) => axios.post(`${getConfig().apiUrl}account/refresh`, { 
  accessToken: auth.getToken(), 
  refreshToken: auth.getUser().refreshToken
}).then(tokenRefreshResponse => {  
  auth.setToken(tokenRefreshResponse.data);  
  failedRequest.response.config.headers['Authorization'] = 'Bearer ' + tokenRefreshResponse.data.token;
  return Promise.resolve();
});

// Instantiate the interceptor
createAuthRefreshInterceptor(axios, refreshAuthLogic);

 

  useEffect(() => {

    const uploadFile = async (files: any) => {
      if (files.length) {
  
        const prepareHeaders = (sessionToken: any) => {
          const token = auth.getToken();
          if (token) {
            return {
              'Authorization': `bearer ${token}`,
              'SessionToken': sessionToken
            };
          }
  
          return {};
        };
        let sessionToken: string | null = null;        
        for (const file of files) {
        //files.forEach(async (file: any) => {        
          if (file.file.size > 1073741824) {
            failureUploadFile(file.id, { message: `File ${file.file.name} must be less than 1GB` });
          } else {
            const formPayload = new FormData()            
            formPayload.append('model', JSON.stringify({ boreholeId, clientId, projectId, path: file.file.path, sessionToken }))
            formPayload.append('file', file.file);
          
            try {
              setIsUploading(true);
              var response: any = await axios({
                baseURL: getConfig().apiUrl,            
                headers: prepareHeaders(sessionToken),
                url: `client/${clientId}/project/${projectId}/borehole/${boreholeId}/upload`,
                method: 'post',
                data: formPayload,
                onUploadProgress: progress => {
                  const { loaded, total } = progress;
                  const percentageProgress = Math.floor((loaded / total) * 100);
                  setUploadProgress(file.id, percentageProgress);
                },
              })
              setIsUploading(false);
              sessionToken = response.data.sessionToken;              
              successUploadFile(file.id, response.data);
            } catch (error) {
              setIsUploading(false);
              failureUploadFile(file.id, error);
            }
          }
        }
      }
    }

    const fileToUpload = toArray(fileProgress).filter(file => file.progress === 0 && file.status === 0)
    uploadFile(fileToUpload).catch(console.error);
  }, [uploadedFileAmount])

  return uploadedFileAmount > 0 ? (
      <div className="wrapper">
        <h4>Uploading File{size(fileProgress) > 1 ? "s" : ""}</h4>
        {size(fileProgress)
          ? toArray(fileProgress).sort((a,b) => b.id - a.id).map(file => <UploadItem key={file.id} file={file} />)
          : null}
      </div>    
  ) : null
}

export default UploadProgress;