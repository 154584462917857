import React from "react";
import { Switch } from "react-router-dom";
import { PrivateRoute } from "../../components/privateRoute";
import ListJobs from './list-jobs/list-jobs';

const JobRoutes = ({setBorehole}: any) => {
  return (    
    <Switch>      
      <PrivateRoute exact path="/job/list">
        <ListJobs setBorehole={setBorehole} />        
      </PrivateRoute>      

    </Switch>            
  );
};

export default JobRoutes;
