import CheckboxFilter from "./checkbox-filter";

export default function BoreholeStatusesFilter({boreholeStatuses,
  checkedBoreholeStatuses,
  setCheckedBoreholeStatuses } : any) {    

    console.log("checkedBoreholeStatuses", checkedBoreholeStatuses);
    console.log("boreholeStatuses", boreholeStatuses);
    const onChangeEquipmentList = (e:any) => {   
        const value = +e.target.value;
        if (checkedBoreholeStatuses.includes(value)) {
          const newEquipments = [...checkedBoreholeStatuses].filter((id) => id !== value);
          setCheckedBoreholeStatuses(newEquipments);
        } else {
          const newEquipments = [...checkedBoreholeStatuses];
          newEquipments.push(value);
          setCheckedBoreholeStatuses(newEquipments);
        }
      }

    const onReset = () => {
      setCheckedBoreholeStatuses([]);
    };

    return (
        <CheckboxFilter label="Stage" list={boreholeStatuses} onChangeList={onChangeEquipmentList} checkedList={checkedBoreholeStatuses} onReset={onReset}/>
    )
}