import React, { useEffect, useState } from "react";

const FileUpload = (props: any) => {

    const { setFile, 
            clientLogoFileName } = props;

    const [previewImage, setPreviewImage] = useState<any>();  
    
    useEffect(() => {
        if (clientLogoFileName) {          
          setPreviewImage(clientLogoFileName);         
        }
        },[clientLogoFileName]);

    const onFileChange = (e: any) => {
        setFile(e.target.files[0]);
        const uploadedFile = URL.createObjectURL(e.target.files[0]);
        setPreviewImage(uploadedFile);
      };

    return (
       <>
            <div className="file">
            <label className="file-label">
            <input className="file-input" type="file" name="header-image" onChange={onFileChange} accept="image/*" />
            <span className="file-cta">
                <span className="file-icon">
                <i className="fas fa-upload"></i>
                </span>
                <span className="file-label">
                Choose a file…
                </span>
            </span>
            </label>
        </div>
        {previewImage && <img src={previewImage} alt="Header image" className="mt-2 header-image-preview" /> }
      </>
    );
}

export default FileUpload;