import React, { useEffect, useState } from 'react';
import { useHistory, useParams  } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; 
import { toast } from 'react-toastify';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { useProjectsQuery } from '../../services/projectApi';
import Loader from '../../components/loader';
import { useGetBoreholeQuery, useUpdateMutation, useDeleteMutation } from "../../services/boreholeApi";
import BoreholeForm from './BoreholeForm';
import Errors from '../../components/errors';
import { useBoreholeStatusesQuery } from '../../services/enumApi';
import { useEquipmentQuery } from '../../services/lookupApi';
import getConfig from "../../app/config";
import auth from '../../components/auth';

const EditBorehole = ({setBorehole} : any) => {
  let { clientId, projectId, boreholeId } = useParams<any>();
  const [name, setName] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');

  const [minLoggedDepth, setMinLoggedDepth] = useState('');
  const [maxLoggedDepth, setMaxLoggedDepth] = useState<number>();
  const [minBoreholeDepth, setMinBoreholeDepth] = useState('');
  const [maxBoreholeDepth, setMaxBoreholeDepth] = useState('');

  const [loggingStarted, setLoggingStarted] = useState();
  const [loggingEnded, setLoggingEnded] = useState();
  const [loggingTeam, setLoggingTeam] = useState();
  const [comments, setComments] = useState();
  const [checkedEquipment, setCheckedEquipment] = useState<Array<string>>([]);
  const [status, setStatus] = useState();  
  const [file, setFile] = useState<any>();
  const [clientLogoFileName, setClientLogoFileName] = useState<string>();
  const [selectedProjectId, setSelectedProjectId] = useState<string>(projectId);
  const [curated, setCurated] = useState<boolean>();
  const [useSqlServer, setUseSqlServer] = useState<boolean>(true);

  const [totalLoggedMeterage, setTotalLoggedMeterage] = useState();
  const [numberofDigitizedBoxes, setNumberofDigitizedBoxes] = useState();
  const [uploadDate, setUploadDate] = useState();
  const [resolutionBoxScan, setResolutionBoxScan] = useState();
  const [resolutionHsi, setResolutionHsi] = useState();
  const [notes, setNotes] = useState();

  let history = useHistory();

  const { data: boreholeStatuses, isLoading: boreholeStatusesIsLoading } = useBoreholeStatusesQuery();
  const { data: projects, isLoading: projectsIsLoading, error: projectError } = useProjectsQuery(clientId);
  const { data: equipments, isLoading: equipmentsIsLoading } = useEquipmentQuery();
  
  const { data, error, isLoading, isFetching } = useGetBoreholeQuery( {boreholeId, clientId, projectId });

  const apiUrl = getConfig().apiUrl;
  
  const onSetName = (name: string)  => {
    setName(name);
    setBorehole({name, id: boreholeId });
  };

  const onSetSelectedProjectId = (projectId: string) => {      
      setSelectedProjectId(projectId);
  }

  useEffect(() => {
    if (!isLoading) {
      setName(data?.name);
      setBorehole({ name: data?.name, id: boreholeId });
      setLatitude(data?.latitude);
      setLongitude(data?.longitude);
      setLoggingStarted(data?.loggingStarted?.substring(0, 10));
      setLoggingEnded(data?.loggingEnded?.substring(0, 10));
      setLoggingTeam(data?.loggingTeam);
      setComments(data?.comments);
      setStatus(data?.status);
      setSelectedProjectId(data?.projectId);      
      setCheckedEquipment(data?.equipment);
      setClientLogoFileName(`${apiUrl}client/${clientId}/download/ClientLogo/${data?.clientLogoFileName}?access_token=${auth.getTokenForImages()}`);
      setCurated(data?.curated);
      setMinLoggedDepth(data?.minLoggedDepth);
      setMaxLoggedDepth(data?.maxLoggedDepth);
      setMinBoreholeDepth(data?.minBoreholeDepth);
      setMaxBoreholeDepth(data?.maxBoreholeDepth);
      setUseSqlServer(data?.useSqlServer);
      setTotalLoggedMeterage(data?.totalLoggedMeterage);      
      setNumberofDigitizedBoxes(data?.numberOfDigitizedBoxes);
      setUploadDate(data?.uploadDate?.substring(0, 10));
      setResolutionBoxScan(data?.resolutionBoxScan);
      setResolutionHsi(data?.resolutionHsi);
      setNotes(data?.notes);
    }
  },
  [isLoading])

    const [update, { isLoading: isUpdating, error: updateError }] = useUpdateMutation();
    const [deleteBorehole, { isLoading: isDeleting }] = useDeleteMutation();

    const onUpdateBorehole = (e: any) => {
      e.preventDefault();
      update({ borehole: { clientId, 
                           projectId: selectedProjectId, 
                           boreholeId, 
                           name, 
                           latitude, 
                           longitude, 
                           loggingStarted, 
                           loggingEnded, 
                           loggingTeam, 
                           comments, 
                           status, 
                           equipment: checkedEquipment, 
                           curated, 
                           minLoggedDepth, 
                           maxLoggedDepth, 
                           minBoreholeDepth, 
                           maxBoreholeDepth,
                           totalLoggedMeterage,           
                           numberofDigitizedBoxes, 
                           uploadDate, 
                           resolutionBoxScan, 
                           resolutionHsi, 
                           notes }, file})
      .unwrap()
      .then((response: any) => {
        if (response.success) {
          history.push(`/`); 
          toast.success("Successfully updated borehole");
        }
       })      
       .catch((error) => { toast.error("Error updating borehole") });;
    };

    const onDelete = () => {
      confirmAlert({
        title: 'Confirm Deletion',
        message: `Are you sure you want to delete borehole ${name}?`,
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              deleteBorehole({clientId, projectId, boreholeId}).then((response: any) => {
                history.push(`/`) 
               });
            }
          },
          {
            label: 'No',
            onClick: () => {}
          }
        ]
      });
    };
 
    return (
      <section className="section">
        <div>
            <h1>Edit Borehole</h1>
            <Errors error={updateError || error}/>
            {(isLoading || isUpdating || boreholeStatusesIsLoading || equipmentsIsLoading || projectsIsLoading) && <Loader/>}
            <BoreholeForm 
                boreholeStatuses={boreholeStatuses}
                equipments={equipments?.equipments}
                name={name} 
                setName={onSetName} 
                latitude={latitude} 
                setLatitude={setLatitude} 
                longitude={longitude} 
                setLongitude={setLongitude} 
                minLoggedDepth={minLoggedDepth}
                setMinLoggedDepth={setMinLoggedDepth}
                maxLoggedDepth={maxLoggedDepth}
                setMaxLoggedDepth={setMaxLoggedDepth}
                minBoreholeDepth={minBoreholeDepth}
                setMinBoreholeDepth={setMinBoreholeDepth}
                maxBoreholeDepth={maxBoreholeDepth}
                setMaxBoreholeDepth={setMaxBoreholeDepth}
                loggingStarted={loggingStarted}
                setLoggingStarted={setLoggingStarted}
                loggingEnded={loggingEnded}
                setLoggingEnded={setLoggingEnded}
                loggingTeam={loggingTeam}
                setLoggingTeam={setLoggingTeam}
                comments={comments}
                setComments={setComments}
                status={status}
                setStatus={setStatus}                
                checkedEquipment={checkedEquipment}
                setCheckedEquipment={setCheckedEquipment}
                curated={curated}
                setCurated={setCurated}
                useSqlServer={useSqlServer}
                setUseSqlServer={setUseSqlServer}
                canChangeUseSqlServer={false}
                onSaveBorehole={onUpdateBorehole}
                file={file}
                setFile={setFile}                
                clientLogoFileName={clientLogoFileName}
                projects={projects?.projects}
                selectedProjectId={selectedProjectId}
                setSelectedProjectId={onSetSelectedProjectId}
                canChangeProject={true}
                canDelete={data?.canDelete}
                canUpdateDepths={data?.canUpdateDepths}
                onDelete={onDelete}
                totalLoggedMeterage={totalLoggedMeterage}
                setTotalLoggedMeterage={setTotalLoggedMeterage}
                numberofDigitizedBoxes={numberofDigitizedBoxes}
                setNumberofDigitizedBoxes={setNumberofDigitizedBoxes}
                uploadDate={uploadDate}
                setUploadDate={setUploadDate}
                resolutionBoxScan={resolutionBoxScan}
                setResolutionBoxScan={setResolutionBoxScan}
                resolutionHsi={resolutionHsi}
                setResolutionHsi={setResolutionHsi}
                notes={notes}
                setNotes={setNotes} />                  
        </div>
      </section>
    )
};

export default EditBorehole;