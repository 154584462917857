import Moment from 'react-moment';
import { Link, useParams } from 'react-router-dom';
import Errors from '../../components/errors';
import Loader from '../../components/loader';
import { useProjectsQuery } from '../../services/projectApi';
import auth from '../../components/auth';

const ProjectList = () => {
    let { clientId } = useParams<any>();

    //if clientId is null, then get it from the user
    if (!clientId) {
      clientId = auth.getUserClientId(); // currentUser?.clientId;
    }

    //const clientId = "c8a4108c-9b0a-462c-bf3e-6bc5960aa6f8";
    const { data, isLoading, error} = useProjectsQuery(clientId);

    if (isLoading) {
        return <Loader/>
    }

    return (
      <section className="section">
        <div>
          <h1>{data?.clientName} Projects</h1>
          {/* <Link to={`/client/${clientId}/project/add`} className="button mb-4">Add Project</Link> */}
          <Errors error={error}/>
          {data?.canAdd && <div className="mb-4">
              <Link to={`/client/${clientId}/project/add`} className="button">Add Project</Link>
            </div>
          }
          {!isLoading &&
          <div className="columns is-multiline">
              {(data?.projects || []).map((project: any) => { return (                    
                  <div key={project.id} className="card">
                  <div className="card-image">                       
                  </div>
                  <div className="card-content">
                    <div className="media">
                      
                      </div>
                      <div className="media-content">
                        <p className="title is-4">{project.name}</p>                       
                            {project.created &&
                              <p className="is-size-7" title={project.created}>
                                Created:&nbsp;
                                <Moment fromNow>{project.created}</Moment>

                                {project.createdBy && <> by {project.createdBy}</>}
                              </p>
                            }
                            <p className="is-size-7">
                              Boreholes:&nbsp;{project.boreholeCount}
                            </p>
                      </div>
                    </div>
                
                    <div className="content">                        
                    </div>
                  <footer className="card-footer">
                      <div className="card-footer-item">
                          <Link to={`/client/${clientId}/project/${project.id}/borehole`}>Boreholes</Link>
                      </div>
                      {project.canEdit && <div className="card-footer-item">
                          <Link to={`/client/${clientId}/project/${project.id}`}>Edit</Link>
                      </div>}
                  </footer>
                </div>)
                  })}
            </div>              
          }     
      </div>
    </section>
    )
};

export default ProjectList;