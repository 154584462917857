import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import './panel.scss';
import RightPanel from './right-panel';
import AddTrack from './add-track';
import { useTrackTypesHierarchyQuery } from '../../services/trackTypeApi';
import { useParams } from 'react-router-dom';

const AddStatisticsPanel = (props: any) => {
    const {
      addStatisticalTrack,
      deleteStatisticalTrack,
      statisticsTracks
    } = props;    

    let { boreholeId, clientId, projectId, templateId } = useParams<any>();

    const { data: trackTypesHierarchyData, isLoading: trackTypesHierarchyDataIsLoading } = useTrackTypesHierarchyQuery({ boreholeId, clientId, projectId }, { refetchOnMountOrArgChange: true });

    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [selectedTrackType, setSelectedTrackType] = useState<any>();
    const [selectedTrackCategory, setSelectedTrackCategory] = useState<any>(null);
    const [selectedTemplateTrackType, setSelectedTemplateTrackType] = useState(0);
    const [checkedTrackTypes, setCheckedTrackTypes] = useState<Array<string>>([]);
    //const [selectedTrackTypes, setSelectedTrackTypes] = useState<any[]>([]);

    const tabs = [      
      { number: 0, icon: <img src={"/images/icons/Pencil Icon.svg"} className="tab-icon" title="Edit"/>},
      { number: 1, icon: <img src={"/images/icons/Atlas Add Track Icon black.svg"} className="tab-icon" title="Add"/> },
    ];

    const onAddTrackFormSubmit = () => {

      const allTrackTypes = trackTypesHierarchyData?.trackTypeCategories.reduce((previousValue : any, currentValue: any) => {
        if (currentValue.trackTypes) {
          previousValue.push(...currentValue.trackTypes);
        }
        return previousValue;
      }, []);

      const existingIds = statisticsTracks?.map((t: any) => t.trackTypeId);
      
      const newSelectedTrackTypes = checkedTrackTypes.filter((trackTypeId: any) => !existingIds?.includes(trackTypeId) ).map((trackTypeId: any) => {
        const trackType = allTrackTypes.find((trackType: any) => trackType.id === trackTypeId);          
        const trackCategory = trackTypesHierarchyData.trackTypeCategories.find((c: any) => c.id === trackType?.trackCategoryId);        
        return { trackTypeId, displayName: trackType?.name, trackCategoryName: trackCategory?.name }
      });
      
      addStatisticalTrack(newSelectedTrackTypes);

      //setSelectedTrackTypes([...selectedTrackTypes, ...newSelectedTrackTypes]);
      setCheckedTrackTypes([]);
      setSelectedTab(0);
    };

return (
  <RightPanel title="Statistics for report" icon="calculator-svgrepo-com 2">
     <div className="tabs is-boxed panel-tab">
          <ul>
              {tabs.map((tab: any, index: number) => (
                <>
                  <li key={index} className={selectedTab == tab.number ? 'is-active' : ''} onClick={() => tab.click ? tab.click() : setSelectedTab(tab.number)}><a>{tab.icon}</a></li>
                </>
              ))}                    
          </ul>          
      </div>
      <div className="adjustment-tab-tab">
      { selectedTab === 0 && 
      <div>
        <table className='table'>
          <thead>
            <tr>
              <th>Track Category</th>
              <th>Track Type</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {statisticsTracks?.map((trackType: any) => (
              <tr>
                <td>{trackType.trackCategoryName}</td>
                <td>{trackType.displayName}</td>
                <td><img src={"/images/icons/Atlas Delete Icon.svg"} className="tab-icon is-clickable" title="Delete" onClick={() => deleteStatisticalTrack(trackType)}/></td>
              </tr>
            ))}
            </tbody>
        </table>
      </div>}
      { selectedTab === 1 && 
      
        <AddTrack
          onAddTrackFormSubmit={onAddTrackFormSubmit}
          selectedTrackType={selectedTrackType} 
          setSelectedTrackType={setSelectedTrackType}
          trackTypeCategories={trackTypesHierarchyData?.trackTypeCategories} 
          checkedTrackTypes={checkedTrackTypes}
          setCheckedTrackTypes={setCheckedTrackTypes} 
          selectedTemplateTrackType={selectedTemplateTrackType}
          setSelectedTemplateTrackType={setSelectedTemplateTrackType}                    
          selectedTrackCategory={selectedTrackCategory}
          setSelectedTrackCategory={setSelectedTrackCategory}      
          allowedTrackCategories={[0]}
          />
        }
      </div>
   </RightPanel>
)};

export default AddStatisticsPanel;