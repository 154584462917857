import { useState } from 'react';
import { SketchPicker } from 'react-color';
import { Link } from 'react-router-dom';
import rgbHex from 'rgb-hex';

const EditLookupForm = ({ code,
    color,
    setColor, 
    onSaveLookup,        
    readOnly    
     }: any) => {

    const [displayLineColorPicker, setDisplayLineColorPicker] = useState(false);

    const handleLineColorClick = () => {
        setDisplayLineColorPicker(!displayLineColorPicker);
        };

    const handleLineColorClose = () => {
        setDisplayLineColorPicker(false);
        };

    const popover: any = {
        position: 'relative',
        marginTop: "10px",
        zIndex: '100',        
        right: '0px'
        }
        const cover: any = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
        }

    const onChangeFillColor = (value: any) => {
        setColor(convertToHex(value));
    };

    const convertToHex = (value: any) => {      
        return "#" + rgbHex(value.rgb.r, value.rgb.g, value.rgb.b, value.rgb.a);
      };
    
    return (
            <div>              
                {code} 
                <div className="lookup-color-edit mr-2" style={{backgroundColor: color}} onClick={handleLineColorClick}></div>
                 { displayLineColorPicker ? <div style={ popover }>
                     <div style={ cover } onClick={ handleLineColorClose }/>
                     <SketchPicker color={ color } onChangeComplete={onChangeFillColor} />                     
                   </div> : null }

                   {color}

              <div className="field is-grouped mt-3">
                    <div className="control">
                        <Link className='button' to={`/admin/lithology/lookup/list`}>Cancel</Link>
                    </div>
                    {!readOnly && 
                        <div className="control">
                            <button className="button is-link" type="submit" onClick={onSaveLookup}>Save</button>
                        </div>        
                    }        
                </div>
        </div>
    )    
};

export default EditLookupForm;