import React, { useEffect, useRef, useState } from 'react';
import { scaleLinear } from 'd3-scale';
import { select, axisLeft } from 'd3';
import './depth-track.scss';

export default function DepthTrack(props: any) {
  const svgRef: any = useRef();

  const { startDepth, 
          endDepth,
          depthUnit, 
          availableHeight,
          minHeaderHeight,
          trackTop, 
          headerBottomPadding,
          topMargin,
          depthMajorIntervals, 
          showDepthGridLines, 
          showDepthMinorIntervals,
          depthMinorIntervals,
          deselectAllTracks,
          showValueAxisAnnotation } = props;

  const yTicks = +depthMajorIntervals;

  const parentWidth = 50;

  const margins = {
    top: minHeaderHeight + topMargin + 1,
    right: 20,
    bottom: headerBottomPadding,
    left: parentWidth,
  };

  const width = parentWidth - margins.left - margins.right;   
  //const height = windowHeight - (minHeaderHeight + 128) - margins.top - margins.bottom;  
  
  const trackHeight = availableHeight - minHeaderHeight - topMargin - headerBottomPadding - 1;
  const svgHeight = availableHeight - 5; //trackHeight + headerBottomPadding - margins.top;
  const totalWidth = width + margins.left + margins.right;

  const getYRange = () => {
    if (startDepth >= 0 && endDepth && endDepth > startDepth) {
      return [startDepth, endDepth];
    }

    return [startDepth, endDepth];
  };

  var isMajorTick = function (index: any) {
    if (!showDepthMinorIntervals) {      
      return true;
    }

    return (index % (+depthMinorIntervals + 1)) === 0;
  }

const renderChart = () => {
    const svg = select(svgRef.current);
    const yScale = scaleLinear()
    .domain(getYRange())
    .range([0, trackHeight]);

    let ticks = yTicks;
    
    if (showDepthMinorIntervals) {
      ticks += yTicks * (+depthMinorIntervals);
    }

    const yAxis: any = axisLeft(yScale).ticks(ticks)
    .tickFormat((d: any, i : number) => {
      return isMajorTick(i) ? d : "";
    });    
    svg.select(".y-axis").selectAll("*").remove();
    var y_axis = svg.select(".y-axis").call(yAxis);

    y_axis.selectAll("g")
        .filter(function (d, i) {
          return !isMajorTick(i);
        })
        .classed("minor-tick", true);
    
    svg.select(".unit")  
    .attr("transform",
          "translate(" + ((width/2) - 5)  + " ," + 
                        (-10) + ")")
    .style("text-anchor", "middle")  
    .style("font-size", "10px")  
    .style("fill", "grey")
    svg.select(".unit-text").text(depthUnit === 1 ? "m" : "ft");
}
  useEffect(() => {
    renderChart();
  }, [ availableHeight, 
      startDepth, 
      endDepth, 
      depthUnit, 
      minHeaderHeight, 
      depthMajorIntervals, 
      showDepthGridLines,
      depthMinorIntervals,
      showDepthMinorIntervals,
      showValueAxisAnnotation,
      trackTop]);

  return (
    <div className="depth-track" onClick={deselectAllTracks}>
     
        <div className={`svg-chartdepthtrack`} >
          {showDepthGridLines &&
            <svg width={totalWidth}
              height={svgHeight}>      
              <g ref={svgRef} transform={`translate(${margins.left}, ${margins.top})`}>
                <g className="unit" >
                    <text className="unit-text"></text>
                </g>
                <g className="y-axis" />
              </g>
            </svg>
          }
        </div>     
    </div>
  );  
}
