import React, { useEffect, useState } from 'react';
import FolderTree, { testData } from 'react-folder-tree';
import { useSelector } from 'react-redux';
import CheckboxTree, { Node } from 'react-checkbox-tree';
import '../../../node_modules/react-checkbox-tree/lib/react-checkbox-tree.css';
import { useAppDispatch } from '../../app/hooks';
import {  useGetBoreholeRawFilesQuery } from "../../services/boreholeApi";
import Progress from '../../components/progress';
import SmallLoader from '../../components/small-loader';
import getConfig from '../../app/config';
import auth from '../../components/auth';
import { RootState } from '../../app/store';
import formatBytes from '../../components/FormatBytes';
import { 
  getRawFiles
   } from "../../services/borehole-files-slice";

const RawFiles = (props: any) =>
{
    const { boreholeId, 
            clientId, 
            projectId, 
            setCreateZipProgress, 
            createZipProgress, 
            isDownloadingFiles,             
            setIsDownloadingFiles, 
            isDeleteingFiles,
            setIsDeletingFiles,
            checked, 
            setChecked,
            isFetchingRawFiles,
            rawFiles,
            deleteRawFiles } = props;
    //const { data: rawFiles, isFetching: isFetchingRawFiles } = useGetBoreholeRawFilesQuery({ boreholeId, clientId, projectId });  
    const dispatch = useAppDispatch();

    useEffect(() => {        
      dispatch(getRawFiles({ clientId, projectId, boreholeId }));      
    }, [boreholeId]);
    
    const [expanded, setExpanded] = useState<any>([]);
    const [totalSelectedFilesSize, setTotalSelectedFilesSize] = useState(0);

    const [nodes, setNodes] = useState<any[]>([]);
   
    useEffect(() => {
      const mapNode = (node: any): Node => {
        if (!node) {
          return { label:null, value:""};
        }
    
        return { value: node.fullName, label: node.name + "  (" + formatBytes(node.size) + ")", children: node.children === null ? null : (node.children || []).map((n: any) => mapNode(n)) }
      };
    
      const nodes : Node[] = (rawFiles || []).map((file: any) => mapNode(file));  
      setNodes(nodes);
    },
    [rawFiles]);


    useEffect(() => {
        const totalSize = (files: any[]): number => {
          let checkedFiles = files.filter((file: any) => checked.includes(file.fullName));
          var folderSize: number = checkedFiles.reduce((previousValue : any, currentValue: any) => currentValue.size + previousValue, 0);
          files.forEach((file: any) => {
            if (file.children) {
              folderSize += totalSize(file.children)
            }
          })
          
          return folderSize;
        };
    
        if (checked && rawFiles) {
          setTotalSelectedFilesSize(totalSize(rawFiles));
        }
      },
      [checked]);

      const apiUrl = getConfig().apiUrl;

  const download = (fileName: any, fileType: number = 3) => 
  {
    const fileUrl = `${apiUrl}client/${clientId}/project/${projectId}/borehole/${boreholeId}/download?fileName=${encodeURIComponent(fileName)}&fileType=${fileType}&access_token=${auth.getTokenForImages()}`;
    fetch(fileUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {       
      return response.blob();
    })
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link: any = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        fileName.replace(/^.*[\\\/]/, '')
      );
  
      // Append to html link element page
      document.body.appendChild(link);
  
      // Start download
      link.click();
  
      // Clean up and remove the link
      link.parentNode.removeChild(link);
      setChecked([]);
      setIsDownloadingFiles(false);
    });
  };

  const dowloadMultiple = (fileNames: any[]) => 
  {
    //downloadFiles({clientId, projectId, boreholeId, fileNames});
    setIsDownloadingFiles(true);
    setCreateZipProgress({percent: null, message: "Queueing", fileName: "fileName", failed: false});
    const fileUrl = `${apiUrl}client/${clientId}/project/${projectId}/borehole/${boreholeId}/download`;
    fetch(fileUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'authorization': `Bearer ${auth.getTokenForImages()}`,
      },      
      body: JSON.stringify({ fileNames })
    });    
  };

  const deleteMultiple = (fileNames: any[]) => 
  {    
    setIsDeletingFiles(true);
    deleteRawFiles(fileNames);
    setChecked([]);
  };

  const onNameClick = (node: any) => {   
    if (node.isLeaf) {
      download(node.value);
    }
  };


   
  const onCheck = (checked: any) => {      
    setChecked(checked);    
   };
 
   const onExpand = (expanded: any, targetNode: any) => {
     setExpanded(expanded);
   };
 
   const downloadSelected = () => {
     dowloadMultiple(checked);
   };

   const deleteSelected = () => {
    deleteMultiple(checked);
  };

  const selectAll = () => {    
    const fileNames = nodes.map(n => n.value);
    setChecked(fileNames);
  }

  const unselectAll = () => {        
    setChecked([]);
  }

return (
    <>
      <h4>Raw Files</h4>
      {isFetchingRawFiles && <SmallLoader />}      
      {/* {createZipProgress && <div>{createZipProgress.message}</div>} */}
      <CheckboxTree
                nodes={nodes}
                checked={checked}
                expanded={expanded}
                onCheck={onCheck}
                onExpand={onExpand}
                onClick={onNameClick}
                disabled={isDownloadingFiles || isDeleteingFiles}
      />      
      {isDownloadingFiles && <div className="mt-4"><Progress progress={{progress: createZipProgress?.percent, message: createZipProgress.message}} /></div>}
      {checked?.length > 0 && <div className="mt-2">{checked?.length} files selected {formatBytes(totalSelectedFilesSize)}</div>}
      <div className="buttons mt-4 mb-4">
        {nodes?.length > 0 && <>
          <button className="button is-primary" disabled={!checked?.length || isDownloadingFiles} onClick={downloadSelected}>Download selected</button>
          <button className="button is-danger" disabled={!checked?.length || isDeleteingFiles} onClick={deleteSelected}>Delete selected</button>
          <button className="button" disabled={isDeleteingFiles} onClick={selectAll}>Select All</button>
          <button className="button" disabled={!checked?.length || isDeleteingFiles} onClick={unselectAll}>Unselect All</button>
        </>}
      </div>
        
      {/* <div>{currentFolder?.fullName}</div>
      
      <div>
        {(folders || []).map((f: any) => ( 
          <a onClick={() => folderClick(f)}>{f.name}</a>
        ))}
      </div>
      <table className="table">
          <thead>
            <tr>
              <th>Name</th>              
              <th>Size</th>
              <th>Last Modified</th>
            </tr>
          </thead>
          <tbody>
          {(folderFiles || []).map((file: any, index: number) => (
            <tr key={index}>
              <td>
                {file.isFolder && <a onClick={() => openFolder(file)}><i className="fa fa-folder" aria-hidden="true"></i> {file.name}</a>}
                {!file.isFolder && <a download={file.name} href={`${apiUrl}client/${clientId}/project/${projectId}/borehole/${boreholeId}/download?fileName=${file.fullName}&fileType=3&access_token=${auth.getToken()}`}><i className="fa fa-file" aria-hidden="true"></i> {file.name}</a>}
              </td>
              <td>{formatBytes(file.size)}</td>
              <td><Moment format="DD/MM/YY HH:mm:ss">{file.lastModified}</Moment></td>
            </tr>
          ))}
          </tbody>
      </table> */}
</>
)
}

export default RawFiles;