import React from 'react';
import TextField from '../../../components/Input/text-field'
import { Link } from 'react-router-dom';

const UpdateUserForm = (props: any) => {

    const { firstName, 
        setFirstName, 
        lastName, 
        setLastName,
        emailAddress, 
        setEmailAddress,
        handleSubmit,        
        firstNameErrors,
        lastNameErrors,
        emailAddressErrors,
        twoFactorEnabled,
        canDisableTwoFactor } = props;

        const onHandleSubmit = (e: any) => {
            e.preventDefault();
            handleSubmit();
        }

return (
    <div className="container">
        <div className="columns is-centered">
            <div className="column is-5-tablet is-4-desktop is-4-widescreen">
                <form className="box" onSubmit={onHandleSubmit}>
                    <h4>Update Details</h4>
                    <TextField type="text" label="First Name" errors={firstNameErrors} value={firstName} onChange={setFirstName}></TextField>
                    <TextField type="text" label="Last Name" errors={lastNameErrors} value={lastName} onChange={setLastName}></TextField>
                    <TextField type="text" label="Email Address" errors={emailAddressErrors} value={emailAddress} onChange={setEmailAddress} readOnly={true}></TextField>
                    <div className='mb-3'>Please contact Atlas Admin to change email address.</div>
                    {!twoFactorEnabled && <div><Link to={`/user/enable-mfa`}>Enable two factor authentication</Link></div>}
                    {twoFactorEnabled && <div><Link to={`/user/change-mfa`}>Change two factor authentication</Link></div>}
                    {twoFactorEnabled && canDisableTwoFactor && <div><Link to={`/user/disable-mfa`}>Disable two factor authentication</Link></div>}
                    <div className="field is-grouped">
                        <div className="control mt-3">
                            <button type="submit" className="button is-primary">Update</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>        
)}

export default UpdateUserForm;