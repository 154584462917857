import Moment from "react-moment"
import { Link } from "react-router-dom"

export default function BoreholesCards({boreholes, canEdit, canAccessFiles, clientId, moreToLoad, 
  loadMore }: any) { 

    return (
      <>
        <div className="columns is-multiline">
            {(boreholes || []).map((borehole: any) => { return (                    
                    <div key={borehole.id} className="card">
                     <div className="card-image">                       
                     </div>
                     <div className="card-content">
                       <div className="media">
                        
                         </div>
                         <div className="media-content">
                           <p className="title is-4 mb-1">{borehole.name}</p>
                              {borehole.created &&
                                <p className="is-size-7" title={borehole.created}>
                                  Created:&nbsp;
                                  <Moment fromNow>{borehole.created}</Moment>
                                  {borehole.createdBy && <> by {borehole.createdBy}</>}
                                </p>
                              }
                           <p className="subtitle is-6">{borehole.maxBoreholeDepth}{borehole.maxBoreholeDepth ? "m" : <>&nbsp;</>}</p>                           
                         </div>
                       </div>
                   
                       <div className="content">                        
                       </div>
                     <footer className="card-footer">
                         <div className="card-footer-item">
                             {(borehole.maxBoreholeDepth && (borehole.scalesGenerated >= 1 || borehole.hasScaledData)) &&
                                <Link to={`/client/${clientId}/project/${borehole.projectId}/borehole/${borehole.id}/log-viewer`}>Log</Link>
                             }
                         </div>
                         <div className="card-footer-item">                    
                            {(borehole.deepZoomImagesGenerated >= 1) &&         
                              <Link to={`/client/${clientId}/project/${borehole.projectId}/borehole/${borehole.id}/image-viewer`}>Image</Link>                             
                            }
                         </div>
                         <div className="card-footer-item">
                            {canEdit && 
                              <Link to={`/client/${clientId}/project/${borehole.projectId}/borehole/${borehole.id}/edit`}>Edit</Link>
                            }
                         </div>
                         <div className="card-footer-item">
                            {canAccessFiles && 
                              <Link to={`/client/${clientId}/project/${borehole.projectId}/borehole/${borehole.id}/files`}>Files</Link>
                            }
                         </div>
                    </footer>
                   </div>)
                    })}
        </div>
        {moreToLoad && <button className="button mb-4" onClick={loadMore}>Load More</button>}
        </>
    )
}