import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { AppDispatch, RootState } from '../../../app/store';
import { addGroup, 
         addBorehole, 
         updateGroupOrder, 
         deleteGroup, 
         addTracks, 
         deleteTrack, 
         deleteTracks,
         deleteAllTracks,
         updateGroupTrackOrder, 
         updateGroupName,
         updateGroupColor } from "../../../services/viewsSlice";
import RightPanel from '../right-panel';
import AddBorehole from './add-borehole';
import ListGroups from './list-groups';
import { confirmAlert } from 'react-confirm-alert';
import { getMultipleBoreholeTrackDataForTrackTypes } from "../../../features/borehole/templateSlice";

const BoreholeCorrelationPanel = (props: any) => {
    const {             
       onClose,       
       boreholesByProject,
       clientId,
       projectId,
       boreholeId,
       tracks,
       addBoreholes,
       pixelHeight,
       startDepth,
       endDepth,
       byBoreholeDepth,
       depthUnit
    } = props;    

    const tabs = [
      { number: 0, display: true, icon: <img src={"/images/icons/Atlas Add Track Icon black.svg"} className="tab-icon" title="Add Borehole"/> },
      { number: 1, display: true, icon: <img src={"/images/icons/group-list.svg"} className="tab-icon" title="Groups"/> },      
    ];

    const dispatch: AppDispatch = useDispatch();
    const [selectedTab, setSelectedTab] = useState<number>(0);

    const selectView = (state: RootState) => state.views;
    const {       
      view
    } = useSelector(selectView);

    useEffect(() => {
      if (view?.groups?.length) {
        setSelectedTab(1);
      }
    },[]);

    const onAddBoreholeFormSubmit = (boreholes: any) => {      
      addBoreholes(boreholes);    
      setSelectedTab(1);
    };

    const onAddTracks = (groupId: any, boreholeId: any, boreholeName: any, projectName: any, tracks: any[], trackCategoryName: any) => {      
      dispatch(addTracks({groupId, tracks: tracks.map((track: any) => { 
        // const trackType = allTrackTypes.find((trackType: any) => trackType.id === trackTypeId);
        //   return { trackTypeId, displayName: trackType?.name, allowedCurveTypes: trackType?.allowedCurveTypes, units: trackType?.units }
        
        return { 
          boreholeName, 
          boreholeId, 
          projectName, 
          trackName: track.trackName, 
          trackId: track.trackTypeId, 
          trackTypeId: track.trackTypeId, 
          trackCategoryName, 
          curves: track.curves, 
          trackType: track.trackType,
          imageType: track.imageType,
          allowedCurveTypes: track.allowedCurveTypes, 
          units: track.units }
        })
      }));

      //const boreholeTrackTypes = tracks.filter((t: any) => !t.imageType).map((track: any) => { return { boreholeId, trackTypeId: track.trackTypeId }});
      const boreholeTrackTypes: any[] = [];
      tracks.filter((t: any) => !t.imageType).forEach((track: any) => {
        boreholeTrackTypes.push({ boreholeId, trackTypeId: track.trackTypeId });
        track.curves?.forEach((curve: any) => {
          boreholeTrackTypes.push({ boreholeId, trackTypeId: curve.trackTypeId });
        });
      });
      const boreholeImageTypes: any[] = tracks.filter((t: any) => t.imageType).map((track: any) => { return { boreholeId, imageTypeId: track.imageType }});            
      dispatch(getMultipleBoreholeTrackDataForTrackTypes({ 
        clientId,
        boreholeTrackTypes,
        boreholeImageTypes,
        pixelHeight,
        startDepth,
        endDepth,
        byBoreholeDepth,
        depthUnit }));
    };

    const onAddGroup = (groupName: string) => {
      dispatch(addGroup({ groupName }));
    };

    const onUpdateGroupOrder = (sourceIndex: number, destinationIndex: number) => {
      dispatch(updateGroupOrder({sourceIndex, destinationIndex}));
    };

    const onUpdateTrackOrder = (groupId: any, sourceIndex: number, destinationIndex: number) => {
      dispatch(updateGroupTrackOrder({groupId, sourceIndex, destinationIndex}));
    };    

    const onDeleteGroup = (groupId: any, name: string) => {
      confirmAlert({
        title: 'Delete Group',
        message: `Are you sure you want to delete ${name}?`,
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              dispatch(deleteGroup({groupId}));
            }
          },
          {
            label: 'No',
            onClick: () => {}
          }
        ]
      });
    };
    
    const onDeleteTrack = (groupId: any, trackId: any, boreholeName: any, trackName: any) => {      
      confirmAlert({
        title: 'Delete Track',
        message: `Are you sure you want to delete track ${trackName} from group ${boreholeName}?`,
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              dispatch(deleteTrack({groupId, trackId}));
            }
          },
          {
            label: 'No',
            onClick: () => {}
          }
        ]
      });
    };

    const onDeleteTracks = (groupId: any, trackIds: any, groupName: any) => {
      confirmAlert({
        title: 'Delete Track',
        message: `Are you sure you want to delete selected tracks from group ${groupName}?`,
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              dispatch(deleteTracks({groupId, trackIds}));
            }
          },
          {
            label: 'No',
            onClick: () => {}
          }
        ]
      });
    };

    const onDeleteAllTracks = (groupId: any, groupName: any) => {
      confirmAlert({
        title: 'Delete Track',
        message: `Are you sure you want to delete all tracks from group ${groupName}?`,
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              dispatch(deleteAllTracks({groupId}));
            }
          },
          {
            label: 'No',
            onClick: () => {}
          }
        ]
      });
    };

    const onChangeGroupName = (groupId: any, name: string) => {
      dispatch(updateGroupName({ groupId, name }));
    };

    const onChangeGroupColor = (groupId: any, color: string) => {
      dispatch(updateGroupColor({ groupId, color }));
    };

return(
  
  <RightPanel title="Borehole Correlation" icon="Correlation Icon">
    <div className="add-track-panel-contents">
      <div className="tabs is-boxed panel-tab">
          <ul>
              {tabs.map((tab: any, index: number) => (
                <>
                  {tab.display && <li key={index} className={selectedTab == tab.number ? 'is-active' : ''} onClick={() => tab.click ? tab.click() : setSelectedTab(tab.number)}><a>{tab.icon}</a></li> }
                </>
              ))}                    
          </ul>          
      </div>
      <div className="mt-2">
        {selectedTab === 0 &&
          <AddBorehole
            onAddBoreholeFormSubmit={onAddBoreholeFormSubmit}          
            onClose={onClose}          
            boreholesByProject={boreholesByProject}
            projectId={projectId}
            boreholeId={boreholeId}
            />
        }
        {selectedTab === 1 &&
            <ListGroups 
                groups={view.groups} 
                updateGroupOrder={onUpdateGroupOrder} 
                addGroup={onAddGroup} 
                deleteGroup={onDeleteGroup} 
                deleteTrack={onDeleteTrack}
                deleteTracks={onDeleteTracks}
                deleteAllTracks={onDeleteAllTracks}
                clientId={clientId}
                projectId={projectId}
                boreholeId={boreholeId}
                addTracks={onAddTracks}
                updateTrackOrder={onUpdateTrackOrder}
                changeGroupName={onChangeGroupName}
                changeGroupColor={onChangeGroupColor} />
        }
       </div>
    </div>
    </RightPanel>
)};

export default BoreholeCorrelationPanel;