import React from "react";
import { Switch } from "react-router-dom";
import { PrivateRoute } from "../../components/privateRoute";
import ListTrackType from './list/image-type-list';
import EditImageType from './edit-image-type/edit';
import AddTrackType from './edit-image-type/add';

const ImageTypeRoutes = () => {
  return (    
    <Switch>      
      <PrivateRoute exact path="/admin/image-type/list">
        <ListTrackType />        
      </PrivateRoute>   
      <PrivateRoute exact path="/admin/image-type/edit/:imageTypeId">
        <EditImageType />        
      </PrivateRoute>   
      <PrivateRoute exact path="/admin/image-type/add">
        <AddTrackType />        
      </PrivateRoute>   
    </Switch>            
  );
};

export default ImageTypeRoutes;
