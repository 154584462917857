import { DefaultHttpClient, HttpRequest, HttpResponse, HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert'; 
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useAppDispatch } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import Errors from '../../../components/errors';
import Loader from '../../../components/loader';
import { getJobs, 
         deleteJob,
         cancelJob,
         updateJobProgress,
         updateJobSubProgress,
         updateJobstatus,
         addJob,
         jobHubStatus } from '../../../services/jobSlice';
import List from './list';
import getConfig from "../../../app/config";
import auth from '../../../components/auth';
import { createAsyncThunk } from '@reduxjs/toolkit';

const ListRoles = ({setBorehole}:any) => {
    
  const dispatch = useAppDispatch();

  const [connection, setConnection] = useState<any>();  

  useEffect(() => {
    setBorehole(null);
    onGetJobs(14);
  },[]);  

  const selectAccount = (state: RootState) => state.job;
  const { 
    jobs,
    jobsLoading,
    canDelete,
    canCancel,
    clientId,
    otherClientImageJobsInProgress,
    otherClientDataJobsInProgress,
    error,
    jobHubConnected,
    showJobPriorityPoints
  } = useSelector(selectAccount); 

  const onGetJobs = (days: number) => {
    dispatch(getJobs(days));
  };

  const startSignalRConnection = async (connection: HubConnection) =>
  {
      setConnection(connection);
      await connection
      .start()
      .then(() => {                     
          console.info("Job SignalR Connected");     
          dispatch(jobHubStatus({ connected: true }));
        })
      .catch((err: any) => console.error("Job SignalR Connection Error: ", err))
  };

class CustomHttpClient extends DefaultHttpClient {
    public send(request: HttpRequest): Promise<HttpResponse> {
      request.headers = { ...request.headers, "authorization": auth.getTokenForSignalR() };
      return super.send(request);
    }
  }

const connectHubAsync = async () => {
      const connection = new HubConnectionBuilder()
        .withUrl(getConfig().jobhubUrl, { accessTokenFactory: () => auth.getTokenForSignalR() })
        .withAutomaticReconnect({
          nextRetryDelayInMilliseconds: retryContext => {
              if (retryContext.elapsedMilliseconds < 600000) {
                  return Math.random() * 10000;
              } else {                  
                  return null;
              }
            }
        })
        .configureLogging(LogLevel.Information)
        .build();
      connection.onreconnecting(() => {         
        dispatch(jobHubStatus({ connected: false })); 
      });
      connection.onreconnected(() => { 
        dispatch(jobHubStatus({ connected: true })); 
      });
      connection.onclose(async () => {
          dispatch(jobHubStatus({ connected: false })); 
          console.error("Job SignalR connection closed");     
          //console.error("SignalR connection closed. Retrying to connect in 5 seconds.");     
          //dispatch(reconnectingHub());
          setTimeout(async () => await startSignalRConnection(connection), 2000);
        }
      );

      await startSignalRConnection(connection);

      return connection;
    };

  const reconnectHub = createAsyncThunk<any, void>(
      "account/reconnectHub",
      async () => {
      }
  )
  
  const reconnectingHub = createAsyncThunk<any, void>(
    "account/reconnectingHub",
    async () => {
    }
  )


    useEffect(() => { 
      connectHubAsync();
    },[]);


  useEffect(() => {
    
    const subscribeToProgress = async () => {
      if (!jobHubConnected) {
        return;
      }

      connection.off("UpdateJobProgress");
      connection.off("UpdateJobSubProgress");
      connection.off("UpdateJobStatus");
      connection.off("AddJob");

      connection.on("UpdateJobProgress", (jobId: string, overallProgress: any, message: any, failed: boolean, warning: boolean) => {    
        
        dispatch(updateJobProgress({jobId, overallProgress, message, failed, warning}));        
      });

      connection.on("UpdateJobSubProgress", (jobId: string, subProgress: any) => {    
        dispatch(updateJobSubProgress({jobId, subProgress}));
      });

      connection.on("UpdateJobStatus", (jobId: string, status: any, statusDescription: string, started: any, completed: any) => {            
        dispatch(updateJobstatus({jobId, status, statusDescription, started, completed }));
      });
      
      connection.on("AddJob", (job: any) => {
        dispatch(addJob(job));
      });      
    
      connection.invoke("SubscribeToClient", clientId);      
    }

    if (jobHubConnected && clientId) {      
      
      if (connection?.state === "Connected") {
        subscribeToProgress();                
      }

      return function cleanUp() {
        if (connection?.state === "Connected") {
          connection?.invoke("UnsubscribeFromClient", clientId);
        }
      }
    }
    
  }, [jobHubConnected, clientId]);
    

    const onDelete = (jobId: string, jobName: string) => {
        confirmAlert({
            title: 'Confirm Delete Job',
            message: `Are you sure you want to delete job ${jobName}?`,
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                  dispatch(deleteJob({jobId}));
                }
              },
              {
                label: 'No',
                onClick: () => {}
              }
            ]
          });        
    };

    const onCancel = (jobId: string, jobName: string) => {
      confirmAlert({
          title: 'Confirm Cancel Job',
          message: `Are you sure you want to cancel job ${jobName}?`,
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                dispatch(cancelJob({jobId}));
              }
            },
            {
              label: 'No',
              onClick: () => {}
            }
          ]
        });        
  };

    
    return (
        <section className="section">
            <div>
                <div className='columns'>
                  <div className="column">
                    <h1>Jobs</h1>   
                  </div>
                  <div className="column">
                    <div className='is-pulled-right'>
                      Hub Connected:
                      <div className={`hub-state ${jobHubConnected ? "has-background-success" : "has-background-danger" }`}></div>
                    </div>
                  </div>
                </div>                
                
                {(jobsLoading) && <Loader/>}
                <Errors error={error}/>
                
                <List jobs={jobs} 
                      canDelete={canDelete}
                      onDelete={onDelete} 
                      onCancel={onCancel}
                      canCancel={canCancel}
                      otherClientImageJobsInProgress={otherClientImageJobsInProgress}
                      otherClientDataJobsInProgress={otherClientDataJobsInProgress}
                      showJobPriorityPoints={showJobPriorityPoints}
                      onGetJobs={onGetJobs}
                          />                
            </div>
        </section>
    )    
};

export default ListRoles;