import Select from 'react-select';

const Templates = (props: any) => {

    const {        
        builtInTemplateOptions,
        userTemplateOptions,
        boreholeTemplateOptions,
        projectTemplateOptions,
        clientTemplateOptions,
        onChangeBuiltInTemplate,
        onChangeUserTemplate,
        onChangeBoreholeTemplate,
        onChangeProjectTemplate,
        onChangeClientTemplate,
        selectedTemplateId      
      } = props; 


    const customStyles = {
        option: (provided: any, { data, isDisabled, isFocused, isSelected }:any) => ({
          ...provided,          
          backgroundColor: isSelected ? 'rgba(0, 54, 113, 1)' : '#FFFFFF',
          color: isDisabled
            ? '#ccc'
            : isSelected ? "white" : data.color,
          cursor: isDisabled ? 'not-allowed' : 'default',
          padding: 5,
          paddingLeft: "12px",
          "&:hover": {
            backgroundColor: "rgba(0, 54, 113, 0.3)"
          }
        })        
      };

      let selectedBuiltInTemplateIdValue = null,   
    selectedUserTemplateIdValue = null,
    selectedBoreholeTemplateIdValue = null,
    selectedProjectTemplateIdValue = null,
    selectedClientTemplateIdValue = null;

    const getSelectedTemplateId = () => {
      let selectedTemplateIdValue: any = null;
      if (selectedTemplateId) {
        selectedTemplateIdValue = builtInTemplateOptions.find((o:any) => o.value == selectedTemplateId);
        if (selectedTemplateIdValue) {
          selectedBuiltInTemplateIdValue = selectedTemplateIdValue;        
          return;
        } else {
          selectedTemplateIdValue = userTemplateOptions.find((o:any) => o.value == selectedTemplateId);
        }
        if (selectedTemplateIdValue) {
          selectedUserTemplateIdValue = selectedTemplateIdValue;
          return;
        } else {
          selectedTemplateIdValue = boreholeTemplateOptions.find((o:any) => o.value == selectedTemplateId);
        }
        if (selectedTemplateIdValue) {
          selectedBoreholeTemplateIdValue = selectedTemplateIdValue;
          return;
        } else {
          selectedTemplateIdValue = projectTemplateOptions.find((o:any) => o.value == selectedTemplateId);
        }
        if (selectedTemplateIdValue) {
          selectedProjectTemplateIdValue = selectedTemplateIdValue;
          return;
        } else {
          selectedTemplateIdValue = clientTemplateOptions.find((o:any) => o.value == selectedTemplateId);
        }

        if (selectedTemplateIdValue) {
          selectedClientTemplateIdValue = selectedTemplateIdValue;
        }
    }};

    getSelectedTemplateId();

return(
<div className="field">
          <label className="label">Available Templates</label>
          <div>
            Default
            <div className="control">
                <Select options={builtInTemplateOptions} styles={customStyles} onChange={onChangeBuiltInTemplate} value={selectedBuiltInTemplateIdValue} classNamePrefix="select"></Select>
            </div>
          </div>
          <div className='mt-2'>
            User
            <div className="control">
                <Select options={userTemplateOptions} styles={customStyles} onChange={onChangeUserTemplate} value={selectedUserTemplateIdValue} classNamePrefix="select"></Select>                         
            </div>
          </div>
          <div className='mt-2'>
            Borehole
            <div className="control">
                <Select options={boreholeTemplateOptions} styles={customStyles} onChange={onChangeBoreholeTemplate} value={selectedBoreholeTemplateIdValue} classNamePrefix="select"></Select>                         
            </div>
          </div>
          <div className='mt-2'>
            Project
            <div className="control">
                <Select options={projectTemplateOptions} styles={customStyles} onChange={onChangeProjectTemplate} value={selectedProjectTemplateIdValue} classNamePrefix="select"></Select>                         
            </div>
          </div>
          <div className='mt-2'>
            Client
            <div className="control">
                <Select options={clientTemplateOptions} styles={customStyles} onChange={onChangeClientTemplate} value={selectedClientTemplateIdValue} classNamePrefix="select"></Select>                         
            </div>
          </div>
      </div>
)
}

export default Templates;