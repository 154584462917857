import { Link } from 'react-router-dom';
import TextField from '../../../components/Input/text-field';

const EditLexiconForm = ({ code,
    setCode,
    description,
    setDescription, 
    onSaveLexicon,        
    readOnly    
     }: any) => {
    
    return (
            <div>
              
                <TextField
                  type="text" 
                  label="Code" 
                  value={code} 
                  onChange={setCode}
                  readOnly={readOnly} />
   
                <TextField
                  type="text" 
                  label="Description" 
                  value={description} 
                  onChange={setDescription}
                  readOnly={readOnly} />

              <div className="field is-grouped">
                    <div className="control">
                        <Link className='button' to={`/admin/lithology/lexicon/list`}>Cancel</Link>
                    </div>
                    {!readOnly && 
                        <div className="control">
                            <button className="button is-link" type="submit" onClick={onSaveLexicon}>Save</button>
                        </div>        
                    }        
                </div>            
        </div>
    )    
};

export default EditLexiconForm;