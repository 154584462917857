import Panel from '../panel';
import Errors from '../../errors';
import Progress from '../../progress';
import { useState } from 'react';
import ClientTemplates from './client-templates';
import ExportPanelBase from './export-panel-base';

const ExportPanel = (props: any) => {
  const {      
    isExporting,
    setIsExporting,
    exportFile,
    error,
    progress,
    exportFileName,
    setExportFileName,
    downloaded,
    url,
    showTemplates
  } = props;   

  const [headerType, setHeaderType] = useState<any>(0);
  const [delimator, setDeliminator] = useState<any>(" ");
  const [selectedTemplateId, setSelectedTemplateId] = useState<any>(0);
  const [dataExportType, setSataExportType] = useState<any>(0);
  const [selectedLithologyTrackType, setSelectedLithologyTrackTypeId] = useState<any>();
  const [perMeter, setPerMeter] = useState<any>();
  
const onHandleOkExportModal = (e: any) =>{
  e.preventDefault();  
  exportFile(headerType, delimator, selectedTemplateId, dataExportType, selectedLithologyTrackType?.value, perMeter);
}
const setTemplateName = (name: any) => {
  setExportFileName(name + ".csv");
};

return(
  <Panel title="Export" icon="Export Icon">
    <form onSubmit={onHandleOkExportModal}>
        <div>
            <div className="panel-section">
              <ExportPanelBase 
                  exportFileName={exportFileName} 
                  setExportFileName={setExportFileName}
                  headerType={headerType}
                  setHeaderType={setHeaderType}
                  delimator={delimator}
                  setDeliminator={setDeliminator}
                />
                
                {showTemplates && <ClientTemplates              
                    selectedTemplateId={selectedTemplateId}
                    setSelectedTemplateId={setSelectedTemplateId}
                    setTemplateName={setTemplateName}
                  />}
                
                {isExporting && <Progress progress={progress} /> }
                {downloaded && (
                  <div className='mb-2'>
                    The file should automatically download, if not click <a href={url} target="_blank" download={exportFileName}>here</a>.
                  </div>
                )}
                
                {error && <Errors error={{ data: { errors: error}}} />} 
      
                <button className="button is-light mt-2" type="submit" onClick={onHandleOkExportModal} disabled={isExporting}>Export</button>
            </div>
       </div>
    </form>
  </Panel>
)};

export default ExportPanel;