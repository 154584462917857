import React, { useState } from 'react';
import * as QueryString from "query-string"
import ResetPasswordForm from './form';
import { resetPassword } from '../../../services/accountSlice';
import Loader from '../../../components/loader';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../app/store';

const ResetPasswordContainer = () =>{

    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmNewPassword, setConfirmNewPassword] = useState<string>('');        
    const [newPasswordErrors, setNewPasswordErrors] = useState<string[] | null>(null);
    const [confirmNewPasswordErrors, setConfirmNewPasswordErrors] = useState<string[] | null>(null);    

    const dispatch: AppDispatch = useDispatch();

    const location = useLocation();
    const params = QueryString.parse(location.search);      
    const emailAddress = params.email;
    const token = params.token;

    const selectAccount = (state: RootState) => state.account;
    const { loading            
          } = useSelector(selectAccount);

    let history = useHistory();

     const onHandleSubmit = () => {
        dispatch(resetPassword({ emailAddress, token, newPassword, confirmNewPassword }))
        .then((response: any) => {
           if (response.payload.ok || response.payload.success) {               
                setNewPasswordErrors(null);
               setConfirmNewPasswordErrors(null);
               history.push(`/login`)
           } else {
               setNewPasswordErrors(response.payload.errors["NewPassword"]);               
               setConfirmNewPasswordErrors(response.payload.errors["ConfirmNewPassword"]);    
           }
          })
          .catch((response: any) => {                
               setConfirmNewPasswordErrors(['New passwords do not match']);    
          });
    }

    return (
        <>
            {loading && <Loader/>}
            <ResetPasswordForm   
                newPassword={newPassword}
                setNewPassword={setNewPassword}
                confirmNewPassword={confirmNewPassword}
                setConfirmNewPassword={setConfirmNewPassword}
                handleSubmit={onHandleSubmit}                
                confirmNewPasswordErrors={confirmNewPasswordErrors}
                newPasswordErrors={newPasswordErrors} />
        </>
    )
    }
    
export default ResetPasswordContainer;