import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import getConfig from "../app/config";
import { toast } from 'react-toastify';
import fetchWithRefresh from './fetchWithRefresh';
import { RootState } from '../app/store';
import internal from 'stream';

export const getTrackTypes = createAsyncThunk<any, void>(
  "trackTypes/list",
  async (user, thunkApi) => {
    const response = await fetchWithRefresh(getConfig().apiUrl + "trackType", thunkApi, {
      method: 'Get',
      headers: {       
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    });

    return response;
  }
)

export const getTrackType = createAsyncThunk<any, {id:string}>(
  "trackTypes/get",
  async (id, thunkApi) => {
    const response = await fetchWithRefresh(getConfig().apiUrl + `trackType/${id}`, thunkApi, {
      method: 'Get',
      headers: {       
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    });

    return response;
  }
)

export const addTrackType = createAsyncThunk<any, any>(
  "trackTypes/add",
  async (arg, thunkApi) => {
    return await fetchWithRefresh(getConfig().apiUrl + `trackType`, thunkApi, {
      method: 'POST',
      headers: {       
        'Content-Type': 'application/json',
        Accept: 'application/json',        
      },
      body: JSON.stringify(arg)
    });
  }
);

export const updateTrackType = createAsyncThunk<any, any>(
  "trackTypes/update",
  async (arg, thunkApi) => {
    const { id } = arg;
    return await fetchWithRefresh(getConfig().apiUrl + `trackType/${id}`, thunkApi, {
      method: 'PUT',
      headers: {       
        'Content-Type': 'application/json',
        Accept: 'application/json',        
      },
      body: JSON.stringify(arg)
    });
  }
);


export interface TrackType {
  headerName: string,
  displayName: string,
  shortDisplayName: string,
  exportHeaderName: string,
  description: string,
  units: string,
  displayUnits: string,
  trackCategoryId : string | null,
  uploadType: number,        
  allowedCurveTypes: any[],
  allowedTrackTypes: any[],
  averagingType: number
}

export interface TrackTypeState {
    loading: boolean,
    trackTypes: any[],
    trackType: TrackType | null,
    error: any,    
  }
  
const initialState: TrackTypeState = {
    loading: false,
    trackTypes: [],    
    trackType: null,
    error: null    
  }

const trackTypeSlice = createSlice({
    name: 'track-type',
    initialState,
    reducers: {           
       clearTrackType: (state) => {
         state.trackType = { headerName: "",
          displayName: "",
          shortDisplayName: "",
          exportHeaderName: "",
          description: "",
          units: "",
          displayUnits: "",
          trackCategoryId : null,
          uploadType: 1,        
          allowedCurveTypes: [],
          allowedTrackTypes: [],
          averagingType: 0 }
       } 
    },
    extraReducers:  (builder) => {      
          builder.addCase(getTrackTypes.pending, (state) => {         
            state.error = null;      
            state.loading = true;
          });

          builder.addCase(getTrackTypes.fulfilled, (state, { payload }) => {   
            if (payload?.success || payload.status == 200) {
                state.trackTypes = payload.trackTypes;
            } else {
              toast.error("Error getting track types");
            }

            state.loading = false;
          }); 
       
          builder.addCase(getTrackTypes.rejected, (state, action) => {
            debugger;
            if (action.payload) {
              state.error = action.payload;
              toast.error("Error getting track types");
            } else {
              state.error = action.error;
              toast.error("Error getting track types");
            }

            state.loading = false;
          });

          builder.addCase(getTrackType.pending, (state) => {    
            state.error = null;
            state.loading = true;
          });

          builder.addCase(getTrackType.fulfilled, (state, { payload }) => {   
            if (payload?.success || payload.status == 200) {
                state.trackType = payload;
            } else {
              toast.error("Error getting track type");
            }

            state.loading = false;
          }); 
       
          builder.addCase(getTrackType.rejected, (state, action) => {
            if (action.payload) {
              state.error = action.payload;
              toast.error("Error getting track type");
            } else {
              state.error = action.error;
              toast.error("Error getting track type");
            }

            state.loading = false;
          });     
          
          builder.addCase(updateTrackType.pending, (state) => {   
            state.error = null;            
            state.loading = true;
          });

          builder.addCase(updateTrackType.fulfilled, (state, { payload }) => {   
            if (payload?.success || payload.status == 200) {
                state.trackTypes = payload.trackTypes;
                toast.success("Successfully updated track type");
            } else {
              toast.error("Error updating track type");
            }

            state.loading = false;
          }); 
       
          builder.addCase(updateTrackType.rejected, (state, action) => {            
            if (action.payload) {
              state.error = { data: { errors: action.payload } };
              toast.error("Error updating track type");
            } else {
              state.error = action.error;
              toast.error("Error updating track type");
            }

            state.loading = false;
          });

          builder.addCase(addTrackType.pending, (state) => {    
            state.error = null;           
            state.loading = true;
          });

          builder.addCase(addTrackType.fulfilled, (state, { payload }) => {   
            if (payload?.success || payload.status == 200) {
                state.trackTypes = payload.trackTypes;
                toast.success("Successfully added track type");
            } else {
              state.error = { data: { errors: payload.errors } };
              toast.error("Error adding track type");
            }

            state.loading = false;
          }); 
       
          builder.addCase(addTrackType.rejected, (state, action) => {
            if (action.payload) {
              state.error = { data: { errors: action.payload } };
              toast.error("Error adding track type");
            } else {
              state.error = action.error;
              toast.error("Error adding track type");
            }

            state.loading = false;
          });
    }    
})

export const { clearTrackType } = trackTypeSlice.actions

export const selectTrackType = (state: RootState) => state.trackType;

export default trackTypeSlice.reducer;