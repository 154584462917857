import React from 'react';
import { useHistory, useParams  } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useUpdateEmailAdressMutation, useGetUserEmailAddressQuery } from '../../../services/userApi';
import Container from './container';

const AddUser = ({}:any) => {
    const { userId } = useParams<any>();
    const { data: user, isLoading: userIsLoading, error } = useGetUserEmailAddressQuery({ userId });    
    const [ update, { isLoading, error: addError }] = useUpdateEmailAdressMutation();

    let history = useHistory();

    const onUpdateUser = (user: any) => {        
        update({...user, userId })
        .unwrap()
        .then((response: any) => {
            toast.success("Successfully updated user email address");
            history.push(`/admin/user/list`);
         })
         .catch((error) => { toast.error("Error updating user email address") });
      };

    return (
        <Container title="Edit User Email Address" 
            user={user} 
            userIsLoading={userIsLoading} 
            onSave={onUpdateUser}
            isLoading={isLoading}
            error={{...error, ...addError}} />
    )    
};

export default AddUser;