import { Link } from "react-router-dom";

const UsersInRoleRow = ({index, user, canEdit, canEditRoles, canDelete, onRemoveRoleFromUser, onCheckRow, checkedRows} : any) => {

    return (
        <tr key={index}>
            <td><input type="checkbox" onChange={() => onCheckRow(user.id)} checked={checkedRows.findIndex((r: any) => r == user.id) >= 0}/></td>
            <td>{user.name}</td>                                
            <td>
                <div className="columns list-users-in-role-viewer-columns">
                    <div className="column">{user.canEdit && canEdit && <><Link to={`/admin/user/edit/${user.id}`}>Edit</Link></>}</div>
                    <div className="column">{canEditRoles && <><Link to={`/admin/user/roles/${user.id}`}>Roles</Link></>}</div>                        
                </div>           
            </td>
        </tr>
    )
}

export default UsersInRoleRow;