import React from 'react';

const YScaleProperties = (props: any) => {

    const {        
        setShowDepthGridlines,
        majorIntervals, 
        setMajorIntervals,
        showDepthGridLines,
        showDepthMinorIntervals,
        setShowDepthMinorIntervals,
        minorIntervals,
        setMinorIntervals
    } = props;

return(
    <div>
        <div className="columns mr-1">
            <div className="column">
                <div className="field">
                    <label className="label">Major unit</label>
                    <div className="control">
                        <input className="input" type="number" step="any" value={majorIntervals} onChange={(e) => setMajorIntervals(+e.target.value)} />
                    </div>
                </div>
            </div>
            <div className="column">
                <div className="field">
                    <label className="label">Minor unit</label>
                    <div className="control">
                    <input className="input" type="number" step="any" value={minorIntervals} onChange={(e) => setMinorIntervals(+e.target.value)} disabled={!showDepthMinorIntervals} />
                    </div>
                </div>
            </div>
        </div>
       
        <div>
            <label className="checkbox">
                <input type="checkbox" checked={showDepthGridLines} onChange={(e) => setShowDepthGridlines(e.target.checked)} /> Gridlines
            </label>  
        </div>

        <div>
            <label className="checkbox">
                <input type="checkbox" checked={showDepthMinorIntervals} onChange={(e) => setShowDepthMinorIntervals(e.target.checked)} /> Show Minor Gridlines
            </label>  
        </div>
    </div>
)};

export default YScaleProperties;