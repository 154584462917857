import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../app/hooks";
import Errors from "../../../components/errors";
import Loader from "../../../components/loader";
import { getTrackTypes, selectTrackType } from "../../../services/trackTypeSlice";
import List from "./list";

export default function TrackTypeList() {

    const dispatch = useAppDispatch();       
    useEffect(() => {        
       dispatch(getTrackTypes());                  
     }, []);

     const { 
      trackTypes,
      error,
      loading
     } = useSelector(selectTrackType);

     const [uploadType, setUploadType] = useState<string>("");
     const [nameFilter, setNameFilter] = useState<string>("");
     const [filteredTrackTypes, setFilteredTrackTypes] = useState<any>(trackTypes);

     useEffect(() => {        
      setFilteredTrackTypes(trackTypes);
     }, [trackTypes]);
         
     const uploadTypes = trackTypes ? [...new Set(trackTypes.map((item: any) => item.uploadTypeDescription))] : [];
  
     const onSetUploadType = (uploadType: string) => {
      setUploadType(uploadType);
      onFilter(uploadType, nameFilter);
      // if (uploadType) {
      //   setSilteredTrackTypes(trackTypes.filter((trackType: any) => trackType.uploadTypeDescription === uploadType));
      // } else {
      //   setSilteredTrackTypes(trackTypes);
      // }
     };

     const onSetNameFilter = (nameFilter: string) => {
      setNameFilter(nameFilter);
      onFilter(uploadType, nameFilter);
      // if (nameFilter) {
      //   setSilteredTrackTypes(trackTypes.filter((trackType: any) => trackType.name.toLowerCase().includes(nameFilter.toLowerCase())));
      // } else {
      //   setSilteredTrackTypes(trackTypes);
      // }
     };     

     const onFilter = (uploadType: string, nameFilter: string) => {     
      if (nameFilter || uploadType) {
        setFilteredTrackTypes(trackTypes.filter((trackType: any) => (!nameFilter || trackType.name.toLowerCase().includes(nameFilter.toLowerCase())) && (!uploadType || trackType.uploadTypeDescription === uploadType)));
      } else {
        setFilteredTrackTypes(trackTypes);
      }
     };

    return (
      <section className="section">
            <div>
                <h1>Catalog</h1>       
                {loading && <Loader />}
                <Errors error={error}/>
                <List trackTypes={filteredTrackTypes} 
                      uploadTypes={uploadTypes} 
                      uploadType={uploadType} 
                      setUploadType={onSetUploadType} 
                      nameFilter={nameFilter}
                      setNameFilter={onSetNameFilter}
                      canAdd={true} />
            </div>
      </section>
    )
}
  