import { useEffect, useRef, useState } from "react";
import { Icon } from "@iconify/react";

const Collapsible = ({ id, 
                       label,                         
                        isSelected,
                        children,
                        showHide,
                        leftPadding,
                        showReset,
                        reset
                        } : any) => 
{
    const [collapsed, setCollapsed] = useState<Boolean>(true);
    
    useEffect(() => {
        if (isSelected) {
          setCollapsed(false);
          if (showHide) {
            showHide(true);
          }
        }
      }, [isSelected]);

        
    const onSetCollapsed = () => {
      if (showHide) {
        showHide(collapsed);
      }

      setCollapsed(!collapsed);
    };

    const onSetSelected = () => {

    };
    
    return (
      <div style={{height: "100%", display: "block"}}>
        <div className={`collapsible is-size-6 ${isSelected ? "track-active" : ""}`} onClick={onSetCollapsed}>

        {/* <div className="level">
          <div className="level-left">
            <div className="level-item">
              {label}
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              <img src="/images/icons/Reset Icons.svg" />
            </div>
            <div className="level-item">
              <Icon icon="mdi:chevron-down-circle-outline" width="24" height="24" style={{display: "inline-block"}} rotate={collapsed ? 0 : 2} fr={0}/>
            </div>
          </div>
        </div> */}


          <div className="track-order-label">{label}</div>          
          {showReset && reset && <img src="/images/icons/Reset Icons.svg" width="22" className="reset-icon" onClick={reset} />}
          <div className="collapsible-icon">
            <Icon icon="mdi:chevron-down-circle-outline" width="24" height="24" rotate={collapsed ? 0 : 2}/>
          </div>
        </div>
        <div onMouseDown={onSetSelected} className="collapsible-content" style={{display: collapsed ? "none" : "inline-block", overflow: "visible" }}>
            <div className="panel-contents">
                <div className="panel-section" style={{paddingLeft: (leftPadding ?? "1rem")}}>
                    {children}
                </div>
            </div>
        </div>
    </div>
    )
};

export default Collapsible;