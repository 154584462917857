import React, { useEffect, useState } from 'react';
import UpdateUserForm from './form';
import { updateDetails } from '../../../services/accountSlice';
import Loader from '../../../components/loader';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../app/store';

const UpdateAccountDetailsContainer = () =>{

    const [firstName, setFirstName] = useState<string | null>('');
    const [lastName, setLastName] = useState<string | null>('');
    const [emailAddress, setEmailAddress] = useState<string | null>('');    
    const [firstNameErrors, setFirstNameErrors] = useState<string[] | null>(null);
    const [lastNameErrors, setLastNameErrors] = useState<string[] | null>(null);
    const [emailAddressErrors, setEmailAddressErrors] = useState<string[] | null>(null);
    
    const dispatch: AppDispatch = useDispatch();

    const selectAccount = (state: RootState) => state.account;
    const { loading,
            error,
            user            
          } = useSelector(selectAccount);

    let history = useHistory();

    useEffect(() => {
        if (user) {
            setFirstName(user.firstName);
            setLastName(user.lastName);
            setEmailAddress(user.emailAddress);
        }
    }, 
    [JSON.stringify(user)]);

    const onHandleSubmit = () => {
        dispatch(updateDetails({ firstName, lastName, emailAddress }))
         .then((response: any) => {
            if (response.payload.success) {
                setFirstNameErrors(null);
                setLastNameErrors(null);
                setEmailAddressErrors(null);
                history.push(`/client/${response.payload.clientId}/project`)
            } else {
                setFirstNameErrors(response.payload["FirstName"]);
                setLastNameErrors(response.payload["LastName"]);    
                setEmailAddressErrors(response.payload["EmailAddress"]);
            }
           })
           .catch((response: any) => { 
                setFirstNameErrors(['']);
                setLastNameErrors(['']);    
           });
    }

    return (
        <>
            {loading && <Loader/>}
            {/* {error && <Errors error={{ data: { errors: error}}} />}   */}
            <UpdateUserForm  
                firstName={firstName}
                setFirstName={setFirstName}
                lastName={lastName}
                setLastName={setLastName}
                emailAddress={emailAddress}
                setEmailAddress={setEmailAddress}
                handleSubmit={onHandleSubmit}
                firstNameErrors={firstNameErrors}
                lastNameErrors={lastNameErrors}
                emailAddressErrors={emailAddressErrors}
                twoFactorEnabled={user?.twoFactorEnabled}
                canDisableTwoFactor={user?.canSkipTwoFactor} />
        </>
    )
    }
    
export default UpdateAccountDetailsContainer;