import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { SketchPicker } from 'react-color';
import rgbHex from 'rgb-hex';

const Row = ({ lookup, updateLithologyLookup }:any) => {

    const [displayLineColorPicker, setDisplayLineColorPicker] = useState(false);
    const [color, setColor] = useState<any>();
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        if (lookup?.color) {
            setColor(lookup.color);
        }
    }, [lookup]);

    const handleLineColorClick = () => {
        setEditMode(true);
        setDisplayLineColorPicker(!displayLineColorPicker);
    };

    const handleLineColorClose = () => {
        setDisplayLineColorPicker(false);
        setEditMode(false);
        setColor(lookup.color);
    };

    const popover: any = {
        width: "100%",
        margin: 0,
        display: "flex",
        flexGrow: 0,
        // position: 'relative',
        // marginTop: "10px",
        // zIndex: '100',        
        // right: '0px'
        }

    const onChangeFillColor = (value: any) => {
        setColor(convertToHex(value));
    };

    const convertToHex = (value: any) => {      
        return "#" + rgbHex(value.rgb.r, value.rgb.g, value.rgb.b, value.rgb.a);
      };

      const onSave = () => {
        setEditMode(false);
        setDisplayLineColorPicker(false);

        updateLithologyLookup({...lookup, color});
        // setCodeError(false);
        // if (lexicon.id) {
        //     updateLithologyLexicon({ ...lexicon, code, description })
        //         .unwrap()
        //         .then(() => { setEditMode(false); })
        //         .catch(() => { setCodeError(true); });
        // } else {
        //     addLithologyLexicon({ code, description })
        //         .unwrap()
        //         .then(() => { setEditMode(false); })
        //         .catch(() => { setCodeError(true); });
        // }
    }

    const onCancel = () => {        
        setEditMode(false);
        setDisplayLineColorPicker(false);
        setColor(lookup.color);        
    }

    return (    
        <>
            <tr>                
                <td>{lookup.code}</td>
                <td><div className='lookup-color-inline-edit' style={{backgroundColor: color}} onClick={handleLineColorClick}></div> <span className='color-code' onClick={handleLineColorClick}>{color}</span>
                    { displayLineColorPicker ? <div className='lookup-color-select'>                     
                     <SketchPicker color={ color } onChangeComplete={onChangeFillColor} width={"200px"}/>
                   </div> : null }                
                </td>
                <td>
                    {editMode && <div className="row-buttons">
                        <button className='button is-primary mr-2' onClick={onSave}><span className="icon"><i className="fas fa-check" title="Ok" aria-hidden="true"></i></span></button>
                        <button className='button' onClick={onCancel}><i className="fas fa-times" title="Ok" aria-hidden="true"></i></button>
                </div>}
                </td>
            </tr>           
        </>        
    )    
};

export default Row;