import React from 'react';
import { Link, useParams } from 'react-router-dom';
import Loader from '../../components/loader';

const ProjectForm = (props: any) => {

  const {name, setName, onSaveProject, loading} = props;
  let { clientId } = useParams<any>();
    
  const onNameChanged = (e: any) => setName(e.target.value);  
 
    return (
      <>
            {loading && <Loader/>}
            <div className="columns">
              <div className="column is-one-quarter">
              <form className="control">
                <div className="field">
                  <label className="label">Name</label>
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      id="boreholeName"
                      name="boreholeName"
                      value={name}
                      placeholder="Name"
                      onChange={onNameChanged}
                    />
                  </div>
                </div>            
                
                <div className="field is-grouped">
                    <div className="control">
                      <Link to={`/`} className="button mr-4">Cancel</Link>                      
                      <button className="button is-link" type="submit" onClick={onSaveProject}>Save Project</button>
                  </div>
                </div>
              </form>
              </div>
            </div>    
      </>    
    )
};

export default ProjectForm;