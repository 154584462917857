import React, { useEffect, useState } from 'react';
import MfaForm from './form';
import { changeTwoFactorAuthentication, changeTwoFactorAuthenticationWithRecoveryCode } from '../../../services/accountSlice';
import Loader from '../../../components/loader';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../app/store';
import auth from "../../../components/auth";

const DisableTwoFactorContainer = () => {
        
    const dispatch: AppDispatch = useDispatch();

    let history = useHistory();
        
    const [authenticatorCode, setAuthenticatorCode] = useState<string>('');
    const [showRecoveryMfa, setShowRecoveryMfa] = useState(false);
    const [recoveryAuthenticatorCode, setRecoveryAuthenticatorCode] = useState<string>('');
    
    useEffect(() => {
        if (authenticatorCode?.length >= 6) {
            onHandleSubmit();
        }   
    }, [authenticatorCode]);

    const selectAccount = (state: RootState) => state.account;
    const { loading,
            error            
          } = useSelector(selectAccount);
    
    const onHandleSubmit = () => {      
        if (showRecoveryMfa) {
            dispatch(changeTwoFactorAuthenticationWithRecoveryCode({recoveryCode:recoveryAuthenticatorCode, token: auth.getToken()}))
            .then((response: any) => 
            {
                if (response.payload.success) {
                    history.push('/user/enable-mfa');
                }
            });
        } else {
            dispatch(changeTwoFactorAuthentication(authenticatorCode))
                .then((response: any) => 
                {
                    if (response.payload.success) {
                        history.push('/user/enable-mfa');
                    }
                });
        }
    }    

    return (
        <>
            {loading && <Loader/>}
            {/* {error && <Errors error={{ data: { errors: error}}} />}   */}
            <MfaForm  
                handleSubmit={onHandleSubmit}
                authenticatorCode={authenticatorCode}
                setAuthenticatorCode={setAuthenticatorCode}
                showRecoveryMfa={showRecoveryMfa}
                setShowRecoveryMfa={setShowRecoveryMfa}
                recoveryAuthenticatorCode={recoveryAuthenticatorCode}
                setRecoveryAuthenticatorCode={setRecoveryAuthenticatorCode}
                error={error} />
        </>
    )
    }
    
export default DisableTwoFactorContainer;