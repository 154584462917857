import { isNumber } from 'lodash';
import numeral from 'numeral';

const FormatNumber = (num: any):any => {
   if (!isNumber(num) ) {
      return "";
   }
   
    if (num == 0) {
        return 0;
    }

    const absNum = Math.abs(num);

    if (absNum >= 1000000000) {
        return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
     }
     if (absNum >= 1000000) {
        return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
     }
     if (absNum >= 1000) {
        return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
     }

     if (absNum < 0.001) {
        return (num * 1000).toFixed(1).replace(/\.0$/, '') + 'm';
     }

     if (absNum < 0.000001) {
      return (num * 1000000).toFixed(1).replace(/\.0$/, '') + 'μ';
     }

     return Math.round(num * 1000) / 1000;
}

export default FormatNumber;