import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../app/hooks';
import Errors from '../../../components/errors';
import Loader from '../../../components/loader';

import { getLithologyLexicons, 
         selectLithology, 
         updateLithologyLexicon, 
         addRow, 
         addLithologyLexicon, 
         deleteAddingRow,
         deleteLithologyLexicon } from "../../../services/lithologySlice";
import List from './list';

const ListLithologyLexicons = ({}:any) => {
  
  const dispatch = useAppDispatch();       
  useEffect(() => {        
    dispatch(getLithologyLexicons());
  }, []);

  const { 
    lithologyLexicons,
    loading,
    error,
    addingLexicon
  } = useSelector(selectLithology); 

  const onUpdateLithologyLexicon = (lithologyLexicon: any) => {
    return dispatch(updateLithologyLexicon(lithologyLexicon));    
  }

  const onAddLithologyLexicon = (lithologyLexicon: any) => {
    return dispatch(addLithologyLexicon(lithologyLexicon));
  };

  const onAdd = () => {
    dispatch(addRow());
  };

  const onDeleteAddingRow = () => {
    dispatch(deleteAddingRow());
  };

  const onDeleteLithologyLexicon = (lithologyLexiconId: any) => {
    dispatch(deleteLithologyLexicon(lithologyLexiconId));
  }
    
    return (
        <section className="section">
            <div>
                <div className='columns'>
                  <div className="column">
                    <h1>Lithology Lexicons</h1>   
                  </div>                  
                </div>                
                <button className="button" onClick={onAdd} disabled={addingLexicon}>Add</button>
                {(loading) && <Loader/>}
                <Errors error={error}/>
                
                <List lithologyLexicons={lithologyLexicons} 
                      updateLithologyLexicon={onUpdateLithologyLexicon}
                      addLithologyLexicon={onAddLithologyLexicon}
                      deleteAddingRow={onDeleteAddingRow}
                      deleteLithologyLexicon={onDeleteLithologyLexicon} />
            </div>
        </section>
    )    
};

export default ListLithologyLexicons;