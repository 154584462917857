import React, { useEffect, useRef } from "react";
import { ResizableBox } from "react-resizable";

const TrackWrapper = ({scrollIntoView, children, trackWidth, chartHeight, onResize, editMode, trackClick, minWidth}: any) => {

    const fieldRef = useRef<HTMLInputElement>(null);

    useEffect(() => {      
      if (scrollIntoView && fieldRef.current) {
        fieldRef.current.scrollIntoView({
          behavior: "smooth",
          block:"start",
          inline: "nearest"
        });
      }
    }, [scrollIntoView]);

    return (
        <ResizableBox 
            width={trackWidth} 
            height={chartHeight}            
            axis="x" 
            onResize={onResize}
            resizeHandles={editMode ? ['e'] : []}
            minConstraints={[minWidth ?? 150, chartHeight]} 
            maxConstraints={[300, chartHeight]}>
            <div className={`mr-2 track ${editMode ? "selected-track-outline" : ""}`} ref={fieldRef} style={{overflow: "visible"}} onClick={trackClick}>
                {children}
            </div>
        </ResizableBox>
    )
};

export default TrackWrapper;