import React, { useEffect, useRef, useState } from "react";
import GoogleMapReact, { fitBounds } from 'google-map-react';
import useSupercluster from "use-supercluster";
import './map-viewer.scss';
import LocationPin from './location-pin';
import { useHistory } from "react-router-dom";

const MapViewer = ({ boreholeLocations, projectId, clientId } : any) => {

    const [zoom, setZoom] = useState(15);
    const [center, setCenter] = useState({ lat: 51.5, lng: 0 });
    const [bounds, setBounds] = useState<any>(null);
    const [selectedBorehole, setSelectedBorehole] = useState<any>(null);

    const history = useHistory();
    const mapRef = useRef<any>();
    const mapsRef = useRef<any>();
    const mapContainerRef = useRef<any>();

    const ApiKey = "AIzaSyCHIlYpjxapPUONTtBayQWsrFo2up7fAno";    

    const Marker = ({ children }:any) => children;

    const getMapOptions = (maps: any) => {
        return {
            streetViewControl: true,
            scaleControl: true,
            fullscreenControl: true,
            //styles: mapStyles,
            gestureHandling: "greedy",
            disableDoubleClickZoom: true,        
            mapTypeControl: true,            
            mapTypeControlOptions: {
                style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: maps.ControlPosition.TOP_LEFT,
                mapTypeIds: [
                    maps.MapTypeId.HYBRID,
                    maps.MapTypeId.ROADMAP,
                    maps.MapTypeId.SATELLITE                    
                ]
            },
            zoomControl: true,
            clickableIcons: false
        };
    }

    const points = boreholeLocations.map((borehole: any) => ({
        type: "Feature",
        properties: { cluster: false, boreholeId: borehole.id, category: borehole.category, name: borehole.name },
        geometry: {
          type: "Point",
          coordinates: [
            parseFloat(borehole.longitude),
            parseFloat(borehole.latitude)
          ]
        }
      }));

    const { clusters, supercluster } = useSupercluster({
        points,
        bounds,
        zoom,
        options: { radius: 75, maxZoom: 20 }
      });

    const iunitilaiseMap = ({ map, maps }: any) => {
        mapRef.current = map;
        mapsRef.current = maps;
        map.setMapTypeId(maps.MapTypeId.HYBRID);

        fitToBounds();
    };

    const fitToBounds = () => {
      const map = mapRef.current;
      const maps = mapsRef.current;
        
      const bounds = new maps.LatLngBounds(); // need handler incase `google` not yet available
      let mapMarkers :any[] = [];
      if (!boreholeLocations) {
          return;        
      }

      mapMarkers = boreholeLocations.filter((location: any) => location.latitude != null && location.longitude != null );
                
      mapMarkers.forEach(marker => {
          bounds.extend(new maps.LatLng(marker.latitude, marker.longitude));
      });
    
      const newBounds = {
        ne: {
            lat: bounds.getNorthEast().lat(),
            lng: bounds.getNorthEast().lng()
        },
        sw: {
            lat: bounds.getSouthWest().lat(),
            lng: bounds.getSouthWest().lng()
        }
      };
    
      const size = {
        width: mapContainerRef.current.clientWidth,
        height: mapContainerRef.current.clientHeight,
      };
    
      const {center, zoom} = fitBounds(newBounds, size);
    
      setZoom(zoom);
      setCenter(center);
    };

    const onClick = (boreholeId: string) => {
        history.push(`/client/${clientId}/project/${projectId}/borehole/${boreholeId}/log-viewer`);
    };

    const onSetSelectedBorehole = (boreholeId: any) => {
      setSelectedBorehole(boreholeId);
    };

    const zommToBorehole = (location: any) => {
      setSelectedBorehole(location.id);
      setCenter({ lat: location.latitude, lng: location.longitude});
      setZoom(15);
    };

    return (
        <section className="section">
            <h1>Map</h1>   
            <div className="map-container">
              <div className="map-borehole-list">
                {(boreholeLocations || []).map((location: any, index: number) => (
                  <div className={`item ${selectedBorehole === location.id ? "selected" : ""} `} 
                       onClick={() => zommToBorehole(location)} 
                       onMouseOver={() => onSetSelectedBorehole(location.id)} 
                       onMouseOut={() => onSetSelectedBorehole(null)}>
                        {location.number}. {location.name}
                  </div>
                ))}
                <button className="button" onClick={fitToBounds}>Zoom to all</button>
              </div>
              <div className="google-map" ref={mapContainerRef}>
                  <GoogleMapReact
                      bootstrapURLKeys={{ key: ApiKey, libraries:['geometry', 'drawing', 'visualization'] }}
                      options={getMapOptions}
                      defaultCenter={center}
                      defaultZoom={10}
                      center={center}
                      zoom={zoom}        
                      onGoogleApiLoaded={iunitilaiseMap}
                      onChange={({ zoom, bounds }) => {
                          setZoom(zoom);
                          setBounds([
                            bounds.nw.lng,
                            bounds.se.lat,
                            bounds.se.lng,
                            bounds.nw.lat
                          ]);
                        }}
                      yesIWantToUseGoogleMapApiInternals
                  >
  {/* 
  {clusters.map(cluster => {
            const [longitude, latitude] = cluster.geometry.coordinates;
            const {
              cluster: isCluster,
              point_count: pointCount,
              boreholeId
            } = cluster.properties;

            if (isCluster) {
              return (
                  <Marker
                  key={`cluster-${cluster.id}`}
                  lat={latitude}
                  lng={longitude}
                >
                  <div
                    className="cluster-marker"
                    style={{
                      width: `${10 + (pointCount / points.length) * 20}px`,
                      height: `${10 + (pointCount / points.length) * 20}px`
                    }}
                    onClick={() => {
                      const expansionZoom = Math.min(
                        supercluster.getClusterExpansionZoom(cluster.id),
                        20
                      );
                      mapRef.current.setZoom(expansionZoom);
                      mapRef.current.panTo({ lat: latitude, lng: longitude });
                    }}
                  >
                    {pointCount}
                  </div>
                </Marker>
              );
            }

            return (
              <Marker
                  key={`crime-${boreholeId}`}
                  lat={latitude}
                  lng={longitude}>               
                  <LocationPin
                      key={`crime-${cluster.properties.boreholeId}`}
                      onClick={() => onClick(cluster.properties.boreholeId)}              
                      name={cluster.properties.name}
                      >              
                  </LocationPin> 
              </Marker>
            );
          })} */}


                  {(boreholeLocations || []).map((location: any, index:number) => (
                      <LocationPin
                          key={index}
                          id={location.id}
                          onClick={() => onClick(location.id)}
                          lat={location.latitude}
                          lng={location.longitude}
                          text={location.number}
                          name={location.name}
                          selectedBorehole={selectedBorehole}
                          setSelectedBorehole={onSetSelectedBorehole}
                      />
                      ))} 
                  </GoogleMapReact>
              </div>
            </div>
        </section>
    )
};

export default MapViewer;