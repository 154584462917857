import React from 'react';
import { useParams } from 'react-router-dom';
import TextField from '../../../components/Input/text-field';
import Loader from '../../../components/loader';
import { useGetRoleQuery } from '../../../services/roleApi';

import Container from './container';

const ViewRole = ({}:any) => {
    
    const { roleId } = useParams<any>();    
    const { data: role, isLoading: roleIsLoading } = useGetRoleQuery({ roleId });
       

    return (
        <Container title="View Role" 
                  role={role} 
                  roleIsLoading={roleIsLoading}                   
                  isLoading={roleIsLoading}
                  readOnly={true}  />
    )    
};

export default ViewRole;