import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, Prompt, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import useExitPrompt from "../../app/use-exit-prompt";
import { useUploadFileMutation, 
         useUploadImagesMutation, 
         useScaleImagesMutation, 
         useScaleDataMutation,
         useCreateDeepZoomImagesMutation
          } from "../../services/boreholeApi";
import Errors from '../../components/errors';
import Loader from '../../components/loader';
import Progress from '../../components/progress';
import getConfig from "../../app/config";
import { useAppDispatch } from '../../app/hooks';
import Upload from '../../components/Upload/Upload';
import formatBytes from '../../components/FormatBytes';
import './BoreholeUpload.scss';
import auth from '../../components/auth';
import { RootState } from '../../app/store';
import RawFiles from './BoreholeRawFiles';
import { confirmAlert } from 'react-confirm-alert';
import { getFiles, 
        getRawFiles,
        updateDataFile, 
        removeDataFile,
        addImageFile, 
        addRawFile,        
        updateDataFileProgress,
        updateDataFileProcessed,
        updateScaleDataFileProgress,
        updateDataFileScaled,
        updateDataFileSubProgress,        
        removeDataFileProgress,
        cancelDataFileProgress,
        updateImageFileProgress,
        updateImageFileSubProgress,
        removeScaleImageProgress,
        cancelScaleImageProgress,
        updateImageFileScaled,
        scaleImage,
        deepZoomImage,
        deleteDataFile,
        updateImageFileDeepZoomProgress,
        updateImageFileDeepZoomSubProgress,
        removeDeepZoomImageProgress,
        cancelDeepZoomImageProgress,
        showDataFileErrors,
        deleteRawFiles,
        runMachineLearning, 
        updateImageFileDownloadProgress} from "../../services/borehole-files-slice";
import { cleanValue } from 'react-select/dist/declarations/src/utils';
import BoreholeImageFiles from './BoreholeImageFiles';

const BoreholeFiles = ({setBorehole}: any) => {
  
  const { boreholeId, clientId, projectId } = useParams<any>();
  const dispatch = useAppDispatch();

  const selectAccount = (state: RootState) => state.account;
  const { 
    connection,
    connected
  } = useSelector(selectAccount);

  const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);

  useEffect(() => {
    return () => {
      setShowExitPrompt(false)
    }
  }, [])

  useEffect(() => {

    const subscribeToProgress = async () => {
      if (!connection) {
        return;
      }

      connection.off("SendProcessFileProgress");
      connection.off("SendProcessFileSubProgress");
      connection.off("SendScaleDataProgress");
      connection.off("SendProcessFileScaled");
      connection.off("SendScaleDataSubProgress");
      connection.off("SendDeleteDataProgress");
      connection.off("SendDeleteDataSubProgress");      
      connection.off("SendExtractZipProgress");
      connection.off("SendDataFileExtracted");
      connection.off("SendImageFileExtracted");
      connection.off("SendCreateZipProgress");
      connection.off("SendDownloadZip");
      connection.off("SendDownloadSelectedImageFilesProgress");
      connection.off("SendDownloadSelectedImageFiles");
      connection.off("SendProcessFileJobDeleted");
      connection.off("SendProcessFileJobCancelled");
      connection.off("SendCreateDeepZoomImagesProgressJobCancelled");
      connection.off("SendScaleImagesProgressJobCancelled");

      connection.on("SendProcessFileProgress", (percent: any, message: any, uploadType: any, fileName: string, failed: any) => {
        dispatch(updateDataFileProgress({uploadType, percent, message, fileName, failed}));        
      });

      connection.on("SendProcessFileSubProgress", (percent: any, uploadType: any, fileName: string) => {        
        dispatch(updateDataFileSubProgress({uploadType, percent, fileName}));        
      });

      connection.on("SendProcessFileProcessed", (uploadType: any, fileName: string, lithologyTrackTypeId: any, errors: any[], extraColumnsFound: any[], columnsNotFound: any[]) => {
        dispatch(updateDataFileProcessed({uploadType, fileName, lithologyTrackTypeId, errors, extraColumnsFound, columnsNotFound}));        
      });

      connection.on("SendScaleDataProgress", (percent: any, message: any, uploadType: any, fileName: string, failed: any) => {        
        dispatch(updateScaleDataFileProgress({uploadType, percent, message, fileName, failed }));        
      });

      connection.on("SendProcessFileScaled", (uploadType: any, fileName: string) => {
        dispatch(updateDataFileScaled({uploadType, fileName}));        
      });

      connection.on("SendScaleDataSubProgress", (percent: any, uploadType: any, fileName: string) => {        
        dispatch(updateDataFileSubProgress({uploadType, fileName, percent }));        
      });

      connection.on("SendDeleteDataProgress", (percent: any, message: any, uploadType: any, fileName: string, failed: any) => {
        if (percent == 100) {
          onRemoveDataFile(uploadType, fileName);
        } else {          
          dispatch(updateDataFileProgress({uploadType, percent, message, fileName, failed}));
        }
      });      

      connection.on("SendDeleteDataSubProgress", (percent: any, uploadType: any, fileName: string) => {        
          dispatch(updateDataFileSubProgress({uploadType, percent, fileName}));
      });      

      connection.on("SendProcessFileJobDeleted", (uploadType: any, fileName: string) => {        
          dispatch(removeDataFileProgress({ uploadType, fileName }));
      });

      connection.on("SendProcessFileJobCancelled", (uploadType: any) => {        
        dispatch(cancelDataFileProgress({ uploadType }));
      });

      connection.on("SendCreateZipProgress", (percent: any, message: any, fileName: any, failed: any) => {
        setCreateZipProgress({percent, message, fileName, failed});
      });   
      
      connection.on("SendDownloadSelectedImageFilesProgress", (percent: any, message: any, fileName: any, imageTypeId: number, failed: any) => {        
        dispatch(updateImageFileDownloadProgress({percent, message, fileName, imageTypeId, failed}))
      });     

      connection.on("SendExtractZipProgress", (percent: any, message: any, zipFileName: string, secondsRemaining: number | null, failed: any) => {
        setExtractZipProgress((prevState: any) => { 
          let returnState;
          if (prevState === undefined) {
            returnState = {
              [zipFileName]: {
                  zipFileName: zipFileName,
                  progress: percent,
                  message: message,
                  failed: failed,
                  secondsRemaining: secondsRemaining
              }};
          } else {
          returnState = {
            ...prevState,
            [zipFileName]: {
                ...prevState[zipFileName],
                zipFileName: zipFileName,
                progress: percent,
                message: message,
                failed: failed,
                secondsRemaining: secondsRemaining
            }};
          }

          return returnState;
        }
        )

      });     

      connection.on("SendDataFileExtracted", (dataFile: any) => {
        dispatch(updateDataFile(dataFile));
      });  

      connection.on("SendImageFileExtracted", (imageFile: any) => {
        dispatch(addImageFile(imageFile));      
      });  

      connection.on("SendDownloadZip", (fileNames: string[]) => {        
        setCreateZipProgress({percent: 100, message: "Initiating downloading to browser", fileName: "fileName", failed: false});
        fileNames.forEach((fileName: string) => {
          download(fileName, 4);
        });
      });  

      connection.on("SendDownloadSelectedImageFiles", (imageTypeId: number, fileNames: string[]) => {     
        dispatch(updateImageFileDownloadProgress({percent: 100, message: "Initiating downloading to browser", fileName: "fileName", failed: false, imageTypeId }))
        //setDownloadSelectedImageFilesProgress({percent: 100, message: "Initiating downloading to browser", fileName: "fileName", failed: false});
        fileNames.forEach((fileName: string) => {
          download(fileName, 4);
        });
      });  
            
      connection.invoke("SubscribeToBorehole", boreholeId);      
    }

    if (connected) {      
      if (connection?.state === "Connected") {
        subscribeToProgress();                
      }

      return function cleanUp() {
        if (connection?.state === "Connected") {          
          connection?.invoke("UnsubscribeFromBorehole", boreholeId);
        }
      }
    }
    
  }, [connected]);

const onUpdateImageFileDownloadProgress = (percent: number, message: string, fileName: string, imageTypeId: number ) => {
  dispatch(updateImageFileDownloadProgress({percent, message, fileName, failed: false, imageTypeId }))
};

  //const [fileProgress, setFileProgress] = useState<any>({});
  const [isUploading, setIsUploading] = useState(false);
  const [createZipProgress, setCreateZipProgress] = useState<any>(null);  
  const [extractZipProgress, setExtractZipProgress] = useState<any>(undefined);
  //const [imageFileProgress, setImageFileProgress] = useState<any>({});
  //const [imageDeepZoomProgress, setDeepZoomProgress] = useState<any>({});
  const [uploadError, setUploadError] = useState<any>();
  const [showImageFiles, setShowImageFiles] = useState<any>({});
  const [folderFiles, setFolderFiles] = useState<any>([]);
  const [currentFolder, setCurrentFolder] = useState<any>();
  const [showAllImageTypes, setShowAllImageTypes] = useState<boolean>(false);
  const [showAllDataTypes, setShowAllDataTypes] = useState<boolean>(false);

  const [checked, setChecked] = useState<any>([]);
  const [isDownloadingFiles, setIsDownloadingFiles] = useState(false);
  const [isDeletingFiles, setIsDeletingFiles] = useState(false);
  
  const [uploadFile, { isLoading, error }] = useUploadFileMutation();  
  const [scaleImages, { isLoading: isScalingImages, error: scaleImagesError }] = useScaleImagesMutation();
  const [scaleData, { isLoading: isScalingData, error: scaleDataError }] = useScaleDataMutation();
    
  const [createDeepZoomImages, { isLoading: isCreatingDeepZoomImages, error: deepZoomImagesError }] = useCreateDeepZoomImagesMutation();
  //const [downloadFiles, { isLoading: isDownloadingFiles }] = useDownloadFilesMutation();
  
    //const { data: uploadTypes, isLoading: enumsLoading} = useUploadTypeQuery();
  //const { data, error: getError, isFetching } = useGetBoreholeFilesQuery({ boreholeId, clientId, projectId });
  useEffect(() => {        
    dispatch(getFiles({ clientId, projectId, boreholeId }));    
    dispatch(getRawFiles({ clientId, projectId, boreholeId }));
  }, [boreholeId]);

  const selectBoreholeFiles = (state: RootState) => state.boreholeFiles;
  const { filesLoading, 
          name: boreholeName, 
          dataFileUploads, 
          imageFileUploads,
          error: filesError,
          rawError,          
          isDeleteingImageFiles,
          rawFilesLoading: isFetchingRawFiles,
          rawFiles,
          isDeleteingRawFiles,
          showLithofaciesMachineLearning,
          isRunningMachineLearning
        } = useSelector(selectBoreholeFiles);
  
  //const { data: rawFiles, error: rawError, isFetching: isFetchingRawFiles } = useGetBoreholeRawFilesQuery({ boreholeId, clientId, projectId });  
  
  useEffect(() => {
      if (!isFetchingRawFiles) {
        setFolderFiles(rawFiles);
        //setCurrentFolder(rawFiles.fullName);
      }
  }, [isFetchingRawFiles]);

  useEffect(() => {
    if (boreholeName) {
      setBorehole({name: boreholeName, id: boreholeId})
    }
  }, [boreholeName]);
  
  const onUploadFile = (formData: any) => {
    uploadFile(formData);
  }

  const onScaleImages = (boreholeId: any) => {    
    scaleImages({boreholeId, projectId, clientId})    
  }

  const onScaleData = (uploadType: string, fileName: string) => {    
    dispatch(updateDataFileProgress({uploadType, percent: null, message: null, fileName, failed: false}));
    scaleData({boreholeId, projectId, clientId, uploadType, fileName })
    .unwrap()
      .then((payload) => { })
      .catch((error) => { toast.error("Error scaling data") });;
  }

  const onScaleImageType = (boreholeId: any, imageType: number) => {
    scaleImages({boreholeId, projectId, clientId, imageType})
      .unwrap()
      .then((payload) => {dispatch(scaleImage({imageType})) })
      .catch((error) => { toast.error("Error scaling images") });
  }

  const onDeepZoomImageType = (boreholeId: any, imageType: number) => {
    createDeepZoomImages({boreholeId, projectId, clientId, imageType})
    .unwrap()
      .then((payload) => {dispatch(deepZoomImage({imageType})) })
      .catch((error) => { toast.error("Error deep zooming images") });
  }

  const onSetError = (error: any) => {    
    setUploadError(error);
  };

  const fileUploaded = (response: any) => {    
    response.files?.forEach((file: any) => {
      dispatch(updateDataFile(file));      
    });    

    response.imageFiles?.forEach((file: any) => {
      dispatch(addImageFile(file));      
    });    

    response.rawFiles?.forEach((file: any) => {
      dispatch(addRawFile(file));
    });    
  };

  const download = (fileName: any, fileType: number = 3) => 
  {
    const fileUrl = `${apiUrl}client/${clientId}/project/${projectId}/borehole/${boreholeId}/download?fileName=${encodeURIComponent(fileName)}&fileType=${fileType}&access_token=${auth.getTokenForImages()}`;
    fetch(fileUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link: any = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        fileName
      );
  
      // Append to html link element page
      document.body.appendChild(link);
  
      // Start download
      link.click();
  
      // Clean up and remove the link
      link.parentNode.removeChild(link);
      setChecked([]);
      setIsDownloadingFiles(false);
    });
  };

  const dowloadMultiple = (fileNames: any[]) => 
  {
    //downloadFiles({clientId, projectId, boreholeId, fileNames});
    setIsDownloadingFiles(true);
    setCreateZipProgress({percent: null, message: "Queueing", fileName: "fileName", failed: false});
    const fileUrl = `${apiUrl}client/${clientId}/project/${projectId}/borehole/${boreholeId}/download`;
    fetch(fileUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'authorization': `Bearer ${auth.getToken()}`,
      },      
      body: JSON.stringify({ fileNames })
    });    
  };

  const onShowDataFileErrors = (uploadType: any, fileName: string) => {
    dispatch(showDataFileErrors({uploadType, fileName}));
  };

  const onDeleteDataFile = (uploadType: any, uploadTypeName: string, fileName: string, lithologyTrackTypeId: any) => {
    confirmAlert({
      title: 'Confirm Delete Data File',
      message: `Are you sure you want to delete ${uploadTypeName}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            dispatch(deleteDataFile({clientId, projectId, boreholeId, uploadType, fileName, lithologyTrackTypeId}));
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };

  const onRunMachineLearning = () => {
    dispatch(runMachineLearning({clientId, projectId, boreholeId}));    
  };

  const onRemoveDataFile = (uploadType: any, fileName: string) => {    
    dispatch(removeDataFile({ uploadType, fileName }));
  }

  const onShowAllImageTypes = () => {
    setShowAllImageTypes(!showAllImageTypes);
  };

  const onShowAllDataTypes = () => {
    setShowAllDataTypes(!showAllDataTypes);
  };

  const onDeleteRawFiles = (fileNames:any[]) => {
    dispatch(deleteRawFiles({clientId, projectId, boreholeId, fileNames }));
  }

  useEffect(() => {
    setShowExitPrompt(isUploading);
  }, [isUploading]);

  const apiUrl = getConfig().apiUrl;

  const canRunMachineLearning = dataFileUploads?.some((i:any) => i.processed && i.uploadType == 10) && dataFileUploads?.some((i:any) => i.processed && i.uploadType == 1);

  return (
    <section className="section">
      <div>        
        {(isLoading || isScalingImages || isUploading || isScalingData || isCreatingDeepZoomImages) && <Loader />}  
        <h2 className="mt-2" style={{textAlign: "center"}}>{boreholeName}</h2>
        <h4>Upload Files</h4>      
        <Errors error={error || filesError || rawError} />
        {(isLoading || isDeleteingImageFiles || isDeleteingRawFiles) && <Loader />}
        <Prompt when={isUploading} message="Are you sure you want to cancel uploading files?" />
        {(!isLoading) &&
          <div>
            <Upload boreholeId={boreholeId} projectId={projectId} clientId={clientId} setError={onSetError} fileUploaded={fileUploaded} setIsUploading={setIsUploading}/>
            {extractZipProgress && <div>{Object.values(extractZipProgress).map((zipFileProgress: any, index: any) => (
              <div key={zipFileProgress.zipFileName} className="mb-2">
                  {zipFileProgress.zipFileName}
                  <Progress progress={zipFileProgress} />
              </div>
            ))}</div>  }
          </div>}        
          <Errors error={uploadError?.response || uploadError} />
          <div className="buttons mt-2">
            <Link to={`/client/${clientId}/project/${projectId}/borehole/${boreholeId}/log-viewer`} className="button">Log Viewer</Link>
            <Link to={`/client/${clientId}/project/${projectId}/borehole/${boreholeId}/image-viewer`} className="button">Image Viewer</Link>
          </div>                
        {!filesLoading && 
        <>
        <h4>Data Files</h4>
        <Errors error={scaleDataError} />
        {(showLithofaciesMachineLearning && canRunMachineLearning) && <button onClick={onRunMachineLearning} className={`button ${isRunningMachineLearning ? "is-loading" : ""}`}>Predict Lithofacies</button>}
        <div className="buttons mt-2">
            <button className='button' onClick={onShowAllDataTypes}>{showAllDataTypes ? "Hide non-uploaded" : "Show All"}</button>        
        </div>
        <table className="table is-narrow upload-table">
          <thead>
            <tr>
              <th className="type-column">Type</th>
              <th className="has-text-centered">Uploaded</th>
              <th className="has-text-centered">Processed</th>
              <th className="has-text-centered">Scaled</th>
              <th>Progress</th>
              <th>File Name</th>
              <th>File Size</th>
              <th>Uploaded</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {(dataFileUploads?.filter((i:any) => i.inProgress || i.isUploaded || showAllDataTypes) || []).map((dataFileUpload: any, index: number) => {
              const uploaded = dataFileUpload.isUploaded;
              return (
                <>
                  <tr key={index}>
                    <td className="type-column">{dataFileUpload.uploadTypeDescription}</td>
                    <td className="has-text-centered"><span className="icon">{uploaded ?  
                          <i className="fas fa-check"></i>
                        : <i className="fas fa-times"></i>}
                        </span>
                    </td>
                    <td className="has-text-centered"><span className="icon">{dataFileUpload.processed ?  
                          <i className="fas fa-check"></i>
                        : <i className="fas fa-times"></i>}
                        {((dataFileUpload.errors?.length > 0 || dataFileUpload.extraColumnsFound?.length > 0 || dataFileUpload.columnsNotFound?.length > 0)) && <button onClick={() => onShowDataFileErrors(dataFileUpload.uploadType, dataFileUpload.fileName)} className="button is-small ml-2 mt-1">Errors</button>}
                        </span>
                    </td>
                    <td className="has-text-centered"> {dataFileUpload.requiresScaling && <span className="icon">                     
                      {dataFileUpload.scaled ?  
                          <i className="fas fa-check"></i>
                        : <><i className="fas fa-times"></i>{dataFileUpload.processed && <button onClick={() => onScaleData(dataFileUpload.uploadType, dataFileUpload.fileName)} className="button is-small ml-2 mt-1" disabled={dataFileUpload.inProgress}>Scale</button>}</>}
                        </span> }
                    </td>
                    <td className="progress-column">                    
                      {dataFileUpload.inProgress && <Progress progress={{progress: dataFileUpload.progress, subProgress: dataFileUpload.subProgress, message: dataFileUpload.progressMessage, failed: dataFileUpload.failed }} />}
                    </td>                
                    <td>
                      {dataFileUpload.containsFile && <a download={dataFileUpload.fileName} href={`${apiUrl}client/${clientId}/project/${projectId}/borehole/${boreholeId}/download?fileName=${dataFileUpload.fileName}&fileType=1&uploadType=${dataFileUpload.uploadType}&access_token=${auth.getTokenForImages()}`}>{dataFileUpload.fileName}</a>}
                      {!dataFileUpload.containsFile && <>{dataFileUpload.fileName}</> }
                    </td>
                    <td>{formatBytes(dataFileUpload.fileSize)}</td>
                    <td>{dataFileUpload.uploaded && format(new Date(dataFileUpload.uploaded), 'dd/MM/yyyy HH:mm:ss')}</td>
                    <td>
                    {(!dataFileUpload.inProgress && uploaded) && <>                        
                          <button className="button" onClick={() => onDeleteDataFile(dataFileUpload.uploadType, dataFileUpload.uploadTypeDescription, dataFileUpload.fileName, dataFileUpload.lithologyTrackTypeId)}><img src="/images/icons/Atlas Delete Icon.svg" title="Delete" className='delete-file-icon' /></button>
                        </>} 
                    </td>
                  </tr>     
                  {(dataFileUpload.showErrors) && <tr>  
                    {/* || dataFileUpload.errors?.length || dataFileUpload.extraColumnsFound?.length || dataFileUpload.columnsNotFound?.length */}
                      <td colSpan={9}>
                        <table>
                          <thead>
                            <th>Errors</th>
                            <th>Extra Columns</th>
                            <th>Columns Not Found</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{(dataFileUpload.errors || []).map((error:string) => (<>{error}<br/></>))}</td>
                              <td>{(dataFileUpload.extraColumnsFound || []).map((extraColumn:string) => (<>{extraColumn}<br/></>))}</td>
                              <td>{(dataFileUpload.columnsNotFound || []).map((column:string) => (<>{column}<br/></>))}</td>
                            </tr>
                          </tbody>
                        </table>
                        
                      </td>
                  </tr>}
                </>
              )
            }
            )}
          </tbody>
        </table>

       <BoreholeImageFiles onUpdateImageFileDownloadProgress={onUpdateImageFileDownloadProgress} />

      <RawFiles boreholeId={boreholeId} 
                clientId={clientId} 
                projectId={projectId} 
                setCreateZipProgress={setCreateZipProgress} 
                createZipProgress={createZipProgress}
                isDownloadingFiles={isDownloadingFiles} 
                setIsDownloadingFiles={setIsDownloadingFiles}
                isDeletingFiles={isDeletingFiles}
                setIsDeletingFiles={setIsDeletingFiles}
                checked={checked} 
                setChecked={setChecked}
                isFetchingRawFiles={isFetchingRawFiles}
                rawFiles={rawFiles}
                deleteRawFiles={onDeleteRawFiles} />
      </>
    }

    </div>
    </section>
  )
};

export default BoreholeFiles;
/*
const mapDispatchToProps = (dispatch: any) => ({
  setUploadFile: (files: any) => dispatch(setUploadFile(files)),
})

export default connect(null, mapDispatchToProps)(Borehole);
*/