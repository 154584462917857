import React, { useEffect, useState } from 'react';
import Errors from '../../../components/errors';
import Loader from '../../../components/loader';
import Form from './form';

const UserContainer = ({ title, userIsLoading, user, onSave, isLoading, error }:any) => {

    const [name, setName] = useState('');    
    const [emailAddress, setEmailAddress] = useState('');        
    
    useEffect(() => {
        if (!userIsLoading && user) {
            setName(user.name);
            setEmailAddress(user.emailAddress);    
        }
    },[userIsLoading, user]);

    const onSaveUser = (e: any) => {
        e.preventDefault();
        onSave({emailAddress });
     };
    
    const onSetEmailAddress = (emailAddress: any) => {
        setEmailAddress(emailAddress);
    };

    return (
        <section className="section">
            <div>
                <h1>{title}</h1>
                <Errors error={error}/>
                {(isLoading) && <Loader/>}
                <Form name={name}                       
                      emailAddress={emailAddress}                      
                      setEmailAddress={onSetEmailAddress} 
                      onSaveUser={onSaveUser}                      
                       />
            </div>
        </section>
    )    
};

export default UserContainer;