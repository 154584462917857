import React, { useEffect } from 'react';

const LocationPin = (props: any) => {
    const { id, text, image, onClick, clickable, name, selectedBorehole, setSelectedBorehole } = props;
    const K_WIDTH = 40;
    const K_HEIGHT = 40;

    const greatPlaceStyle: any = {
        // initially any map object has left top corner at lat lng coordinates
        // it's on you to set object origin to 0,0 coordinates
        position: 'absolute',
        width: K_WIDTH,
        height: K_HEIGHT,
        left: -K_WIDTH / 2,
        top: -K_HEIGHT / 2,
      
        border: '5px solid rgba(0, 54, 113, 1)',
        borderRadius: K_HEIGHT,
        backgroundColor: 'white',
        textAlign: 'center',
        color: '#395471',
        fontSize: 16,
        fontWeight: 'bold',
        padding: 4,
        cursor: 'pointer'
      };

    const greatPlaceStyleHover = {
    ...greatPlaceStyle,
    border: '5px solid #e48f00',
    //color: '#e48f00'
    };

    const isHover =  props.$hover;
    useEffect(() => {
        if (isHover) {
            setSelectedBorehole(id);
        } else {
            setSelectedBorehole(null);
        }
    }, [isHover]);

    const style = isHover || selectedBorehole === id ? greatPlaceStyleHover : greatPlaceStyle;

    return (
        <div>
            <div style={style} onClick={onClick}>
                {text}            
            </div>
            {name && (isHover || selectedBorehole === id) && <div style={{position: "relative", top: "-7px", right: `${(-K_WIDTH / 2) - 2}px`, backgroundColor:"white", width: "100px", whiteSpace: "nowrap", padding: "2px" }}>{name}</div>}
        </div>
  )}

  export default LocationPin;