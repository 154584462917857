import React from 'react';
import TextField from '../../../components/Input/text-field';

const ChangePasswordForm = (props: any) => {

    const { currentPassword, 
        setCurrentPassword, 
        newPassword, 
        setNewPassword,
        confirmNewPassword, 
        setConfirmNewPassword,
        handleSubmit,        
        currentPasswordErrors,
        newPasswordErrors,
        confirmNewPasswordErrors } = props;

        const onHandleSubmit = (e: any) => {
            e.preventDefault();
            handleSubmit();
        }

return (
    <div className="container">
        <div className="columns is-centered">
            <div className="column is-5-tablet is-4-desktop is-4-widescreen">
                <form className="box" onSubmit={onHandleSubmit}>
                    <h4>Change Password</h4>
                    <TextField type="password" label="Current Password" errors={currentPasswordErrors} value={currentPassword} onChange={setCurrentPassword} icon="fa-lock"></TextField>
                    <TextField type="password" label="New Password" errors={newPasswordErrors} value={newPassword} onChange={setNewPassword} icon="fa-lock"></TextField>
                    <TextField type="password" label="Confirm New Password" errors={confirmNewPasswordErrors} value={confirmNewPassword} onChange={setConfirmNewPassword} icon="fa-lock"></TextField>

                    <div className="field is-grouped">
                        <div className="control mt-3">
                            <button type="submit" className="button is-primary">Change Password</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>        
)}

export default ChangePasswordForm;