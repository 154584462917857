import React, { useEffect, useState } from 'react';
import Errors from '../../../components/errors';
import Loader from '../../../components/loader';
import Form from './form';
import { useClientPriorityQuery } from '../../../services/enumApi';

const ClientContainer = ({ title, clintIsLoading, client, onSave, isLoading, error, readOnly }:any) => {
    
    const [name, setName] = useState('');
    const [priority, setPriority] = useState();

    const { data: clientPriorities, isLoading: clientPrioritiesLoading } = useClientPriorityQuery();
    
    useEffect(() => {
        if (!clintIsLoading && client) {
            setName(client.name);
            setPriority(client.priority);
        }
    },[clintIsLoading, client]);

    const onUpdateClient = (e: any) => {
        e.preventDefault();
        onSave({name, priority });
      };
   
    return (
        <section className="section">
            <div>
                <h1>{title}</h1>
                <Errors error={error}/>
                {(isLoading || clientPrioritiesLoading) && <Loader/>}

                <Form name={name}                      
                    setName={setName}       
                    priority={priority} 
                    setPriority={setPriority}
                    clientPriorities={clientPriorities}
                    onSave={onUpdateClient}   
                    readOnly={readOnly}
                    />
            </div>
        </section>
    )    
};

export default ClientContainer;