import React from 'react';
import { Link } from 'react-router-dom';

const List = ({ clients, onDelete, canAdd, canEdit, canDelete }:any) => {

    return (
            <div className="columns">
              <div className="column is-half">
                  <table className="table">
                      <thead>
                          <tr>
                              <th>Name</th>
                              <th>Actions</th>
                          </tr>
                      </thead>
                      <tbody>
                       {(clients || []).map((client: any, index: number) => (
                            <tr key={index}>
                                <td>{client.name}</td>                                
                                <td>{canEdit && <><Link to={`edit/${client.id}`}>Edit</Link> &nbsp;&nbsp;</>}
                                {canDelete && <a onClick={() => onDelete(client.id, client.name)}>Delete</a>}
                                </td>
                            </tr>
                        ))}
                      </tbody>
                  </table>
                  {canAdd && 
                    <div className="field is-grouped">
                        <div className="control">
                            <Link className="button" to={`add`}>Add Client</Link>
                        </div>                  
                    </div>
                   }                    
              </div>
            </div>
    )    
};

export default List;