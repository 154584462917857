import React from 'react';
import { Link } from 'react-router-dom';
import Errors from '../../../components/errors';
import TextField from '../../../components/Input/text-field';

const LoginForm = (props: any) => {

    const { emailAddress, 
        setEmailAddress, 
        password, 
        setPassword,
        handleSubmit,
        emailError,
        passwordError,
        loginError,
        loggedOut,
        showClientList,
        showMfa,
        clientList, 
        selectClient,
        authenticatorCode, 
        setAuthenticatorCode,
        showRecoveryMfa, 
        setShowRecoveryMfa,
        recoveryAuthenticatorCode, 
        setRecoveryAuthenticatorCode,
        onLoginWithRecoverAndChangeTwoFactor,
        remainingRecoveryCodes } = props;

    const onHandleSubmit = (e: any) => {
        e.preventDefault();
        handleSubmit();
    }

    const emailAddressErrors = emailError ? ["Email Address incorrect "] : null;
    const passwordErrors = passwordError ? ["Password incorrect"] : null;

    const onSelectClient = (e: any, clientId: string) => {
        e.preventDefault();
        selectClient(clientId);
    };

    const onOnLoginWithRecoverAndChangeTwoFactor = (e: any) => {
        e.preventDefault();
        onLoginWithRecoverAndChangeTwoFactor();
    }

const minimumRemainingRecoverCodes: number = 1;

return (
    <div className="container">
        <div className="columns is-centered">
            <div className="column is-5-tablet is-4-desktop is-4-widescreen">
                <form className="box" onSubmit={onHandleSubmit}>
                    <h4>Login</h4>
                    {loggedOut &&<div className="mb-2">You have been logged out</div>}
                    {!showClientList && !showMfa && <div>
                        <TextField
                            type="text" 
                            label="Email Address" 
                            errors={emailAddressErrors} 
                            value={emailAddress} 
                            onChange={setEmailAddress}
                            icon="fa-user"
                        />

                        <TextField
                            type="password" 
                            label="Password" 
                            errors={passwordErrors} 
                            value={password} 
                            onChange={setPassword}
                            icon="fa-lock"
                        />
                        </div>}

                    {showClientList && !showMfa && <div>
                            <h4>Please select client</h4>
                            {clientList.map((client: any, index: number) => (
                                <div key={index}><a href="" onClick={(e: any) => onSelectClient(e, client.id)}>{client.name}</a></div>
                            ))}
                        </div>}

                    {showMfa && !showClientList && <div>
                        {!showRecoveryMfa &&
                        <>
                            <TextField                             
                                label="Authenticator Code"                             
                                value={authenticatorCode} 
                                onChange={setAuthenticatorCode}
                                icon="fa-lock"
                                maxLength={6}
                            />
                            <button className='button' onClick={setShowRecoveryMfa}>Use recovery code</button>
                        </>}
                        {showRecoveryMfa && <div>
                            {remainingRecoveryCodes > minimumRemainingRecoverCodes && <div>You have {remainingRecoveryCodes} one-use recovery codes remaining. If you choose to change 2FA now and abort the process, 2FA will be removed from your account, and you will need to re-enable it at the next login.</div>}
                            {remainingRecoveryCodes <= minimumRemainingRecoverCodes && <div>You have {remainingRecoveryCodes} remaining recovery codes. If you choose to change 2FA now and abort the process, 2FA will be removed from your account, and you will need to re-enable it at the next login.</div>}
                            
                            <TextField                             
                                label="Recovery Code"                             
                                value={recoveryAuthenticatorCode} 
                                onChange={setRecoveryAuthenticatorCode}
                                icon="fa-lock"
                            /></div>}
                        </div>}

                    <Errors error={loginError}/>
                    
                    {!showClientList && <div className="field is-grouped">
                        {(!showRecoveryMfa || remainingRecoveryCodes > minimumRemainingRecoverCodes) && <div className="control mt-3">
                            <button type="submit" className="button is-primary">Login</button>
                        </div>}
                        {showRecoveryMfa && 
                            <div className="control mt-3">
                                <button className="button" onClick={onOnLoginWithRecoverAndChangeTwoFactor}>Login and change 2FA</button>
                            </div>
                        }
                    </div>}
                </form>

                <Link to={{
                    pathname: "/forgot-password",
                    state: { emailAddress: emailAddress }
                    }}>Forgot Password</Link>
            </div>
        </div>
    </div>        
)}

export default LoginForm;