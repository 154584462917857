import { createSlice, createAsyncThunk, PayloadAction, current } from "@reduxjs/toolkit";
import getConfig from "../app/config";
import { addTadpoleTrack } from "../features/borehole/templateSlice";
import fetchWithRefresh from "../services/fetchWithRefresh";

export const getFiles = createAsyncThunk<any, {clientId: string, projectId: string, boreholeId: string}>(
    "boreholeFiles/getFiles",
    async (arg) => {
      const { clientId, projectId, boreholeId } = arg;                                                    
      return await fetchWithRefresh(getConfig().apiUrl + `client/${clientId}/project/${projectId}/borehole/${boreholeId}/files`);
    }
);

export const getRawFiles = createAsyncThunk<any, {clientId: string, projectId: string, boreholeId: string}>(
  "boreholeFiles/getRawFiles",
  async (arg) => {
    const { clientId, projectId, boreholeId } = arg;                                                    
    return await fetchWithRefresh(getConfig().apiUrl + `client/${clientId}/project/${projectId}/borehole/${boreholeId}/download/list`);
  }
);

export const deleteImageFile = createAsyncThunk<any, {clientId: string, projectId: string, boreholeId: string, imageType: number, imageFileId: string}>(
  "boreholeFiles/deleteImageFile",
  async (arg, thunkApi) => {
    const { clientId, projectId, boreholeId, imageFileId } = arg;
    return await fetchWithRefresh(getConfig().apiUrl + `client/${clientId}/project/${projectId}/borehole/${boreholeId}/upload/image/${imageFileId}`, thunkApi, {
      method: 'DELETE',
      headers: {       
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }});
  }
);

export const deleteImageFiles = createAsyncThunk<any, {clientId: string, projectId: string, boreholeId: string, imageType: number}>(
  "boreholeFiles/deleteImageFiles",
  async (arg, thunkApi) => {
    const { clientId, projectId, boreholeId, imageType } = arg;                                                    
    return await fetchWithRefresh(getConfig().apiUrl + `client/${clientId}/project/${projectId}/borehole/${boreholeId}/upload/images/${imageType}`, thunkApi, {
      method: 'DELETE',
      headers: {       
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }});
  }
);

export const deleteSelectedImageFiles = createAsyncThunk<any, {clientId: string, projectId: string, boreholeId: string, imageType: number, fileIds: any}>(
  "boreholeFiles/deleteSelectedImageFiles",
  async (arg, thunkApi) => {
    const { clientId, projectId, boreholeId, imageType, fileIds } = arg;                                                    
    return await fetchWithRefresh(getConfig().apiUrl + `client/${clientId}/project/${projectId}/borehole/${boreholeId}/upload/images/${imageType}/selected`, thunkApi, {
      method: 'DELETE',
      headers: {       
        'Content-Type': 'application/json',
        Accept: 'application/json'        
      },
      body: JSON.stringify({fileIds})});    
  }
);

export const downloadSelectedImageFiles = createAsyncThunk<any, {clientId: string, projectId: string, boreholeId: string, imageType: number, fileIds: any}>(
  "boreholeFiles/downloadSelectedImageFiles",
  async (arg, thunkApi) => {
    const { clientId, projectId, boreholeId, imageType, fileIds } = arg;    
    return await fetchWithRefresh(getConfig().apiUrl + `client/${clientId}/project/${projectId}/borehole/${boreholeId}/upload/images/${imageType}/selected`, thunkApi, {
      method: 'POST',
      headers: {       
        'Content-Type': 'application/json',
        Accept: 'application/json'        
      },
      body: JSON.stringify({fileIds})});    
  }
);

export const deleteRawFiles = createAsyncThunk<any, {clientId: string, projectId: string, boreholeId: string, fileNames: any[]}>(
  "boreholeFiles/deleteRawFiles",
  async (arg, thunkApi) => {
    const { clientId, projectId, boreholeId, fileNames } = arg;                                                    
    return await fetchWithRefresh(getConfig().apiUrl + `client/${clientId}/project/${projectId}/borehole/${boreholeId}/files`, thunkApi, {
      method: 'DELETE',
      headers: {       
        'Content-Type': 'application/json',
        Accept: 'application/json'        
      },
      body: JSON.stringify({fileNames})});
  }
);

export const deleteDataFile = createAsyncThunk<any, {clientId: string, projectId: string, boreholeId: string, uploadType: number, fileName: string, lithologyTrackTypeId: any}>(
  "boreholeFiles/deleteDataFile",
  async (arg, thunkApi) => {
    const { clientId, projectId, boreholeId, uploadType, fileName, lithologyTrackTypeId } = arg;
    const lithologyUrl = lithologyTrackTypeId ? `/${lithologyTrackTypeId}` : "";
    return await fetchWithRefresh(getConfig().apiUrl + `client/${clientId}/project/${projectId}/borehole/${boreholeId}/upload/data/${uploadType}/file/${fileName}/LithologyTrackTypeId${lithologyUrl}`, thunkApi, {
      method: 'DELETE',
      headers: {       
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }});
  }
);

export const createDeepZoomImages = createAsyncThunk<any, {clientId: string, projectId: string, boreholeId: string, uploadType: number}>(
  "boreholeFiles/createDeepZoomImages",
  async (arg, thunkApi) => {
    const { clientId, projectId, boreholeId, uploadType } = arg;                                                    
    return await fetchWithRefresh(getConfig().apiUrl + `client/${clientId}/project/${projectId}/borehole/${boreholeId}/image/CreateDeepZoom`, thunkApi, {
      method: 'DELETE',
      headers: {       
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }});
  }
);

export const runMachineLearning = createAsyncThunk<any, {clientId: string, projectId: string, boreholeId: string}>(
  "boreholeFiles/runMachineLearning",
  async (arg, thunkApi) => {
    const { clientId, projectId, boreholeId } = arg;                                                    
    return await fetchWithRefresh(getConfig().apiUrl + `client/${clientId}/project/${projectId}/borehole/${boreholeId}/machineLearning`, thunkApi, {
      method: 'POST',
      headers: {       
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: "{}"});
  }
);

export interface BoreholeFilesState {
    filesLoading: boolean,
    rawFilesLoading: boolean,
    name: string | null,
    dataFileUploads: any[],
    imageFileUploads: any[],
    rawFiles: any[],
    error: any,
    rawError: any,
    deleteImageFileError: any,
    isDeleteingImageFiles: boolean,
    isDeleteingRawFiles: boolean,
    deleting: boolean,
    totalStorageSize: number,
    showLithofaciesMachineLearning: boolean,
    isRunningMachineLearning: boolean
  }
  
  const initialState: BoreholeFilesState = {
    filesLoading: false,
    rawFilesLoading: false,
    name: null,
    dataFileUploads: [],
    imageFileUploads: [],
    rawFiles: [],
    error: null,
    rawError: null,
    deleteImageFileError: null,
    isDeleteingImageFiles: false,
    deleting: false,
    isDeleteingRawFiles: false,
    totalStorageSize: 0,
    showLithofaciesMachineLearning: false,
    isRunningMachineLearning: false
  }

  const updateDataFileOffline = (state: BoreholeFilesState, dataFile: any) => {
    const { fileName, fileSize, uploadType, uploaded, isUploaded, containsFile, lithologyTrackTypeId } = dataFile;    
    var dataFile = state.dataFileUploads.find((f: any) => f.uploadType === uploadType && (f.lithologyTrackTypeId == lithologyTrackTypeId || !f.allowMultipleFiles));
    // if dataFile does not exist, then check to see if there is already one of this upload type,
    // if there is, then need to add a new one, otherwise use the single one that is there already
    if (!dataFile) {
      dataFile = state.dataFileUploads.find((f: any) => f.uploadType === uploadType);
      if (dataFile) {
        // add a new one
        dataFile = { uploadType, uploadTypeDescription: dataFile.uploadTypeDescription, allowMultipleFiles: dataFile.allowMultipleFiles };
        state.dataFileUploads.push(dataFile);
        state.dataFileUploads.sort((a: any, b: any) => {              
          return ''.concat(a.uploadType, a.fileName).toLowerCase().localeCompare(''.concat(b.uploadType, b.fileName).toLowerCase());
        });
      }
    }
    if (dataFile) {
      dataFile.fileName = fileName;
      dataFile.fileSize = fileSize;
      dataFile.uploaded = uploaded;
      dataFile.isUploaded = isUploaded ?? true;
      dataFile.processed = false;
      dataFile.scaled = false;
      dataFile.progressMessage = "Pending";
      dataFile.failed = false;
      dataFile.progress = null;
      dataFile.subProgress = null;
      dataFile.inProgress = true;
      dataFile.containsFile = containsFile ?? true;
    }
  }

const templateSlice = createSlice({
    name: 'boreholeFiles',
    initialState,
    reducers: {      
      updateDataFile: (state, action: PayloadAction<any>) => {
        updateDataFileOffline(state, action.payload);      
      },
      updateDataFileProgress: (state, action: PayloadAction<any>) => {                
        
        const { uploadType, percent, message, fileName, failed } = action.payload;          
        var dataFile = state.dataFileUploads.find((f: any) => f.uploadType === uploadType && f.fileName === fileName);
        if (dataFile) {
          dataFile.progress = percent;
          dataFile.progressMessage = message;
          dataFile.inProgress = true;
          dataFile.failed = failed;
          if (percent == 100) {            
            dataFile.inProgress = false;
          }
        }
      },
      showDataFileErrors: (state, action: PayloadAction<any>) => {        
        const { uploadType, fileName } = action.payload;          
        var dataFile = state.dataFileUploads.find((f: any) => f.uploadType === uploadType && f.fileName === fileName);
        if (dataFile) {
          dataFile.showErrors = !dataFile.showErrors;          
        }
      },
      updateDataFileProcessed: (state, action: PayloadAction<any>) => {        
        const { uploadType, lithologyTrackTypeId, errors, extraColumnsFound, columnsNotFound } = action.payload;                          
        var dataFile = state.dataFileUploads.find((f: any) => f.uploadType === uploadType && f.lithologyTrackTypeId == lithologyTrackTypeId);
        if (dataFile) {
            dataFile.processed = !errors || errors.length === 0;
            dataFile.errors = errors;
            dataFile.extraColumnsFound = extraColumnsFound;
            dataFile.columnsNotFound = columnsNotFound;
        }
      },
      updateDataFileScaled: (state, action: PayloadAction<any>) => {        
        const { uploadType, fileName } = action.payload;          
        var dataFile = state.dataFileUploads.find((f: any) => f.uploadType === uploadType && f.fileName === fileName);
        if (dataFile) {
            dataFile.scaled = true;            
        }
      },      
      updateScaleDataFileProgress: (state, action: PayloadAction<any>) => {                
        const { uploadType, percent, message, fileName, failed } = action.payload;          
        var dataFile = state.dataFileUploads.find((f: any) => f.uploadType === uploadType && f.fileName === fileName);
        if (dataFile) {
          dataFile.progress = percent;
          dataFile.progressMessage = message;
          dataFile.inProgress = true;
          dataFile.failed = failed;
          if (percent == 100) {
            dataFile.scaled = true;
            dataFile.inProgress = false;
          }
        }
      },
      removeDataFileProgress: (state, action: PayloadAction<any>) => {        
        const { uploadType, fileName } = action.payload;        
        var dataFile = state.dataFileUploads.find((f: any) => f.uploadType === uploadType && f.fileName === fileName);
        if (dataFile) {
          dataFile.progress = null;
          dataFile.progressMessage = null;
          dataFile.inProgress = false;
          dataFile.failed = false;          
        }
      },
      cancelDataFileProgress: (state, action: PayloadAction<any>) => {        
        const { uploadType, fileName } = action.payload;        
        var dataFile = state.dataFileUploads.find((f: any) => f.uploadType === uploadType && f.fileName === fileName);
        if (dataFile) {     
          if (!dataFile.progress) {
            dataFile.progress = 0;
          }

          dataFile.progressMessage = "Cancelled";
          dataFile.failed = true;
        }
      },
      updateDataFileSubProgress: (state, action: PayloadAction<any>) => {     
        const { uploadType, fileName, percent } = action.payload;          
        var dataFile = state.dataFileUploads.find((f: any) => f.uploadType === uploadType && f.fileName === fileName);
        if (dataFile) {
          dataFile.subProgress = percent;          
        }
      },
      removeDataFile: (state, action: PayloadAction<any>) => {              
        const { uploadType, fileName } = action.payload;
        var dataFile = state.dataFileUploads.find((f: any) => f.uploadType === uploadType && f.fileName === fileName);
        if (dataFile) {          
          dataFile.progress = 100;
          dataFile.isUploaded = false;
          dataFile.inProgress = false;
          dataFile.processed = false;
        }
      },
      updateImageFileProgress: (state, action: PayloadAction<any>) => {                
        const { imageType, percent, message, failed, warning } = action.payload;          
        var imageFile = state.imageFileUploads.find((f: any) => f.imageType === imageType);
        if (imageFile) {        
          imageFile.progress = percent;
          imageFile.progressMessage = message;
          imageFile.inProgress = true;
          imageFile.failed = failed;
          imageFile.warning = warning;
          if (percent == 100) {
            imageFile.inProgress = false;
          }
        }
      },
      updateImageFileSubProgress: (state, action: PayloadAction<any>) => {        
        const { imageType, percent } = action.payload;          
        var imageFile = state.imageFileUploads.find((f: any) => f.imageType === imageType);
        if (imageFile) {        
          imageFile.subProgress = percent;          
        }
      },
      updateImageFileDownloadProgress: (state, action: PayloadAction<any>) => {        
        const { percent, message, fileName, imageTypeId, failed } = action.payload;          
        var imageFile = state.imageFileUploads.find((f: any) => f.imageType === imageTypeId);
        if (imageFile) {        
          imageFile.downloadProgress = message ? action.payload : null
        }
      },
      removeScaleImageProgress: (state, action: PayloadAction<any>) => {        
        const { imageType } = action.payload;          
        var imageFile = state.imageFileUploads.find((f: any) => f.imageType === imageType);
        if (imageFile) {        
          imageFile.progress = null; 
          imageFile.subProgress = null; 
          imageFile.inProgress = false; 
        }
      },
      cancelScaleImageProgress: (state, action: PayloadAction<any>) => {        
        const { imageType } = action.payload;          
        var imageFile = state.imageFileUploads.find((f: any) => f.imageType === imageType);
        if (imageFile) {        
          if (!imageFile.progress) {
            imageFile.progress = 0;
          }

          imageFile.progressMessage = "Cancelled";      
          imageFile.failed = true;
        }
      },
      updateImageFileScaled: (state, action: PayloadAction<any>) => {        
        const { imageType } = action.payload;                  
        var imageFile = state.imageFileUploads.find((f: any) => f.imageType === imageType);        
        if (imageFile) {
          imageFile.scaled = true;
          imageFile.boreholeDepthNeedsRegenerating = false;
          imageFile.loggedDepthNeedsRegenerating = false;
        }
      },
      updateImageFileDeepZoomProgress: (state, action: PayloadAction<any>) => {        
        const { imageType } = action.payload;
        var imageFile = state.imageFileUploads.find((f: any) => f.imageType === imageType);
        if (imageFile) {    
          const inProgress = action.payload.progress < 100;                    
          imageFile.deepZoomProgress = {...imageFile.deepZoomProgress, ...action.payload, inProgress };
        }
      },
      updateImageFileDeepZoomSubProgress: (state, action: PayloadAction<any>) => {        
        const { imageType, progress } = action.payload;          
        var imageFile = state.imageFileUploads.find((f: any) => f.imageType === imageType);
        if (imageFile?.deepZoomProgress) {        
          imageFile.deepZoomProgress.subProgress = progress;          
        }
      },
      updateImageFileDeepZoomCompleted: (state, action: PayloadAction<any>) => {
        const { imageType } = action.payload;          
        var imageFile = state.imageFileUploads.find((f: any) => f.imageType === imageType);
        if (imageFile?.deepZoomProgress) {        
          imageFile.isDeepZoom = true;          
          imageFile.deepZoomNeedsRegenerating = false;
        }
      },
      removeDeepZoomImageProgress: (state, action: PayloadAction<any>) => {        
        const { imageType } = action.payload;          
        var imageFile = state.imageFileUploads.find((f: any) => f.imageType === imageType);
        if (imageFile) {        
          imageFile.deepZoomProgress = null;           
        }
      },
      cancelDeepZoomImageProgress: (state, action: PayloadAction<any>) => {        
        const { imageType } = action.payload;          
        var imageFile = state.imageFileUploads.find((f: any) => f.imageType === imageType);
        if (imageFile) {                           
          if (!imageFile.deepZoomProgress.progress) {
            imageFile.deepZoomProgress.progress = 0;
          }

          imageFile.deepZoomProgress.message = "Cancelled";    
          imageFile.deepZoomProgress.failed = true;       
        }
      },
      scaleImage: (state, action: PayloadAction<any>) => {        
        const { imageType } = action.payload;          
        var imageFile = state.imageFileUploads.find((f: any) => f.imageType === imageType);
        if (imageFile) {        
          imageFile.progressMessage = "Pending";
          imageFile.inProgress = true;
          imageFile.failed = false;
        }
      },
      deepZoomImage: (state, action: PayloadAction<any>) => {        
        const { imageType } = action.payload;          
        var imageFile = state.imageFileUploads.find((f: any) => f.imageType === imageType);
        if (imageFile) {        
          const deepZoomProgress = {message: "Pending", progress: null, inProgress: true, failed: false };
          imageFile.deepZoomProgress = {...imageFile.deepZoomProgress, ...deepZoomProgress};                    
        }
      },
      addImageFile: (state, action: PayloadAction<any>) => {        
        const { fileName, fileSize, imageType, uploaded, id } = action.payload;   
        var imageFile = state.imageFileUploads.find((f: any) => f.imageType === imageType);
        if (imageFile) {          
          imageFile.isUploaded = true;  
          imageFile.deepZoomNeedsRegenerating = true;
          imageFile.loggedDepthNeedsRegenerating = true;
          imageFile.boreholeDepthNeedsRegenerating = true;
          if (!imageFile.files) {
            imageFile.files = [];
          }

          var existingImageFile = imageFile.files.find((f: any) => f.fileName === fileName);
          if (existingImageFile) {
            existingImageFile.fileSize = fileSize;
            existingImageFile.uploaded = uploaded;
          } else {
            imageFile.files.push({fileName, fileSize, uploaded, id });         
            imageFile.files.sort((a: any, b: any) => {
              return a.fileName.toLowerCase().localeCompare(b.fileName.toLowerCase());
            });
          }
        }        
      },
      addRawFile: (state, action: PayloadAction<any>) => {        
        const { fileName, fileSize, path } = action.payload;   
        
        //TODO: Use Path to put in correct place
        let existingFile = state.rawFiles.find((file: any) => file.name === fileName);
        if (existingFile) {
          existingFile.size = fileSize;
        } else {        
          state.rawFiles.push({fullName: fileName, name: fileName, size: fileSize, children: null });
          state.rawFiles.sort((a: any, b: any) => {
               return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
          });
        }
      },
      removeImageFile: (state, action: PayloadAction<any>) => {        
        const { imageType, id } = action.payload;               
        var imageFile = state.imageFileUploads.find((f: any) => f.imageType === imageType);        
        if (imageFile) {                    
          if (imageFile.files) {
            var existingImageFileIndex = imageFile.files.findIndex((f: any) => f.id === id);
            if (existingImageFileIndex) {
              imageFile.files.splice(existingImageFileIndex, 1);
              // if last file
              if (imageFile.files.length === 0) {
                imageFile.isUploaded = false;  
              }
            }
          }          
        }        
      }    
    },
    extraReducers:  (builder) => {
        builder.addCase(getFiles.pending, (state, { payload }) => {
            state.filesLoading = true;
            state.error = null;
            state.rawError = null;            
          });
        builder.addCase(getFiles.fulfilled, (state, { payload }) => {            
            state.filesLoading = false;            
            if (!payload.success) {
             state.error = { errors: payload.errors };
            } else {
              state.name = payload.name;
              state.dataFileUploads = payload.dataFileUploads;
              state.imageFileUploads = payload.imageFileUploads;       
              state.totalStorageSize = payload.totalStorageSize;             
              state.showLithofaciesMachineLearning = payload.showLithofaciesMachineLearning;
            }
          });
        builder.addCase(getFiles.rejected, (state, action) => {
              state.filesLoading = false;
            state.error = action?.payload ?? action.error;
          });
        
        builder.addCase(getRawFiles.pending, (state, { payload }) => {
          state.rawFilesLoading = true;
          state.rawError = null;
        });
        builder.addCase(getRawFiles.fulfilled, (state, { payload }) => {            
          state.rawFilesLoading = false;            
          if (!payload.success) {
           state.rawError = { errors: payload.errors };
          } else {
            state.rawFiles = payload.files;
          }
        });
        builder.addCase(getRawFiles.rejected, (state, action) => {
          state.rawFilesLoading = false;
          state.rawError = action?.payload ?? action.error;
        });

        builder.addCase(deleteDataFile.pending, (state, action:any) => {
          const { uploadType, fileName, lithologyTrackTypeId } = action.meta.arg;
          state.filesLoading = true;
          state.error = null;
          var dataFile = state.dataFileUploads.find((f: any) => f.uploadType === uploadType && f.lithologyTrackTypeId === lithologyTrackTypeId);          
          dataFile.progressMessage = "Pending";
          dataFile.progress = null;
          dataFile.inProgress = true;
        });

        builder.addCase(deleteDataFile.fulfilled, (state, { payload }) => {            
            state.filesLoading = false;
            if (!payload.success) {
              
              state.error = { errors: payload.errors };
            } else {
              
            }
          });
        builder.addCase(deleteDataFile.rejected, (state, action) => {
            state.filesLoading = false;
            state.error = action?.payload ? { errors: action.payload } :  action.error;
          });

          builder.addCase(deleteImageFile.pending, (state, action: PayloadAction<any>) => {            
            state.deleting = true;
            state.error = null;            
          });

        builder.addCase(deleteImageFile.fulfilled, (state, action ) => {      
          const { payload, meta } = action;
          const { imageType } = meta.arg;
          const { fileId } = payload;         

          state.deleting = false;

          var imageFile = state.imageFileUploads.find((f: any) => f.imageType === imageType);        
          if (imageFile) {                    
            imageFile.boreholeDepthNeedsRegenerating = true;
            imageFile.loggedDepthNeedsRegenerating = true;
            imageFile.deepZoomNeedsRegenerating = true;
            if (imageFile.files) {
              var existingImageFileIndex = imageFile.files.findIndex((f: any) => f.id === fileId);
              if (existingImageFileIndex >= 0) {
                imageFile.files.splice(existingImageFileIndex, 1);
                // if last file
                if (imageFile.files.length === 0) {
                  imageFile.isUploaded = false;  
                }
              }
            }          
          }        
        });

        builder.addCase(deleteImageFile.rejected, (state, { payload }) => {                
          state.deleting = false;
          state.deleteImageFileError = { errors: payload };
        });

        builder.addCase(deleteImageFiles.pending, (state, { payload }) => {
          state.isDeleteingImageFiles = true;
        });

        builder.addCase(deleteImageFiles.fulfilled, (state, response) => {      
          const { imageType } = response.meta.arg;         
          state.isDeleteingImageFiles = false;        
          var imageFile = state.imageFileUploads.find((f: any) => f.imageType === imageType);        
          if (imageFile) {                    
            imageFile.isUploaded = false;  
            imageFile.isScaled = false;
            imageFile.isDeepZoomCreated = false;
            imageFile.progress = null;
            imageFile.inProgress = false;
            imageFile.deepZoomProgress = null;
            imageFile.boreholeDepthNeedsRegenerating = false;
            imageFile.loggedDepthNeedsRegenerating = false;
            imageFile.files = [];            
          }        
        });

        builder.addCase(deleteSelectedImageFiles.pending, (state, { payload }) => {
          state.deleteImageFileError = null;
          state.isDeleteingImageFiles = true;
        });

        builder.addCase(deleteSelectedImageFiles.rejected, (state, { payload }) => {
          state.deleteImageFileError = { errors: payload };
          state.isDeleteingImageFiles = false;
        });

        builder.addCase(deleteSelectedImageFiles.fulfilled, (state, action) => {      
          const { payload, meta } = action;
          const { imageType, fileIds } = meta.arg;          

          state.isDeleteingImageFiles = false;

          var imageFile = state.imageFileUploads.find((f: any) => f.imageType === imageType);        
          if (imageFile) {   
            if (!payload.allDeleted) {
              imageFile.boreholeDepthNeedsRegenerating = true;
              imageFile.loggedDepthNeedsRegenerating = true;
              imageFile.deepZoomNeedsRegenerating = true;
              if (imageFile.files) {
                fileIds.forEach((fileId: any) => {
                  var existingImageFileIndex = imageFile.files.findIndex((f: any) => f.id === fileId);
                  if (existingImageFileIndex >= 0) {
                    imageFile.files.splice(existingImageFileIndex, 1);
                    // if last file
                    if (imageFile.files.length === 0) {
                      imageFile.isUploaded = false;  
                    }
                  }
                });              
              }          
            } else {
              imageFile.isUploaded = false;  
              imageFile.isScaled = false;
              imageFile.isDeepZoomCreated = false;
              imageFile.progress = null;
              imageFile.inProgress = false;
              imageFile.deepZoomProgress = null;
              imageFile.boreholeDepthNeedsRegenerating = false;
              imageFile.loggedDepthNeedsRegenerating = false;
              imageFile.files = [];    
            }
          }        
        });
        
        builder.addCase(downloadSelectedImageFiles.pending, (state, { payload }) => {
          state.deleteImageFileError = null;
          state.isDeleteingImageFiles = true;
        });

        builder.addCase(downloadSelectedImageFiles.rejected, (state, { payload }) => {
          state.deleteImageFileError = { errors: payload };
          state.isDeleteingImageFiles = false;
        });

        builder.addCase(downloadSelectedImageFiles.fulfilled, (state, action) => {      
          const { payload } = action;          
          state.isDeleteingImageFiles = false;
        });

        builder.addCase(deleteRawFiles.rejected, (state, { payload }) => {
          state.isDeleteingRawFiles = false;
          state.deleteImageFileError = { errors: payload };
        });

        builder.addCase(deleteRawFiles.pending, (state, { payload }) => {
          state.isDeleteingRawFiles = true;
        });

        builder.addCase(deleteRawFiles.fulfilled, (state, response) => {      
          const { fileNames } = response.meta.arg;         
          state.isDeleteingRawFiles = false;     
          fileNames.forEach((fileName: string) => {
            var imageFileIndex = state.rawFiles.findIndex((f: any) => f.fullName === fileName);        
            state.rawFiles.splice(imageFileIndex, 1);
          });             
        });

        builder.addCase(runMachineLearning.rejected, (state, { payload }) => {
          state.isRunningMachineLearning = false;
          state.error = { errors: payload };
        });

        builder.addCase(runMachineLearning.pending, (state, { payload }) => {
          state.isRunningMachineLearning = true;
        });

        builder.addCase(runMachineLearning.fulfilled, (state, response: any) => {
          const { fileNames } = response.meta.arg;
          state.isRunningMachineLearning = false;          
          response.payload.files?.forEach((file: any) => {
            updateDataFileOffline(state, { ...file, containsFile: false });
          });    
        });

        builder.addCase(deleteImageFiles.rejected, (state, { payload }) => {
          state.isDeleteingImageFiles = false;
          state.deleteImageFileError = { errors: payload };
        });
    }    
})

export const { 
                updateDataFile,
                updateDataFileProgress,
                updateDataFileProcessed,
                updateScaleDataFileProgress,
                updateDataFileScaled,
                updateDataFileSubProgress,
                removeDataFileProgress,
                cancelDataFileProgress,
                removeDataFile,
                addImageFile,
                addRawFile,
                updateImageFileProgress,
                updateImageFileSubProgress,
                removeScaleImageProgress,
                cancelScaleImageProgress,
                updateImageFileScaled,
                removeImageFile, 
                scaleImage,
                deepZoomImage,
                updateImageFileDeepZoomProgress,
                updateImageFileDeepZoomSubProgress,
                removeDeepZoomImageProgress,
                cancelDeepZoomImageProgress,
                updateImageFileDeepZoomCompleted,
                showDataFileErrors,
                updateImageFileDownloadProgress } = templateSlice.actions

export default templateSlice.reducer;