import React, { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { scaleLinear } from 'd3-scale';
import { extent } from 'd3-array';
import { select, pointer, bisector  } from 'd3';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import lodashDebounce from 'lodash.debounce';
import { ResizeCallbackData  } from 'react-resizable';
import '../../../../node_modules/react-resizable/css/styles.css';
import { v4 as uuidv4 } from 'uuid';
import {
  useWindowWidth
} from '@react-hook/window-size'
import TrackWrapper from '../track-wrapper';

export default function Lithology(props: any) {
  const rootSvgRef: any = useRef();    
  const svgRef: any = useRef();
  const widowWidthRef: any = useRef();
  const container: any = useRef();

  const { 
      track, 
      id, 
      curves,
      trackWidth,
      topMargin,
      startDepth, 
      endDepth, 
      depthUnit,
      showYAxis,
      showGridlines,
      showValueAxisAnnotation,
      scaleType,      
      setTrackWidth,
      editMode,      
      depthMajorIntervals,
      showDepthGridLines,
      deletedCurves,
      availableHeight,
      headerHeight,
      minHeaderHeight,
      headerBottomPadding,
      showDepthMinorIntervals,
      depthMinorIntervals,
      trackHeaderClick,
      updateCurve,
      parentRef,
      setMetaDataDepth,
      selectLithology,
      addLithology,
      trackEditMode
    } = props;

  const { datas, scrollIntoView, isSelected, codesAndColors } = track;
  
  const trackEditModeRef: any = useRef();

  useEffect(() => {
    trackEditModeRef.current = trackEditMode;
  },[trackEditMode]);

  const fieldRef = useRef<HTMLInputElement>(null);

  useEffect(() => {      
    if (scrollIntoView && fieldRef.current) {
      fieldRef.current.scrollIntoView({
        behavior: "smooth",
        block:"start",
        inline: "end"
      });
    }
  }, [scrollIntoView]);

  
useEffect(() => {
  if (!datas) {
    return;
  }
  const reversedCurves = [...curves];    
  reversedCurves.sort((a: any, b:any) => a.displayOrder - b.displayOrder);
  
  const totalCurves = reversedCurves.length;
  let lithologyData: any[] = [totalCurves];
  reversedCurves.forEach((curve: any, index: number) => {
    var trackTrackData = datas?.find((d: any) => curve.trackTypeId == d.trackTypeId);
    trackTrackData?.data?.forEach((data: any, dataIndex: number) => {
      lithologyData[dataIndex] = {...lithologyData[dataIndex], depth: data.depth, [curve.id]: data.value, gap: data.gap };
    });
  });

  renderChart();
  
}, [JSON.stringify(datas), 
    JSON.stringify(curves),
    availableHeight, 
    startDepth, 
    endDepth,       
    scaleType,
    trackWidth,
    editMode,
    showDepthMinorIntervals,
    depthMajorIntervals,
    showDepthGridLines,
    minHeaderHeight,
    depthMinorIntervals,
    showValueAxisAnnotation]);


  //const windowSize: any = useWindowDimensions();

  const windowWidth = useWindowWidth();

  useEffect(() => {
    widowWidthRef.current = windowWidth;
  },[windowWidth]);
 
  const [curveXRange, setCurveXRange] = useState<any>({});

  const onResizeDebounce = (e: SyntheticEvent, data: ResizeCallbackData) => {      
    setTrackWidth(id, data.size.width);
  };

  const onResize = useCallback(lodashDebounce(onResizeDebounce, 40), []);

  const margins = {
    top: topMargin,
    right: 0,
    bottom: 0,
    left: 0,
  };

  var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0],
    x = w.innerWidth || e.clientWidth || g.clientWidth,
    y = w.innerHeight|| e.clientHeight|| g.clientHeight;

  const width = trackWidth - margins.left - margins.right - 10;    
  
  const trackHeight = availableHeight - headerBottomPadding;
  const svgHeight = trackHeight - minHeaderHeight - 1;
  const graphHeight = svgHeight - margins.top - margins.bottom;

  const getYRange = (data: any) => {    
    if (startDepth >= 0 && endDepth && endDepth > startDepth) {
      return [startDepth, endDepth];
    }

    if (!data) {
      return [0,0];
    }

    return extent(data, (d: any) => d.depth);
  };

  //const trackHeight = graphHeight + margins.top + margins.bottom + 2 + minHeaderHeight;

  const defaultTicks = 6;

  useEffect(() => {
    if (!datas || !curves) {
      return;
    }

    const reversedCurves = [...curves];
    reversedCurves.reverse();
    let count = 1;
    const totalCurves = reversedCurves.length;
    reversedCurves.forEach((curve: any) => {
      var trackTrackData = datas?.find((d: any) => curve.trackTypeId == d.trackTypeId);
      if (trackTrackData && trackTrackData.data) {
       // renderChart(trackTrackData.data, curve, count == totalCurves, reversedCurves, datas);          
      }
      count++;
    });    
    
    deletedCurves?.forEach((curve: any) => {      
        //renderChart([], curve, false, null, null);  
    });          
    
  }, [JSON.stringify(datas), 
      JSON.stringify(curves),
      availableHeight, 
      startDepth, 
      endDepth,       
      scaleType,
      trackWidth,
      editMode,
      depthMajorIntervals,
      showDepthGridLines,
      minHeaderHeight,
      showDepthMinorIntervals,
      depthMinorIntervals,
      showValueAxisAnnotation]);

  if (!datas) {
    return null;
  }


  const lithologyData = datas[0]?.data;  
  const reversedLithologyData = Array.from((lithologyData || []));
  reversedLithologyData?.sort((a: any, b:any) => b.depth - a.depth);  

// const codesAndColors = [...new Map(lithologyData.map((item: any) =>
//   [item["value"], { code: item.value, color: item.color }])).values()].filter((value: any) => value.code);

const headerPadding = () => {    
  const totalHeaderHeight = headerHeight * codesAndColors.length;
  const padding = minHeaderHeight - totalHeaderHeight - 19.2;    
  if (padding > 0) {
    return <div style={{minHeight: padding}}></div>
  }
  
  return null;
};

const renderChart = () => {
  
  const svg = select(svgRef.current!);  
  const yScale = scaleLinear()
  .domain(getYRange(lithologyData))
  .range([0, graphHeight]);
    

  svg.selectAll("rect").remove();

  if (lithologyData) {
    lithologyData.forEach((data: any, index: number) => {      
      if (data.value) {
        const nextDepth = index < lithologyData.length - 1 ? lithologyData[index + 1]?.depth : endDepth;
        const y = yScale(data.depth);
        const height = yScale(nextDepth) - y;
        
        svg.append('rect')
        .attr('x', 0)
        .attr('y', y)
        .attr('width', width)
        .attr('height', height)
        .attr('fill', data.color ?? "transparent")
        .attr('clip-path', `url(#clip-${id})`);
      }
    });
  }

    var centerBisect = bisector(function(d: any) { return d.depth; }).center;    
    
    function handleMouseMove(event: any) {         
      if (!lithologyData || lithologyData.length === 0) {
        return;
      }
     
       const currentYPosition = pointer(event)[1];
       const yValue = yScale.invert(currentYPosition);       
       let depth = 0;
       if (trackEditModeRef.current) {
          const index = centerBisect(lithologyData, yValue);
          const value = lithologyData[index]?.value;
          depth = lithologyData[index]?.depth;
          
          if (value == null || depth == null) {
            return;
          }
           
          let valueArray: any[] = [];
          valueArray.push({ displayName: "Lithology", value: lithologyData[index]?.value, units: "", isAverage: false, scale: 1});
            
          setMetaDataDepth({ depth, values: valueArray});
          displayLine(depth);  
        } else {    
          depth = Math.round(yValue * 100) / 100;
          setMetaDataDepth({ depth, values: [] });
        }       
     }

     const displayLine = (depth: number) => {
        const focus = svg
          .select('.focus-line');
        focus.style("opacity", 0.5);
        focus
            .attr("y1", yScale(depth))
            .attr("y2", yScale(depth))
            .raise(); 
     }

    const debounce = (fn: Function, ms = 100) => {
      let timeoutId: ReturnType<typeof setTimeout>;
      return function (this: any, ...args: any[]) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => fn.apply(this, args), ms);
      };
    };

     const handleMouseClick = (event:any) => {
        const currentYPosition = pointer(event)[1];     
        const yValue = yScale.invert(currentYPosition);     
        if (trackEditModeRef.current) {   
          const data: any = reversedLithologyData.find((data: any) => data.depth < yValue);                
          selectLithology(track, data?.id);
        } else {
          const depth = Math.round(yValue * 100) / 100;
          addLithology(track.lithologyTypeId, depth);
        }
     };
   
       function handleMouseOver(event: any) {        
         handleMouseMove(event);
       }
   
       function handleMouseOut() {
         const focus = svg
         .select('.focus-line');    
         focus.style("opacity", 0);
         setMetaDataDepth(null);
       }
   
       svg.append("rect")
       .attr("class", "overlay" + uuidv4())
       .attr("width", width)
       .attr("height", graphHeight)
       .style("opacity", 0)
       .on("mouseover", handleMouseOver)
       .on("mouseout", handleMouseOut)
       .on("mousemove", handleMouseMove)
       .on("click", handleMouseClick);
}

return (
    <TrackWrapper trackWidth={trackWidth} 
      onResize={onResize}
      minWidth={100}
      chartHeight={trackHeight}
      scrollIntoView={scrollIntoView}
      editMode={isSelected}
      trackClick={trackHeaderClick}>
      <div className="track-label" ref={container}>
        {headerPadding()}
        <div className={`has-text-centered track-label ${isSelected ? "highlighted-track" : ""}`} style={{backgroundColor: track.color}}>
          <div className="">{track.displayName}</div>
          </div>
          <div className='track-label'>
            {(codesAndColors || []).map((codeAndColor: any) => (
              <div>
              <div className="header-color-box" style={{backgroundColor: codeAndColor.color}}></div>{codeAndColor.code}
            </div>
            ))}          
        </div>      
      </div>         
      {/* {datas[0]?.data.map((data: any) => (<div>{data.depth}</div>))}  */}
      <svg width={trackWidth}
        height={svgHeight}
        ref={rootSvgRef}>    
        
        <defs>
          <clipPath id={`clip-${id}`}>
            <rect className="clip-rect" x={0} y={0} width={trackWidth - 10} height={graphHeight} />
          </clipPath>
        </defs>
        <g ref={svgRef} transform={`translate(${margins.left}, ${margins.top})`}>
          <g className="unit">
              <text className="unit-text"></text>
          </g>
          <g className="x-axis-grid gridLines vertical-grid-lines" strokeOpacity={showGridlines ? 1 : 0 }/>
          <g className="y-axis-grid gridLines horizontal-grid-lines" strokeOpacity={showDepthGridLines ? 1 : 0 }/>
          <g className="x-axis" />          
          <g className="y-axis" />
          <line className="focus-line" x1={0} y1={0} x2={width} y2={0} stroke="black" strokeWidth={1} style={{opacity:0}}></line>
        </g>
      </svg>
    </TrackWrapper>
  );  
}
