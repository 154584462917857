import { CSVLink, CSVDownload } from 'react-csv';
import moment from 'moment';
import Loader from '../../../components/loader';
import Errors from '../../../components/errors';
import { useGetLoginsQuery } from '../../../services/userApi';
import List from './list';
import { useState } from 'react';
import { DateRange } from 'react-date-range';
import { convertDateToUTCISO } from '../../../components/date-formatters';

const LoginAudit = ({}:any) => {
    
    const [selectionRange, setSelectionRange] = useState({
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
      color: "rgba(0, 54, 113, 1)",   
      autoFocus: true,
      showDateDisplay: true
    });

    const handleSelect = (ranges: any) => {      
      setSelectionRange(ranges.selection);
      console.log("date changed");
      //onChange({ start: ranges.selection.startDate, end: ranges.selection.endDate})    
    }

    const { data: userLogins, isLoading, error } = useGetLoginsQuery({fromDate: selectionRange.startDate, toDate: selectionRange.endDate });

    const csvData = userLogins?.userLogins?.map((userLogin: any) => {
      return [ userLogin.name, userLogin.emailAddress, moment.utc(userLogin.timestamp).local().format("LL HH:mm:ss"), userLogin.ipAddress, userLogin.browser, userLogin.userAgent ]
     });
     
     csvData?.unshift(["Name", "Email Address", "Timestamp", "IP Address", "Browser", "User Agent"]);
    
    return (
        <section className="section">
            <div>
                <h1>Login Audit</h1>                
                {(isLoading) && <Loader/>}
                <Errors error={error }/>
                <div className="login-audit-date-selector">
                  {/* <DateFilter label="Login Date" startDate={fromDate} endDate={toDate} onChange={onChangeDates}/> */}
                  <DateRange             
                    className="date-filter"    
                    ranges={[selectionRange]}
                    onChange={handleSelect}
                    direction="vertical"
                    weekStartsOn={1}
                    maxDate={new Date()}
                />
                </div>
                <List userLogins={userLogins?.userLogins} />
                {csvData && <CSVLink className="button mb-3 mt-1" data={csvData} filename={`Login Audit ${convertDateToUTCISO(selectionRange.startDate)} to ${convertDateToUTCISO(selectionRange.endDate)}`}>Export Audit</CSVLink>}
            </div>
        </section>
    )    
};

export default LoginAudit;