const GroupTrackCurve = (props: any) => {
  const {                   
      curve,      
    } = props;    
   

    return (   
        <div className="group-track-curve">
            <div className="has-text-weight-bold">{curve.displayName}</div>
            <div>Project: {curve.projectName}</div>
            <div>Borehole: {curve.boreholeName}</div>
            {curve.trackCategoryName && <div>Track Category: {curve.trackCategoryName}</div>}
        </div>        
  )
};

export default GroupTrackCurve;