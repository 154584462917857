import React from 'react';
import { Link } from 'react-router-dom';
import TextField from '../../../components/Input/text-field';
import SelectField from '../../../components/Input/select-field';

const EditRoleForm = ({ name,
    setName,
    priority, 
    setPriority,
    clientPriorities,
    onSave,    
    readOnly
     }:any) => {

    return (
            <div className="columns">
              <div className="column is-one-quarter">
                <TextField
                  type="text" 
                  label="Name" 
                  value={name} 
                  onChange={setName}
                  readOnly={readOnly} />

              <SelectField                  
                  label="Priority" 
                  value={priority} 
                  values={clientPriorities}
                  onChange={setPriority}
                  readOnly={readOnly} />

               {!readOnly && 
                    <div className="field is-grouped">
                        <div className="control">
                          <Link className='button' to={`/admin/client/list`}>Cancel</Link>
                        </div>
                        <div className="control">
                            <button className="button is-link" type="submit" onClick={onSave}>Save Client</button>
                        </div>                
                    </div>
                }

              </div>
            </div>
    )    
};

export default EditRoleForm;