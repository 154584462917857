import { Link } from 'react-router-dom';
import './list.scss';

const Row = ({ role, onDelete, canAdd, canEdit, canView, canDelete, canListUsers, onCheckRow, checkedRows }:any) => {

    return (    
        <>
            <tr>
                <td><input type="checkbox" onChange={() => onCheckRow(role.id)} checked={checkedRows.findIndex((r: any) => r == role.id) >= 0}/></td>
                <td>{role.name}</td>                
                <td>
                    <div className="columns list-roles-viewer-columns">
                        <div className="column">{role.isEditable && canEdit && <><Link to={`edit/${role.id}`}>Edit</Link></>}
                            {!role.isEditable && canView && <><Link to={`view/${role.id}`}>View</Link></>} </div>
                        <div className="column">{canAdd && <><Link to={`copy/${role.id}`}>Copy</Link></>}</div>
                        <div className="column">{canListUsers && <><Link to={`users-in-role/${role.id}`}>Users</Link></>}</div>                        
                    </div>
                </td>
            </tr>           
        </>        
    )    
};

export default Row;