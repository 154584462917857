import React, { useEffect, useState } from 'react';
import { useHistory, useParams  } from 'react-router-dom';
import { toast } from 'react-toastify';
import Errors from '../../components/errors';
import { useUpdateMutation, useGetProjectQuery } from "../../services/projectApi";
import ProjectForm from './project-form';

const EditProject = ({} : any) => {
  let { clientId, projectId } = useParams<any>();
  const [name, setName] = useState('');

  const { data, error, isLoading, isFetching } = useGetProjectQuery( { clientId, projectId });

  const onSetName = (name: string) => {
    setName(name);
  };

  useEffect(() => {
    if (!isLoading) {
      setName(data?.name);      
    }
  },
  [isLoading])

  let history = useHistory();

  const [update, { isLoading: isUpdating, error: updateError }] = useUpdateMutation();

    const onUpdateProject = (e: any) => {
      e.preventDefault();
      update({clientId, projectId, name})
        .unwrap()
        .then((response: any) => {
          toast.success("Successfully updated project");
          history.push(`/`)  // redirect
        })
        .catch((error) => { toast.error("Error updating project") });
    };
 
    return (
      <section className="section">
        <div>
            <h1>Edit Project</h1>
            <Errors error={{...error, ...updateError}}/>
            <ProjectForm name={name} setName={onSetName} onSaveProject={onUpdateProject} loading={isLoading || isUpdating }  />         
        </div>
      </section>
    )
};

export default EditProject;