import React from 'react';
import TextField from '../../../components/Input/text-field'
import QRCode from 'react-qr-code';
import Errors from '../../../components/errors';
import Moment from 'react-moment';

const EnableTwoFactorForm = (props: any) => {

const { authenticatorKey,
        authenticatorKeyUrl,
        handleSubmit,
        authenticatorCode, 
        setAuthenticatorCode,        
        recoveryCodes,
        error, 
        canSkip,
        onSkip,
        showContinue,
        onContinue,
        twoFactorDeadline,
        atLogin
       } = props;

const onHandleSubmit = (e: any) => {
    e.preventDefault();
    handleSubmit();
}

const validated = recoveryCodes && recoveryCodes.length > 0;

const canSave = authenticatorCode.length >= 6;

const onSetAuthenticatorCode = (value: string) => {
    setAuthenticatorCode(value);    
}

const onOnContinue = (e : any) => {
    e.preventDefault();
    onContinue();
};

const onOnSkip = (e : any) => {
    e.preventDefault();
    onSkip();
};

return (
    <div className="container">
        <div className="columns is-centered">
            <div className="column is-5-tablet is-4-desktop is-4-widescreen">
                <form className="box" onSubmit={onHandleSubmit}>
                    <h4>Enable two factor authentication</h4>
                    {!validated && <div>
                        {canSkip && twoFactorDeadline && <div className='mb-2'>You have until <Moment format='DD/MM/YYYY HH:mm:ss'>{twoFactorDeadline}</Moment> UTC to enable this.</div>}
                        <div className='mb-2'>Scan this barcode within the <a href="https://www.microsoft.com/en-us/security/mobile-authenticator-app" target="_blank">Microsoft Authenticator app</a>, or enter this key {authenticatorKey}:</div>
                        {authenticatorKeyUrl && <QRCode value={authenticatorKeyUrl}/>}                    
                        <div className='mb-2'>Once setup enter the code shown:</div>
                        <TextField type="text" label="Authenticator Code" value={authenticatorCode} onChange={onSetAuthenticatorCode} maxLength={6}></TextField>
                        <button className='button is-primary' type="submit" disabled={!canSave}>Submit</button>
                        {canSkip && onSkip && <button className='button ml-4' onClick={onOnSkip} >Skip</button>}
                    </div>}
                    <Errors error={error}/>
                    {validated && <div className='mb-2'>
                        <label className='label'>Recovery codes</label>
                        Save these somewhere safe to be able to recover your account if you loose your phone.

                        {recoveryCodes.map((code: string, index: number) => (
                        <div key={index}>
                            {code}
                        </div>                        
                        ))}

                        {showContinue && onContinue && <button className='button mt-3' onClick={onOnContinue}>Continue</button>}
                    </div>}
                </form>
            </div>
        </div>
    </div>        
)}

export default EnableTwoFactorForm;