import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectImageType, addImageType, clearImageType } from '../../../services/imageTypeSlice';

import Container from './container';

const EditImageType = ({}:any) => {    
    const dispatch = useAppDispatch();    
    let history = useHistory();  

    const { 
        trackType,
        loading,
        error
       } = useAppSelector(selectImageType);

    useEffect(() => { 
        dispatch(clearImageType())}, 
    []);

    const onSave = (imageType: any) => {
        dispatch(addImageType(imageType)).then((response: any) => {
            if (response.payload.success) {
                history.push(`/admin/image-type/list`)
            } 
           });
    };    

    return (
       <Container title="Add Image Type" 
                  trackType={trackType}                   
                  isLoading={loading}
                  onSave={onSave}
                  error={error}  />
    )    
};

export default EditImageType;