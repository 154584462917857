import React from 'react';
import ReactModal from 'react-modal';
import Modal from '../modal/modal2';

const YScaleDialog = (props: any) => {

    const {
        showScaleModal,
        handleCloseScaleModal,
        handleOkScaleModal,
        onScaleFormSubmit,        
        setShowDepthGridlines,
        majorIntervals, 
        setDialogMajorIntervals,
        showDepthGridLines,
        showDepthMinorIntervals,
        setShowDepthMinorIntervals,
        minorIntervals,
        setMinorIntervals
    } = props;


    const modelStyle: any = {    
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.25)'
        },       
        content: {
          position: 'absolute',
          top: '40px',
          left: '40px',
          right: '40px',
          bottom: '40px',
          border: 'none',
          background: 'transparent',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '4px',
          outline: 'none',
          padding: '20px'
        }
      }

return(
<ReactModal 
isOpen={showScaleModal}
ariaHideApp={false}
style={modelStyle}
contentLabel="Set Y axis range"
>
<Modal 
 closeModal={handleCloseScaleModal} 
 handleOkModal={handleOkScaleModal}            
 title="Vertical scale"
 width="400px"
>
   <form onSubmit={onScaleFormSubmit}>
   <table>               
       <tbody>
            <tr>
                <th colSpan={2}>
                    Intervals
                </th>
            </tr>
            <tr>
                <td>Major</td>
                <td><input className="input" type="number" step="any" value={majorIntervals} onChange={(e) => setDialogMajorIntervals(+e.target.value)} /></td>            
            </tr>
            <tr>
                <td>Minor</td>
                <td><input className="input" type="number" step="any" value={minorIntervals} onChange={(e) => setMinorIntervals(+e.target.value)} /></td>
                
            </tr>
        </tbody>
    </table>   
    <div>
        <label className="checkbox">
            <input type="checkbox" checked={showDepthGridLines} onChange={(e) => setShowDepthGridlines(e.target.checked)} /> Gridlines
        </label>  
    </div>

    <div>
        <label className="checkbox">
            <input type="checkbox" checked={showDepthMinorIntervals} onChange={(e) => setShowDepthMinorIntervals(e.target.checked)} /> Show Minor Gridlines
        </label>  
    </div>
     
     <button type="submit" style={{display: "none"}}></button>
   </form>
 
 
 </Modal>
</ReactModal>
)};

export default YScaleDialog;