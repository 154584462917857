import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import getConfig from "../app/config";
import { toast } from 'react-toastify';
import fetchWithRefresh from './fetchWithRefresh';
import { RootState } from '../app/store';

export const getLithologyLookups = createAsyncThunk<any, void>(
  "lithology/lookups",
  async (thunkApi) => {
    const response = await fetchWithRefresh(getConfig().apiUrl + `Lithology/Lookup`, thunkApi, {
      method: 'Get',
      headers: {       
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    });

    return response;
  }
);

export const getLithologyLookup = createAsyncThunk<any, {id: string}>(
  "lithology/getLookup",
  async ({id}, thunkApi) => {
    const response = await fetchWithRefresh(getConfig().apiUrl + `Lithology/Lookup/${id}`, thunkApi, {
      method: 'Get',
      headers: {       
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    });

    return response;
  }
);

export const getLithologyLexicons = createAsyncThunk<any, void>(
  "lithology/lexicons",
  async (thunkApi) => {
    const response = await fetchWithRefresh(getConfig().apiUrl + `Lithology/Lexicon`, thunkApi, {
      method: 'Get',
      headers: {       
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    });

    return response;
  }
);

export const getLithologyLexicon = createAsyncThunk<any, {id: string}>(
  "lithology/getLexicon",
  async ({id}, thunkApi) => {
    const response = await fetchWithRefresh(getConfig().apiUrl + `Lithology/Lexicon/${id}`, thunkApi, {
      method: 'Get',
      headers: {       
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    });

    return response;
  }
);

export const addLithologyLexicon = createAsyncThunk<any, any>(
  "lithology/addLexicon",
  async (lithologyLexicon, thunkApi) => {
    const response: any = await fetchWithRefresh(getConfig().apiUrl + `Lithology/Lexicon`, thunkApi, {
      method: 'POST',
      headers: {       
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(lithologyLexicon)
    });

    return response;
  }
)

export const updateLithologyLexicon = createAsyncThunk<any, any>(
  "lithology/updateLexicon",
  async (lithologyLexicon, thunkApi) => {    
    const response = await fetchWithRefresh(getConfig().apiUrl + `Lithology/Lexicon/${lithologyLexicon.id}`, thunkApi, {
      method: 'PUT',
      headers: {       
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(lithologyLexicon)
    });

    return response;
  }
)

export const deleteLithologyLexicon = createAsyncThunk<any, any>(
  "lithology/deleteLexicon",
  async (lithologyLexiconId, thunkApi) => {    
    const response = await fetchWithRefresh(getConfig().apiUrl + `Lithology/Lexicon/${lithologyLexiconId}`, thunkApi, {
      method: 'DELETE',
      headers: {       
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    });

    return response;
  }
)

export const updateLithologyLookup = createAsyncThunk<any, any>(
  "lithology/updateLookup",
  async (lithologyLookup, thunkApi) => {
    const response = await fetchWithRefresh(getConfig().apiUrl + `Lithology/Lookup/${lithologyLookup.id}`, thunkApi, {
      method: 'PUT',
      headers: {       
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(lithologyLookup)
    });

    return response;
  }
)

export interface ImageTypeState {
    loading: boolean,
    saving: boolean,
    lithologyLookups: any[],
    lithologyLexicons: any[],
    lithologyLexicon: any,
    lithologyLookup: any,
    error: any,    
    addingLexicon: boolean
  }
  
const initialState: ImageTypeState = {
    loading: false,
    saving: false,
    lithologyLookups: [],
    lithologyLexicons: [],
    lithologyLexicon: null,
    lithologyLookup: null,
    error: null,
    addingLexicon: false
  }

const lithologySlice = createSlice({
    name: 'lithology',
    initialState,
    reducers: {           
      addRow: (state) => {
        state.addingLexicon = true;
        state.lithologyLexicons.unshift({ editMode: true });
      },
      deleteAddingRow: (state) => {
        state.addingLexicon = false;
        state.lithologyLexicons.splice(0, 1);
      }
    },
    extraReducers:  (builder) => {      
          builder.addCase(getLithologyLookups.pending, (state) => {         
            state.error = null;      
            state.loading = true;
          });

          builder.addCase(getLithologyLookups.fulfilled, (state, { payload }) => {   
            if (payload?.success || payload.status == 200) {
                state.lithologyLookups = payload.lithologyLookups;
            } else {
              toast.error("Error getting lithology lookups");
            }

            state.loading = false;
          }); 
       
          builder.addCase(getLithologyLookups.rejected, (state, action) => {
            debugger;
            if (action.payload) {
              state.error = action.payload;
              toast.error("Error getting lithology lookups");
            } else {
              state.error = action.error;
              toast.error("Error getting lithology lookups");
            }

            state.loading = false;
          });

          builder.addCase(getLithologyLexicons.pending, (state) => {         
            state.error = null;      
            state.loading = true;
          });

          builder.addCase(getLithologyLexicons.fulfilled, (state, { payload }) => {   
            if (payload?.success || payload.status == 200) {
                state.lithologyLexicons = payload.lithologyLexicons;
            } else {
              toast.error("Error getting lexicons lookups");
            }

            state.loading = false;
          }); 
       
          builder.addCase(getLithologyLexicons.rejected, (state, action) => {            
            if (action.payload) {
              state.error = action.payload;
              toast.error("Error getting lexicons lookups");
            } else {
              state.error = action.error;
              toast.error("Error getting lexicons lookups");
            }

            state.loading = false;
          });

          builder.addCase(getLithologyLexicon.pending, (state) => {         
            state.error = null;      
            state.loading = true;
          });

          builder.addCase(getLithologyLexicon.fulfilled, (state, { payload }) => {   
            if (payload?.success || payload.status == 200) {
                state.lithologyLexicon = payload;
            } else {
              toast.error("Error getting lexicon");
            }

            state.loading = false;
          }); 
       
          builder.addCase(getLithologyLexicon.rejected, (state, action) => {
            debugger;
            if (action.payload) {
              state.error = action.payload;
              toast.error("Error getting lexicon");
            } else {
              state.error = action.error;
              toast.error("Error getting lexicon");
            }

            state.loading = false;
          });

          builder.addCase(addLithologyLexicon.pending, (state) => {         
            state.error = null;      
            state.saving = true;
          });

          builder.addCase(addLithologyLexicon.fulfilled, (state, action) => {               
            state.lithologyLexicons[0] = { 
              id: action.payload.id,
              code: action.meta.arg.code,
              description: action.meta.arg.description
            };
            state.lithologyLexicons.sort((a: any, b: any) => {
              return a.code.toLowerCase().localeCompare(b.code.toLowerCase());
            });

            state.addingLexicon = false;
            state.saving = false;
            toast.success("Successfully added lithology lexicon");
          }); 
       
          builder.addCase(addLithologyLexicon.rejected, (state, action) => {            
            if (action.payload) {
              state.error = { data: { errors: action.payload } };
              toast.error("Error adding lithology lexicon");
            } else {
              state.error = action.error;
              toast.error("Error adding lithology lexicon"); 
            }

            state.saving = false;
          });

          builder.addCase(updateLithologyLexicon.pending, (state) => {         
            state.error = null;      
            state.saving = true;
          });

          builder.addCase(updateLithologyLexicon.fulfilled, (state, action) => {   
            state.saving = false;            
            const lexicon = state.lithologyLexicons.find((l: any) => l.id == action.meta.arg.id);
            lexicon.code = action.meta.arg.code;
            lexicon.description = action.meta.arg.description;
            state.lithologyLexicons.sort((a: any, b: any) => {
              return a.code.toLowerCase().localeCompare(b.code.toLowerCase());
            });
            toast.success("Successfully updated lithology lexicon");
          }); 
       
          builder.addCase(updateLithologyLexicon.rejected, (state, action) => {            
            if (action.payload) {
              state.error = { data: { errors: action.payload } };
              toast.error("Error updating lithology lexicon");
            } else {
              state.error = action.error;
              toast.error("Error updating lithology lexicon");
            }

            state.saving = false;
          });
          
          builder.addCase(deleteLithologyLexicon.pending, (state) => {         
            state.error = null;      
            state.saving = true;
          });

          builder.addCase(deleteLithologyLexicon.fulfilled, (state, action) => {   
            state.saving = false;      
            const lexiconIndex = state.lithologyLexicons.findIndex((l: any) => l.id == action.meta.arg);
            
            state.lithologyLexicons.splice(lexiconIndex, 1);
            toast.success("Successfully deleted lithology lexicon");
          }); 
       
          builder.addCase(deleteLithologyLexicon.rejected, (state, action) => {            
            if (action.payload) {
              state.error = { data: { errors: action.payload } };
              toast.error("Error deleting lithology lexicon");
            } else {
              state.error = action.error;
              toast.error("Error deleting lithology lexicon");
            }

            state.saving = false;
          });

          builder.addCase(getLithologyLookup.pending, (state) => {         
            state.error = null;      
            state.loading = true;
          });

          builder.addCase(getLithologyLookup.fulfilled, (state, { payload }) => {   
            if (payload?.success || payload.status == 200) {
                state.lithologyLookup = payload;
            } else {
              toast.error("Error getting lookup");
            }

            state.loading = false;
          }); 
       
          builder.addCase(getLithologyLookup.rejected, (state, action) => {
            debugger;
            if (action.payload) {
              state.error = action.payload;
              toast.error("Error getting lookup");
            } else {
              state.error = action.error;
              toast.error("Error getting lookup");
            }

            state.loading = false;
          });

          builder.addCase(updateLithologyLookup.pending, (state) => {         
            state.error = null;      
            state.saving = true;
          });

          builder.addCase(updateLithologyLookup.fulfilled, (state, { payload }) => {   
            state.saving = false;
            toast.success("Successfully updated lithology lookup");
          }); 
       
          builder.addCase(updateLithologyLookup.rejected, (state, action) => {            
            if (action.payload) {
              state.error = { data: { errors: action.payload } };
              toast.error("Error updating lithology lookup");
            } else {
              state.error = action.error;
              toast.error("Error updating lithology lookup");
            }

            state.saving = false;
          });
    }    
})

export const { addRow, deleteAddingRow } = lithologySlice.actions

export const selectLithology = (state: RootState) => state.lithology;

export default lithologySlice.reducer; 