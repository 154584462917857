import React, { useEffect, useState } from 'react';
import SmallLoader from '../../small-loader';
import Panel from '../panel';
import { setPaperSize, 
         setOrientation,
         setStartDepth,
         setEndDepth,
         setDepthPerPage,
         setPagesToPrintOver,
          setPrintOverPages,
          setTracksPerPage,
          setFixTracksPerPage,
          setFixTotalPages,
          setTotalPages,
          setLeftDepthScale,
          setRightDepthScale,
          setPrintStatisticalSummary, 
          setSelectedDataForDepthRange,
          addSelectedDataForDepthRange,
          deleteSelectedDataForDepthRange,
          clearSelectedDataForDepthRange } from '../../../services/printSlice';
import './print-panel.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../app/store';
import Errors from '../../errors';
import Progress from '../../progress';
import CheckboxField from '../../Input/checkbox-field';
import SelectField from '../../Input/select-field';

const PrintPanel = (props: any) => {
    const {
      childLoading,
      handleOkPrintModal,
      isPrinting,       
      paperSizes,
      startDepth: externalStartDepth,
      endDepth: externalEndDepth,
      minDepth,
      maxDepth,
      depthUnit,
      error,
      progress,
      showSummaryReport,
      statisticsTracks,
      dataForDepthRangeOptions
    } = props;   
    
    const dispatch: AppDispatch = useDispatch();
    const selectPrint = (state: RootState) => state.print;
    const {
            paperSize,
            orientation,
            startDepth,
            endDepth,
            depthPerPage,
            printOverPages,
            pagesToPrintOver,
            fixTracksPerPage,
            tracksPerPage,
            fixTotalPages,
            totalPages,
            leftDepthScale,
            rightDepthScale,
            fanFold,
            printStatisticalSummary,
            selectedDataForDepthRange
          } = useSelector(selectPrint);

//const [pagesToPrintOver, setPagesToPrintOver] = useState<number>(1);
const [pixedlWidth, setPixelWidth] = useState<number>(600);
const [pixedlHeight, setPixelHeight] = useState<number>(900);
const [depthPerMeterError, setDepthPerMeterError] = useState<any>();
const [startDepthError, setStartDepthError] = useState<any>();
const [endDepthError, setEndDepthError] = useState<any>();
const [showAddData, setShowAddData] = useState<boolean>(true);
const [selectedData, setSelectedData] = useState<boolean>(true);
//const [fanFold, setFanFold] = useState<boolean>(false);
//const [tracksPerPage, setTracksPerPage] = useState<number>(4);
//const [fixTracksPerPage, setFixTracksPerPage] = useState<boolean>(false);
//const [totalPages, setTotalPages] = useState<number>(1);
//const [fixTotalPages, setFixTotalPages] = useState<boolean>(false);
//const [leftDepthScale, setLeftDepthScale] = useState<boolean>(true);
//const [rightDepthScale, setRightDepthScale] = useState<boolean>(true);

//const [internalStartDepth, setInternalStartDepth] = useState<number>(startDepth);
//const [internalEndDepth, setInternalEndDepth] = useState<number>(endDepth);
//const [depthPerPage, setDepthPerPage] = useState<number>(endDepth - startDepth);

//const [printOverPages, setPrintOverPages] = useState<boolean>(true);

useEffect(() => {
  //setInternalStartDepth(startDepth);
  if (externalStartDepth !== null || startDepth === null || externalStartDepth > startDepth) {    
    dispatch(setStartDepth(externalStartDepth));
  }
}, [externalStartDepth]);

useEffect(() => {
  //setInternalEndDepth(endDepth);
  if (externalEndDepth !== null || endDepth === null || externalEndDepth < endDepth) {
    dispatch(setEndDepth(externalEndDepth));
  }
}, [externalEndDepth]);  

useEffect(() => {
  dispatch(setDepthPerPage(externalEndDepth - externalStartDepth));
}, [externalStartDepth, externalEndDepth]);  

useEffect(() => {
  dispatch(clearSelectedDataForDepthRange());
  if (filteredDataForDepthRangeOptions && filteredDataForDepthRangeOptions.length > 0) {
    setSelectedData(filteredDataForDepthRangeOptions[0].value);
  }
},[]);

const showPixelDimensions = false;

const onHandleOkPrintModal = (e: any) =>{
  e.preventDefault();
  setDepthPerMeterError(null);
  setStartDepthError(null);
  setEndDepthError(null);
  var errored = false;
  if (!printOverPages && depthPerPage && depthPerPage < 0 ) {
    setDepthPerMeterError("Depth per meter must be greater than 0");
    errored = true;
  }

  if (startDepth && startDepth < 0 ) {
    setStartDepthError("Start depth must be greater then or equal to 0");
    errored = true;
  }

  if (startDepth && endDepth && startDepth >= endDepth ) {
    setStartDepthError("End depth must be greater than start depth");
    errored = true;
  }

  if (errored) {
    return;
  }

  handleOkPrintModal(paperSize, 
                   orientation, 
                   pagesToPrintOver, 
                   showPixelDimensions ? pixedlWidth : null, 
                   showPixelDimensions ? pixedlHeight : null, 
                   fanFold, 
                   tracksPerPage,
                   fixTracksPerPage,
                   totalPages,
                   fixTotalPages,
                   startDepth,
                   endDepth,
                   depthPerPage,
                   printOverPages,
                   leftDepthScale,
                   rightDepthScale, 
                   printStatisticalSummary, 
                   selectedDataForDepthRange.map((d: any) => d.id));
}

const onSetPaperSize = (e: any) => {
   dispatch(setPaperSize(+e.target.value));
}

const onSetOrientation = (e: any) => {
  dispatch(setOrientation(+e.target.value));
}

const onSetLeftDepthScale = (value: boolean) => {
  dispatch(setLeftDepthScale(value));
}

const onSetRightDepthScale = (value: boolean) => {
  dispatch(setRightDepthScale(value));
}

const onSetFixTotalPages = (value: boolean) => {
  if (value) {
    dispatch(setFixTracksPerPage(false));
  }

  dispatch(setFixTotalPages(value));
}

const onSetFixTracksPerPage = (value: boolean) => {
  if (value) {
    dispatch(setFixTotalPages(false));
  }

  dispatch(setFixTracksPerPage(value));
}

const onSetPrintStatisticalSummary = (value: boolean) => {
  dispatch(setPrintStatisticalSummary(value));
}

const onSetSelectedDataForDepthRange = (value: boolean, index: number) => {
  dispatch(setSelectedDataForDepthRange({value, index}));
}

const onAddSelectedDataForDepthRange = (id: number, name: string) => {
  dataForDepthRangeOptions.find((d: any) => d.value == selectedData);
  dispatch(addSelectedDataForDepthRange({id, name}));
}

const onDeleteSelectedDataForDepthRange = (id: number) => {
  dispatch(deleteSelectedDataForDepthRange(id));
}

const onChangeStartDepth = (e: any) => {
  var value = +e.target.value;
  if (value < minDepth || value > maxDepth) {
    return;
  }
  
  dispatch(setStartDepth(value));
};

const onBlurDepth = (e: any) => {
  if (endDepth !== null && startDepth !== null)
  {
    if (depthPerPage === null || (depthPerPage > endDepth - startDepth)) {
      dispatch(setDepthPerPage(endDepth - startDepth));
    }
  }
};

const onChangeEndDepth = (e: any) => {
  var value = +e.target.value;
  if (value < minDepth || value > maxDepth) {
    return;
  }

  dispatch(setEndDepth(value));
};

const onChangeDepthPerPage = (e: any) => {
  var value = +e.target.value;  
  if (endDepth === null || startDepth === null || value > endDepth - startDepth) {
    return;
  }
  
  dispatch(setDepthPerPage(value));
};

const onSetPrintOverPages = (printOverPages: boolean) => {
  dispatch(setPrintOverPages(printOverPages));
};

const onSetPagesToPrintOver = (e: any) => {
  dispatch(setPagesToPrintOver(+e.target.value));
};

const onSetTracksPerPage = (e: any) => {  
  dispatch(setTracksPerPage(+e.target.value));
};

const onSetTotalPages = (e: any) => {  
  dispatch(setTotalPages(+e.target.value));
};

const groupedStatisticsTracks: any[] = [];
statisticsTracks?.forEach((track: any) => {
  const index = groupedStatisticsTracks.findIndex((t: any) => t.trackCategoryName == track.trackCategoryName);
  if (index === -1) {
    groupedStatisticsTracks.push({trackCategoryName: track.trackCategoryName, tracks: [track.displayName]});
  } else {
    groupedStatisticsTracks[index].tracks.push(track.displayName);
  }
});

const filteredDataForDepthRangeOptions = dataForDepthRangeOptions.filter((o: any) => !selectedDataForDepthRange.map((selected: any) => selected.id).includes(o.value));

const onSelectAddData = (e: any) => {  
  e.preventDefault();  
  const data = dataForDepthRangeOptions.find((d: any) => d.value == selectedData);  
  if (data) {
    dispatch(addSelectedDataForDepthRange({id: data.value, name: data.name}));
    //onSetSelectedDataForDepthRange(value, index)
    setShowAddData(false);
  }
};

const onSetShowAddData = () => {
  setShowAddData(true);
  setSelectedData(filteredDataForDepthRangeOptions[0].value);
};

const onSetSelectedData = (e: any) => {
  //e.preventDefault();
  setSelectedData(e);
  //onSetSelectedDataForDepthRange(value, index)
};

console.log("statisticsTracks", statisticsTracks);

return(
  <Panel title="PDF" icon="Atlas Print Icon">
    
        <form onSubmit={onHandleOkPrintModal}>
          <div>
            <div className="panel-section">
                <div className="field">
                  <label className="label">Paper Size</label>
                  <div className="control">
                      <div className="select">          
                        <select value={paperSize} onChange={onSetPaperSize}>
                            {(paperSizes || []).map((paperSize: any, index: number) => (
                              <option key={index} value={paperSize.value}>{paperSize.name}</option>  
                            ))}                            
                        </select>
                      </div>
                    </div>
                </div>
                <div className="field">
                  <label className="label">Orientation</label>
                  <div className="control">
                      <div className="select">          
                        <select value={orientation} onChange={onSetOrientation}>
                              <option value={1}>Portrait</option>
                              <option value={0}>Landscape</option>
                          </select>
                      </div>
                    </div>
                </div>
              </div>
              <div className="panel-section">
                <label className="label">Print depth</label>
                <div className="level">
                  <div className="level-left">
                    <div className="level-item">
                      <div className="level-item print-depth">
                          <span className="material-icons" title="Start Depth">
                              vertical_align_top
                            </span>        
                          </div>
                          <div className="level-item print-depth">
                              <div className="field depth-number-field">
                                <input type="number" className={`input print-dpeth-input ${startDepthError ? "is-danger" : ""}`} step="any" min={minDepth} max={maxDepth} value={startDepth ?? undefined} disabled={childLoading} onChange={onChangeStartDepth} onBlur={onBlurDepth} />
                              </div>
                          </div>
                          <div className="level-item print-depth">          
                            <span className="material-icons" title="End Depth">
                              vertical_align_bottom
                            </span>        
                          </div>
                          <div className="level-item">
                              <div className="field depth-number-field">
                                <input type="number" className={`input print-dpeth-input ${endDepthError ? "is-danger" : ""}`} step="any" size={1} min={minDepth} max={maxDepth} value={endDepth ?? undefined} disabled={childLoading} onChange={onChangeEndDepth} onBlur={onBlurDepth} />
                              </div>
                          </div>
                    </div>
                  </div>
                </div>
                {startDepthError && <div className='help is-danger'>{startDepthError}</div>}
              </div>

              <div className="panel-section">
                <div className="field">
                  <label className="label"><input type="radio" checked={!printOverPages} onClick={() => onSetPrintOverPages(false)}/> {depthUnit == 1 ? "m" : "ft"} per page</label>
                  <div className="control">
                      <input type="number" className={`input print-dpeth-input ${depthPerMeterError ? "is-danger" : ""}`} step="any" size={1} min={0.01} max={(endDepth ?? 0) - (startDepth ?? 0)} value={depthPerPage ?? undefined} disabled={childLoading || printOverPages} onChange={onChangeDepthPerPage} />
                      {depthPerMeterError && <div className='help is-danger'>{depthPerMeterError}</div>}
                  </div>
                </div>
              
                <div className="field">
                  <label className="label"><input type="radio" checked={printOverPages} onClick={() => onSetPrintOverPages(true)}/> Pages to print over</label>
                  <div className="control">
                      <div className="select">          
                          <select value={pagesToPrintOver} onChange={onSetPagesToPrintOver} disabled={!printOverPages}>
                                  {[...Array(500)].map((x, i) =>
                                    <option key={i} value={i + 1}>{i + 1}</option>
                                  )}                      
                          </select>
                      </div>
                  </div>
                </div>
              </div>   
                     {/* <label className="checkbox">
                      <input type="checkbox" checked={fanFold} onChange={(e) => setFanFold(e.target.checked)} disabled={pagesToPrintOver < 2}/>  Fan Fold
                    </label> */}
     
     <div className="panel-section">
                 <div className="field">
                  <label className="label">
                    <input type="checkbox" checked={fixTracksPerPage} onChange={(e) => onSetFixTracksPerPage(e.target.checked)}/>  Tracks per page
                  </label>
                  <div className="control">
                    <div className="select">          
                          <select value={tracksPerPage} onChange={onSetTracksPerPage}>
                              {[...Array(20)].map((x, i) =>
                                <option key={i} value={i + 1}>{i + 1}</option>
                              )}                      
                            </select>
                          </div>
                    </div>
                  </div>

                    
                    <div className="field mb-2">
                      
                          <label className="label">
                            <input type="checkbox" checked={fixTotalPages} onChange={(e) => onSetFixTotalPages(e.target.checked)}/>  Fit to pages
                          </label>
                      
                        <div className="control">
                          <div className="select">          
                          <select value={totalPages || 1} onChange={onSetTotalPages}>
                              {[...Array(20)].map((x, i) =>
                                <option key={i} value={i + 1}>{i + 1}</option>
                              )}                      
                            </select>
                        </div>
                      </div>
                    </div>
                     
                    <div className="field mb-2">
                      
                          <label className="label">
                            Depth Scale
                          </label>
                      
                        <div className="control">
                            <label><input type="checkbox" checked={leftDepthScale} onChange={(e) => onSetLeftDepthScale(e.target.checked)}/> Left</label>
                            <label><input className="ml-2" type="checkbox" checked={rightDepthScale} onChange={(e) => onSetRightDepthScale(e.target.checked)}/> Right</label>
                        </div>
                      
                    </div>
                 {showSummaryReport && <CheckboxField className="mb-3" label="Statistical Summary" checked={printStatisticalSummary} onChange={onSetPrintStatisticalSummary}/>}
                 {printStatisticalSummary && <div>
                  {statisticsTracks?.length === 0 && <span>No data selected</span>}
                  {statisticsTracks?.length > 0 && <div>{groupedStatisticsTracks?.map((trackCategory: any) => (<><div className='has-text-weight-bold'>{trackCategory.trackCategoryName}:</div><div className='mb-2'>{trackCategory.tracks.join(", ")}</div></>))}</div>}
                  <div className="mb-4" >
                    <label className='label'>Data for Depth Range</label>
                    {!dataForDepthRangeOptions?.length && <>No Data</>}
                    {dataForDepthRangeOptions?.length > 0 && <>
                      <table>                      
                        {selectedDataForDepthRange.map((selected: any, index: number) => (
                          <tr>
                            <td key={index}>{selected.name}</td>
                            <td><img src={"/images/icons/Atlas Delete Icon.svg"} className="delete-data-for-depth-range is-clickable" title="Delete" onClick={() => onDeleteSelectedDataForDepthRange(selected.id)}/></td>
                          </tr>
                        ))}    
                      </table>                                         
                      {showAddData && <SelectField values={filteredDataForDepthRangeOptions} onChange={onSetSelectedData} value={selectedData} />}
                      <div className='button-group'>
                        {showAddData && <button className='button is-primary mr-2' onClick={onSelectAddData}>Select</button>}
                        {!showAddData && filteredDataForDepthRangeOptions.length > 0 && <button className='button' onClick={onSetShowAddData}>Add Data</button>}
                      </div>
                    </>}
                  </div>
                 </div>}
                  {showPixelDimensions && 
                    <>
                      <div className="level">
                        <div className="level-left">
                          <div className="level-item">
                            Pixel Width
                          </div>
                          <div className="level-item">
                            <input className="input" value={pixedlWidth} onChange={(e: any) => setPixelWidth(+e.target.value)} style={{width: "5rem"}}/>
                          </div>
                        </div>
                      </div>
                      <div className="level">
                        <div className="level-left">
                          <div className="level-item">
                            Pixel Height
                          </div>
                          <div className="level-item">
                            <input className="input" value={pixedlHeight} onChange={(e: any) => setPixelHeight(+e.target.value)} style={{width: "5rem"}}/>
                          </div>
                        </div>
                      </div>
                    </>
                  }   

                  {false && isPrinting && <div className="mb-2">Generating PDF... <SmallLoader /></div>}
                  {isPrinting && <Progress progress={progress} /> }
                  {error && <Errors error={{ message: error}} />} 
        
                  <button className="button is-light" type="submit" onClick={onHandleOkPrintModal} disabled={isPrinting || (printStatisticalSummary && (selectedDataForDepthRange.length === 0 || statisticsTracks?.length == 0))}>Print</button>
                </div>            
          </div>
       </form>
    </Panel>
)};

export default PrintPanel;