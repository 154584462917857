import React from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import Progress from '../../../components/progress';

const Row = ({ job, onDelete, canDelete, canCancel, onCancel, showJobPriorityPoints }:any) => {

    return (    
        <>
            <tr>
                <td><Link to={`/client/${job.clientId}/project/${job.projectId}/borehole/${job.boreholeId}/files`}>{job.boreholeName}</Link></td>
                <td>{job.name}</td>
                <td>{job.type}</td>
                <td>{job.stateDescription}</td>
                <td>{job.priorityDescription}{showJobPriorityPoints && <> {job.priorityPoints}</>}</td>
                <td>{job.createdBy}</td>
                <td>{format(new Date(job.createdDate), 'dd/MM/yyyy HH:mm:ss')}</td>
                <td className='job-progress-column'><Progress progress={{ progress: job.overallProgress, subProgress: job.subProgress, message: job.message, failed: job.state === 102 || job.state === 101, warning: job.warning }} /></td>                
                <td>{job.startedDate && format(new Date(job.startedDate), 'dd/MM/yyyy HH:mm:ss')}</td>                
                <td>{canDelete && <a onClick={() => onDelete(job.id, job.name)}>Delete</a>}&nbsp;&nbsp;
                    {canCancel && job.state < 20 && <a onClick={() => onCancel(job.id, job.name)}>Cancel</a>}
                </td>
            </tr>           
        </>        
    )    
};

export default Row;