import React, { useEffect, useState } from 'react';
import Errors from '../../../components/errors';
import Loader from '../../../components/loader';
import Form from './form';

const UserContainer = ({ title, userIsLoading, user, onSave, isLoading, error, emailAddressReadOnly }:any) => {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');        
    
    useEffect(() => {
        if (!userIsLoading && user) {
            setFirstName(user.firstName);
            setLastName(user.lastName);
            setEmailAddress(user.emailAddress);    
        }
    },[userIsLoading, user]);

    const onSaveUser = (e: any) => {
        e.preventDefault();
        onSave({firstName, lastName, emailAddress });
     };
    
    const onSetEmailAddress = (emailAddress: any) => {
        setEmailAddress(emailAddress);
    };

    return (
        <section className="section">
            <div>
                <h1>{title}</h1>
                <Errors error={error}/>
                {(isLoading) && <Loader/>}
                <Form firstName={firstName} 
                      lastName={lastName}
                      emailAddress={emailAddress}
                      setFirstName={setFirstName} 
                      setLastName={setLastName} 
                      setEmailAddress={onSetEmailAddress} 
                      onSaveUser={onSaveUser}
                      emailAddressReadOnly={emailAddressReadOnly}
                       />
            </div>
        </section>
    )    
};

export default UserContainer;