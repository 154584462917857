import React, { useState } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { matchPath } from 'react-router'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../app/store';
import { logout, selectUser } from '../services/accountSlice'
import LogViewerIcon from './icons/log-viewer-icon';
import ImageViewerIcon from './icons/image-viewer-icon';
import FileIcon from './icons/file-icon';

import './menu.scss';
import { toast } from 'react-toastify';

const Menu = (props: any) =>
{
  const { borehole, setBorehole } = props;

  const [mobileMenu, setMobileMenu] = useState(false);

  const burgerClick = () => {
    setMobileMenu(!mobileMenu);
  };

  const useParams2 = (path: any) : any => {
    const { pathname } = useLocation()
    const match = matchPath(pathname, { path })
    return match?.params || {}
    };

  let { clientId, projectId } = useParams2("/client/:clientId/project/:projectId");
  
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();

  const selectAccount = (state: RootState) => state.account;
  const { 
    loggedIn,
    user
  } = useSelector(selectAccount);

const onLogout = () => {  
  dispatch(logout());  
  history.push(`/logout`);
}

const clearBoreholeLinks = () => {
  setBorehole(null);
};

return (    
  
<nav className="navbar is-fixed-top" role="navigation" aria-label="main navigation">
  <div className="navbar-brand">
    <Link className="navbar-item" to={'/'}>
      <img src="https://www.geotek.co.uk/wp-content/uploads/2016/09/logo-web-whitebg_150px.png" />
      <span className="navbar-item atlas-title">ATLAS</span>
    </Link>    
    <a role="button" 
        onClick={burgerClick} className={
            "navbar-burger " + (mobileMenu ? " is-active" : "")
          } 
          aria-label="menu" 
          aria-expanded="false" 
          data-target="navbarBasicExample">
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  </div>



  <div id="navbarBasicExample" className={"navbar-menu" + (mobileMenu ? " is-active" : "")}>
    <div className="navbar-start">
        {user?.clientName && <span className="navbar-item client-name">{user?.clientName}</span>}        
        <NavLink className="navbar-item" to={`/`} exact={true}>Boreholes</NavLink>
    </div>
    {/* {borehole?.name &&
      <div className="navbar-item navbar-borehole-name">
        {borehole.name}
      </div>
    } */}
    <div className="navbar-end  mr-1">
      {borehole?.id &&
        <div className="navbar-item">
            Views: <NavLink className="navbar-item" to={`/client/${clientId}/project/${projectId}/borehole/${borehole.id}/image-viewer`} isActive={(match, location) => {
                  return location.pathname.endsWith(
                    `/image-viewer`
                  );
                }}><ImageViewerIcon className="menu-icon" /></NavLink> 
                  <NavLink className="navbar-item" to={`/client/${clientId}/project/${projectId}/borehole/${borehole.id}/log-viewer`} isActive={(match, location) => {
                  return location.pathname.endsWith(
                    `/log-viewer`
                  );
                }}><LogViewerIcon className="menu-icon" /></NavLink>
                  {user?.canAccessBoreholeFiles && <NavLink className="navbar-item" to={`/client/${clientId}/project/${projectId}/borehole/${borehole.id}/files`} isActive={(match, location) => {
                  return location.pathname.endsWith(
                    `/files`
                  );
                }}><FileIcon className="menu-icon"/> </NavLink>}
          </div>
      }
        {user?.isAnyAdmin && <div className="navbar-item">
          <NavLink className="navbar-item" to={`/admin`} onClick={clearBoreholeLinks} isActive={(match, location) => {
                  return location.pathname.startsWith(
                    `/admin`
                  );
                }}>Admin</NavLink>
        </div>}        
        {user?.isJobAdmin && <div className="navbar-item">
          <Link className="navbar-item" to={`/job/list`}>Jobs</Link>
        </div>}                
    {loggedIn &&      
      <div className="navbar-item has-dropdown is-hoverable">
                <a className="navbar-item name">
                  <div className="user-circle">
                    {user?.firstName?.substring(0, 1)}{user?.lastName?.substring(0, 1)}
                  </div>
                </a>

                <div className="navbar-dropdown is-boxed">
                  <div className="navbar-item user-details">
                    <div>
                      <div className="user-details-name">{user?.name}</div>
                      <div className="user-details-email-address">
                        {user?.emailAddress}
                      </div>
                    </div>
                  </div>
                  <hr className="navbar-divider" />              
                  <Link
                    to="/user"
                    className="navbar-item"
                    onClick={(event) => {
                      event.currentTarget.blur();
                    }}>
                    User Details
                  </Link>
                  <hr className="navbar-divider" />
                  <Link
                    to="/change-password"
                    className="navbar-item"
                    onClick={(event) => {
                      event.currentTarget.blur();
                    }}>
                    Change Password
                  </Link>
                  <hr className="navbar-divider" />
                  <a className="navbar-item" onClick={() => onLogout()}>
                    Logout
                  </a>
                </div>
        {/* <div className="buttons">
          {!loggedIn &&    
            <Link className="button is-light" to={'/login'}>Log in</Link>            
          }
          {loggedIn &&    
            <button className="button is-light" onClick={onLogout}>Log out</button>            
          }
        </div> */}
      </div>
      }
      {!loggedIn &&    
       <div className="navbar-item">
            <Link className="navbar-item" to={'/login'}>Log in</Link>            
            </div>
          }
    </div>
  </div>


</nav>  
);
}

export default Menu;