import React, { useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useHistory, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getImageType, selectImageType, updateImageType, deleteImageType } from '../../../services/imageTypeSlice';

import Container from './container';

const EditImageType = ({}:any) => {
    
    const { imageTypeId } = useParams<any>();        

    const dispatch = useAppDispatch();    
    let history = useHistory();  

    useEffect(() => {
        dispatch(getImageType(imageTypeId))
    }, []);

    const { 
        imageType,
        loading,
        error
       } = useAppSelector(selectImageType);

    const onSave = (imageType: any) => {
        dispatch(updateImageType({...imageType, id: imageTypeId})).then((response: any) => {
            if (response.payload.success) {
                history.push(`/admin/image-type/list`)
            } else {
                
            }
           });
    };    

    const onDelete = () => {
        confirmAlert({
            title: 'Confirm Delete Image Type',
            message: `Are you sure you want to delete ${imageType?.description}?`,
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                    dispatch(deleteImageType({id: imageTypeId})).then((response: any) => {
                        if (response.payload.success) {
                            history.push(`/admin/image-type/list`)
                        }
                       });
                }
              },
              {
                label: 'No',
                onClick: () => {}
              }
            ]
          });
    };    
    
    return (
       <Container title="Edit Image Type" 
                  imageType={imageType}
                  isLoading={loading}
                  onSave={onSave}
                  onDelete={onDelete}
                  error={error}  />
    )
};

export default EditImageType;