import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import getConfig from "../app/config";
import { toast } from 'react-toastify';
import fetchWithRefresh from './fetchWithRefresh';
import { RootState } from '../app/store';

export const getImageTypes = createAsyncThunk<any, void>(
  "imageTypes/list",
  async (user, thunkApi) => {
    const response = await fetchWithRefresh(getConfig().apiUrl + "imageType", thunkApi, {
      method: 'Get',
      headers: {       
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    });

    return response;
  }
)

export const getImageType = createAsyncThunk<any, {id:string}>(
  "imageTypes/get",
  async (id, thunkApi) => {
    const response = await fetchWithRefresh(getConfig().apiUrl + `imageType/${id}`, thunkApi, {
      method: 'Get',
      headers: {       
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    });

    return response;
  }
)

export const addImageType = createAsyncThunk<any, any>(
  "imageTypes/add",
  async (arg, thunkApi) => {
    return await fetchWithRefresh(getConfig().apiUrl + `imageType`, thunkApi, {
      method: 'POST',
      headers: {       
        'Content-Type': 'application/json',
        Accept: 'application/json',        
      },
      body: JSON.stringify(arg)
    });
  }
);

export const updateImageType = createAsyncThunk<any, any>(
  "imageTypes/update",
  async (arg, thunkApi) => {
    const { id } = arg;
    return await fetchWithRefresh(getConfig().apiUrl + `imageType/${id}`, thunkApi, {
      method: 'PUT',
      headers: {       
        'Content-Type': 'application/json',
        Accept: 'application/json',        
      },
      body: JSON.stringify(arg)
    });
  }
);

export const deleteImageType = createAsyncThunk<any, any>(
  "imageTypes/delete",
  async (arg, thunkApi) => {
    const { id } = arg;
    return await fetchWithRefresh(getConfig().apiUrl + `imageType/${id}`, thunkApi, {
      method: 'DELETE',
      headers: {       
        'Content-Type': 'application/json',
        Accept: 'application/json',        
      }
    });
  }
);

export interface ImageType {
  description: string,
  subFolder: string,
  fileName: string,
  exportHeaderName: string,
  legendName: string,
  legendRollover: boolean,
  canAdjustImage: boolean
}

export interface ImageTypeState {
    loading: boolean,
    imageTypes: any[],
    imageType: ImageType | null,
    error: any,    
  }
  
const initialState: ImageTypeState = {
    loading: false,
    imageTypes: [],    
    imageType: null,
    error: null    
  }

const imageTypeSlice = createSlice({
    name: 'image-type',
    initialState,
    reducers: {           
       clearImageType: (state) => {
         state.imageType = { description: "",
          subFolder: "",
          fileName: "",
          exportHeaderName: "",
          legendName: "",
          legendRollover: false,
          canAdjustImage: false }
       } 
    },
    extraReducers:  (builder) => {      
          builder.addCase(getImageTypes.pending, (state) => {         
            state.error = null;      
            state.loading = true;
          });

          builder.addCase(getImageTypes.fulfilled, (state, { payload }) => {   
            if (payload?.success || payload.status == 200) {
                state.imageTypes = payload.imageTypes;
            } else {
              toast.error("Error getting image types");
            }

            state.loading = false;
          }); 
       
          builder.addCase(getImageTypes.rejected, (state, action) => {
            debugger;
            if (action.payload) {
              state.error = action.payload;
              toast.error("Error getting image types");
            } else {
              state.error = action.error;
              toast.error("Error getting image types");
            }

            state.loading = false;
          });

          builder.addCase(getImageType.pending, (state) => {    
            state.error = null;
            state.loading = true;
          });

          builder.addCase(getImageType.fulfilled, (state, { payload }) => {   
            if (payload?.success || payload.status == 200) {
                state.imageType = payload;
            } else {
              toast.error("Error getting image type");
            }

            state.loading = false;
          }); 
       
          builder.addCase(getImageType.rejected, (state, action) => {
            if (action.payload) {
              state.error = action.payload;
              toast.error("Error getting image type");
            } else {
              state.error = action.error;
              toast.error("Error getting image type");
            }

            state.loading = false;
          });     
          
          builder.addCase(updateImageType.pending, (state) => {   
            state.error = null;            
            state.loading = true;
          });

          builder.addCase(updateImageType.fulfilled, (state, { payload }) => {   
            if (payload?.success || payload.status == 200) {
                state.imageTypes = payload.imageTypes;
                toast.success("Successfully updated image type");
            } else {
              toast.error("Error updating image type");
            }

            state.loading = false;
          }); 
       
          builder.addCase(updateImageType.rejected, (state, action) => {            
            if (action.payload) {
              state.error = { data: { errors: action.payload } };
              toast.error("Error updating image type");
            } else {
              state.error = action.error;
              toast.error("Error updating image type");
            }

            state.loading = false;
          });

          builder.addCase(addImageType.pending, (state) => {    
            state.error = null;           
            state.loading = true;
          });

          builder.addCase(addImageType.fulfilled, (state, { payload }) => {   
            if (payload?.success || payload.status == 200) {
                state.imageTypes = payload.imageTypes;
                toast.success("Successfully added image type");
            } else {
              state.error = { data: { errors: payload.errors } };
              toast.error("Error adding image type");
            }

            state.loading = false;
          }); 
       
          builder.addCase(addImageType.rejected, (state, action) => {
            if (action.payload) {
              state.error = { data: { errors: action.payload } };
              toast.error("Error adding image type");
            } else {
              state.error = action.error;
              toast.error("Error adding image type");
            }

            state.loading = false;
          });

          builder.addCase(deleteImageType.pending, (state) => {   
            state.error = null;            
            state.loading = true;
          });

          builder.addCase(deleteImageType.fulfilled, (state, { payload }) => {   
            if (payload?.success || payload.status == 200) {
                state.imageTypes = payload.imageTypes;
                toast.success("Successfully deleted image type");
            } else {
              toast.error("Error deleting image type");
            }

            state.loading = false;
          }); 
       
          builder.addCase(deleteImageType.rejected, (state, action) => {            
            if (action.payload) {
              state.error = { data: { errors: action.payload } };
              toast.error("Error deleting image type");
            } else {
              state.error = action.error;
              toast.error("Error deleting image type");
            }

            state.loading = false;
          });
    }    
})

export const { clearImageType } = imageTypeSlice.actions

export const selectImageType = (state: RootState) => state.imageType;

export default imageTypeSlice.reducer; 