export default (props: any) =>
<svg width="100%" height="100%" viewBox="0 0 24 24" version="1.1"  {...props}>
    <title>Image Viewer</title>
    <g transform="matrix(0.724528,0,0,0.724528,-321.6,-284.104)">
        <path d="M447.75,396.871L445.57,396.871C444.634,396.871 443.875,397.631 443.875,398.566L443.875,423.426C443.875,424.362 444.634,425.121 445.57,425.121L470.43,425.121C471.365,425.121 472.125,424.362 472.125,423.426L472.125,421.5L449.445,421.5C448.509,421.5 447.75,420.74 447.75,419.805L447.75,396.871Z"/>
    </g>
    <g transform="matrix(1.06895,0,0,1.06895,-0.827427,-0.827427)">
        <path d="M23.226,2.009C23.226,1.375 22.711,0.86 22.077,0.86L5.227,0.86C4.593,0.86 4.078,1.375 4.078,2.009L4.078,18.859C4.078,19.493 4.593,20.008 5.227,20.008L22.077,20.008C22.711,20.008 23.226,19.493 23.226,18.859L23.226,2.009ZM6.196,17.212L9.924,12.637L11.657,14.763L15.601,9.19L21.277,17.212L6.196,17.212ZM9.134,3.314C10.755,3.314 12.071,4.631 12.071,6.252C12.071,7.873 10.755,9.19 9.134,9.19C7.513,9.19 6.196,7.873 6.196,6.252C6.196,4.631 7.513,3.314 9.134,3.314Z"/>
    </g>
</svg>
