
import './list.scss';
import moment from 'moment';

const List = ({ userLogins 
                 }:any) => {
    return (
            <div className="">              
                  <table className="geotek-table">
                      <thead>
                          <tr>                              
                              <th>Name</th>
                              <th>Email Address</th>
                              <th>Timestamp</th>
                              <th>IP Address</th>
                              <th>Browser</th>
                          </tr>
                      </thead>
                      <tbody>
                       {(userLogins || []).map((userLogin: any, index: number) => (
                            <tr key={index}>                                
                                <td>{userLogin.name}</td>
                                <td>{userLogin.emailAddress}</td>
                                <td>{moment.utc(userLogin.timestamp).local().format("LL HH:mm:ss")}</td>
                                <td>{userLogin.ipAddress}</td>
                                <td className='login-browser' title={userLogin.browser}>{userLogin.browser}</td>
                            </tr>
                        ))}
                      </tbody>
                  </table>       
            </div>
    )    
};

export default List;