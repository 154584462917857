import React from "react";
import { Switch } from "react-router-dom";
import { PrivateRoute } from "../../components/privateRoute";
import ListRole from './list-roles/list-roles';
import EditRole from './edit-role/edit-role';
import ViewRole from './edit-role/view-role';
import AddRole from './edit-role/add-role';
import CopyRole from './edit-role/copy-role';
import ListUsersInRole from './list-users-in-role/list-users-in-role';

const RoleRoutes = () => {
  return (    
    <Switch>      
      <PrivateRoute exact path="/admin/role/list">
        <ListRole />        
      </PrivateRoute>
      <PrivateRoute exact path="/admin/role/add">
        <AddRole />        
      </PrivateRoute>
      <PrivateRoute exact path="/admin/role/edit/:roleId">
        <EditRole />        
      </PrivateRoute>
      <PrivateRoute exact path="/admin/role/copy/:roleId">
        <CopyRole />        
      </PrivateRoute>
      <PrivateRoute exact path="/admin/role/view/:roleId">
        <ViewRole />        
      </PrivateRoute>
      <PrivateRoute exact path="/admin/role/users-in-role/:roleId">
        <ListUsersInRole />        
      </PrivateRoute>
    </Switch>            
  );
};

export default RoleRoutes;
