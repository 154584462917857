import React from "react";
import { Switch } from "react-router-dom";
import { PrivateRoute } from "../../components/privateRoute";
import ListLexicons from './list-lexicons/list-lexions';
import EditLexicon from './edit-lexicon/edit-lexicon';
import AddLexicon from './edit-lexicon/add-lexicon';
import ListLookups from './list-lookups/list-lookups';
import EditLookup from './edit-lookup/edit-lookup';

const LithologyRoutes = () => {
  return (    
    <Switch>      
      <PrivateRoute exact path="/admin/lithology/lexicon/list">
        <ListLexicons />        
      </PrivateRoute>      
      <PrivateRoute exact path="/admin/lithology/lexicon/edit/:lithologyLexiconId">
        <EditLexicon />        
      </PrivateRoute>      
      <PrivateRoute exact path="/admin/lithology/lexicon/add">
        <AddLexicon />        
      </PrivateRoute>      
      <PrivateRoute exact path="/admin/lithology/lookup/list">
        <ListLookups />        
      </PrivateRoute>      
      <PrivateRoute exact path="/admin/lithology/lookup/edit/:lithologyLookupId">
        <EditLookup />        
      </PrivateRoute>      
    </Switch>            
  );
};

export default LithologyRoutes;
