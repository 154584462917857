import Collapsible from "../../../components/panels/collapsible";

export default function TextFilter({label, text, setText } : any) {    

    const onChange = (e: any) => {
        setText(e.target.value);
    };

    const onReset = () => {
        setText("");
    };

    return (
        <Collapsible label={label} isSelected={text} showReset={text} reset={onReset}>
            <div>
                <input type="text" className="input" placeholder="Enter text here" value={text} onChange={onChange}></input>
            </div>
        </Collapsible>
    )
}