import { useState } from 'react';
import TextField from '../../Input/text-field';

const AddGroup = (props: any) => {

    const {       
      addGroup,
    } = props;    
    
    const [groupName, setGroupName] = useState<string>();
    
    const onOnAddGroupFormSubmit =  (e: any) => {
      e.preventDefault();
      addGroup(groupName);
      setGroupName("");
    };
    
return(
  
        <form onSubmit={onOnAddGroupFormSubmit}>    
          <TextField label="Add Group" placeholder="Group Name" value={groupName} onChange={setGroupName} />
          
          <div className="buttons mt-3 sticky-buttons">
            <button className="button is-primary" onClick={onOnAddGroupFormSubmit} disabled={!groupName}>Add</button>
            {/* <button className="button" onClick={onOnClose}>Cancel</button> */}
          </div>
        </form>     
)};

export default AddGroup;