import { Icon } from "@iconify/react";
import { useState } from "react";
import GroupTracks from "./group-tracks";
import TextField from "../../Input/text-field";
import './group.scss';
import ColorField from "../../Input/color-field";
import AddTracksToGroup from "./add-tracks-to-group";

const Group = (props: any) => {

    const {       
      isSelected,
      name,
      color,      
      id,
      deleteGroup,
      tracks,
      deleteTrack,
      deleteTracks,
      deleteAllTracks,
      boreholeId, 
      clientId, 
      projectId, 
      boreholes,
      addTracks,
      updateTrackOrder,
      changeGroupName,
      changeGroupColor,
      setSelectedGroup
    } = props;    

    const [collapsed, setCollapsed] = useState<boolean>(true);

    const onSetCollabpsed = () => {
      setCollapsed(!collapsed);
      if (collapsed) {
        setSelectedGroup(id);
      } else {
        setSelectedGroup(null);
      }
    };

    const onDelete = () => {
      deleteGroup(id, name);
    };

    const onDeleteTrack = (trackId: any, boreholeName: any, trackName: any) => {      
      deleteTrack(id, trackId, boreholeName, trackName);
    };

    const onDeleteTracks = (trackIds: any) => {      
      deleteTracks(id, trackIds, name);
    };

    const onDeleteAllTracks = () => {
      deleteAllTracks(id, name);
    };

    const onChangeGroupName = (value: string) => {      
      changeGroupName(id, value);
    };

    const onChangeGroupColor = (value: string) => {      
      changeGroupColor(id, value);
    };

    const tabs = [
      { number: 0, display: true, icon: <img src={"/images/icons/Pencil Icon.svg"} className="tab-icon" title="Edit"/> },
      { number: 1, display: true, icon: <img src={"/images/icons/list.svg"} className="tab-icon" title="Tracks"/> },
      { number: 2, display: true, icon: <img src={"/images/icons/Atlas Add Track Icon black.svg"} className="tab-icon" title="Add"/> },
      { number: 3, display: true, icon: <img src={"/images/icons/Atlas Delete Icon.svg"} className="tab-icon" title="Delete"/>, click: onDelete },
    ];

    const [selectedTab, setSelectedTab] = useState<number>(0);
    
    const onAddTracks = (boreholeId: any, boreholeName: any, projectName: any, tracks: any[], trackCategoryName: any) => {      
      addTracks(id, boreholeId, boreholeName, projectName, tracks, trackCategoryName);
      setSelectedTab(1);
      setSelectedGroup(null);
    };
    
    const onUpdateTrackOrder = (sourceIndex: any, destimationIndex: any) => {
      updateTrackOrder(id, sourceIndex, destimationIndex);
    };

    return (  
        <div>                
              <div className={`collapsible is-size-6 ${isSelected ? "track-active" : ""}`} onClick={onSetCollabpsed} >                  
              <div className="track-order-label">{name}</div>
              <div className="collapsible-icon">
                <Icon icon="mdi:chevron-down-circle-outline" width="24" height="24" rotate={collapsed ? 0 : 2} />
              </div>
            </div>  
            <div className="collapsible-content" style={{display: collapsed ? "none" : "inline-block", overflow: "visible" }}>
              <div className="tabs is-boxed panel-tab">
                <ul>
                    {tabs.map((tab: any, index: number) => (
                      <>
                        {tab.display && <li key={index} className={selectedTab == tab.number ? 'is-active' : ''} onClick={() => tab.click ? tab.click() : setSelectedTab(tab.number)}><a>{tab.icon}</a></li> }
                    </>
                    ))}                    
                </ul>          
              </div>
              {selectedTab === 0 &&
                <div>
                  <TextField label="Name" value={name} onChange={onChangeGroupName} />
                  <ColorField label="Group Color" color={color} onChange={onChangeGroupColor} />
                </div>
              }
              {selectedTab === 1 &&
                <GroupTracks id={id} tracks={tracks} deleteTrack={onDeleteTrack} deleteTracks={onDeleteTracks} deleteAllTracks={onDeleteAllTracks} updateTrackOrder={onUpdateTrackOrder} />
              }
              {selectedTab === 2 && 
                <AddTracksToGroup boreholeId={boreholeId}
                  clientId={clientId}
                  projectId={projectId}
                  boreholes={boreholes}
                  addTracks={onAddTracks}/>
                
              }
            </div>  
      </div> 
  )
};

export default Group;