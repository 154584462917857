import decodeJWT from "jwt-decode";

const isLoggedIn = () => {
  if (!localStorage.user) {
    return false;
  }

  return !!localStorage.user && localStorage.token != "null";
};

const isAuthenticated = () => {
  let authenticated = false;
  const user = getUser();
  if (user) {
      const expires = new Date(user.expires);
      const expiresTommorrow = new Date(expires.getTime() + (1000 * 60 * 60 * 24));      
      let dateTime: Date = new Date();
      authenticated = expiresTommorrow > dateTime;
  }
  
  return authenticated;
};

const isAdmin = () => {
  let authenticated = false;
  const user = getUser();
  if (user) {
      const expires = new Date(user.expires);
      const expiresTommorrow = new Date(expires.getTime() + (1000 * 60 * 60 * 24));      
      let dateTime: Date = new Date();
      authenticated = expiresTommorrow > dateTime;
  }
  
  return authenticated && user.isAdmin;
};

const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
};

const getToken = () => {
  return localStorage.token;
};

const getTokenForSignalR = () => {
  return localStorage.tokenForSignalR;
};

const getTokenForImages = () => {
  return localStorage.tokenForImages;
};

const setToken = (data: any) => {
  localStorage.setItem('token', data.token);
  localStorage.setItem('tokenForSignalR', data.tokenForSignalR);
  localStorage.setItem('tokenForImages', data.tokenForImages);
  localStorage.setItem('user', JSON.stringify((data || {})));
};

const updateToken = (token: any, expires: any) => {  
  localStorage.setItem('token', token);
  let currentUser = getUser();
  let newUser = { ...currentUser, token, expires };
  localStorage.setItem('user', JSON.stringify((newUser || {})));
};

const getUserClientId = () => {
  return getUser()?.clientId;
};

const getUser = () => {
  return isLoggedIn() && (JSON.parse(localStorage.getItem('user')!) || undefined);
};

const getCurrentUserInfo = () => {
  const decoded: any = decodeJWT(localStorage.token);
  return {
    name: decoded.name,
    firstName: decoded.given_name,
    lastName: decoded.family_name,
    emails: decoded.emails,
    city: decoded.city,
    country: decoded.country
  };
};

export default {
  isLoggedIn,
  isAuthenticated,
  logout,
  setToken,
  updateToken,
  getToken,
  getTokenForSignalR,
  getTokenForImages,
  getUser,
  getCurrentUserInfo,
  isAdmin,
  getUserClientId
};
