import React from 'react';
import Row from './row';
import './list.scss';
import SmallLoader from '../../../components/small-loader';

const List = ({ jobs, onDelete, canDelete, canCancel, onCancel }:any) => {

    return (
            <div className="columns">
              <div className="column">
                  <table className="table">
                      <thead>
                          <tr>
                              <th>Client</th> 
                              <th>Borehole</th>
                              <th>Name</th>
                              <th>Type</th>
                              <th>State</th>
                              <th>Created By</th>
                              <th>Created</th>
                              <th className='job-progress-column'>Progress</th>
                              <th>Started</th>
                              <th>Completed</th>
                              <th>Actions</th>
                          </tr>
                      </thead>
                      <tbody>
                       {(jobs || []).map((job: any, index: number) => (
                           <Row key={job.id} 
                                            job={job}
                                            onDelete={onDelete}                                            
                                            canDelete={canDelete}
                                            onCancel={onCancel}
                                            canCancel={canCancel} />
                        ))}
                      </tbody>
                  </table>                  
              </div>
            </div>
    )    
};

export default List;