import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PrintState {
  paperSize: number,
  orientation: number,
  pagesToPrintOver: number,
  fanFold: boolean,
  tracksPerPage: number,
  fixTracksPerPage: boolean,
  totalPages: number,
  fixTotalPages: boolean,
  leftDepthScale: boolean,
  rightDepthScale: boolean,
  startDepth: number | null,
  endDepth: number | null,
  depthPerPage: number | null,
  printOverPages: boolean,
  printStatisticalSummary: boolean,
  selectedDataForDepthRange: any[]
  }
  
  const initialState: PrintState = {
    paperSize: 4,
    orientation: 1,
    pagesToPrintOver: 1,
    fanFold: false,
    tracksPerPage: 4,
    fixTracksPerPage: false,
    totalPages: 1,
    fixTotalPages: false,
    leftDepthScale: true,
    rightDepthScale: true,
    startDepth: null,
    endDepth: null,
    depthPerPage: null,
    printOverPages: true,
    printStatisticalSummary: false,
    selectedDataForDepthRange: []
  }

const printSlice = createSlice({
    name: 'print',
    initialState,
    reducers: {      
      setPaperSize: (state, action: PayloadAction<any>) => {  
        const paperSize = action.payload;
        state.paperSize = paperSize;        
       },
       setOrientation: (state, action: PayloadAction<any>) => {  
        const orientation = action.payload;
        state.orientation = orientation;        
       },
       setStartDepth: (state, action: PayloadAction<any>) => {  
        const startDepth = action.payload;
        state.startDepth = startDepth;        
       },
       setEndDepth: (state, action: PayloadAction<any>) => {  
        const endDepth = action.payload;
        state.endDepth = endDepth;        
       },
       setDepthPerPage: (state, action: PayloadAction<any>) => {  
        const depthPerPage = action.payload;
        state.depthPerPage = depthPerPage;        
       },
       setPagesToPrintOver: (state, action: PayloadAction<any>) => {  
        const pagesToPrintOver = action.payload;
        state.pagesToPrintOver = pagesToPrintOver;        
       },
       setPrintOverPages: (state, action: PayloadAction<any>) => {  
        const printOverPages = action.payload;
        state.printOverPages = printOverPages;        
       },
       setTracksPerPage: (state, action: PayloadAction<any>) => {  
        const tracksPerPage = action.payload;
        state.tracksPerPage = tracksPerPage;        
       },       
       setFixTracksPerPage: (state, action: PayloadAction<any>) => {  
        const fixTracksPerPage = action.payload;
        state.fixTracksPerPage = fixTracksPerPage;        
       },       
       setFixTotalPages: (state, action: PayloadAction<any>) => {  
        const fixTotalPages = action.payload;
        state.fixTotalPages = fixTotalPages;        
       },       
       setTotalPages: (state, action: PayloadAction<any>) => {  
        const totalPages = action.payload;
        state.totalPages = totalPages;        
       },       
       setLeftDepthScale: (state, action: PayloadAction<any>) => {  
        const leftDepthScale = action.payload;
        state.leftDepthScale = leftDepthScale;        
       },
       setRightDepthScale: (state, action: PayloadAction<any>) => {  
        const rightDepthScale = action.payload;
        state.rightDepthScale = rightDepthScale;
       },       
       setPrintStatisticalSummary: (state, action: PayloadAction<any>) => {  
        const printStatisticalSummary = action.payload;
        state.printStatisticalSummary = printStatisticalSummary;
       },
       setSelectedDataForDepthRange: (state, action: PayloadAction<any>) => {  
        const {value, index} = action.payload;
        state.selectedDataForDepthRange[index] = value;
       },
       addSelectedDataForDepthRange: (state, action: PayloadAction<any>) => {          
        const {id, name} = action.payload;
        state.selectedDataForDepthRange.push({id, name});
       },
       deleteSelectedDataForDepthRange: (state, action: PayloadAction<any>) => {          
        const id = action.payload;
        const index = state.selectedDataForDepthRange.findIndex((d: any) => d.id == id);
        if (index > -1) {
          state.selectedDataForDepthRange.splice(index, 1);
        }
       },
       clearSelectedDataForDepthRange: (state) => {     
        state.printStatisticalSummary = false;     
        state.selectedDataForDepthRange = [];
       }
    }    
})

export const { setPaperSize, 
               setOrientation,
               setStartDepth,
               setEndDepth,
               setDepthPerPage,
               setPagesToPrintOver,
               setPrintOverPages,
               setTracksPerPage,
               setFixTracksPerPage,
               setFixTotalPages,
               setTotalPages,
               setLeftDepthScale,
               setRightDepthScale,
               setPrintStatisticalSummary,
               setSelectedDataForDepthRange,
               addSelectedDataForDepthRange,
               deleteSelectedDataForDepthRange,
               clearSelectedDataForDepthRange } = printSlice.actions

export default printSlice.reducer;