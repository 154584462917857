import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../app/hooks';
import { getLithologyLookup, selectLithology, updateLithologyLookup } from '../../../services/lithologySlice';

import Container from './container';

const EditRole = ({}:any) => {    
    const { lithologyLookupId } = useParams<any>();
    const dispatch = useAppDispatch();       

    useEffect(() => {
        dispatch(getLithologyLookup({id: lithologyLookupId}));
    }, [lithologyLookupId]);
    
    let history = useHistory();

    const {  
        lithologyLookup,
        loading,
        saving,
        error
      } = useSelector(selectLithology); 

    const onUpdateLithologyLexicon = (lithologyLookup: any) => {  
        lithologyLookup.id = lithologyLookupId;
        dispatch(updateLithologyLookup(lithologyLookup))        
        .unwrap()
        .then((response: any) => {
            toast.success("Successfully updated lithology lookup");
            history.push(`/admin/lithology/lookup/list`) ;
         })
      };

    return (
       <Container title="Edit Lookup" 
                  lithologyLookup={lithologyLookup} 
                  loading={loading} 
                  saving={saving}
                  onSave={onUpdateLithologyLexicon}                  
                  error={{...error}}  /> 
    )    
};

export default EditRole;