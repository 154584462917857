import React, { useState } from 'react';
import { useHistory, useParams  } from 'react-router-dom';
import { toast } from 'react-toastify';
import Errors from '../../components/errors';
import { useAddMutation } from "../../services/projectApi";
import ProjectForm from './project-form';

const AddProject = ({} : any) => {
  let { clientId } = useParams<any>();
  const [name, setName] = useState('');
  
  const onSetName = (name: string) => {
    setName(name);
  };

  let history = useHistory();

    const [add, { isLoading, error: addError }] = useAddMutation();

    const onAddProject = (e: any) => {
      e.preventDefault();
      add({clientId, name})
        .unwrap()
        .then((response: any) => {
          toast.success("Successfully added project");
          history.push(`/client/${clientId}/project/${response.projectId}/borehole/add`)  // redirect
        })
        .catch((error) => { 
          toast.error("Error adding project") });
    };
 
    return (
      <section className="section">
        <div>
            <h1>Add Project</h1>
            <Errors error={addError}/>
            <ProjectForm name={name} setName={onSetName} onSaveProject={onAddProject} loading={ isLoading } />         
        </div>
      </section>
    )
};

export default AddProject;