import React from 'react';
import Slider, { Range, SliderTooltip } from 'rc-slider';
import "rc-slider/assets/index.css";
import "./input-range.scss";
import { CSSProperties } from 'react';

const InputRange = (props: any) => {
    const { value, onChange, maxValue, minValue, disabled, showHandles } = props;
    
      const onOnChange = (value: any) => {
        onChange({min: value[0], max: value[1]})
      };

      const handleStyle : CSSProperties[] = [{
        height: 16,
        width: 16,
        marginTop: -8,
        backgroundColor: "#e48f00",
        border: 0
      },
      {
        height: 16,
        width: 16,
        marginTop: -8,
        backgroundColor: "#e48f00",
        border: 0
      }];

      const { Handle } = Slider;

      const  handle = (props: any) => {
        const { value, dragging, index, ...restProps } = props;
        return (
            <SliderTooltip
                prefixCls="rc-slider-tooltip"
                overlay={`${value}m`}                        
                visible={showHandles}
                placement="top"
                key={index}>
             <Handle value={value} {...restProps} />
          </SliderTooltip>
        );
      };

    return (
        <Range
          className="mt-3"
          defaultValue={[minValue, maxValue]}
          min={minValue}
          max={maxValue}        
          onChange={onOnChange}
          value={[value.min, value.max]}
          handleStyle={handleStyle}
          railStyle={{backgroundColor: "rgba(0, 54, 113, 1)"}}
          trackStyle={[{backgroundColor: "#e48f00"}]}
          //handle={handle}
          allowCross={false}
          disabled={disabled}
      />
    )
}

export default InputRange;