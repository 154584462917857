import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../app/hooks';
import { getLithologyLexicon, selectLithology, updateLithologyLexicon } from '../../../services/lithologySlice';

import Container from './container';

const EditRole = ({}:any) => {    
    const { lithologyLexiconId } = useParams<any>();
    const dispatch = useAppDispatch();       

    useEffect(() => {
        dispatch(getLithologyLexicon({id: lithologyLexiconId}));
    }, [lithologyLexiconId]);
    
    let history = useHistory();

    const {  
        lithologyLexicon,
        loading,
        saving,
        error
      } = useSelector(selectLithology); 

    const onUpdateLithologyLexicon = (lithologyLexicon: any) => {  
        lithologyLexicon.id = lithologyLexiconId;
        dispatch(updateLithologyLexicon(lithologyLexicon))        
        .unwrap()
        .then((response: any) => {
            toast.success("Successfully updated lithology lexicon");
            history.push(`/admin/lithology/lexicon/list`) ;
         })
      };

    return (
       <Container title="Edit Lexicon" 
                  lithologyLexicon={lithologyLexicon} 
                  loading={loading} 
                  saving={saving}
                  onSave={onUpdateLithologyLexicon}                  
                  error={{...error}}  /> 
    )    
};

export default EditRole;