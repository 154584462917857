import React from 'react';
import { Link } from 'react-router-dom';
import TextField from '../../../components/Input/text-field';

const ResetPasswordForm = (props: any) => {

    const { newPasswordErrors,
        newPassword,
        setNewPassword,
        confirmNewPasswordErrors,
        confirmNewPassword,
        setConfirmNewPassword,
        handleSubmit } = props;

        const onHandleSubmit = (e: any) => {
            e.preventDefault();
            handleSubmit();
        }

return (
    <div className="container">
        <div className="columns is-centered">
            <div className="column is-5-tablet is-4-desktop is-4-widescreen">
                <form className="box" onSubmit={onHandleSubmit}>
                    <h4>Reset Password</h4>
                    <TextField type="password" label="New Password" errors={newPasswordErrors} value={newPassword} onChange={setNewPassword} icon="fa-lock"></TextField>
                    <TextField type="password" label="Confirm New Password" errors={confirmNewPasswordErrors} value={confirmNewPassword} onChange={setConfirmNewPassword} icon="fa-lock"></TextField>

                    <div className="field is-grouped">
                        <div className="control mt-3">
                            <button type="submit" className="button is-primary">Reset Password</button>
                        </div>
                    </div>
                </form>

                <Link to={"/login"}>Login</Link>
            </div>
        </div>
    </div>        
)}

export default ResetPasswordForm;