import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useGetClientQuery, useUpdateMutation } from '../../../services/clientApi';

import Container from './container';

const EditRole = ({}:any) => {
    
    const { clientId } = useParams<any>();    
    const { data: client, isLoading: clientIsLoading, error } = useGetClientQuery({ clientId });
    const [update, { isLoading, error: updateError }] = useUpdateMutation();
    let history = useHistory();

    const onUpdateClient = (client: any) => {        
        update({...client, clientId })
        .unwrap()
        .then((response: any) => {
            toast.success("Successfully updated client");
            history.push(`/admin/client/list`) ;
         })
         .catch((error) => { toast.error("Error updating client") });
      };

    return (
       <Container title="Edit Client" 
                  client={client} 
                  clientIsLoading={clientIsLoading} 
                  onSave={onUpdateClient}
                  isLoading={isLoading}
                  error={{...error, ...updateError}}  />
    )    
};

export default EditRole;