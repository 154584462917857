import { useState } from "react";
import InputRange from "../../../components/input-range/input-range";
import Collapsible from "../../../components/panels/collapsible";
import './depth-filter.scss'

export default function DepthFilter({ 
    minimumBoreholeDepth,
    maximumBoreholeDepth,
    minimumDepth,
    maximumDepth, 
    onChange,    
    showHandles } : any) {    

    const [visible, setVisible] = useState<boolean>(false);

    const showHide = (show: boolean) => {
        setVisible(show);
    };

    const onReset = () => {
        onChange({min: minimumBoreholeDepth, max: maximumBoreholeDepth});
    };

    return (
        <Collapsible label="Borehole Depth" isSelected={maximumDepth < maximumBoreholeDepth || minimumDepth > minimumBoreholeDepth} showHide={showHide} showReset={maximumDepth < maximumBoreholeDepth || minimumDepth > minimumBoreholeDepth} reset={onReset}>
            <div className="control depth-filter">
                <div className="depth-filter-value">
                    {minimumDepth} m
                    <div className="depth-filter-value-right">{maximumDepth} m</div>
                </div>
                <InputRange
                    maxValue={maximumBoreholeDepth}
                    minValue={minimumBoreholeDepth}
                    formatLabel={(value: any) => `${value}`}
                    onChange={onChange}
                    allowSameValues={true}
                    value={{min: minimumDepth, max: maximumDepth}}     
                    showHandles={showHandles && visible}
                    labelContainer="inputRangeLabelContainer"                    
                />
            </div>            
        </Collapsible>
    )
}