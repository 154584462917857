import { useState } from 'react';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { RootState } from '../../../app/store';

const Templates = (props: any) => {

    const {                
        selectedTemplateId,
        setSelectedTemplateId,
        setTemplateName
      } = props; 

      const [selectedBuiltInTemplateId, setSelectedBuiltInTemplateId] = useState<any>();
      const [selectedUserTemplateId, setSelectedUserTemplateId] = useState<any>();
      const [selectedProjectTemplateId, setSelectedProjectTemplateId] = useState<any>();
      const [selectedClientTemplateId, setSelectedClientTemplateId] = useState<any>();
      const [currentTemplateLevel, setCurrentTemplateLevel] = useState<string>();

      const selectTemplates = (state: RootState) => state.templates;
      const { 
              allTemplates,
              builtInTemplates,
              userTemplates,
              projectTemplates,
              clientTemplates,
              templatesLoading,           
              error
            } = useSelector(selectTemplates);
  
      const builtInTemplateOptions: any = builtInTemplates.map((t: any) => { return { label: t.name, value: t.id, isDisabled: false}});
      const userTemplateOptions: any = userTemplates.map((t: any) => { return { label: t.name, value: t.id, isDisabled: false}});    
      const projectTemplateOptions: any = projectTemplates.map((t: any) => { return { label: t.name, value: t.id, isDisabled: false}});
      const clientTemplateOptions: any = clientTemplates.map((t: any) => { return { label: t.name, value: t.id, isDisabled: false}});

      const onChangeBuiltInTemplate = (e: any) => {                   
        selectBuiltInTemplate(e.value);
      };
  
      const onChangeUserTemplate = (e: any) => {             
          selectUserTemplate(e.value);        
      };
  
      const onChangeProjectTemplate = (e: any) => {     
        selectProjectTemplate(e.value);
      };
  
      const onChangeClientTemplate = (e: any) => {     
        selectClientTemplate(e.value);
      };

      const selectBuiltInTemplate = (templateId: string) => {
        const selectedTemplate: any = builtInTemplateOptions.find((o:any) => o.value == templateId);
        setTemplateName(selectedTemplate.label);
        setSelectedBuiltInTemplateId(selectedTemplate);
        setSelectedUserTemplateId(null);        
        setSelectedProjectTemplateId(null);
        setSelectedClientTemplateId(null);
        setSelectedTemplateId(templateId); 
        setCurrentTemplateLevel("Default");        
      }
  
      const selectUserTemplate = (templateId: string) => {
        const selectedTemplate: any = userTemplateOptions.find((o:any) => o.value == templateId);
        setTemplateName(selectedTemplate.label);
        setSelectedBuiltInTemplateId(null);      
        setSelectedUserTemplateId(selectedTemplate);
        setSelectedProjectTemplateId(null);
        setSelectedClientTemplateId(null);
        setSelectedTemplateId(templateId); 
        setCurrentTemplateLevel("User");        
      }
    
      const selectProjectTemplate = (templateId: string) => {
        const selectedTemplateId2: any = projectTemplateOptions.find((o:any) => o.value == templateId);
        setSelectedBuiltInTemplateId(null);
        setSelectedUserTemplateId(null);
        setSelectedProjectTemplateId(selectedTemplateId2);
        setSelectedClientTemplateId(null);
        setSelectedTemplateId(templateId); 
        setCurrentTemplateLevel("Project");
      }
  
      const selectClientTemplate = (templateId: string) => {
        const selectedTemplate: any = clientTemplateOptions.find((o:any) => o.value == templateId);
        setTemplateName(selectedTemplate.label);
        setSelectedBuiltInTemplateId(null);
        setSelectedUserTemplateId(null);
        setSelectedProjectTemplateId(null);
        setSelectedClientTemplateId(selectedTemplate);
        setSelectedTemplateId(templateId); 
        setCurrentTemplateLevel("Client");
      }

    const customStyles = {
        option: (provided: any, { data, isDisabled, isFocused, isSelected }:any) => ({
          ...provided,          
          backgroundColor: isSelected ? 'rgba(0, 54, 113, 1)' : '#FFFFFF',
          color: isDisabled
            ? '#ccc'
            : isSelected ? "white" : data.color,
          cursor: isDisabled ? 'not-allowed' : 'default',
          padding: 5,
          paddingLeft: "12px",
          "&:hover": {
            backgroundColor: "rgba(0, 54, 113, 0.3)"
          }
        })        
      };

    // const getSelectedTemplateId = () => {
    //   let selectedTemplateId: any = null;
    //   if (selectedTemplateId) {
    //     selectedTemplateId = builtInTemplateOptions.find((o:any) => o.value == selectedTemplateId);
    //     if (selectedTemplateId) {
    //       selectedBuiltInTemplateId = selectedTemplateId;        
    //       return;
    //     } else {
    //       selectedTemplateId = userTemplateOptions.find((o:any) => o.value == selectedTemplateId);
    //     }
    //     if (selectedTemplateId) {
    //       selectedUserTemplateId = selectedTemplateId;
    //       return;
    //     } else {
    //      // selectedTemplateId = boreholeTemplateOptions.find((o:any) => o.value == selectedTemplateId);
    //     }
    //     if (selectedTemplateId) {
    //       selectedBoreholeTemplateId = selectedTemplateId;
    //       return;
    //     } else {
    //       selectedTemplateId = projectTemplateOptions.find((o:any) => o.value == selectedTemplateId);
    //     }
    //     if (selectedTemplateId) {
    //       selectedProjectTemplateId = selectedTemplateId;
    //       return;
    //     } else {
    //       selectedTemplateId = clientTemplateOptions.find((o:any) => o.value == selectedTemplateId);
    //     }

    //     if (selectedTemplateId) {
    //       selectedClientTemplateId = selectedTemplateId;
    //     }
    // }};

    // getSelectedTemplateId();

return(
<div className="field">
          <label className="label">Available Templates</label>
          <div>
            Default
            <div className="control">
                <Select options={builtInTemplateOptions} styles={customStyles} onChange={onChangeBuiltInTemplate} value={selectedBuiltInTemplateId} classNamePrefix="select"></Select>
            </div>
          </div>
          <div className='mt-2'>
            User
            <div className="control">
                <Select options={userTemplateOptions} styles={customStyles} onChange={onChangeUserTemplate} value={selectedUserTemplateId} classNamePrefix="select"></Select>                         
            </div>
          </div>
          {/* <div className='mt-2'>
            Borehole
            <div className="control">
                <Select options={boreholeTemplateOptions} styles={customStyles} onChange={onChangeBoreholeTemplate} value={selectedBoreholeTemplateId} classNamePrefix="select"></Select>                         
            </div>
          </div> */}
          {projectTemplateOptions?.length > 0 && <div className='mt-2'>
            Project
            <div className="control">
                <Select options={projectTemplateOptions} styles={customStyles} onChange={onChangeProjectTemplate} value={selectedProjectTemplateId} classNamePrefix="select"></Select>                         
            </div>
          </div>}
          <div className='mt-2'>
            Client
            <div className="control">
                <Select options={clientTemplateOptions} styles={customStyles} onChange={onChangeClientTemplate} value={selectedClientTemplateId} classNamePrefix="select"></Select>                         
            </div>
          </div>
      </div>
)
}

export default Templates;