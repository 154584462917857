import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useGetRoleQuery, useUpdateMutation } from '../../../services/roleApi';

import Container from './container';

const EditRole = ({}:any) => {
    
    const { roleId } = useParams<any>();
    const { data: role, isLoading: roleIsLoading, error } = useGetRoleQuery({ roleId });
    const [update, { isLoading, error: updateError }] = useUpdateMutation();
    let history = useHistory();

    const onUpdateRole = (role: any) => {        
        update({...role, roleId })
        .unwrap()
        .then((response: any) => {
            toast.success("Successfully updated role");
            history.push(`/admin/role/list`) ;
         })
         .catch((error) => { toast.error("Error updating role") });
      };

    return (
       <Container title="Edit Role" 
                  role={role} 
                  roleIsLoading={roleIsLoading} 
                  onSave={onUpdateRole}
                  isLoading={isLoading}
                  error={{...error, ...updateError}}  />
    )    
};

export default EditRole;