import { useState } from 'react';
import { Link } from 'react-router-dom';
import './list.scss';

const List = ({ users, 
                onDelete, 
                deleteMultiple,
                canAdd, 
                canEdit, 
                canDelete, 
                canEditEmailAddress, 
                canEditRoles,
                canResetTwoFactor, 
                onRemove,
                removeMultiple, 
                canRemove,
                resetTwoFactor }:any) => {

    const [checkedRows, setCheckedRows] = useState<any[]>([]);

    const onCheckRow = (id: string) => {
        const checkRowsCopy = Array.from(checkedRows);
        const foundIndex = checkRowsCopy.findIndex((r: any) => r == id);
        if (foundIndex >= 0) {
            checkRowsCopy.splice(foundIndex, 1);
        } else {
            checkRowsCopy.push(id);
        }

        setCheckedRows(checkRowsCopy);
    };

    const onCheckAll = () => {        
        if (checkedRows.length === users.length) {     
            setCheckedRows([]);
        } else {
            const checkRowsCopy = Array.from(checkedRows);
            users.forEach((user: any) => {
                const foundIndex = checkRowsCopy.findIndex((r: any) => r == user.id);
                if (foundIndex === -1) {                    
                    checkRowsCopy.push(user.id);
                }
            });

            setCheckedRows(checkRowsCopy);
        }
    };

    const onDeleteMultiple = () => {
        if (deleteMultiple(checkedRows)) {
            setCheckedRows([]);
        }
    }

    const onRemoveMultiple = () => {
        if (removeMultiple(checkedRows)) {
            setCheckedRows([]);
        }
    }

    const onResetTwoFactor = (userId: any, username: string) => {
       debugger;
        resetTwoFactor(userId, username)
    };

    return (
            <div className="">              
                  <table className="geotek-table">
                      <thead>
                          <tr>
                              <th><input type="checkbox" onChange={onCheckAll} checked={checkedRows.length === users?.length && users?.length > 0}/></th>
                              <th>Name</th>
                              <th>Email Address</th>
                              <th>Actions</th>
                          </tr>
                      </thead>
                      <tbody>
                       {(users || []).map((user: any, index: number) => (
                            <tr key={index}>
                                <td><input type="checkbox" onChange={() => onCheckRow(user.id)} checked={checkedRows.findIndex((r: any) => r == user.id) >= 0}/></td>
                                <td>{user.name}</td>
                                <td className='grey-email-address'>{user.emailAddress}</td>
                                <td>
                                    <div className="columns list-users-viewer-columns">
                                        <div className="column">{canEdit && user.canEdit && <><Link to={`edit/${user.id}`}>Edit</Link></>}</div>
                                        <div className="column">{canEditRoles && <><Link to={`roles/${user.id}`}>Roles</Link></>}</div>
                                        <div className="column">{canEditEmailAddress && <><Link to={`email-address/${user.id}`}>Email</Link></>}</div>
                                        <div className="column">{canResetTwoFactor && user.hasTwoFactorEnabled &&  <><a onClick={() => onResetTwoFactor(user.id, user.name)}>Reset&nbsp;2FA</a></>}</div>                                       
                                    </div>
                                </td>
                            </tr>
                        ))}
                      </tbody>
                  </table>                
                  <div className="field is-grouped">
                    {canAdd && 
                        <div className="control">
                            <Link className="button" to={`add`}>Add User</Link>
                        </div>}
                    {canDelete && 
                        <div className="control">
                            <button className="button is-primary" onClick={onDeleteMultiple} disabled={checkedRows.length === 0 || users?.length === 0}>Delete Users</button>
                        </div>}
                    {canRemove && 
                        <div className="control">
                            <button className="button is-primary" onClick={onRemoveMultiple} disabled={checkedRows.length === 0 || users?.length === 0}>Remove Users</button>
                        </div>}
                  </div>            
            </div>
    )    
};

export default List;