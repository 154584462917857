import getConfig from "../app/config";
import auth from "../components/auth";

const fetchWithRefresh = async (url: RequestInfo, thunkApi?: any, init?: RequestInit) =>
  {  
      let response: any = await fetch(url, {
        ...init,
        headers: {
          ...init?.headers,
          Authorization: `Bearer ${auth.getToken()}`,
        }
      })

      if (response.status === 409) {
        const trackData = await response.json();      
        if (thunkApi) {
          return thunkApi.rejectWithValue(trackData.errors);
        } else {
          return trackData;
        }
      } else if (response.status === 403) {        
        if (thunkApi) {
          return thunkApi.rejectWithValue(["Unauthorized"]);
        } else {
          return {success: false, errors: {status: 403, message:"Unauthorized"}};
        }      
      } else if (response.status === 401) {              
        // use refresh token
        if (auth.isLoggedIn()) {
          const refreshResult : any = await fetch(getConfig().apiUrl + `account/refresh` , {
            method: 'POST',            
            body: JSON.stringify({ 
              accessToken: auth.getToken(), 
              refreshToken: auth.getUser().refreshToken
            }),
            headers: {
              Authorization: `Bearer ${auth.getToken()}`,
              'Content-Type': 'application/json'
            }            
          })
          const data = await refreshResult.json();
          if (data) {
            // store the new token
            auth.setToken(data);
            // retry the initial query
            response = await fetch(url, 
              {
                ...init,
                headers: {
                  ...init?.headers,
                  Authorization: `Bearer ${auth.getToken()}`,
                }
              })
             const trackData = await response.json();      
             return trackData;
          } else {
              auth.logout();
              window.location.href = '/login';          
          }
        }
      } else {
        const trackData = await response.json();      
        return trackData;
      }      
  };

  export default fetchWithRefresh;