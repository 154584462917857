import React, { useEffect, useState } from 'react';
import './App.css';
import {
  BrowserRouter,
  BrowserRouter as Router  
} from "react-router-dom";
import { useDispatch } from 'react-redux';
import { AppDispatch } from './app/store';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Routes from './Routes';
import Menu from './components/menu';
import { useAppSelector } from './app/hooks';
import { selectAccount } from './services/accountSlice';

import { connectHub, getUser } from './services/accountSlice';
import auth from './components/auth';
import UserConfirmation from './app/user-confirmation';

function App() {
  const dispatch: AppDispatch = useDispatch();

  const [borehole, setBorehole] = useState<any>();
  
  useEffect(() => {
    if (auth.isAuthenticated()) {
      dispatch(getUser());
    }
  }, []);

  const currentAccount = useAppSelector(selectAccount);

  useEffect(() => {    
    if (!currentAccount.connected && !currentAccount.loading && auth.isAuthenticated()) {
      dispatch(connectHub());
    }
  }, [currentAccount?.loading]);

  return ( 
    
    <div className="App">
      <BrowserRouter
        getUserConfirmation={(message, callback) =>
          UserConfirmation(message, callback)     
        }>
            <header className="App-header">
              <Menu borehole={borehole} setBorehole={setBorehole} />
            </header>
            <div className="content">
                  <Routes setBorehole={setBorehole}/>
            </div>
        </BrowserRouter>
      <ToastContainer position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </div>
    
  );
}

export default App;
