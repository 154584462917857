import TextareaAutosize from 'react-textarea-autosize';

const TextAreaField = (props: any) => {

    const { 
            type = "text",
            label, 
            placeholder,
            errors,
            value,
            onChange,
            icon,
            info,
            maxLength,
            className, 
            readOnly } = props;

return (
    <div className={`field ${className ? className : ""}`}>
        {label && <label className="label">{label}</label>}
        {info && <div className='is-size-7 mb-2'>{info}</div>}
        <div className={`control ${icon ? "has-icons-left" : ""}`}>
            {readOnly && <div>{value}</div>}
            {!readOnly && 
             <TextareaAutosize
                className='text-track-teaxarea textarea'
                maxRows={10}
                defaultValue={""}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                />
            }            
        </div>
        {errors?.length > 0 && errors.map((e: any, index: number) => <p key={index} className="help is-danger">{e}</p>)}
    </div>
)
}

export default TextAreaField;