import './list.scss';
import ListTable from './list-table';

const List = ({ lithologyLookups, updateLithologyLookup }:any) => {

    if (!lithologyLookups || !lithologyLookups.length) {
        return null;
    }
    
    const firstHalfLength = Math.floor(lithologyLookups.length / 2);
    const firstHalf = lithologyLookups.slice(0, firstHalfLength);
    const secondHalf = lithologyLookups.slice(firstHalfLength);

    return (
            <div className="columns">
              <div className="column">
                <ListTable lithologyLookups={firstHalf} updateLithologyLookup={updateLithologyLookup}></ListTable>
              </div>
              <div className="column">
                <ListTable lithologyLookups={secondHalf} updateLithologyLookup={updateLithologyLookup}></ListTable>
              </div>
            </div>
    )    
};

export default List;