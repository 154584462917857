import React from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Container from './container';
import { useAddMutation, useGetUserPermissionsQuery } from '../../../services/roleApi';

const AddRole = ({}:any) => {    
    const { data: userPermissions, isLoading: userPermissionsIsLoading, error } = useGetUserPermissionsQuery();
    const [add, { isLoading, error: addError }] = useAddMutation();
    let history = useHistory();

    const onAddRole = (role: any) => {        
        add({...role })
        .unwrap()
        .then((response: any) => {
            toast.success("Successfully added role");
            history.push(`/admin/role/list`) ;
         })
         .catch((error) => { toast.error("Error adding role") });
      };

    return (
       <Container title="Add Role" 
                  onSave={onAddRole}
                  roleIsLoading={userPermissionsIsLoading} 
                  isLoading={isLoading}
                  userPermissions={userPermissions}
                  error={addError} />
    )    
};

export default AddRole;