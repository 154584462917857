import CheckboxFilter from "./checkbox-filter";

export default function EquipmentFilter({equipments, checkedEquipment, setCheckedEquipment } : any) {    

    const onChangeEquipmentList = (e:any) => {   
        const value = +e.target.value;        
        if (checkedEquipment.includes(value)) {
          const newEquipments = [...checkedEquipment].filter((id) => id !== value);
          setCheckedEquipment(newEquipments);
        } else {
          const newEquipments = [...checkedEquipment];
          newEquipments.push(value);
          setCheckedEquipment(newEquipments);
        }
      }

    const onReset = () => {
        setCheckedEquipment([]);
    };

    return (
        <CheckboxFilter label="Equipment" list={equipments} onChangeList={onChangeEquipmentList} checkedList={checkedEquipment} onReset={onReset}/>
    )
}