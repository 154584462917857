import ColorPicker from './color-picker';

const ColorField = (props: any) => {

    const { 
            label, 
            errors,
            color,            
            onChange,
            readOnly,
            info,
            className
             } = props;

  return (
  <div className={`field ${className ? className : ""}`}>
        {label && <label className="label">{label}</label>}
        {info && <div className='is-size-7 mb-2'>{info}</div>}
        <div className={`control`}>
          <ColorPicker color={color} onChange={onChange} disabled={readOnly} />
        </div>
        {errors?.length > 0 && errors.map((e: any, index: number) => <p key={index} className="help is-danger">{e}</p>)}
    </div>
  )
}

export default ColorField;