import React from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAddMutation } from '../../../services/userApi';
import Container from './container';

const AddUser = ({}:any) => {    
    let history = useHistory();

    const [add, { isLoading, error: addError }] = useAddMutation();

    const onAddUser = (user: any) => {        
        add(user)
        .unwrap()
        .then((response: any) => {
            toast.success("Successfully added user");            
            history.push(`/admin/user/roles/${response.id}`) ;
         })
         .catch((error) => { toast.error("Error adding user") });
      };

    return (
        <Container title="Add User" 
            onSave={onAddUser}            
            isLoading={isLoading}
            error={addError}
            emailAddressReadOnly={false} />
    )    
};

export default AddUser;