import SmallLoader from '../../small-loader';
import Panel from '../panel';
import Select from 'react-select';
import Errors from '../../errors';
import SelectField from '../../Input/select-field';
import TextField from '../../Input/text-field';
import Progress from '../../progress';
import { useState } from 'react';
import ClientTemplates from './client-templates';
import RadioField from '../../Input/radio-field';
import { useLithologyTrackTypesQuery } from '../../../services/trackTypeApi';
import ExportPanelBase from './export-panel-base';
import CheckboxField from '../../Input/checkbox-field';

const ExportPanelMultiBorehole = (props: any) => {
  const {      
    isExporting,
    setIsExporting,
    exportFile,
    error,
    progress,
    exportFileName,
    setExportFileName,
    downloaded,
    url,
    showTemplates
  } = props;   

  const [headerType, setHeaderType] = useState<any>(0);
  const [delimator, setDeliminator] = useState<any>(" ");
  const [selectedTemplateId, setSelectedTemplateId] = useState<any>(0);
  const [dataExportType, setSataExportType] = useState<any>(0);
  const [selectedLithologyTrackType, setSelectedLithologyTrackTypeId] = useState<any>();
  const [perMeter, setPerMeter] = useState<any>();
  const [includeTo, setIncludeTo] = useState<boolean>(false);

  const { data: lithologyTrackTypes, isLoading: lightologyTrackTypesDataIsLoading } = useLithologyTrackTypesQuery();
  
const onHandleOkExportModal = (e: any) =>{
  e.preventDefault();  
  exportFile(headerType, delimator, selectedTemplateId, dataExportType, selectedLithologyTrackType?.value, perMeter, includeTo);
}

const setTemplateName = (name: any) => {
  setExportFileName(name + ".csv");
};

const onChangeDataExportType = (type: any) => {
  setSataExportType(type);
};

const onChangeLithologyTrackType = (trackType: any) => {
  setSelectedLithologyTrackTypeId(trackType);
};

const lithologyTrackTypesOptions = lithologyTrackTypes?.lithologies.map((t: any) => { return { label: t.name, value: t.id }});

const customStyles = {
  option: (provided: any, { data, isDisabled, isFocused, isSelected }:any) => ({
    ...provided,          
    backgroundColor: isSelected ? 'rgba(0, 54, 113, 1)' : '#FFFFFF',
    color: isDisabled
      ? '#ccc'
      : isSelected ? "white" : data.color,
    cursor: isDisabled ? 'not-allowed' : 'default',
    padding: 5,
    paddingLeft: "12px",
    "&:hover": {
      backgroundColor: "rgba(0, 54, 113, 0.3)"
    }
  })        
};

return(
  <Panel title="Export" icon="Export Icon">
    <form onSubmit={onHandleOkExportModal}>
        <div>
            <div className="panel-section">
            <ExportPanelBase 
                  exportFileName={exportFileName} 
                  setExportFileName={setExportFileName}
                  headerType={headerType}
                  setHeaderType={setHeaderType}
                  delimator={delimator}
                  setDeliminator={setDeliminator}
                />
                
                {showTemplates && <ClientTemplates              
                    selectedTemplateId={selectedTemplateId}
                    setSelectedTemplateId={setSelectedTemplateId}
                    setTemplateName={setTemplateName}
                  />}
                
                <label className="label">Data Export Type</label>
                <RadioField className="mb-1" label="Complete Export" group="ExportType" onChange={() => onChangeDataExportType(0)} checked={dataExportType === 0} />
                <RadioField className="mb-1" label="Summary Export - Per Meter" group="ExportType" onChange={() => onChangeDataExportType(1)} checked={dataExportType === 1}/>
                <RadioField label="Summary Export - Data for Depth Range" group="ExportType" onChange={() => onChangeDataExportType(2)} checked={dataExportType === 2}/>

                {dataExportType === 1 && <div className='mt-2'>
                   <TextField type="number" value={perMeter} onChange={setPerMeter} />
                   </div>}
                {dataExportType === 2 && <div className='mt-2'>
                  <Select options={lithologyTrackTypesOptions} styles={customStyles} onChange={onChangeLithologyTrackType} value={selectedLithologyTrackType} classNamePrefix="select"></Select>
                  <CheckboxField className="mt-2" label="Include values in to" checked={includeTo} onChange={setIncludeTo} />
                </div>}

                {isExporting && <Progress className="mt-1" progress={progress} /> }
                {downloaded && (
                  <div className='mb-2 mt-2'>
                    The file should automatically download, if not click <a href={url} target="_blank" download={exportFileName}>here</a>.
                  </div>
                )}
                
                {error && <Errors error={{ data: { errors: error}}} />} 
      
                <button className="button is-light mt-2" type="submit" onClick={onHandleOkExportModal} disabled={isExporting}>Export</button>
            </div>
       </div>
    </form>
  </Panel>
)};

export default ExportPanelMultiBorehole;