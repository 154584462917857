import React, { useEffect, useState } from "react";
import { Icon } from '@iconify/react';
import Slider from "../slider";
import './track-image-filter.scss';
import Percentage from "./percentage";

const DEFAULT_OPTIONS = [
  {
    name: "Brightness",
    property: "brightness",
    value: 100,
    initalValue: 100,
    range: {
      min: 0,
      max: 600
    },
    unit: "%"
  },
  {
    name: "Contrast",
    property: "contrast",
    value: 100,
    initalValue: 100,
    range: {
      min: 0,
      max: 200
    },
    unit: "%"
  },
  {
    name: "Saturation",
    property: "saturate",
    value: 100,
    initalValue: 100,
    range: {
      min: 0,
      max: 200
    },
    unit: "%"
  },
  {
    name: "Grayscale",
    property: "grayscale",
    value: 0,
    initalValue: 0,
    range: {
      min: 0,
      max: 100
    },
    unit: "%"
  }
];

function TrackImageFilter({setFilter, track, setSelectedTrack, setCollapsed, isSelected } : any) {
  const [options, setOptions] = useState(DEFAULT_OPTIONS);
  //const [externalOptions, setExternalOptions] = useState(DEFAULT_OPTIONS);
  //const [collapsed, setCollapsed] = useState(true);

  const filter = track.filter;

  useEffect(() => {
    if (isSelected) {
      setCollapsed(track.id, false);
    }
  }, [isSelected]);

  function handleSliderChange(value: any, optionName: string) {  
    setOptions((prevOptions) => {
      return prevOptions.map((option) => {
        if (option.name !== optionName) return option;
        return { ...option, value };
      });
    });    

    // setExternalOptions((prevOptions) => {
    //   return prevOptions.map((option) => {
    //     if (option.name !== optionName) return option;
    //     return { ...option, value };
    //   });
    // });    
  }

  useEffect(() => {
    getImageStyle();
  }
  ,[options]);

  useEffect(() => {
    let newOptions = [...options];
    
    DEFAULT_OPTIONS.forEach((option: any) => {
      let regEx = new RegExp(`${option.property}\\((\\d+)`);
      const value = filter ? filter.match(regEx)[1] : option.initalValue;
      let propertyOption : any = newOptions.find((o: any) => { return o.property === option.property });
      propertyOption.value = +value;
    });    

    setOptions(newOptions);
  }
  ,[filter]);
  
  function getImageStyle() {
    const filters = options.map((option) => {
      return `${option.property}(${option.value}${option.unit})`;
    });

    setFilter(filters.join(" "), track.id);
  }

  const onSetSelectedTrack = () => {    
    setSelectedTrack(track);
  };  

  const onSetCollapsed = () => {
    if (track.collapsed) {
      onSetSelectedTrack();
      setCollapsed(track.id, false);
    }
    if (!track.collapsed) {      
      setSelectedTrack(null);
      setCollapsed(track.id, true);
    }
  };
  
  return (
    <div>
        <div className={`collapsible is-size-6 ${isSelected ? "track-active" : ""}`} onClick={onSetCollapsed}>
          <div className="track-order-label">{track.label}</div>
          <div className="collapsible-icon">
            <Icon icon="mdi:chevron-down-circle-outline" width="24" height="24" rotate={track.collapsed ? 0 : 2} />
          </div>
        </div>
        <div onMouseDown={onSetSelectedTrack} className="collapsible-content" style={{display: track.collapsed ? "none" : "block"}}>
          <table>
              {options.map((option: any, index: number) => (
                <tr>
                  <td >{option.name}</td>
                  <td className="slider-column"> <Slider
                            min={option.range.min}
                            max={option.range.max}
                            value={option.value}
                            handleChange={(e: any) => handleSliderChange(e.target.value, option.name)}
                        /></td>
                  <td><Percentage value={option.value}/></td>
                  <td><button className="button is-small" onClick={() => handleSliderChange(option.initalValue, option.name)}><i className="fas fa-undo" title="Reset"></i></button></td>
                </tr>
              ))}
          </table>
        </div>
    </div>
  );
}

export default TrackImageFilter;
