import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Panel from '../panel';
import { useTemplateLevelsQuery } from '../../../services/enumApi';
import Templates from './templates';
import SmallLoader from '../../small-loader';

const TemplatesPanel = (props: any) => {
    const {
      currentTemplate,
      currentTemplateLevel,
      moveTemplateLevel,
      moveError,
      builtInTemplateOptions,
      userTemplateOptions,
      boreholeTemplateOptions,
      projectTemplateOptions,
      clientTemplateOptions,
      onChangeBuiltInTemplate,
      onChangeUserTemplate,
      onChangeBoreholeTemplate,
      onChangeProjectTemplate,
      onChangeClientTemplate,
      selectedTemplateId,      
      childLoading,
      onSave,
      onAdd,
      canSaveTemplate,
      canDeleteTemplate,
      notSaved,
      onSaveAs,
      onNewTemplate,
      mouseWheelZoom,
      setMouseWheelZoom,
      deleteTemplate
    } = props; 

    const { data: templateLevels, isLoading: boreholeStatusesIsLoading } = useTemplateLevelsQuery(false);
    const [templateLevel, setTemplateLevel] = useState<any>();

    let selectedBuiltInTemplateIdValue = null,   
    selectedUserTemplateIdValue = null,
    selectedBoreholeTemplateIdValue = null,
    selectedProjectTemplateIdValue = null,
    selectedClientTemplateIdValue = null;

    const getSelectedTemplateId = () => {
      let selectedTemplateIdValue: any = null;
      
      if (selectedTemplateId) {        
        selectedTemplateIdValue = builtInTemplateOptions.find((o:any) => o.value == selectedTemplateId);
        if (selectedTemplateIdValue) {
          selectedBuiltInTemplateIdValue = selectedTemplateIdValue;        
          return;
        } else {
          selectedTemplateIdValue = userTemplateOptions.find((o:any) => o.value == selectedTemplateId);
        }
        if (selectedTemplateIdValue) {
          selectedUserTemplateIdValue = selectedTemplateIdValue;
          return;
        } else {
          selectedTemplateIdValue = boreholeTemplateOptions.find((o:any) => o.value == selectedTemplateId);
        }
        if (selectedTemplateIdValue) {
          selectedBoreholeTemplateIdValue = selectedTemplateIdValue;
          return;
        } else {
          selectedTemplateIdValue = projectTemplateOptions.find((o:any) => o.value == selectedTemplateId);
        }
        if (selectedTemplateIdValue) {
          selectedProjectTemplateIdValue = selectedTemplateIdValue;
          return;
        } else {
          selectedTemplateIdValue = clientTemplateOptions.find((o:any) => o.value == selectedTemplateId);
        }

        if (selectedTemplateIdValue) {
          selectedClientTemplateIdValue = selectedTemplateIdValue;
        }
    }};

    getSelectedTemplateId();

    const customStyles = {
      option: (provided: any, { data, isDisabled, isFocused, isSelected }:any) => ({
        ...provided,          
        backgroundColor: isSelected ? 'rgba(0, 54, 113, 1)' : '#FFFFFF',
        color: isDisabled
          ? '#ccc'
          : isSelected ? "white" : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',
        padding: 5,
        paddingLeft: "12px",
        "&:hover": {
          backgroundColor: "rgba(0, 54, 113, 0.3)"
        }
      })        
    };

    const templateLevelOptions = templateLevels?.map((t: any) => { return { label: t.name, value: t.value, isDisabled: t.name == currentTemplateLevel}});

    const onChangeTemplateLevel = (e: any) => {
      setTemplateLevel(e);
    };

    useEffect(() => {
      if (templateLevels?.length) {
        setTemplateLevel(templateLevels[0]?.value);
      }
    }, [templateLevels?.length]);

    const onMoveTemplateLevel = () => {
      moveTemplateLevel(templateLevel.value);
    };

    const anySelected = selectedBuiltInTemplateIdValue || selectedUserTemplateIdValue || selectedBoreholeTemplateIdValue || selectedProjectTemplateIdValue || selectedClientTemplateIdValue;

const loadingTemplates = !builtInTemplateOptions?.length && !userTemplateOptions?.length && !boreholeTemplateOptions?.length && !projectTemplateOptions?.length && !clientTemplateOptions?.length;

return(
  <Panel title="Templates" icon="Atlas Save Template 2 Icon">
    <div className="panel-section">

    {loadingTemplates && <div className="mt-1"><SmallLoader /> </div>}
    {!loadingTemplates && <>
      <div className="field">
        <label className="label">Current Template</label>
        <input className='input' value={currentTemplate?.name ?? ""} disabled/>
      </div>
      {currentTemplateLevel && currentTemplateLevel !== "Default" && <div className="field">
        <label className="label">Move Current Template</label>
        <div className='columns '>
          <div className='column is-one-third'>
            From
            <div className="mt-2 has-text-weight-semibold">
              {currentTemplateLevel}
            </div>
          </div>
          <div className='column'>
            To
            <nav className="level">
              <div className="level-left">
                <div className="level-item">                  
                  <Select options={templateLevelOptions} styles={customStyles} onChange={onChangeTemplateLevel} value={templateLevel} classNamePrefix="select"></Select>
                </div>
                <div className="level-item">
                  <button className='button is-primary is-small' onClick={onMoveTemplateLevel} disabled={!anySelected}>Ok</button>
                </div>
              </div>
            </nav>
          </div>
        </div>  
        {moveError && <p className="help is-danger">{moveError}</p>}        
      </div>
      }

      <Templates      
        builtInTemplateOptions={builtInTemplateOptions}
        userTemplateOptions={userTemplateOptions}
        boreholeTemplateOptions={boreholeTemplateOptions}
        projectTemplateOptions={projectTemplateOptions}
        clientTemplateOptions={clientTemplateOptions}
        onChangeBuiltInTemplate={onChangeBuiltInTemplate}
        onChangeUserTemplate={onChangeUserTemplate}
        onChangeBoreholeTemplate={onChangeBoreholeTemplate}
        onChangeProjectTemplate={onChangeProjectTemplate}
        onChangeClientTemplate={onChangeClientTemplate}
        selectedTemplateId={selectedTemplateId}
      />
    
    <div>           
        <button className="button is-light mr-1 save-button" onClick={onSave} disabled={childLoading || !canSaveTemplate || !notSaved}>
          <span className="material-icons" title="Save">
            save
          </span>&nbsp; Save
        </button>
      
        <button className="button save-button mr-1" onClick={onSaveAs} disabled={childLoading || !notSaved}>
          <span className="material-icons" title="Save as">
            save
          </span>&nbsp; Save as...
        </button>
        <button className="button save-button" onClick={deleteTemplate} disabled={childLoading || !canDeleteTemplate || !anySelected}>
          <img src={"/images/icons/Atlas Delete Icon.svg"} className="tab-icon" title="Delete"/>
        </button>
         <button className="button save-button mr-1 mt-2" onClick={onNewTemplate} disabled={childLoading}>
          <span className="material-icons" title="New">
            add
          </span>&nbsp; New...
        </button>
      </div>
      </>}
    </div>
    
  </Panel>
)};

export default TemplatesPanel;