export const dashType : any = (curve: any) => {
    if (curve.lineStyle === "line") {
      return "0";
    } else if (curve.lineStyle === "dash") {
      return "5,5";
    } else if (curve.lineStyle === "long-dash") {
      return "10,5";
    } else if (curve.lineStyle === "dots") {
      return "1,1"
    } else if (curve.lineStyle === "short-long-dash") {
      return "3,3,6,3";
    }
};