import React from "react";
import { Switch } from "react-router-dom";
import { PrivateRoute } from "../../components/privateRoute";
import ListClients from './list-clients/list-clients';
import EditClient from './edit-client/edit-client';
import AddClient from './edit-client/add-client';

const ClientRoutes = () => {
  return (    
    <Switch>      
      <PrivateRoute exact path="/admin/client/list">
        <ListClients />        
      </PrivateRoute>
      <PrivateRoute exact path="/admin/client/add">
        <AddClient />        
      </PrivateRoute>
      <PrivateRoute exact path="/admin/client/edit/:clientId">
        <EditClient />
      </PrivateRoute>      
    </Switch>            
  );
};

export default ClientRoutes;
