import React from "react";
import { Switch } from "react-router-dom";
import ProjectList from './ProjectList';
import AddProject from './add-project';
import EditProject from './edit-project';
import { PrivateRoute } from "../../components/privateRoute";

const ProjectRoutes = () => {
  return (
    
    <Switch>
      <PrivateRoute exact path="/client/:clientId/project/add">
        <AddProject/>
      </PrivateRoute>                         
      <PrivateRoute exact path="/client/:clientId/project/:projectId">
        <EditProject/>
      </PrivateRoute>       
      <PrivateRoute exact path="/client/:clientId/project">
        <ProjectList/>
      </PrivateRoute>             
    </Switch>                   
    
  );
};

export default ProjectRoutes;
