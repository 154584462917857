import './track-order-panel.scss';
import { Draggable } from 'react-beautiful-dnd';

const GroupTrackOrder = (props: any) => {
    const {
      track,
      index
    } = props;  

return (
    <Draggable key={`${track.boreholeId}_${track.trackId}`} draggableId={`${track.boreholeId}_${track.trackId}`} index={index}>
        {(provided: any) => (
            <div className="track-order" 
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                >
                <div className="track-order-label">
                {track.boreholeName}<br/>
                {track.label}
                </div>
                <div className="track-order-icon">              
                    <i className="material-icons">reorder</i>
                </div>
            </div>
        )}
    </Draggable>
)};

export default GroupTrackOrder;