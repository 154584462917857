import React from "react";
import {  Draggable } from "react-beautiful-dnd";

const CurveOrderItem = ({ curve, index }: any) => {

    return (
        <Draggable draggableId={curve.id} index={index}>
        {(provided: any) => (
            <div className="track-order" 
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                >
                <div className="track-order-label">
                    {curve.displayName}
                </div>
                <div className="track-order-icon">              
                    <i className="material-icons">reorder</i>
                </div>
            </div>
        )}
    </Draggable>
    )
}

export default CurveOrderItem;