import { useState } from 'react';
import { Link } from 'react-router-dom';
import Row from './row';

const List = ({ users, onRemoveRoleFromUser, removeUserFromRole, canEdit, canEditRoles, canDelete }:any) => {
    const [checkedRows, setCheckedRows] = useState<any[]>([]);

    const onCheckRow = (id: string) => {
        const checkRowsCopy = Array.from(checkedRows);
        const foundIndex = checkRowsCopy.findIndex((r: any) => r == id);
        if (foundIndex >= 0) {
            checkRowsCopy.splice(foundIndex, 1);
        } else {
            checkRowsCopy.push(id);
        }

        setCheckedRows(checkRowsCopy);
    };

    const onCheckAll = () => {        
        if (checkedRows.length === users.length) {     
            setCheckedRows([]);
        } else {
            const checkRowsCopy = Array.from(checkedRows);
            users.forEach((user: any) => {
                const foundIndex = checkRowsCopy.findIndex((r: any) => r == user.id);
                if (foundIndex === -1) {                    
                    checkRowsCopy.push(user.id);
                }
            });

            setCheckedRows(checkRowsCopy);
        }
    };

    const onRemoveMuliple = () => {
        if (removeUserFromRole(checkedRows)) {
            setCheckedRows([]);
        }
    }

    return (
            <div className="columns">
              <div className="column is-half">
                  <table className="geotek-table">
                      <thead>
                          <tr>
                          <th><input type="checkbox" onChange={onCheckAll} checked={checkedRows.length === users?.length && users?.length > 0}/></th>
                            <th>Name</th>
                            <th>Actions</th>
                          </tr>
                      </thead>
                      <tbody>
                       {(users || []).map((user: any, index: number) => (
                            <Row key={index} 
                                 user={user}
                                 canEdit={canEdit}
                                 canEditRoles={canEditRoles}
                                 canDelete={canDelete} 
                                 onRemoveRoleFromUser={onRemoveRoleFromUser}
                                 onCheckRow={onCheckRow}
                                 checkedRows={checkedRows} />                            
                        ))}
                      </tbody>
                  </table>
                  <div className="field is-grouped">
                    <div className="control">
                        <Link className="button" to={`/admin/role/list`}>Back</Link>
                    </div>
                    <div className="control">
                        <button className="button is-primary" onClick={onRemoveMuliple} disabled={checkedRows.length === 0 || users?.length === 0}>Remove Users</button>
                    </div>
                  </div>                                       
              </div>
            </div>
    )    
};

export default List;