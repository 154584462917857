import React, { useEffect, useState } from "react";
import "./small-loader.scss";

const SmallLoader = () => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  return <div className={`small-loader-wrapper ${!isLoading ? "" : "is-active"}`}>           
           <div className="small-loader is-loading">            
           </div>
         </div>;
};

export default SmallLoader;
