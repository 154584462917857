import React from 'react';
import LeftSideMenu from './left-side-menu';
import SideMenuButton from './side-menu-button';

const BoreholeFilterLeftSideMenu = ({showLeftSidePanel, 
                        onShowHideLeftSidePanel,
                        leftPanelOffset,
                        onShowExportPanel,
                        onShowFilterPanel,
                        showExportPanel,
                        showFilterPanel}: any) => {                            

    return (        
            <LeftSideMenu showLeftSidePanel={showLeftSidePanel}
                          onShowHideLeftSidePanel={onShowHideLeftSidePanel}
                          leftPanelOffset={leftPanelOffset}>
                <SideMenuButton highlighted={showFilterPanel} onClick={onShowFilterPanel} title="Filters" icon="Filter Icon" />
                <SideMenuButton highlighted={showExportPanel} onClick={onShowExportPanel} title="Export" icon="Export Icon"  />                    
            </LeftSideMenu>

    )
}

export default BoreholeFilterLeftSideMenu;