import React from "react";

const Percentage = (props: any) => {
    const { value } = props;

    const onClick = () => {

    };

    return (
        <>
            <span onClick={onClick}>{value}</span>%
        </>
    )
};

export default Percentage;