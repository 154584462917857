import './list.scss';
import SmallLoader from '../../../components/small-loader';
import InProgressList from './inprogress-list';
import CompletedList from './completed-list';

const List = ({ jobs, onDelete, canDelete, canCancel, onCancel, otherClientImageJobsInProgress, otherClientDataJobsInProgress, showJobPriorityPoints, onGetJobs }:any) => {

    const inProgressJobs = jobs?.filter((j: any) => j.state < 100).sort((a: any, b:any) => {
        if (a.priorityPoints > b.priorityPoints) {
            return -1;
        } else if (a.priorityPoints < b.priorityPoints) {
            return 1;
        }

        return a.createdDate < b.createdDate ? -1 : a.createdDate > b.createdDate ? 1 : 0
    });
    
    const completedJobs = jobs?.filter((j: any) => j.state >= 100).sort((a: any, b:any) => new Date(a.completedDate) < new Date(b.completedDate) ? 1 : new Date(a.completedDate) > new Date(b.completedDate) ? -1 : 0);

    return (
            <div className="columns">
              <div className="column">
                    {otherClientImageJobsInProgress && <div>Other Image jobs in progress <SmallLoader /></div>}
                    {otherClientDataJobsInProgress && <div>Other Data jobs in progress <SmallLoader /></div>}

                    <InProgressList jobs={inProgressJobs}
                                                onDelete={onDelete}                                            
                                                canDelete={canDelete}
                                                onCancel={onCancel}
                                                canCancel={canCancel}
                                                showJobPriorityPoints={showJobPriorityPoints}
                                                 />

                    <CompletedList jobs={completedJobs} onGetJobs={onGetJobs}/>              
              </div>
            </div>
    )    
};

export default List;