import React from 'react';
import { Link } from 'react-router-dom';
import SelectField from '../../../components/Input/select-field';
import TextField from '../../../components/Input/text-field';
import Row from './row';

const List = ({ trackTypes, uploadTypes, uploadType, setUploadType, nameFilter, setNameFilter, canAdd }:any) => {
    const uploadTypeOptions = uploadTypes.map((uploadType: any) => { return { name:uploadType, value: uploadType } });    
    return (
            <div className="columns">
                    <div className="column is-half">
                    {canAdd && <div className="field is-grouped">
                        <div className="control">
                            <Link className="button" to={`add`}>Add Track Type</Link>
                        </div>
                    </div>}
                    <SelectField onChange={setUploadType} values={uploadTypeOptions} value={uploadType} allowNull={true} label="Upload Type"  />
                    <TextField label="Name Filter" value={nameFilter} onChange={setNameFilter}  />
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Upload Type</th>
                                <th>Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(trackTypes || []).map((trackType: any, index: any) => (
                                <Row key={index} trackType={trackType} />
                            ))} 
                        </tbody>
                    </table>          
                 
              </div>
            </div>
    )    
};

export default List;