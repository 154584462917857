import React, { useEffect, useState } from 'react';
import YScaleProperties from '../../log-viewer/yscale-properties';
import Panel from '../panel';

const DepthPanel = (props: any) => {
    const {
      depthType, 
      onChangeDepthType,      
      depthUnit,
      changeDepthUnit,            
      childLoading,
      setStartDepth,
      setEndDepth,
      maxDepth,
      minDepth,
      setSkipLoad,
      setShowDepthGridlines,
      majorIntervals,
      setMajorIntervals,
      showDepthGridLines,
      showDepthMinorIntervals,
      setShowDepthMinorIntervals,
      minorIntervals,
      setMinorIntervals
    } = props;    

    const [internalStartDepth, setInternalStartDepth] = useState<number>(0);
    const [internalEndDepth, setInternalEndDepth] = useState<number>(0);
    const [depthPerPage, setDepthPerPage] = useState<number>(0);
    const [startDepthError, setStartDepthError] = useState<any>();
    const [endDepthError, setEndDepthError] = useState<any>();

    useEffect(() => {
      setInternalStartDepth(minDepth);
    }, [minDepth]);

    useEffect(() => {
      setInternalEndDepth(maxDepth);
    }, [maxDepth]);  
    
    useEffect(() => {
      setDepthPerPage(maxDepth - minDepth);
    }, [maxDepth, minDepth]);

    const submit = (e: any) => {
      e.preventDefault();

      setStartDepthError(null);
      setEndDepthError(null);
      
      var errored = false;
      if (internalStartDepth && internalStartDepth < 0 ) {
        setStartDepthError("Start depth must be greater then or equal to 0");
        errored = true;
      }
    
      if (internalStartDepth && internalEndDepth && internalStartDepth >= internalEndDepth ) {
        setStartDepthError("End depth must be greater than start depth");
        errored = true;
      }

      if (errored) {
        return;
      }
    

      setStartDepth(internalStartDepth);
      setEndDepth(internalEndDepth);
    };
    
    const setDepthUnitMeters = () => {
      changeDepthUnit(1);
    }

    const setDepthUnitFeet = () => {
      changeDepthUnit(2);
    }

    const onDepthPerPageChanged = (e: any) => {
      const depth = +e.target.value;
      setDepthPerPage(depth);
      setInternalEndDepth(internalStartDepth + depth);
    }

    const onStartDepthChanged = (e: any) => {
      const depth = +e.target.value;
      setInternalStartDepth(depth);
      setInternalEndDepth(depth + depthPerPage);
    }

    const onEndDepthChanged = (e: any) => {
      const depth = +e.target.value;
      setInternalEndDepth(depth);
      setDepthPerPage(depth - internalStartDepth);
    }

    const onPageDown = (e: any) => {
      e.preventDefault();
      if (internalStartDepth == null) {
         return;
      }

      const pageSize = internalEndDepth - internalStartDepth;
      if (!maxDepth || internalEndDepth + pageSize > maxDepth) {
        return;
      }

      const roundedNewStartDepth = Math.round((internalStartDepth + pageSize) * 1000) / 1000;
      const roundedNewEndDepth = Math.round((internalEndDepth + pageSize) * 1000) / 1000;

      setInternalStartDepth(roundedNewStartDepth);
      setInternalEndDepth(roundedNewEndDepth);
      setSkipLoad(true);
      setStartDepth(roundedNewStartDepth);
      setEndDepth(roundedNewEndDepth);
      setSkipLoad(false);
    };

    const onPageUp = (e: any) => {
      e.preventDefault();
      if (!internalEndDepth || !internalStartDepth) {
        return;
      }

      const pageSize = internalEndDepth - internalStartDepth;
      if (internalStartDepth - pageSize < (minDepth ?? 0)) {
        return;
      }

      setInternalStartDepth(internalStartDepth - pageSize);
      setInternalEndDepth(internalEndDepth - pageSize);
      setSkipLoad(true);
      setStartDepth(internalStartDepth - pageSize);
      setEndDepth(internalEndDepth - pageSize);

      setSkipLoad(false);
    };

    const onReset = (e: any) => {
      e.preventDefault();
      
      setInternalStartDepth(minDepth);
      setInternalEndDepth(maxDepth ?? 0);
      setDepthPerPage(maxDepth - minDepth);
      setSkipLoad(true);
      setStartDepth(minDepth ?? 0);
      setEndDepth(maxDepth);
      
      setSkipLoad(false);
    };

return(
  <Panel title="Depth" icon="depth">
        <div className="panel-section">
          <div className="field">
                <button className={`button mr-2 ${depthUnit == 1 ? "is-light" : ""}`} onClick={setDepthUnitMeters}>Meters</button>
                <button className={`button ${depthUnit == 2 ? "is-light" : ""}`} onClick={setDepthUnitFeet}>Feet</button>              
            </div>

            <div className="field">
                <label className="label">Depth Type</label>
                <div className="control">
                  <div className="select">
                      <select onChange={onChangeDepthType} value={depthType}>                          
                          <option value={"Borehole"}>Borehole</option>
                          <option value={"Logged"}>Logged</option>                          
                      </select>
                  </div>
                </div>
            </div>
          </div>

          <div className="panel-section">
          <form onSubmit={submit}>   
            <div className="field">
                <label className="label">Start Depth</label>
                <div className="control">
                  <div className="depth-number-field">
                      <input type="number" className={`input ${startDepthError ? "is-danger" : ""}`} step="any" size={1} value={internalStartDepth ?? undefined} disabled={childLoading} onChange={onStartDepthChanged} />
                      {/* <span className="material-icons" title="Start Depth" >
                      vertical_align_top
                    </span> */}
                      {startDepthError && <div className='help is-danger'>{startDepthError}</div>}
                    </div>                  
                </div>
            </div>

            <div className="field">
                <label className="label">{depthUnit == 1 ? "m" : "ft"} per page</label>
                <div className="control">
                  <div className="field depth-number-field">
                      <input type="number" className="input" step="any" size={1} value={depthPerPage ?? undefined} disabled={childLoading} onChange={onDepthPerPageChanged} />
                    </div>
                    
                </div>
            </div>

            <div className="field">
                <label className="label">End Depth</label>
                <div className="control">
                  <div className="field depth-number-field">
                      <input type="number" className={`input ${endDepthError ? "is-danger" : ""}`} step="any" size={1} value={internalEndDepth ?? undefined} disabled={childLoading} onChange={onEndDepthChanged} />
                      {endDepthError && <div className='help is-danger'>{endDepthError}</div>}
                    </div>                  
                </div>
            </div>

            <div>
                <button className="button is-primary mr-2"  disabled={childLoading}>
                  OK
                </button>              
                <button className="button is-secondary" onClick={onReset}  disabled={childLoading}>
                  <i className="fas fa-undo" title="Reset"></i>&nbsp;Reset
                </button>
            </div>   
            </form>          
          
            <div className="field mt-2">
                <label className="label">Next / Previous</label>
                <button className="button mr-2" onClick={onPageUp} disabled={childLoading}>
                  <i className="fas fa-arrow-up" title="Up"></i>
                </button>              
                <button className="button" onClick={onPageDown}  disabled={childLoading}>
                  <i className="fas fa-arrow-down" title="Down"></i>
                </button>
            </div>
            </div> 

          <div className="panel-section">
            <YScaleProperties
              setShowDepthGridlines={setShowDepthGridlines}
              majorIntervals={majorIntervals}
              setMajorIntervals={setMajorIntervals}
              showDepthGridLines={showDepthGridLines}
              showDepthMinorIntervals={showDepthMinorIntervals}
              setShowDepthMinorIntervals={setShowDepthMinorIntervals}
              minorIntervals={minorIntervals}
              setMinorIntervals={setMinorIntervals}
            />
          </div>
              
                 
      

  </Panel>
)};

export default DepthPanel;