import React from 'react';
import ProjectRoutes from './features/project/ProjectRoutes';
import BoreholeRoutes from './features/borehole/BoreholeRoutes';
import AccountRoutes from './features/account/routes';
import JobRoutes from './features/job/routes';
import AdminRoutes from './features/admin/routes';

function AppRoutes({setBorehole}: any) {
  return ( 
    <>   
      <BoreholeRoutes setBorehole={setBorehole}/>
      <AdminRoutes setBorehole={setBorehole}/>
      <ProjectRoutes />
      <AccountRoutes />      
      <JobRoutes setBorehole={setBorehole} />
    </>
  );
}

export default AppRoutes;
