import React from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../app/hooks';
import { selectLithology, addLithologyLexicon } from '../../../services/lithologySlice';
import Container from './container';

const AddRole = ({}:any) => {    

    let history = useHistory();
    const dispatch = useAppDispatch();       

    const {  
        loading,
        saving,
        error
      } = useSelector(selectLithology); 

    const onAddLithologyLexicon = (lithologyLexicon: any) => {        
        dispatch(addLithologyLexicon({...lithologyLexicon }))
        .unwrap()
        .then((response: any) => {
            toast.success("Successfully added lithology lexicon");
            history.push(`/admin/lithology/lexicon/list`) ;
         })
      };

    return (
       <Container title="Add Lexicon" 
                  onSave={onAddLithologyLexicon}
                  loading={loading}    
                  saving={saving}              
                  error={error} />
    )    
};

export default AddRole;