import React from 'react';
import { NumericFormat } from 'react-number-format';

const TextField = (props: any) => {

    const { 
            type = "text",
            label, 
            placeholder,
            errors,
            value,
            onChange,
            icon,
            info,
            maxLength,
            className, 
            readOnly, 
            step } = props;

return (
    <div className={`field ${className ? className : ""}`}>
        {label && <label className="label">{label}</label>}
        {info && <div className='is-size-7 mb-2'>{info}</div>}
        <div className={`control ${icon ? "has-icons-left" : ""}`}>
            {readOnly && <div>{value}</div>}
            {!readOnly && <>{type === "integer" && <NumericFormat className={`input ${errors?.length ? "is-danger" : ""}`} value={value} onChange={(e) => onChange(e.target.value)} decimalScale={0} fixedDecimalScale allowedDecimalSeparators={[]} />}
            {type !== "integer" && <input type={type} placeholder={placeholder ?? label} className={`input ${errors?.length ? "is-danger" : ""}`} value={value} onChange={(e) => onChange(e.target.value)} maxLength={maxLength} step={step} />}
            </>
             }
            {icon &&
                <span className="icon is-small is-left">
                    <i className={`fas ${icon}`}></i>
                </span>
            }
        </div>
        {errors?.length > 0 && errors.map((e: any, index: number) => <p key={index} className="help is-danger">{e}</p>)}
    </div>
)
}

export default TextField;