import React from 'react';
import Row from './inprogress-row';
import './list.scss';

const InProgressList = ({ jobs, onDelete, canDelete, canCancel, onCancel, showJobPriorityPoints }:any) => {

    if (!jobs?.length) {
        return null;
    }

    return (
        <>
            <div className='is-size-5 mb-4'>In Progress</div>
            <table className="geotek-table">
                <thead>
                    <tr>
                        <th>Borehole</th>
                        <th>Name</th>
                        <th>Type</th>
                        <th>State</th>
                        <th>Priority</th>
                        <th>Created By</th>
                        <th>Created</th>
                        <th className='job-progress-column'>Progress</th>
                        <th>Started</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                {(jobs || []).map((job: any, index: number) => (
                    <Row key={job.id} 
                                    job={job}
                                    onDelete={onDelete}                                            
                                    canDelete={canDelete}
                                    onCancel={onCancel}
                                    canCancel={canCancel}
                                    showJobPriorityPoints={showJobPriorityPoints} />
                ))}
                </tbody>
            </table>
        </>
    )    
};

export default InProgressList;