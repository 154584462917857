import { Link } from 'react-router-dom';
import Select from 'react-select/dist/declarations/src/Select';
import CheckboxField from '../../../components/Input/checkbox-field';
import CheckboxListField from '../../../components/Input/checkbox-list-field';
import SelectField from '../../../components/Input/select-field';
import TextField from '../../../components/Input/text-field';

const EditRoleForm = ({ headerName,
    setHeaderName,    
    onSaveTrackType, 
    displayName,
    setDisplayName,
    shortDisplayName,
    setShortDisplayName,
    exportHeaderName,
    setExportHeaderName,
    description,
    setDescription,
    units,
    setUnits,
    displayUnits,
    setDisplayUnits,
    trackCategoryId,
    setTrackCategoryId,
    uploadType,
    setUploadType,
    allowedCurveTypes,
    setAllowedCurveTypes,
    allowedTrackTypes,
    setAllowedTrackTypes,
    averagingType,
    setAveragingType,
    curveTypes,
    templateTrackTypes,
    averagingTypes,
    uploadTypes,
    trackCategories,
    readOnly    
     }: any) => {  

    const trackCategoriesOptions = trackCategories?.map((trackCategory:any) => { return { name: trackCategory.name, value: trackCategory.id }});

    return (
            <div className="columns">
              <div className="column is-one-quarter">

                <SelectField  
                  label="Track Category" 
                  values={trackCategoriesOptions} 
                  onChange={setTrackCategoryId} 
                  value={trackCategoryId} />

                <SelectField  
                  label="Upload File Type" 
                  values={uploadTypes} 
                  onChange={setUploadType} 
                  value={uploadType} />

                <TextField
                  type="text" 
                  label="Header Name" 
                  value={headerName} 
                  onChange={setHeaderName}
                  readOnly={readOnly} />

                <TextField                
                  label="Display Name" 
                  value={displayName} 
                  onChange={setDisplayName}
                  readOnly={readOnly} />

                <TextField                
                  label="Short Display Name" 
                  value={shortDisplayName} 
                  onChange={setShortDisplayName}
                  readOnly={readOnly} />
                
                <TextField                
                  label="Description" 
                  value={description} 
                  onChange={setDescription}
                  readOnly={readOnly} />

                <TextField                
                  label="Display Units" 
                  value={displayUnits} 
                  onChange={setDisplayUnits}
                  readOnly={readOnly} />

                <TextField                
                  label="Export Header Name" 
                  value={exportHeaderName} 
                  onChange={setExportHeaderName}
                  readOnly={readOnly} />

                <TextField                
                  label="Export Header Units" 
                  value={units} 
                  onChange={setUnits}
                  readOnly={readOnly} />

                <CheckboxListField 
                    label="Allowed Curve Types" 
                    values={curveTypes}
                    checkedValues={allowedCurveTypes}
                    onChange={setAllowedCurveTypes}/>

                <CheckboxListField 
                    label="Allowed Template Track Types" 
                    values={templateTrackTypes}
                    checkedValues={allowedTrackTypes}
                    onChange={setAllowedTrackTypes}/>

                <SelectField  
                  label="Averaging Type" 
                  values={averagingTypes} 
                  onChange={setAveragingType} 
                  value={averagingType} />

               
                                 
                <div className="field is-grouped">
                    <div className="control">
                        <Link className='button' to={`/admin/track-type/list`}>Cancel</Link>
                    </div>
                    {!readOnly && 
                        <div className="control">
                            <button className="button is-link" type="submit" onClick={onSaveTrackType}>Save Track Type</button>
                        </div>        
                    }        
                </div>
              </div>             
        </div>
    )    
};

export default EditRoleForm;