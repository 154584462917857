import TextField from '../../../components/Input/text-field'
import Errors from '../../../components/errors';

const DisableTwoFactorForm = (props: any) => {
    const { 
            handleSubmit,
            authenticatorCode, 
            setAuthenticatorCode,        
            error
          } = props;

    const onHandleSubmit = (e: any) => {
        e.preventDefault();
        handleSubmit();
    }

    const onSetAuthenticatorCode = (value: string) => {
        setAuthenticatorCode(value);    
    }

    return (
        <div className="container">
            <div className="columns is-centered">
                <div className="column is-5-tablet is-4-desktop is-4-widescreen">
                    <form className="box" onSubmit={onHandleSubmit}>
                        <h4>Diable two factor authentication</h4>
                        <div>                        
                            <div className='mb-2'>Enter authenticator code to disable</div>
                            <TextField type="text" label="Authenticator Code" value={authenticatorCode} onChange={onSetAuthenticatorCode} maxLength={6}></TextField>
                            <button className='button' type="submit">Submit</button>
                        </div>                    
                        <Errors error={error}/>                    
                    </form>
                </div>
            </div>
        </div>        
    )
}

export default DisableTwoFactorForm;