import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import RightPanel from './right-panel';
import './add-track-panel.scss';
import './panel.scss';
import CheckboxField from '../Input/checkbox-field';
import { 
  useTemplateTrackTypesQuery,
  } from '../../services/enumApi';
import SelectField from '../Input/select-field';

const AddTrack = (props: any) => {

    const {       
       onAddTrackFormSubmit,       
       setSelectedTrackType,       
       trackTypeCategories,
       checkedTrackTypes, 
       setCheckedTrackTypes,
       checkedImageTypes,
       setCheckedImageTypes,
       checkedLithologyTypes,
       setCheckedLithologyTypes,
       checkedTextTypes,
       setCheckedTextTypes,
       setSelectedTemplateTrackType,
       selectedTemplateTrackType,
       imageTypes,
       selectedTrackCategory,
       setSelectedTrackCategory,
       allowedTrackCategories,
       onClose
    } = props;    

    useEffect(() => {
        if (selectedTrackCategory && selectedTrackCategory.trackTypes?.length > 0) {
            setSelectedTrackType(selectedTrackCategory.trackTypes[0].id);
        }
    }, [selectedTrackCategory]);

    const trackCategories = trackTypeCategories?.filter((c: any) => !c.hidden);

    useEffect(()=>{
        if (trackCategories?.length) {
          setSelectedTrackCategory(trackCategories[0]);
        }
    },[trackCategories?.length])

    const [trackTypes, setTrackTypes] = useState(selectedTrackCategory?.trackTypes);
    const [excludeNoImages, setExcludeNoImages] = useState(true);
    
    useEffect(() => {
      if (selectedTrackCategory?.trackTypes) {
        setTrackTypes(selectedTrackCategory.trackTypes.filter((trackType: any) => trackType.allowedTrackTypes.some((t: any) => t == selectedTemplateTrackType)));
      }
    }, [selectedTrackCategory?.id, selectedTemplateTrackType])


    const { data: templateTrackTypes, isLoading: templateTrackTypesLoading } = useTemplateTrackTypesQuery(allowedTrackCategories);

    const onChangeTrackTypeList = (e:any) => {            
      const value = +e.target.value;
      if (checkedTrackTypes.includes(value)) {
        const newTrackTypes = [...checkedTrackTypes].filter((id) => id !== value);
        setCheckedTrackTypes(newTrackTypes);
      } else {
        const newTrackTypes = [...checkedTrackTypes];
        newTrackTypes.push(value);
        setCheckedTrackTypes(newTrackTypes);
      }
    }

    const onChangeImageTypeList = (e:any) => {            
      const value = +e.target.value;
      if (checkedImageTypes.includes(value)) {
        const newImageTypes = [...checkedImageTypes].filter((id) => id != value);
        setCheckedImageTypes(newImageTypes);
      } else {
        const newImageTypes = [...checkedImageTypes];
        newImageTypes.push(value);
        setCheckedImageTypes(newImageTypes);
      }
    }

    const onChangeLithologyTypeList = (e:any) => {            
      const value = +e.target.value;
      if (checkedLithologyTypes.includes(value)) {
        const newLithologyTypes = [...checkedLithologyTypes].filter((id) => id != value);
        setCheckedLithologyTypes(newLithologyTypes);
      } else {
        const newLithologyTypes = [...checkedLithologyTypes];
        newLithologyTypes.push(value);
        setCheckedLithologyTypes(newLithologyTypes);
      }
    }

    const onChangeTextTypeList = (e:any) => {      
      const value = +e.target.value;
      if (checkedTextTypes.includes(value)) {
        const newTextTypes = [...checkedTextTypes].filter((id) => id != value);
        setCheckedTextTypes(newTextTypes);
      } else {
        const newTextTypes = [...checkedTextTypes];
        newTextTypes.push(value);
        setCheckedTextTypes(newTextTypes);
      }
    }

    const onChangeTrackType = (value: any) => {      
      setSelectedTemplateTrackType(+value);
    };

    const onChangeTrackCategory = (e: any) => {
      const trackTypeCategory = trackCategories.find((trackCategory: any) => trackCategory.id == e.target.value);
      setSelectedTrackCategory(trackTypeCategory);
    }

    const onOnClose = (e: any) => {
      e.preventDefault();
      onClose();
    };

    const onOnAddTrackFormSubmit = (e: any) => {
      e.preventDefault();            
      onAddTrackFormSubmit();
    }

    const onChangeExcludeNoImages = () => {
      setExcludeNoImages(!excludeNoImages);
    };

    const lithologyTrackTypeCategory = trackTypeCategories?.find((trackCategory: any) => trackCategory.id == "1033");
    const lithologyTrackTypes = lithologyTrackTypeCategory?.trackTypes;

    const textTrackTypeCategory = trackTypeCategories?.find((trackCategory: any) => trackCategory.id == "1038");
    const textTrackTypes = textTrackTypeCategory?.trackTypes;

    const anyChecked = checkedTrackTypes?.length > 0 || checkedImageTypes?.length > 0 || checkedLithologyTypes?.length > 0 || checkedTextTypes?.length > 0;

    const filteredTrackCategories = trackCategories?.filter((t: any) => selectedTemplateTrackType !== 3 || (selectedTemplateTrackType === 3 && t.canUseCumulative));

    useEffect(() => {
      if (filteredTrackCategories?.length) {        
        const trackTypeCategory = filteredTrackCategories[0];
        setSelectedTrackCategory(trackTypeCategory);
      }
    }, [filteredTrackCategories?.length]);

    return (
  
        <form onSubmit={onAddTrackFormSubmit}>    
            <SelectField   
                  label="Track Type" 
                  values={templateTrackTypes?.filter((t: any) => allowedTrackCategories.includes(t.value))} 
                  onChange={onChangeTrackType} 
                  value={selectedTemplateTrackType} />
           
          {(selectedTemplateTrackType === 0 || selectedTemplateTrackType === 3 || selectedTemplateTrackType === 4) && // Charts
            <>
              <div className="field">        
                <label className="label">Track Category</label>
                <div className="control">
                  <div className="select">
                    <select onChange={onChangeTrackCategory} value={selectedTrackCategory?.id}>
                    {(filteredTrackCategories || []).map((trackCategory: any, index: number) => (
                      <option key={index} value={trackCategory.id}>{trackCategory.name}</option>
                      ))};

                    </select>
                  </div>
                </div>
              </div>

              <div className="field">        
                <label className="label">Track Type</label>
                <div className="control">
                  <div className="track-type-list">
                    {(trackTypes || []).map((trackType: any, index: number) => (
                        <div key={index}>
                          <label>
                              <input type="checkbox" value={trackType.id} onChange={(e) => onChangeTrackTypeList(e)} checked={checkedTrackTypes.includes(trackType.id) ? true : false}></input>&nbsp;  {trackType.name} {trackType.noData && <span className="material-icons icon-inline" title="No Data">
                                do_not_disturb
                              </span>     }
                          </label>
                        </div>
                          ))}
                  </div>
                </div>
                <span className="material-icons icon-inline" title="No Data">
                  do_not_disturb
                </span> = no data
              </div>
              </>
            }
          {selectedTemplateTrackType == 1 && // Image
            <>
              <CheckboxField label="Exclude No Images" checked={excludeNoImages} onChange={onChangeExcludeNoImages}/>
              <div className="track-type-list">
                {(imageTypes?.filter((t: any) => !excludeNoImages || !t.noImages) || []).map((imageType: any, index: number) => (
                    <div key={index}>
                      <label>
                          <input type="checkbox" value={imageType.value} onChange={(e) => onChangeImageTypeList(e)} checked={checkedImageTypes.includes(imageType.value) ? true : false}></input>&nbsp;  {imageType.name} {imageType.noImages && <span className="material-icons icon-inline" title="No Images">
                                do_not_disturb
                              </span>     }
                      </label>
                    </div>
                      ))}
              </div>        
              </>
            }

          {selectedTemplateTrackType == 5 && // Lithology
            <>
              <div className="track-type-list">                
                {(lithologyTrackTypes || []).map((lithologyTrackType: any, index: number) => (
                    <div key={index}>
                      <label>
                          <input type="checkbox" value={+lithologyTrackType.id} onChange={(e) => onChangeLithologyTypeList(e)} checked={checkedLithologyTypes.includes(+lithologyTrackType.id) ? true : false}></input>&nbsp; {lithologyTrackType.name} {lithologyTrackType.noData && <span className="material-icons icon-inline" title="No Data">
                                do_not_disturb
                              </span>     }
                      </label>
                    </div>
                ))}
                 
              </div>        
              </>
            }

          {selectedTemplateTrackType == 7 && // text          
              <div className="track-type-list">
                   {(textTrackTypes || []).map((trackType: any, index: number) => (
                        <div key={index}>
                          <label>
                              <input type="checkbox" value={trackType.id} onChange={onChangeTextTypeList} checked={checkedTextTypes.includes(trackType.id) ? true : false}></input>&nbsp;  {trackType.name} {trackType.noData && <span className="material-icons icon-inline" title="No Data">
                                do_not_disturb
                              </span>     }
                          </label>
                        </div>
                          ))}
                {/* <label>
                  <input type="checkbox" value={textTrackTypeId} checked={checkedTextTypes.includes(+textTrackTypeId) ? true : false} onChange={onChangeTextTypeList}></input>&nbsp;Description
                 </label> */}
              </div>                  
            }

          <div className="buttons mt-3 sticky-buttons">
            <button className="button is-primary" onClick={onOnAddTrackFormSubmit} disabled={!anyChecked}>Ok</button>
            <button className="button" onClick={onOnClose}>Cancel</button>
          </div>
        </form>     
)};

export default AddTrack;