import React from 'react';
import { Link } from 'react-router-dom';
import FileUpload from '../../components/file-upload/file-upload';
import CheckboxField from '../../components/Input/checkbox-field';
import SelectField from '../../components/Input/select-field';
import TextField from '../../components/Input/text-field';
import TextAreaField from '../../components/Input/text-area-field';
import { NumericFormat } from 'react-number-format';

const BoreholeForm = (props: any) => {

  const { 
          boreholeStatuses,
          equipments,
          name, 
          setName, 
          latitude, 
          setLatitude, 
          longitude, 
          setLongitude, 
          loggingStarted,
          setLoggingStarted,
          loggingEnded,
          setLoggingEnded,
          loggingTeam,
          setLoggingTeam,
          comments,
          setComments,
          status,
          setStatus,
          checkedEquipment,
          setCheckedEquipment,
          curated,
          setCurated,
          useSqlServer,
          setUseSqlServer,
          canChangeUseSqlServer,
          onSaveBorehole,
          file,
          setFile,
          clientLogoFileName,
          projects,
          selectedProjectId,
          setSelectedProjectId,
          canChangeProject, 
          canDelete,
          canUpdateDepths,
          onDelete,
          minLoggedDepth,
          setMinLoggedDepth,
          maxLoggedDepth,
          setMaxLoggedDepth,
          minBoreholeDepth,
          setMinBoreholeDepth,
          maxBoreholeDepth,
          setMaxBoreholeDepth,
          totalLoggedMeterage, 
          setTotalLoggedMeterage,
          numberofDigitizedBoxes, 
          setNumberofDigitizedBoxes,
          uploadDate, 
          setUploadDate,
          resolutionBoxScan, 
          setResolutionBoxScan,
          resolutionHsi, 
          setResolutionHsi,
          notes, 
          setNotes } = props;

  const onChangeEquipmentList = (e:any) => {            
    const value = +e.target.value;
    if (checkedEquipment.includes(value)) {
      const newEquipments = [...checkedEquipment].filter((id) => id !== value);
      setCheckedEquipment(newEquipments);
    } else {
      const newEquipments = [...checkedEquipment];
      newEquipments.push(value);
      setCheckedEquipment(newEquipments);
    }
  }

  const onOnDelete = (e: any) => {
    e.preventDefault();
    onDelete();
  }

  const projectOptions = projects?.map((p: any) => { return { name: p.name, value: p.id }});

    return (
      <form className="control">
          <div className="columns">
            <div className="column is-one-fifth">
            <SelectField  
                  label="Project" 
                  values={projectOptions} 
                  onChange={setSelectedProjectId} 
                  value={selectedProjectId} 
                  readOnly={!canChangeProject} />
              <TextField
                  type="text" 
                  label="Name" 
                  value={name} 
                  onChange={setName} />        

              <TextField
                  type="number" 
                  label="Latitude" 
                  value={latitude} 
                  onChange={setLatitude} />
              
              <TextField
                  type="number" 
                  label="Longitude" 
                  value={longitude} 
                  onChange={setLongitude} />

              {canUpdateDepths && <>
                <TextField
                    type="number" 
                    label="Minimum Logged Depth" 
                    value={minLoggedDepth} 
                    onChange={setMinLoggedDepth} />

                <TextField
                    type="number" 
                    label="Maximum Logged Depth" 
                    value={maxLoggedDepth} 
                    onChange={setMaxLoggedDepth} />

                <TextField
                    type="number" 
                    label="Minimum Borehole Depth" 
                    value={minBoreholeDepth} 
                    onChange={setMinBoreholeDepth} />

                <TextField
                    type="number" 
                    label="Maximum Borehole Depth" 
                    value={maxBoreholeDepth} 
                    onChange={setMaxBoreholeDepth} />
              </>}
              <CheckboxField label="Curated" 
                  checked={curated} 
                  onChange={setCurated}/>

              <div className="buttons mt-6">
                    <Link to={`/`} className="button mr-4">Cancel</Link>                    
                    <button className="button is-link mr-4" type="submit" onClick={onSaveBorehole}>Save Borehole</button>
                    {canDelete && 
                      <button className="button" onClick={onOnDelete}>Delete</button>
                    }                
              </div>
            </div>
            <div className="column is-one-fifth">
              <TextField
                  type="date" 
                  label="Logging Started" 
                  value={loggingStarted} 
                  onChange={setLoggingStarted} />

                <TextField
                  type="date" 
                  label="Logging Ended" 
                  value={loggingEnded} 
                  onChange={setLoggingEnded} />
              
                <TextField
                  type="text" 
                  label="Logging Team" 
                  value={loggingTeam} 
                  onChange={setLoggingTeam} />        

                <TextField
                  type="text" 
                  label="Comments" 
                  value={comments} 
                  onChange={setComments}
                  maxLength={50} />

                <TextField
                  type="number" 
                  label="Total Logged Meterage" 
                  value={totalLoggedMeterage} 
                  onChange={setTotalLoggedMeterage} />

                <TextField
                  type="integer" 
                  label="Number of Digitized Boxes" 
                  value={numberofDigitizedBoxes} 
                  onChange={setNumberofDigitizedBoxes} />

                <TextField
                  type="date" 
                  label="Upload Date in Atlas" 
                  value={uploadDate} 
                  onChange={setUploadDate} />

                <TextField
                  type="number" 
                  label="Resolution (cm) Box Scan" 
                  value={resolutionBoxScan} 
                  onChange={setResolutionBoxScan} />

                <TextField
                  type="number" 
                  label="Resolution (cm) HSI" 
                  value={resolutionHsi} 
                  onChange={setResolutionHsi} />

                <TextAreaField                  
                  label="Notes" 
                  value={notes} 
                  onChange={setNotes} />

              </div>
              <div className="column is-one-fifth">
                <div className="field">
                  <label className="label">Equipment</label>

                  {(equipments || []).map((equipment: any, index: number) => (
                    <div key={index}>
                      <label>
                          <input type="checkbox" value={equipment.id} onChange={(e) => onChangeEquipmentList(e)} checked={checkedEquipment?.includes(equipment.id) ? true : false}></input>&nbsp;  {equipment.name}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="column is-one-fifth">
                    <FileUpload 
                        file={file} 
                        setFile={setFile}
                        clientLogoFileName={clientLogoFileName}
                         />
              </div>
              <div className="column is-one-fifth">
                <SelectField
                  label="Status"
                  values={boreholeStatuses}
                  value={status}
                  onChange={setStatus} />            
            </div>
          </div>        
      </form>
    )
};

export default BoreholeForm;