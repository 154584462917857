import { useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import TextareaAutosize from 'react-textarea-autosize';
import { useAppDispatch } from "../../../app/hooks";
import Errors from "../../../components/errors"

const TextAdd = (props: any) => {

  const { 
        addTextValue
     } = props;

  const dispatch = useAppDispatch();       

  const [fromDepth, setFromDepth] = useState<number| undefined>(undefined);
  const [toDepth, setToDepth] = useState<number | undefined>(undefined);
  const [text, setText] = useState<any>("");
  const [errors, setErrors] = useState<string[]>([]);

  const onSetFromDepth = (e: any) => {
    const validated = e.target.value.match(/^(\d*[.,]{0,1}\d{0,2}$)/)
    if (!validated) {
       return;
    }

    setFromDepth(+e.target.value);
  };

  const onSetToDepth = (e: any) => {
    const validated = e.target.value.match(/^(\d*[.,]{0,1}\d{0,2}$)/)
    if (!validated) {
       return;
    }

    setToDepth(+e.target.value);
  };
  
  const onTextChange = (e: any) => {  
    setText(e.target.value);
  };

  const onAdd = () => {    
    let errored = false;
    let localErrors = [];
    
    if (fromDepth === undefined) {      
      localErrors.push("'From' cannot be empty.");
      errored = true;
     }
    
    if (toDepth === undefined) {      
      localErrors.push("'To' cannot be empty.");
      errored = true;
     } 

     if (toDepth !== undefined && fromDepth !== undefined && toDepth <= fromDepth) {      
      localErrors.push("'To' must be greater than 'From'.");
      errored = true;
     } 

     if (!text) {
        localErrors.push("'Description' cannot be empty.");
        errored = true;
     } 

     if (errored) {
      setErrors(localErrors);
      return;
     }
    
      if (addTextValue(fromDepth, toDepth, text)) {
        setErrors([]);
        setFromDepth(undefined);
        setToDepth(undefined);
        setText("");
      } else {
        setErrors(["Depth range cannot overlap with existing."]);
      }    
  };

  return (  
          <div>
            {errors.length > 0 && <Errors error={{ errors: {"": errors }}} />}
            <table className='text-edit-table'>
            <thead>
                  <tr>
                    <th>From (m)</th>
                    <th>To (m)</th>
                    <th>Description</th>
                  </tr>
                </thead>
              <tbody>                
                <tr>
                  <td><input type="number" className={`input `} style={{maxWidth: "80px"}} value={fromDepth} onChange={onSetFromDepth} /></td>
                  <td><input type="number" className={`input `} style={{maxWidth: "80px"}} value={toDepth} onChange={onSetToDepth} /></td>
                  <td>
                    <TextareaAutosize
                      className='text-track-teaxarea textarea'
                      maxRows={10}
                      defaultValue={""}
                      value={text}
                      onChange={onTextChange}
                      />
                  </td>
                </tr>
              </tbody>
            </table>
            <button className="button mr-2" onClick={onAdd}>Add</button>
            
          </div>       
)}

export default TextAdd;