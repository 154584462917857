import SelectField from '../../Input/select-field';
import TextField from '../../Input/text-field';
import { useState } from 'react';

const ExportPanelBase = (props: any) => {
  const {          
    exportFileName,
    setExportFileName,
    headerType,
    setHeaderType,
    delimator,
    setDeliminator
  } = props;   
      
const onChangeHeaderType = (value: any) => {  
  setHeaderType(value);
};

const onChangeDelimator = (value: any) => {
  setDeliminator(value);
};

const fileOptions = [{name: "csv", value: 0}];
const headerTypes = [{name: "Multi row", value: 0}, {name: "Single row", value: 1}];
const deliminators = [{name: "Space", value: " "}, 
                      {name: "|", value: "|"},
                      {name: "#", value: "#"},
                      {name: "**", value: "**"},
                      {name: "__", value: "__"},
                      {name: "%%", value: "%%"}
                    ];

return(
  
      <>
          <TextField label="File name" value={exportFileName} onChange={setExportFileName} />
          <SelectField label="File Type" values={fileOptions}/>
          <SelectField label="Header Type" values={headerTypes} value={headerType} onChange={onChangeHeaderType}/>
          {headerType == 1 &&
          <SelectField label="Unit Delimiter" values={deliminators} value={delimator} onChange={onChangeDelimator}/>
          }
        </>
)};

export default ExportPanelBase;