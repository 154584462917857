export default function getConfig() {
    let config = JSON.parse(
        (document.getElementById("configScript")!).innerHTML
    );
  
    if (config.apiUrl === "#{apiUrl}#") {
        //config.apiUrl = "https://ci.api.atlas.geotek.co.uk/";
        config.apiUrl = "https://localhost:44354/";
    }

    const hubUrl = config.apiUrl + "sendhub";
    const jobhubUrl = config.apiUrl + "jobhub";
    const boreholeListHubUrl = config.apiUrl + "boreholelisthub";
    config.apiUrl += "api/";

    return {...config, hubUrl, jobhubUrl, boreholeListHubUrl };
  }