import React from 'react';
import SideMenuButton from './side-menu-button';

const LeftSideMenu = ({showLeftSidePanel, 
                        onShowHideLeftSidePanel, 
                        children, 
                        leftPanelOffset }: any) => {                            

    return (
        <div className="left-side-menu-panel" style={{ marginLeft: showLeftSidePanel ? (leftPanelOffset ?? 300) : 0 }}>
            <div>
                <SideMenuButton highlighted={false} onClick={onShowHideLeftSidePanel} title="Close" icon={showLeftSidePanel ? "Atlas Open 1 Icon" : "Atlas Open 2 Icon"} first={true}/>
                {children}
            </div>
        </div>
    )
}

export default LeftSideMenu;