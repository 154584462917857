import ListTable from './list-table';

const List = ({ lithologyLexicons, updateLithologyLexicon, addLithologyLexicon, deleteAddingRow, deleteLithologyLexicon }: any) => {

    if (!lithologyLexicons || !lithologyLexicons.length) {
        return null;
    }
    
    const firstHalfLength = Math.floor(lithologyLexicons.length / 2);
    const firstHalf = lithologyLexicons.slice(0, firstHalfLength);
    const secondHalf = lithologyLexicons.slice(firstHalfLength);

    return (
            <div className="columns">
              <div className="column">   
                <ListTable lithologyLexicons={firstHalf} 
                           updateLithologyLexicon={updateLithologyLexicon} 
                           addLithologyLexicon={addLithologyLexicon} 
                           deleteAddingRow={deleteAddingRow} 
                           deleteLithologyLexicon={deleteLithologyLexicon} />
              </div>
              <div className="column">                    
                <ListTable lithologyLexicons={secondHalf} 
                           updateLithologyLexicon={updateLithologyLexicon} 
                           addLithologyLexicon={addLithologyLexicon} 
                           deleteAddingRow={deleteAddingRow} 
                           deleteLithologyLexicon={deleteLithologyLexicon} />
              </div>
            </div>
    )    
};

export default List;