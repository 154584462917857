import React, { useEffect, useState } from 'react';

import ReactSelectField from '../../Input/react-select-field';


const AddBorehole = (props: any) => {

    const {       
       onAddBoreholeFormSubmit,              
       onClose, 
       boreholesByProject,
       boreholeId,
       projectId
    } = props;    
    
    const [selectedProjectId, setSelectedProjectId] = useState<any>();
    const [selectedBoreholes, setSelectedBoreholes] = useState<any[]>();   
    
    const projectOptions = boreholesByProject?.map((project: any) => { return { value: project.id, label: project.name }} );
    const selectedProject = boreholesByProject?.find((borehole: any) => borehole.id == selectedProjectId);
    const boreholeOptions = selectedProject?.boreholes.map((borehole: any) => { return { 
            value: borehole.id, 
            label: borehole.name, 
            project: selectedProject.name, 
            minBoreholeDepth: borehole.minBoreholeDepth, 
            maxBoreholeDepth: borehole.maxBoreholeDepth }
          });
    
    useEffect(() => {
      if (projectId && boreholesByProject?.length > 0) {
        setSelectedProjectId(+projectId);
      }      
    },
    [boreholesByProject, projectId]);

    useEffect(() => {          
      if (projectId && boreholeId && boreholeOptions?.length > 0) {                                
        const borehole = boreholeOptions.find((b: any) => b.value == +boreholeId);
        setSelectedBoreholes([borehole]);
      }
    },
    [boreholeOptions?.length, projectId, boreholeId]);

    const onOnClose = (e: any) => {
      e.preventDefault();
      onClose();
    };

    const onOnAddBoreholesFormSubmit = (e: any) => {
      e.preventDefault();      
      onAddBoreholeFormSubmit(selectedBoreholes?.map((borehole:any) => { return { name: borehole.label, id: borehole.value, project: borehole.project, minBoreholeDepth: borehole.minBoreholeDepth, 
        maxBoreholeDepth: borehole.maxBoreholeDepth  } } ));
    }
    
    const anyChecked = (selectedBoreholes?.length ?? 0) > 0;

    const onProjectChange = (selectedItem: any) => {            
      setSelectedProjectId(selectedItem.value);
    };

    const onBoreholeChange = (selectedItems: any) => {                  
      setSelectedBoreholes(selectedItems);
    };
return(
  
        <form onSubmit={onOnAddBoreholesFormSubmit}>    
          
          <ReactSelectField label="Project" options={projectOptions} value={selectedProjectId} onChange={onProjectChange} />
          <ReactSelectField label="Boreholes" options={boreholeOptions} value={selectedBoreholes} onChange={onBoreholeChange} isMulti={true} />            
          
          <div className="buttons mt-3 sticky-buttons">
            <button className="button is-primary" onClick={onOnAddBoreholesFormSubmit} disabled={!anyChecked}>Ok</button>
            <button className="button" onClick={onOnClose}>Cancel</button>
          </div>
        </form>     
)};

export default AddBorehole;