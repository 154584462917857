import React from 'react';

const CheckboxField = (props: any) => {

    const { 
            label, 
            errors,
            checked,
            info,
            onChange,
            readOnly,
            className
             } = props;

  return (
    <div className={className}>
        <label className="checkbox">
            <input type="checkbox" checked={checked} onChange={(e) => onChange(e.target.checked)} disabled={readOnly}/>  {label}
        </label> 
        {info && <div className='is-size-7 mb-2'>{info}</div>}
        {errors?.length > 0 && errors.map((e: any, index: number) => <p key={index} className="help is-danger">{e}</p>)}
    </div>
  )
}

export default CheckboxField;