import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import auth from './auth';

export const PrivateRoute = ({ children, ...rest }: RouteProps) => {
    const authenticated = auth.isAuthenticated();
    console.log("authenticated", authenticated);
    if (!authenticated) {
        console.log("user", auth.getUser());
        console.log("localStorage.user", localStorage.user);        
    }
    return (
        <Route
        {...rest}
        render={({ location }) => {
        
        return authenticated ? (
            children
            ) : (
            <Redirect
                to={{
                pathname: '/login',
                state: { from: location },
                }}
            />
            )
        }}
        />
    )
}