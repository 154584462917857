import { useEffect, useRef, useState } from 'react'
import { useSelector } from "react-redux";
import Select from 'react-select';
import { useAppDispatch } from "../../../app/hooks";
import { getLithologyLookups, selectLithology } from "../../../services/lithologySlice";
import SelectField from '../../Input/select-field';
import { confirmAlert } from 'react-confirm-alert'; 
import './lithology.scss';

const LithologyEdit = (props: any) => {

  const {         
        canAdd,
        clientId,
        lithologyData,
        changeDataValue,
        saveData,
        changeDepth,
        deleteData,
        selectedLithologyId,
        setSelectedLithologyId,
        visible,
        trackEditMode,
        setTrackEditMode
     } = props;

  const dispatch = useAppDispatch();    
  
  const focusInput = useRef<any[]>([]);
  const [focusIndex, setFocusIndex] = useState(-1);
  //const [editMode, setEditMode] = useState(true);

  useEffect(() => {            
    dispatch(getLithologyLookups());
  }, []);

  useEffect(() => {    
    if (selectedLithologyId && visible && focusInput.current.length) {      
      const focusIndex = lithologyData[0]?.data.findIndex((data: any) => data.id === selectedLithologyId);      
      if (focusIndex >= 0) {
        setFocusIndex(focusIndex);
        setSelectedLithologyId(null);
      }
    }    
  }, [selectedLithologyId, visible, focusInput.current.length]);  

  useEffect(() => {
    if (focusIndex >= 0) {    
      focusInput.current[focusIndex]?.focus();
    }
  }, [focusIndex]);

  const { 
    lithologyLookups,
    error,
    loading
  } = useSelector(selectLithology);

  const [localData, setLocalData] = useState<any>();

  useEffect(() => {    
    if (lithologyData && lithologyData[0]?.data) {
      setLocalData(lithologyData[0]?.data);
    }
  },[JSON.stringify(lithologyData)]);

  const lithologyLookupsOptions = lithologyLookups.map((lookup: any) => { return { value: lookup.lithologyLexiconId, label: <><div className='lithology-select-box' style={{backgroundColor: lookup.color}}></div>{lookup.code}</> } });
  lithologyLookupsOptions.unshift({value: undefined, label: "None"});

  // const lithologyLookupsOptions =  [
  //   { value: 'circle', label: <><svg height={10} width={10}>
  //       <g transform={`translate(5, 5)`}>          
  //           <circle r={5} fill="red" stroke="none"/>
  //       </g>
  //   </svg><span>HT</span></> },
    
  // ];

  const onLookupChange = (id: string, item: any) => {        
    const lithologyLexiconId = item.value;
    const lithology = lithologyLookups.find((l: any) => l.lithologyLexiconId === lithologyLexiconId);
    changeDataValue(id, lithology?.code, lithology?.color, lithologyLexiconId);
  }

  const onChangeDepth = (id: any, newDepth: any) => {
    setFocusIndex(-1);
    const copy = Array.from(localData);
    copy.sort((a: any, b: any) => a.depth - b.depth);
    setLocalData(copy);

    changeDepth(id, newDepth);
  }

  const onDeleteData = (id: any, depth: any) => {
    confirmAlert({ 
      title: 'Confirm Delete Data',
      message: `Are you sure you want to remove data at depth ${depth} m?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            deleteData(id);
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }

  const onChange = (id: string, value: any) => {
    const copy = Array.from(localData);
    const dataIndex = copy.findIndex((d: any) => d.id === id);
    const data: any = copy[dataIndex];
    copy.splice(dataIndex, 1);
    const newData = {...data, depth: +value};  

    setLocalData([
      ...copy.slice(0, dataIndex),
      newData,
      ...copy.slice(dataIndex)
    ]);
  };

  const onSetEditMode = (editMode: boolean) => {
    setTrackEditMode(editMode);
  };

  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,          
      backgroundColor: state.isSelected ? '#AAAAAA' : '#FFFFFF',
      padding: 5,
      paddingLeft: "12px"
    }),
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 })        
  }

  return (  
          <div>
             <div className='mouse-wheel-control-text'>
                  Mouse click track:
                </div>
            <div className='mouse-wheel-control-control'>
                  <label className="mr-1" onClick={(e: any) => onSetEditMode(true)}>Edit</label>                  

                  <input type="checkbox" className="switch is-rounded is-outlined" checked={!trackEditMode} />
                  <label className="mouse-wheel-control-switch" onClick={(e: any) => onSetEditMode(!trackEditMode)}></label>

                  <label onClick={(e: any) => onSetEditMode(false)}>Add</label>
                </div>
            <table>
              <thead className='lithology-edit-header'>
                <tr>
                  <th>Depth (m)</th>
                  <th>Lithology</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {(localData || []).map((lithology: any, index: number) => {
                  const selectedValue = lithologyLookupsOptions.find((option: any) => option.value == lithology.lithologyLexiconId);
                  return (
                  <tr key={lithology.id}>
                    <td>
                      <input type="number"
                             ref={(el) => (focusInput.current[index] = el)} 
                             className={`input `} 
                             style={{maxWidth: "100px"}} 
                             value={lithology.depth} 
                             onChange={(e) => onChange(lithology.id, e.target.value)} 
                             onBlur={(e) => onChangeDepth(lithology.id, e.target.value)} /> {/*onBlur={(e) => onChangeDepth(lithology.id, lithology.depth, e.target.value)}*/}
                    </td> 
                    <td>
                      <Select options={lithologyLookupsOptions} styles={customStyles} classNamePrefix="select" value={selectedValue} onChange={(value: any) => onLookupChange(lithology.id, value)} />
                      {/* <SelectField values={lithologyLookupsOptions} allowNull={true} value={lithology.lithologyLexiconId ?? undefined} onChange={(value: any) => onLookupChange(lithology.id, value)}/> */}
                    </td>
                    <td><button onClick={() => onDeleteData(lithology.id, lithology.depth)} className="button is-small" style={{ paddingLeft: "2px", paddingRight: "2px", paddingTop: 0, paddingBottom: 0}}><img src={"/images/icons/Atlas Delete Icon.svg"} className="" style={{fill: "black", width: "20px" }} title="Delete"/></button></td>
                  </tr>
                )})}
              </tbody>
            </table>            
            <button className='button is-primary' onClick={saveData} disabled={!localData?.length}>Save Data</button>
          </div>       
)}

export default LithologyEdit;