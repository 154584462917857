import React from 'react';
import ReactSelect from 'react-select';

const ReactSelectField = (props: any) => {

    const { 
            label,
            options,
            value,
            onChange,
            disabled,
            allowNull,
            readOnly,
            isMulti
          } = props;

   const selected = isMulti ? value : options?.find((v :any) => v.value == value);
   console.log("value", value);
   console.log("selected", selected);
   console.log("options", options);

   const customStyles = {
    option: (provided: any, { data, isDisabled, isFocused, isSelected }: any) => ({
        ...provided,          
        backgroundColor: isSelected ? 'rgba(0, 54, 113, 1)' : isFocused ? 'rgba(0, 54, 113, 0.3)' : '#FFFFFF',      
        color: isDisabled
          ? '#ccc'
          : isSelected ? "white" : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',
        padding: 5,
        paddingLeft: "12px",        
    }),
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 })   
  }

return (
    <div className="field" >
        {label && <label className="label">{label}</label>}
        {readOnly && <div>{selected?.name ? selected?.name : "None"}</div>}
        {!readOnly && 
            <ReactSelect options={options} 
                    styles={customStyles} 
                    classNamePrefix="select" 
                    value={selected} 
                    onChange={onChange} 
                    isDisabled={readOnly || disabled}  
                    menuPortalTarget={document.body} 
                    isMulti={isMulti}
             />            
        }
    </div>
)
}

export default ReactSelectField;