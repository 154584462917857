import React from 'react';
import { Link } from 'react-router-dom';
import Row from './row';

const List = ({ imageTypes, onDelete, canAdd, canEdit, canView, canDelete }:any) => {

    return (
            <div className="columns">
                    <div className="column is-half">
                    {canAdd && <div className="field is-grouped">
                        <div className="control">
                            <Link className="button" to={`add`}>Add Image Type</Link>
                        </div>
                    </div>}
                    <table className="table">
                        <thead>
                            <tr>                                
                                <th>Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(imageTypes || []).map((imageType: any, index: any) => (
                                <Row key={index} trackType={imageType} />
                            ))} 
                        </tbody>
                    </table>          
                 
              </div>
            </div>
    )    
}; 

export default List;