import React, { useEffect, useState } from 'react';
import Errors from '../../../components/errors';
import Loader from '../../../components/loader';
import { useCurveTypesQuery,
         useTemplateTrackTypesQuery,
         useAveragingTypesQuery, 
         useUploadTypeQuery} from '../../../services/enumApi';
import { useTrackCategoriesQuery } from '../../../services/trackTypeApi';
import Form from './form';

const TrackTypeContainer = ({ title, roleIsLoading, trackType, onSave, isLoading, error, readOnly, userPermissions }:any) => {
    
    const [headerName, setHeaderName] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [shortDisplayName, setShortDisplayName] = useState('');
    const [exportHeaderName, setExportHeaderName] = useState('');
    const [description, setDescription] = useState('');
    const [units, setUnits] = useState('');
    const [displayUnits, setDisplayUnits] = useState('');
    const [trackCategoryId, setTrackCategoryId] = useState('');
    const [uploadType, setUploadType] = useState('');
    const [allowedCurveTypes, setAllowedCurveTypes] = useState('');
    const [allowedTrackTypes, setAllowedTrackTypes] = useState('');
    const [averagingType, setAveragingType] = useState('');
    
    useEffect(() => {
        if (!roleIsLoading && trackType) {
            setHeaderName(trackType.headerName);
            setDisplayName(trackType.displayName);
            setShortDisplayName(trackType.shortDisplayName);
            setExportHeaderName(trackType.exportHeaderName);
            setDescription(trackType.description);
            setUnits(trackType.units);
            setDisplayUnits(trackType.displayUnits);
            setTrackCategoryId(trackType.trackCategoryId);
            setUploadType(trackType.uploadType);
            setAllowedCurveTypes(trackType.allowedCurveTypes.map((curveType: any) => curveType.toString()));
            setAllowedTrackTypes(trackType.allowedTrackTypes.map((trackType: any) => trackType.toString()));
            setAveragingType(trackType.averagingType);
        }
    },[roleIsLoading, trackType]);

    const onUpdateTrackType = (e: any) => {        
        e.preventDefault();
        onSave({headerName, displayName, shortDisplayName, exportHeaderName, description, units, displayUnits, trackCategoryId, uploadType, allowedCurveTypes, allowedTrackTypes, averagingType });
      };
    
      const { data: curveTypes, isLoading: curveTypesIsLoading } = useCurveTypesQuery();
      const { data: templateTrackTypes, isLoading: templateTrackTypesLoading } = useTemplateTrackTypesQuery();
      const { data: averagingTypes, isLoading: averagingTypesIsLoading } = useAveragingTypesQuery();
      const { data: uploadTypes, isLoading: uploadTypesIsLoading } = useUploadTypeQuery();
      const { data: trackCategories, isLoading: trackCategoriesIsLoading } = useTrackCategoriesQuery();

    useEffect(() => {
        if (!trackCategoriesIsLoading && !trackType?.trackCategoryId) {
            if (trackCategories.trackCategories?.length > 0) {
                setTrackCategoryId(trackCategories.trackCategories[0].id);
            }
        }
    },[trackCategoriesIsLoading, trackType?.trackCategoryId]);
        
    return (
        <section className="section">
            <div>
                <h1>{title}</h1>
                <Errors error={error}/>
                {(isLoading || roleIsLoading || curveTypesIsLoading || templateTrackTypesLoading || averagingTypesIsLoading || uploadTypesIsLoading || trackCategoriesIsLoading) && <Loader/>}

                <Form headerName={headerName}                      
                    setHeaderName={setHeaderName}                                           
                    onSaveTrackType={onUpdateTrackType}     
                    displayName={displayName}
                    setDisplayName={setDisplayName}
                    shortDisplayName={shortDisplayName}
                    setShortDisplayName={setShortDisplayName}
                    exportHeaderName={exportHeaderName}
                    setExportHeaderName={setExportHeaderName}
                    description={description}
                    setDescription={setDescription}
                    units={units}
                    setUnits={setUnits}
                    displayUnits={displayUnits}
                    setDisplayUnits={setDisplayUnits}
                    trackCategoryId={trackCategoryId}
                    setTrackCategoryId={setTrackCategoryId}
                    uploadType={uploadType}
                    setUploadType={setUploadType}
                    allowedCurveTypes={allowedCurveTypes}
                    setAllowedCurveTypes={setAllowedCurveTypes}
                    allowedTrackTypes={allowedTrackTypes}
                    setAllowedTrackTypes={setAllowedTrackTypes}
                    averagingType={averagingType}
                    setAveragingType={setAveragingType}
                    curveTypes={curveTypes}
                    templateTrackTypes={templateTrackTypes}
                    averagingTypes={averagingTypes}
                    uploadTypes={uploadTypes}
                    trackCategories={trackCategories?.trackCategories}
                    readOnly={readOnly}                    
                    />
                
            </div>
        </section>
    )    
};

export default TrackTypeContainer;