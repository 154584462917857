import React, { useEffect, useState } from 'react';
import MfaForm from './form';
import { getAuthenticatorKey, disableTwoFactorAuthentication } from '../../../services/accountSlice';
import Loader from '../../../components/loader';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../app/store';

const DisableTwoFactorContainer = () => {
        
    const dispatch: AppDispatch = useDispatch();

    let history = useHistory();
        
    const [authenticatorCode, setAuthenticatorCode] = useState<string>('');
    
    useEffect(() => {
        if (authenticatorCode?.length >= 6) {
            onHandleSubmit();
        }   
    }, [authenticatorCode]);

    const selectAccount = (state: RootState) => state.account;
    const { loading,
            error            
          } = useSelector(selectAccount);
    
    const onHandleSubmit = () => {            
        dispatch(disableTwoFactorAuthentication(authenticatorCode))
            .then((response: any) => 
            {
                if (response.payload.success) {
                    history.push('/user');
                }
            });
    }    

    return (
        <>
            {loading && <Loader/>}
            {/* {error && <Errors error={{ data: { errors: error}}} />}   */}
            <MfaForm  
                handleSubmit={onHandleSubmit}
                authenticatorCode={authenticatorCode}
                setAuthenticatorCode={setAuthenticatorCode}                
                error={error} />
        </>
    )
    }
    
export default DisableTwoFactorContainer;