import { Icon } from "@iconify/react";
import { useState } from "react";
import GroupTrackCurve from "./group-track-curve";
import CheckboxField from "../../Input/checkbox-field";

const GroupTrack = (props: any) => {

  const {                   
      track,            
      setSelectedTrack,
      isSelected,
      changeMarkDelete,
      markDelete
    } = props;    

  const [collapsed, setCollapsed] = useState<boolean>(true);

  const onSetCollabpsed = () => {
    setCollapsed(!collapsed);
    if (collapsed) {
      setSelectedTrack(track.trackId);
    } else {
      setSelectedTrack(null);
    }
  };

  const onChangeMarkDelete = (e: any) => {
    changeMarkDelete(track.trackId, e.target.checked);
  }

  return (  
    <div>       
      <div className={`collapsible group-track-header is-size-6 ${isSelected ? "group-track-active" : ""}`} onClick={onSetCollabpsed} >                  
        <div className="track-order-label">{track.trackName}</div>
        <div className="collapsible-icon">
          <input type="checkbox" checked={markDelete} onChange={onChangeMarkDelete} className="group-track-delete-checkbox" />
          <Icon icon="mdi:chevron-down-circle-outline" width="24" height="24" rotate={collapsed ? 0 : 2} />
        </div>
      </div>  
      <div className="collapsible-content" style={{display: collapsed ? "none" : "inline-block", overflow: "visible" }}>
        <div className="group-track-curves">
          {(track?.curves || []).map((curve: any) => (
            <GroupTrackCurve curve={curve}/>
          ))}
          {!track?.curves?.length &&  
            <div className="group-track-curve">            
              <div>Project: {track.projectName}</div>
              <div>Borehole: {track.boreholeName}</div>
            </div>     
            }
        </div>
      </div>
    </div>
  )
};

export default GroupTrack;