import {
    BaseQueryFn,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
  } from '@reduxjs/toolkit/query'
  import getConfig from "../app/config";
  import auth from '../components/auth'
  
  const baseQuery = fetchBaseQuery({ baseUrl: getConfig().apiUrl, prepareHeaders: (headers) => {
    const token = auth.getToken();
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }
    return headers
  } });

  const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
  > = async (args, api, extraOptions) => {
    let result : any;
    let hasError = false;
    try {
      result = await baseQuery(args, api, extraOptions)
    } catch (error) {
      console.error(error);
      hasError = true;
    }

    if (hasError || (result && result.error && result.error.status === 401)) {
        if (auth.isLoggedIn()) {
            // try to get a new token
            const refreshResult = await baseQuery({
                url: '/account/refresh',
                method: 'POST',
                body: { 
                    accessToken: auth.getToken(), 
                    refreshToken: auth.getUser().refreshToken
                },
                headers: {
                  Authorization: "",
                }
            }, api, extraOptions)
            if (refreshResult.data) {
                // store the new token
                auth.setToken(refreshResult.data);
                //api.dispatch(tokenReceived(refreshResult.data))
                // retry the initial query
                result = await baseQuery(args, api, extraOptions)
            } else {
                auth.logout();
                window.location.href = '/login';
                //api.dispatch(loggedOut())
            }
        } else {
            window.location.href = '/login';
        }
    }
    return result
  }

  export default baseQueryWithReauth;