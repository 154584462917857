import React, { useEffect } from "react";
import { useParams } from 'react-router-dom';
import useSwr from "swr";
import MapViewer from "./map-viewer";
import { useGetBoreholeLocationsQuery, useGetCrimeLocationsQuery } from '../../services/boreholeApi'
import Loader from "../loader";

const fetcher = (...args: any) => fetch(args).then(response => response.json());

const MapViewerContainer = ({setBorehole}: any) => {
    const { clientId, projectId } = useParams<any>();
    const { data, error, isLoading } = useGetBoreholeLocationsQuery({ clientId, projectId });
    

    const url = "https://data.police.uk/api/crimes-street/all-crime?lat=52.2648245&lng=-1.1640192&date=2019-10";
    //const { data, error } = useSwr(url, { fetcher });
  
  const crimes = data && !error ? data : [];

    const boreholeLocations = data?.boreholeLocations;
    //const boreholeLocations = crimes?.map((crime: any) => { return {latitude: crime.location.latitude, longitude: crime.location.longitude, boreholeId: crime.id, category: crime.category } });

    useEffect(() => {
        setBorehole(null);
    }, []);
    
    return(
        <div>
            {!boreholeLocations && <Loader/>}
            {boreholeLocations && <MapViewer boreholeLocations={boreholeLocations} projectId={projectId} clientId={clientId} /> }
        </div>
    )
}

export default MapViewerContainer;