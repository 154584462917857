import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import Modal from '../modal/modal2';

const NotSavedDialog = (props: any) => {

const { showNotSavedModal,
        handleCloseNotSavedModal,
        handleYesNotSavedModal,
        handleNoNotSavedModal
     } = props;

return (
  <ReactModal 
    isOpen={showNotSavedModal}
    ariaHideApp={false}
    contentLabel="Not saved"
    >
      <Modal 
        closeModal={handleCloseNotSavedModal} 
        handleYes={handleYesNotSavedModal}
        handleNo={handleNoNotSavedModal}
        title="Not Saved"
      >
          <p>The template has unsaved changes. Do you wish to save the changes?</p>
        </Modal>
    </ReactModal>
)}

export default NotSavedDialog;