import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { format } from 'date-fns';

import { 
         useScaleImagesMutation, 
         useCreateDeepZoomImagesMutation
          } from "../../services/boreholeApi";
import Errors from '../../components/errors';
import Progress from '../../components/progress';
import getConfig from "../../app/config";
import { useAppDispatch } from '../../app/hooks';
import formatBytes from '../../components/FormatBytes';
import './BoreholeUpload.scss';
import auth from '../../components/auth';
import { RootState } from '../../app/store';
import { confirmAlert } from 'react-confirm-alert';
import {        
        deleteImageFile, 
        deleteImageFiles, 
        deleteSelectedImageFiles,
        downloadSelectedImageFiles,
        scaleImage,
        deepZoomImage        
        } from "../../services/borehole-files-slice";
import TextField from '../../components/Input/text-field';

const BoreholeImageTypeFiles = ({imageFileUpload, onUpdateImageFileDownloadProgress}: any) => {
  const { boreholeId, clientId, projectId } = useParams<any>();
  const dispatch = useAppDispatch();
console.log("imageFileUpload", imageFileUpload);
  const selectAccount = (state: RootState) => state.account;
  const { 
    connection,
    connected
  } = useSelector(selectAccount);
  
  const [showImageFiles, setShowImageFiles] = useState<boolean>(false);  
  const [selectedDeletedFiles, setSelectedDeletedFiles] = useState<string[]>([]);
  const [imageNameFilter, setImageNameFilter] = useState<string>("");
  
  const [scaleImages, { isLoading: isScalingImages, error: scaleImagesError }] = useScaleImagesMutation();    
  const [createDeepZoomImages, { isLoading: isCreatingDeepZoomImages, error: deepZoomImagesError }] = useCreateDeepZoomImagesMutation();
  
  const onScaleImageType = (boreholeId: any, imageType: number) => {
    dispatch(scaleImage({imageType}))
    scaleImages({boreholeId, projectId, clientId, imageType})
      .unwrap()
      .then((payload) => { })
      .catch((error) => { toast.error("Error scaling images") });
  }

  const onDeepZoomImageType = (boreholeId: any, imageType: number) => {
    createDeepZoomImages({boreholeId, projectId, clientId, imageType})
    .unwrap()
      .then((payload) => {dispatch(deepZoomImage({imageType})) })
      .catch((error) => { toast.error("Error deep zooming images") });
  }

  const onShowImageFiles = () => {
    setShowImageFiles(!showImageFiles);
    if (imageFileUpload.downloadProgress?.percent >= 100) {
      onUpdateImageFileDownloadProgress(null, null, null, imageFileUpload.imageType);
    }
  };

  const onDeleteImageFile = (imageType: any, imageFileId: any, imageFileName: string) => {
    confirmAlert({
      title: 'Confirm Delete Image File',
      message: `Are you sure you want to delete ${imageFileName}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            dispatch(deleteImageFile({clientId, projectId, boreholeId, imageType, imageFileId}));
            //dispatch(removeImageFile({imageType, id: imageFileId}));
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };

  const onDeleteImageFiles = () => {

    const imageType = imageFileUpload.imageType;
    const imageTypeName = imageFileUpload.imageTypeDescription;

    const deleteSelected = selectedDeletedFiles.length;
    const message = `Are you sure you want to delete ${deleteSelected ? "selected" : "all"} ${imageTypeName} files?`

    confirmAlert({
      title: 'Confirm Delete Image Files',
      message: message,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
          if (deleteSelected) {            
            dispatch(deleteSelectedImageFiles({clientId, projectId, boreholeId, imageType, fileIds: selectedDeletedFiles }));
          } else {
            dispatch(deleteImageFiles({clientId, projectId, boreholeId, imageType}));
          }
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };

  const onDownloadImageFiles = () => {    
    const imageType = imageFileUpload.imageType;
    dispatch(downloadSelectedImageFiles({clientId, projectId, boreholeId, imageType, fileIds: selectedDeletedFiles }));
    onUpdateImageFileDownloadProgress(null, "Queueing", "fileName", imageType);    
  };
  
  const onDeleteCheck = (id: string) => {
    const newSelectedDeletedFiles = Array.from(selectedDeletedFiles);
    const foundIndex = newSelectedDeletedFiles.findIndex((fileId: string) => fileId === id);
    if (foundIndex >= 0) {
      newSelectedDeletedFiles.splice(foundIndex, 1);
    } else {
      newSelectedDeletedFiles.push(id);
    }

    setSelectedDeletedFiles(newSelectedDeletedFiles);
  };

  const onFilterImageFiles = (imageType: any, text: string) => {  
    setImageNameFilter(text);
  };

  const filterImages = () => {
    if (!imageNameFilter) {
      return;
    }

    const filterdFiles = imageFileUpload.files.filter((file: any) => file.fileName.includes(imageNameFilter));
    //const newSelectedDeletedFiles = Array.from(selectedDeletedFiles);
    const newSelectedDeletedFiles: any[] = [];
    filterdFiles.forEach((file: any) => { 
      const foundIndex = newSelectedDeletedFiles.findIndex((fileId: string) => fileId === file.id);
      if (foundIndex === -1) {        
        newSelectedDeletedFiles.push(file.id);
      }
    });

    setSelectedDeletedFiles(newSelectedDeletedFiles);
    setImageNameFilter("");
  };

  const selectAllFile = () => {
    const newSelectedDeletedFiles = Array.from(selectedDeletedFiles);
    imageFileUpload.files.forEach((file: any) => { 
      const foundIndex = newSelectedDeletedFiles.findIndex((fileId: string) => fileId === file.id);
      if (foundIndex === -1) {        
        newSelectedDeletedFiles.push(file.id);
      }
    });

    setSelectedDeletedFiles(newSelectedDeletedFiles);
  };

  const deselectAllFile = () => {
    const newSelectedDeletedFiles = Array.from(selectedDeletedFiles);
    imageFileUpload.files.forEach((file: any) => { 
      const foundIndex = newSelectedDeletedFiles.findIndex((fileId: string) => fileId === file.id);
      if (foundIndex >= 0) {        
        newSelectedDeletedFiles.splice(foundIndex, 1);
      }
    });

    setSelectedDeletedFiles(newSelectedDeletedFiles);
  };

  const apiUrl = getConfig().apiUrl;

  const uploaded = imageFileUpload.isUploaded;
  const isScaled = imageFileUpload.isScaled || imageFileUpload.progress === 100;
  const isDeepZoom = imageFileUpload.isDeepZoomCreated || imageFileUpload.deepZoomProgress?.progress === 100;
  const deepZoomNeedsRegenerating = imageFileUpload.deepZoomNeedsRegenerating;
  const isWarning = imageFileUpload.boreholeDepthNeedsRegenerating || imageFileUpload.loggedDepthNeedsRegenerating
  let warning = "";
  if (isWarning) {
    if (imageFileUpload.boreholeDepthNeedsRegenerating) {
      warning = "Logged depth " ;
      if (imageFileUpload.boreholeDepthNeedsRegenerating) {
        warning += "and ";
      }
    }
    if (imageFileUpload.boreholeDepthNeedsRegenerating) {
      warning += "Borehole depth ";
    }

    warning += "needs regenerating";
  }

  const allFilesSelected = selectedDeletedFiles.length === imageFileUpload.files?.length;
  const noFilesSelected = selectedDeletedFiles.length === 0;

  const inProgress = imageFileUpload?.inProgress || (imageFileUpload?.deepZoomProgress && imageFileUpload?.deepZoomProgress?.inProgress);

  return (
      <>
        <tr>
          <td className="type-column">{imageFileUpload.imageTypeDescription}</td>
          <td className="has-text-centered"><span className="icon">{uploaded ?  
                <i className="fas fa-check"></i>
              : <i className="fas fa-times"></i>}
              </span></td>
          <td className="has-text-centered"><span className="icon">{isScaled ?  
                <i className="fas fa-check"></i>
              : <i className="fas fa-times"></i>}
              </span>                      
              {isWarning && isScaled && <span className="material-icons warning-icon" title={warning}>warning</span>}
              {(!imageFileUpload.inProgress && uploaded && (!isScaled || isWarning)) &&
                <button className="button" onClick={() => onScaleImageType(boreholeId, imageFileUpload.imageType)}>Scale</button>
              }
          </td>
          <td className="has-text-centered"><span className="icon">{isDeepZoom ?  
                <i className="fas fa-check"></i>
              : <i className="fas fa-times"></i>}
              </span>
              {deepZoomNeedsRegenerating && isDeepZoom && <span className="material-icons warning-icon" title={warning}>warning</span>}
              {(!imageFileUpload.deepZoomProgress && uploaded && (!isDeepZoom || deepZoomNeedsRegenerating)) &&
                <button className="button" onClick={() => onDeepZoomImageType(boreholeId, imageFileUpload.imageType)}>Deep Zoom</button>
              }
          </td>
          <td className="progress-column">
              {imageFileUpload.inProgress && <Progress progress={{progress: imageFileUpload.progress, subProgress: imageFileUpload.subProgress, message: imageFileUpload.progressMessage, failed: imageFileUpload.failed, warning: imageFileUpload.warning }} />}
              <Progress progress={imageFileUpload.deepZoomProgress} />
          </td>
          <td>
            {imageFileUpload.files && 
              <button className="button" onClick={onDeleteImageFiles} disabled={inProgress}><img src="/images/icons/Atlas Delete Icon.svg" title="Delete" width={20} className="mr-1" />{selectedDeletedFiles.length ? "Selected" : "All"} </button>
            }                
          </td>
          <td>
            {imageFileUpload.files && 
              <button className="button" onClick={onShowImageFiles}>{showImageFiles ? "Hide" : "Show"}</button>
            }                
          </td>
        </tr>
        <tr>
              <td></td>
              <td colSpan={6}>
              {showImageFiles &&  
              <>
                 <div className='image-file-name-filter'>
                    <TextField onChange={(text: string) => onFilterImageFiles(imageFileUpload.imageType, text)} value={imageNameFilter} className="image-file-name-filter-text" placeholder="Filter selection"/>
                    <button className='button is-primary' onClick={filterImages} disabled={!imageNameFilter}>Filter</button>
                    <button className='button' onClick={selectAllFile} disabled={allFilesSelected}>Select All</button>
                    <button className='button' onClick={deselectAllFile} disabled={noFilesSelected}>Deselect All</button>
                    <button className='button' onClick={onDownloadImageFiles} disabled={noFilesSelected}>Download</button>
                    Total Size: {formatBytes(imageFileUpload.storageSize, 2)}
                  </div>
                  {imageFileUpload?.downloadProgress && <div>
                    <div className="mt-4"><Progress progress={{progress: imageFileUpload.downloadProgress.percent, message: imageFileUpload.downloadProgress.message, failed: imageFileUpload.downloadProgress.failed}} /></div>
                  </div>}
                <table className="table">
                  <thead>
                    <tr>                      
                      <th>File Name</th>
                      <th>File Size</th>
                      <th>Last Modified</th>
                      <th>&nbsp;</th>                      
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan={3}>
                      
                      </td>
                    </tr>
                    {(imageFileUpload.files || []).map((imageFile: any, index: number) => (
                      <tr key={index}>
                        <td><input type="checkbox" className='mr-1' onClick={() => onDeleteCheck(imageFile.id)} checked={selectedDeletedFiles.findIndex((id:any) => id === imageFile.id) >= 0}></input><a download={imageFile.fileName} href={`${apiUrl}client/${clientId}/project/${projectId}/borehole/${boreholeId}/download/image?id=${imageFile.id}&fileName=${imageFile.fileName}&imageType=${imageFileUpload.imageType}&access_token=${auth.getTokenForImages()}`}>{imageFile.fileName}</a></td>
                        <td>{formatBytes(imageFile.fileSize)}</td>
                        <td>{imageFile.uploaded && format(new Date(imageFile.uploaded), 'dd/MM/yyyy HH:mm:ss')}</td>
                        <td>                                                      
                        {!inProgress && <img src="/images/icons/Atlas Delete Icon.svg" title="Delete"  style={{cursor: "pointer"}} width={20} onClick={() => onDeleteImageFile(imageFileUpload.imageType, imageFile.id, imageFile.fileName)} />}
                        </td>
                      </tr>
                  ))}
                </tbody>
              </table>
              </>}
            </td>
      </tr>        
    </> 
  )
};

export default BoreholeImageTypeFiles;