import React from "react";
import './errors.scss';

const Errors = (props: any) => {
    const { error } = props;          
    let errorMessage = error?.data?.errors?.message || error?.message;
    if (error?.status === 403) {
      errorMessage = "Unauthorized";
    }

    const errors = error?.data?.errors || error?.errors;
  return (
    <>
      {((error && errors) || errorMessage) && (
        <div className="content">
          <ul className={`notification error ${props.isWarning ? "is-warning" : "is-danger"}`}>
            {errorMessage && <li>{errorMessage}</li>}
            {!errorMessage && Object.keys(errors).map((field, i) => {
              return errors[field]?.map((error: any, j: number) => {
                if (!props.messageToIgnore || error != props.messageToIgnore) {
                  return <li key={i + "" + j}>{error}</li>;
                }
              });
            })}
          </ul>
        </div>
      )}
    </>
  );
};

export default Errors;
