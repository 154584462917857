import { useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import Select from 'react-select';
import { useAppDispatch } from "../../../app/hooks";
import { getLithologyLookups, selectLithology } from "../../../services/lithologySlice";
import SelectField from '../../Input/select-field';

const LithologyAdd = (props: any) => {

const {         
        canAdd,
        clientId,
        lithologyData,
        changeDataValue,
        saveData,
        addDataValue
     } = props;

  const dispatch = useAppDispatch();       
  useEffect(() => {        
    dispatch(getLithologyLookups());
  }, []);

  const { 
    lithologyLookups,
    error,
    loading
  } = useSelector(selectLithology);

const [depth, setDepth] = useState<any>("");
const [lithologyLexiconId, setCode] = useState<any>(null);

const lithologyLookupsOptions = lithologyLookups.map((lookup: any) => { return { value: lookup.lithologyLexiconId, label: <><div className='lithology-select-box' style={{backgroundColor: lookup.color}}></div>{lookup.code}</> } });
  lithologyLookupsOptions.unshift({value: undefined, label: "None"});

const onAdd = () => {
  const lithology = lithologyLookups.find((l: any) => l.lithologyLexiconId === lithologyLexiconId);      
  addDataValue(+depth, lithology?.code, lithology?.color, lithologyLexiconId);
  setDepth("");
  setCode("");
};

const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,          
    backgroundColor: state.isSelected ? '#AAAAAA' : '#FFFFFF',
    padding: 5,
    paddingLeft: "12px"
  }),
  menuPortal: (base: any) => ({ ...base, zIndex: 9999 })        
}

const onLookupChange = (item: any) => {        
  const lithologyLexiconId = item.value;
  setCode(lithologyLexiconId);  
}

//const selectedValue = lithologyLexiconId == null ? lithologyLookupsOptions[0] : lithologyLookupsOptions.find((option: any) => option.value == lithologyLexiconId);
const selectedValue = lithologyLookupsOptions.find((option: any) => option.value == lithologyLexiconId);

return (  
          <div>
            <table>
              <tbody>
                <tr>
                  <td><input type="number" className={`input `} style={{maxWidth: "100px"}} value={depth} onChange={(e) => setDepth(e.target.value)} /> m</td>
                  <td>
                    <Select options={lithologyLookupsOptions} styles={customStyles} classNamePrefix="select" value={selectedValue} onChange={(value: any) => onLookupChange(value)} />                      
                    {/* <SelectField values={lithologyLookupsOptions} value={lithologyLexiconId ?? undefined} onChange={(value: any) => setCode(value)} /> */}
                    </td>
                </tr>
              </tbody>
            </table>
              
                  
            
            <button className="button mr-2" onClick={onAdd}>Add</button>
            
          </div>       
)}

export default LithologyAdd;