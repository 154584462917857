import React from 'react';
import './panel.scss';

const Panel = (props: any) => {

    const {
        title,
        icon,
        children,
        leftPanelOffset
    } = props;    

return(
<div className="left-panel-container" style={{width: leftPanelOffset ?? 300}}>
    <div className="popout-panel-heading">{icon && <img width="24" style={{position: "relative", top: "3px"}} src={`/images/icons/${icon}.svg`} /> } {title}</div>
        <div className="panel-contents">
            {children}
        </div>
   </div>
)};

export default Panel;