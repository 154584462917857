import './radio-field.scss';

const RadioField = (props: any) => {

    const { 
            label, 
            errors,
            checked,
            info,
            onChange,
            readOnly,
            group,
            className
             } = props;

  return (
    <div className={` ${className}`}>
      
        <label className="radio-field">
          <div className=''>
            <input className='radio-field-circle' type="radio" radioGroup={group} checked={checked} onChange={(e) => onChange(e.target.checked)} disabled={readOnly}/> 
            </div>
            <div >
              {label}
              {info && <div className='is-size-7 mb-2'>{info}</div>}
              {errors?.length > 0 && errors.map((e: any, index: number) => <p key={index} className="help is-danger">{e}</p>)}
            </div>
       </label> 
    </div>
  )
}

export default RadioField;