import React from 'react';

const PointStyles = (props: any) => {
      const { style, pointColor } = props;

    switch(style) {
      case 'circle':
        return <g transform={`translate(5, 5)`}>
              <circle r={5} fill={pointColor} stroke="none"/>
          </g>
        case 'cross':
          return <g>          
                        <line x1="5" y1="0" x2="5" y2="10" stroke={pointColor} strokeWidth="2" />
                        <line x1="0" y1="5" x2="10" y2="5" stroke={pointColor} strokeWidth="2" />
                    </g>                
         case 'triangleUp':
           return <path d="M0,-6.204032394013997L5.372849659117709,3.1020161970069986L-5.372849659117709,3.1020161970069986Z" transform="translate(5,8)" fill={pointColor}></path>
        case 'triangleDown':
            return <path d="M0,-6.204032394013997L5.372849659117709,3.1020161970069986L-5.372849659117709,3.1020161970069986Z" transform="translate(5,3) rotate(180)" fill={pointColor}></path>
        case 'diamond':
            return <path d="M0,-6.580370064762462L3.7991784282579624,0L0,6.580370064762462L-3.7991784282579624,0Z" transform="translate(5,8)" fill={pointColor}></path>
        case 'square':
            return <path d="M-4.47213595499958,-4.47213595499958h8.94427190999916v8.94427190999916h-8.94427190999916Z" transform="translate(5,5)" fill={pointColor}></path>
        default:
            return null;
    }
}

export default PointStyles;