import React, { useEffect, useState } from 'react';
import MfaForm from './form';
import { getAuthenticatorKey, enableTwoFactorAuthentication } from '../../../services/accountSlice';
import Loader from '../../../components/loader';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../app/store';

const EnableTwoFactorContainer = () => {
        
    const dispatch: AppDispatch = useDispatch();

    useEffect(() => {
          dispatch(getAuthenticatorKey());        
      }, []);
    
    const [authenticatorCode, setAuthenticatorCode] = useState<string>('');
    
    useEffect(() => {
        if (authenticatorCode?.length >= 6) {
            onHandleSubmit();
        }   
    }, [authenticatorCode]);

    const selectAccount = (state: RootState) => state.account;
    const { loading,
            error,
            user,            
            authenticatorKey,
            authenticatorKeyUrl,
            recoveryCodes,
            twoFactorDeadline
          } = useSelector(selectAccount);

    const onHandleSubmit = () => {            
        dispatch(enableTwoFactorAuthentication(authenticatorCode));
    }    

    return (
        <>
            {loading && <Loader/>}
            {/* {error && <Errors error={{ data: { errors: error}}} />}   */}
            <MfaForm  
                handleSubmit={onHandleSubmit}                
                authenticatorKey={authenticatorKey} 
                authenticatorKeyUrl={authenticatorKeyUrl}
                authenticatorCode={authenticatorCode}
                setAuthenticatorCode={setAuthenticatorCode}
                recoveryCodes={recoveryCodes}
                error={error}
                twoFactorDeadline={twoFactorDeadline} />
        </>
    )
    }
    
export default EnableTwoFactorContainer;