import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import getConfig from "../app/config";

export const accountApi = createApi({
  reducerPath: 'accountApi',
  baseQuery: fetchBaseQuery({ baseUrl: getConfig().apiUrl }),
  tagTypes: ['account'],
  endpoints: (builder) => ({
    login: builder.mutation<any, { emailAddress: string, password: string }>({      
      query: (arg) => ({
        url: 'account/login',
        method: 'POST',
        body: arg,
      })      
    })
  }),
})

export const { useLoginMutation } = accountApi;