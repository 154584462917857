import React, { useState } from 'react';
import ChangePasswordForm from './form';
import { changePassword } from '../../../services/accountSlice';
import Loader from '../../../components/loader';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../app/store';
import auth from "../../../components/auth";
import Errors from '../../../components/errors';

const ChangePasswordContainer = () =>{

    const [currentPassword, setCurrentPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmNewPassword, setConfirmNewPassword] = useState<string>('');    
    const [currentPasswordErrors, setCurrentPasswordErrors] = useState<string[] | null>(null);
    const [confirmNewPasswordErrors, setConfirmNewPasswordErrors] = useState<string[] | null>(null);
    const [newPasswordErrors, setNewPasswordErrors] = useState<string[] | null>(null);
    
    const dispatch: AppDispatch = useDispatch();

    const selectAccount = (state: RootState) => state.account;
    const { loading,
            error,
            user,
            connection            
          } = useSelector(selectAccount);

    let history = useHistory();

    //const [login, { isLoading }] = useLoginMutation();

    const onSetCurrentPassword = (value: any) => {
        setCurrentPassword(value);
    }

    const onSetNewPassword = (value: any) => {
        setNewPassword(value);
    }

    const onSetConfirmNewPassword = (value: any) => {
        setConfirmNewPassword(value);
    }

    const onHandleSubmit = () => {
        dispatch(changePassword({ currentPassword, newPassword, confirmNewPassword }))
         .then((response: any) => {
            if (response.payload.success) {
                setCurrentPasswordErrors(null);
                setConfirmNewPasswordErrors(null);
                history.push(`/client/${response.payload.clientId}/project`)
            } else {
                setNewPasswordErrors(response.payload["NewPassword"]);
                setCurrentPasswordErrors(response.payload["CurrentPassword"]);
                setConfirmNewPasswordErrors(response.payload["ConfirmNewPassword"]);    
            }
           })
           .catch((response: any) => { 
                setCurrentPasswordErrors(['Current Password incorrect']);
                setConfirmNewPasswordErrors(['New passwords do not match']);    
           });
    }

    return (
        <>
            {loading && <Loader/>}
            {/* {error && <Errors error={{ data: { errors: error}}} />}   */}
            <ChangePasswordForm  
                currentPassword={currentPassword}
                setCurrentPassword={onSetCurrentPassword}
                newPassword={newPassword}
                setNewPassword={onSetNewPassword}
                confirmNewPassword={confirmNewPassword}
                setConfirmNewPassword={onSetConfirmNewPassword}
                handleSubmit={onHandleSubmit}
                currentPasswordErrors={currentPasswordErrors}
                confirmNewPasswordErrors={confirmNewPasswordErrors}
                newPasswordErrors={newPasswordErrors} />
        </>
    )
    }
    
export default ChangePasswordContainer;