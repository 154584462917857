import Group from "./group";
import AddGroup from "./add-group";
import { useState } from 'react';

const ListGroups = (props: any) => {

  const {       
      groups,      
      addGroup,
      deleteGroup,
      deleteTrack,
      deleteTracks,
      deleteAllTracks,
      projectId,
      clientId,
      addTracks,
      updateTrackOrder,
      changeGroupName,
      changeGroupColor
  } = props;    

  const onAddGroup = (groupName: string) => {      
    addGroup(groupName);
  };

  const [selectedGroup, setSelectedGroup] = useState<any>();

  const onSetSelectedGroup = (groupId: any) => {
    setSelectedGroup(groupId);
  };
    
  const boreholes = groups.filter((g: any) => g.boreholeId).map((borehole: any) => {return {id: borehole.boreholeId, name: borehole.boreholeName, projectName: borehole.projectName }});

return(
  <div>
      
              <div>
                {groups.map((group: any, index: number) => (                  
                     <Group 
                        key={group.groupId} 
                        boreholeId={group.boreholeId}
                        clientId={clientId}
                        projectId={projectId}
                        name={group.groupName} 
                        color={group.color} 
                        isSelected={selectedGroup === group.groupId} 
                        id={group.groupId.toString()} 
                        index={index} 
                        deleteGroup={deleteGroup} 
                        tracks={group.tracks} 
                        deleteTrack={deleteTrack}
                        deleteTracks={deleteTracks}
                        deleteAllTracks={deleteAllTracks}
                        addTracks={addTracks}
                        boreholes={boreholes}
                        updateTrackOrder={updateTrackOrder}
                        changeGroupName={changeGroupName}
                        changeGroupColor={changeGroupColor}
                        setSelectedGroup={onSetSelectedGroup} />
                    ))}                            
              </div>
          
      <div className='mt-2'>
        <AddGroup addGroup={onAddGroup} />
      </div>
    </div>
)};

export default ListGroups;