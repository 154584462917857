import React, { useEffect, useState } from 'react';
import Errors from '../../../components/errors';
import Loader from '../../../components/loader';

import Form from './form';

const LookupContainer = ({ title, lithologyLookup, onSave, loading, saving, error, readOnly }:any) => {
    
    const [code, setCode] = useState();    
    const [color, setColor] = useState();    
        
    useEffect(() => {
        if (!loading && lithologyLookup) {
            setColor(lithologyLookup.color);
            setCode(lithologyLookup.code);
        }
    },[loading, lithologyLookup]);

    const onUpdateLithologyLookup = (e: any) => {
        e.preventDefault();
        onSave({ color });
      };

    return (
        <section className="section">
            <div>
                <h1>{title}</h1>
                <Errors error={error}/>
                {(loading || saving) && <Loader/>}

                <Form code={code}                      
                    setCode={setCode}                       
                    color={color}
                    setColor={setColor}
                    onSaveLookup={onUpdateLithologyLookup}
                    />
                
            </div>
        </section>
    )    
};

export default LookupContainer;