import { confirmAlert } from 'react-confirm-alert'; 
import { toast } from 'react-toastify';
import Errors from '../../../components/errors';
import Loader from '../../../components/loader';
import { useGetClientsQuery, useDeleteMutation } from '../../../services/clientApi';
import List from './list';

const ListClients = ({}:any) => {
    
    const { data: clients, isLoading, error } = useGetClientsQuery();
    const [deleteUser, { isLoading: isLoadingDelete, error: deleteError }] = useDeleteMutation();
    console.log("deleteError", deleteError);

    const onDelete = (clientId: string, clientName: string) => {
        confirmAlert({
            title: 'Confirm Delete Client',
            message: `Are you sure you want to delete client ${clientName}?`,
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                    deleteUser({clientId})
                    .unwrap()
                        .then((response: any) => {
                            toast.success("Successfully deleted client");
                        })
                        .catch((error) => { toast.error("Error deleting client") });
                }
              },
              {
                label: 'No',
                onClick: () => {}
              }
            ]
          });        
    };
            
    return (
        <section className="section">
            <div>
                <h1>Clients</h1>              
                <Errors error={deleteError || error}  />
                {(isLoading || isLoadingDelete) && <Loader/>}                
                <List clients={clients?.clients} onDelete={onDelete} canAdd={clients?.canAdd} canEdit={clients?.canEdit} canDelete={clients?.canDelete} />
            </div>
        </section>
    )    
};

export default ListClients;