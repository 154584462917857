import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { RootState } from '../../app/store';
import './admin-menu.scss';

const AdminMenu = () => {

const selectAccount = (state: RootState) => state.account;
  const { 
    user
  } = useSelector(selectAccount);

    return (
        <div className="admin-menu">
            <div className="settings-heading">Admin</div>
            {user?.isUserAdmin && user?.isRoleAdmin && <div className="settings-sub-heading">Permissions</div>}

            {user?.isUserAdmin && <div >
             <NavLink  to={`/admin/user/list`} isActive={(match, location) => {
                  return location.pathname.startsWith(`/admin/user`) && !location.pathname.startsWith(`/admin/user/logins`);
                }}>Users</NavLink>
             </div>}
            {user?.isRoleAdmin && <div >
              <NavLink  to={`/admin/role/list`} isActive={(match, location) => {
                  return location.pathname.startsWith(
                    `/admin/role`
                  );
                }}>Roles</NavLink>
               </div>}
            {user?.canViewLoginAudit && <div >
              <NavLink to={`/admin/user/logins`} isActive={(match, location) => {
                  return location.pathname.startsWith(
                    `/admin/user/logins`
                  );
                }}>Login Audit</NavLink>
               </div>}

            <div className="settings-sub-heading">Client Area</div>
            {user?.isClientAdmin && <div>
                <NavLink to={`/admin/client/list`} isActive={(match, location) => {
                  return location.pathname.startsWith(
                    `/admin/client`
                  );
                }}>Clients</NavLink>
            </div>}

            {user?.canViewJobsForAllClients && <div>
                <NavLink to={`/admin/job/list-all-clients`} isActive={(match, location) => {
                  return location.pathname.startsWith(
                    `/admin/job/list-all-clients`
                  );
                }}>All Clients Jobs</NavLink>
            </div>}

            {user?.isTrackTypeAdmin && <div >
                <NavLink to={`/admin/track-type/list`} isActive={(match, location) => {
                  return location.pathname.startsWith(
                    `/admin/track-type`
                  );
                }}>Catalog</NavLink>
            </div>}
            {user?.isImageTypeAdmin && <div>
                <NavLink to={`/admin/image-type/list`} isActive={(match, location) => {
                  return location.pathname.startsWith(
                    `/admin/image-type`
                  );
                }}>Image Types</NavLink>
            </div>}
            <div><NavLink to={`/admin/lithology/lexicon/list`} isActive={(match, location) => {
                  return location.pathname.startsWith(
                    `/admin/lithology/lexicon`
                  );
                }}>Lithology Lexicon</NavLink></div>
            <div><NavLink to={`/admin/lithology/lookup/list`} isActive={(match, location) => {
                  return location.pathname.startsWith(
                    `/admin/lithology/lookup`
                  );
                }}>Lithology Lookup</NavLink></div>
        </div>
    )
}

export default  AdminMenu;