import React from 'react';
import { useHistory, useParams  } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useUpdateRolesMutation, useGetUserRolesQuery } from '../../../services/userApi';
import Container from './container';

const EditUserRoles = ({}:any) => {
    const { userId } = useParams<any>();
    const { data: userRoles, isLoading: userIsLoading, error } = useGetUserRolesQuery({ userId });    
    const [ update, { isLoading, error: addError }] = useUpdateRolesMutation();

    let history = useHistory();

    const onUpdateUser = (user: any) => {        
        update({...user, userId })
        .unwrap()
        .then((response: any) => {
            toast.success("Successfully updated user roles");
            history.push(`/admin/user/list`);
         })
         .catch((error) => { toast.error("Error updating user roles") });
      };

    return (
        <Container title={`Edit User's Roles`}
            userRoles={userRoles} 
            userIsLoading={userIsLoading} 
            onSave={onUpdateUser}
            isLoading={isLoading}
            error={{...error, ...addError}} />
    )    
};

export default EditUserRoles;