import React from 'react';
import Panel from '../panel';

const XAxisPanel = (props: any) => {
    const {
      showValueAxisAnnotation,
      setShowValueAxisAnnotation
    } = props;        
    
return(
  <Panel title="X Axis" icon="Atlas X Axis Icon">
    <div className="panel-section">
      <h4>X Axis</h4>
      <div className="field">
        <label className="checkbox">
          <input type="checkbox" className="checkbox" checked={showValueAxisAnnotation}  onChange={(e) => setShowValueAxisAnnotation(e.target.checked)} /> Show Value Annotations
        </label>
      </div>
    </div>
  </Panel>
)};

export default XAxisPanel;