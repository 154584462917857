import { useEffect, useRef, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert'; 
import DeleteIcon from '../../../components/icons/delete-icon';
import PencilIcon from '../../../components/icons/pencil-icon';

const Row = ({ lexicon, updateLithologyLexicon, addLithologyLexicon, deleteAddingRow, deleteLithologyLexicon }:any) => {

    const [editMode, setEditMode] = useState(false);
    const [code, setCode] = useState("");
    const [description, setDescription] = useState("");
    const [codeError, setCodeError] = useState(false);

    const codeRef = useRef<any>();
    const descriptionRef = useRef<any>();

    useEffect(() => {
        if (lexicon) {
            setCode(lexicon.code);
            setDescription(lexicon.description);
        }
    },
    [lexicon]);

    useEffect(() => {
        if (lexicon?.editMode) {
            setEditMode(true);            
        }
    },
    [lexicon?.editMode]);

    useEffect(() => {        
        if (editMode && codeRef.current) {
            if (document.activeElement !== descriptionRef.current) {
                codeRef.current.focus();
            }            
        }
    },
    [editMode, codeRef?.current]);

    const onEdit = () => {
        setCodeError(false);
        setEditMode(true);
    };

    const onChangeCode = (newCode: string) => {
        setCode(newCode);
    }

    const onChangeDescription = (newCode: string) => {
        setDescription(newCode);
    }

    const onSave = () => {
        setCodeError(false);
        if (lexicon.id) {
            updateLithologyLexicon({ ...lexicon, code, description })
                .unwrap()
                .then(() => { setEditMode(false); })
                .catch(() => { setCodeError(true); });
        } else {
            addLithologyLexicon({ code, description })
                .unwrap()
                .then(() => { setEditMode(false); })
                .catch(() => { setCodeError(true); });
        }
    }

    const onCancel = () => {        
        setEditMode(false);
        if (!lexicon.id) {
            deleteAddingRow();
        } else {
            setCode(lexicon.code);
            setDescription(lexicon.description);
        }
    }

    const onDelete = () => {
        confirmAlert({
            title: 'Confirm Delete Lithology Lexicon',
            message: `Are you sure you want to delete ${code}?`,
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                  deleteLithologyLexicon(lexicon.id);
                }
              },
              {
                label: 'No',
                onClick: () => {}
              }
            ]
          });
    }

    return (    
        <tr className='editable-row'>                
            <td>{editMode && <input type="text" placeholder="Code" ref={codeRef} className={`input ${codeError ? "is-danger" : ""}`} value={code} onChange={(e) => onChangeCode(e.target.value)} />} {!editMode && <p>{code}</p>}</td>
            <td>{editMode && <input type="text" placeholder="Description" ref={descriptionRef} className={`input`} value={description} onChange={(e) => onChangeDescription(e.target.value)} />} {!editMode && <p>{description}</p>}</td>
            <td>
                {!editMode && <span className='edit-icons'><PencilIcon className="icon" style={{width: "20px"}} onClick={onEdit} /> <DeleteIcon className="icon" style={{width: "20px"}} onClick={onDelete} /> </span>}
                {editMode && <div className="row-buttons">
                    <button className='button is-primary mr-2' onClick={onSave}><span className="icon"><i className="fas fa-check" title="Ok" aria-hidden="true"></i></span></button>
                    <button className='button' onClick={onCancel}><i className="fas fa-times" title="Ok" aria-hidden="true"></i></button>
                </div>}
            </td>
        </tr>           
    )    
};

export default Row;