import React, { useEffect, useState } from 'react';
import LoginForm from './form';
import { login, 
         loginWithClient,
         loginWithSkipEnableTwoFactor,
         connectHub, 
         loginWithMfa, 
         loginWithRecovery, 
         enableTwoFactorAuthentication, 
         getAuthenticatorKeyWithToken,
         loginWithEnableTwoFactor,
         loginWithAuthenticatorToken, 
         changeTwoFactorAuthenticationWithRecoveryCode} from '../../../services/accountSlice';
import { clearFilters } from '../../../services/boreholesSlice';
import Loader from '../../../components/loader';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../app/store';
import EnableTwoFactorForm from "../enable-two-factor/form";

const LoginContainer = (props: any) =>{

    const {loggedOut} = props;
    const [emailAddress, setEmailAddress] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [authenticatorCode, setAuthenticatorCode] = useState<string>('');
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [loginError, setLoginError] = useState<any>();
    const [showClientList, setShowClientList] = useState(false);
    const [showMfa, setShowMfa] = useState(false);
    const [showRecoveryMfa, setShowRecoveryMfa] = useState(false);
    const [recoveryAuthenticatorCode, setRecoveryAuthenticatorCode] = useState<string>('');
    const [clientList, setClientList] = useState([]);
    const [showEnableMfa, setShowEnableMfa] = useState(false);

    const dispatch: AppDispatch = useDispatch();

    const selectAccount = (state: RootState) => state.account;
    const { 
            loading,
            choseClientToken,
            authenticatorToken,
            error,
            authenticatorKey,
            authenticatorKeyUrl,
            recoveryCodes,
            canSkipTwoFactor,
            twoFactorDeadline,
            remainingRecoveryCodes
          } = useSelector(selectAccount);

    let history = useHistory();

    useEffect(() => {        
        if (authenticatorCode?.length >= 6) {                        
            onValidateAuthenticatorCode();
        }
    },[authenticatorCode]);

    const location: any = useLocation();
    const originalLocation = location.state?.from;
    
    const onSetEmailAddress = (value: any) => {
        setEmailAddress(value);
    }

    const onSetPassword = (value: any) => {
        setPassword(value);
    }

    const onsetShowRecoveryMfa = () => {
        setShowRecoveryMfa(true);
        setLoginError(null);
    }    

    const onHandleSubmit = () => {        
        if (showMfa) {            
            if (showRecoveryMfa) {
                onValidateRecoveryCode();
            } else {
                onValidateAuthenticatorCode();
            }            
        } else {            
            dispatch(login({ emailAddress, password }))
            .then((response: any) => {                
                if (response.payload.success) {                
                    postLogin(response);
                } else {
                    //setLoginError(response.payload);
                    setPasswordError(true);    
                }
            })
            .catch((response: any) => { 
                setEmailError(true);
                setPasswordError(true);
            });
        }
    }

    const onValidateAuthenticatorCode = () => {                
        if (showEnableMfa) {
            dispatch(loginWithEnableTwoFactor({authenticatorCode, token: authenticatorToken }));
        } else {
            setLoginError(null);
            
            dispatch(loginWithMfa({ authenticatorCode, token: authenticatorToken }))
            .then((response: any) => {            
                if (response.payload.success) {                        
                    postLogin(response);
                } else {                
                    setLoginError({errors : response.payload.errors});
                }
            })
            .catch((response: any) => { 
                setEmailError(true);
                setPasswordError(true);
            });
        }
    };

    const postLogin = (response: any) => {
        setEmailError(false);
        setPasswordError(false);
        setLoginError(null);
        setShowMfa(false);
        setAuthenticatorCode("");
        setRecoveryAuthenticatorCode("");        
        if (response.payload.redirectToTwoFactor) {
            dispatch(clearFilters());
            dispatch(connectHub());
            setShowEnableMfa(true);
            dispatch(getAuthenticatorKeyWithToken({token:response.payload.token}));
        } else if (response.payload.choseClient) {
            setShowClientList(true);
            setShowEnableMfa(false);
            setClientList(response.payload.clients);
        } else if (response.payload.mfa) {
            setShowMfa(true);
        } else {
            dispatch(clearFilters());
            dispatch(connectHub());            
            if (originalLocation) {
                history.push(originalLocation);
            } else {
                history.push(`/`);
            }
        }
    }

    
    const onValidateRecoveryCode = () => {
        setLoginError(null);       

        dispatch(loginWithRecovery({ recoveryCode: recoveryAuthenticatorCode, token: authenticatorToken, changeTwoFactor: false }))
         .then((response: any) => {            
            if (response.payload.success) {
                postLogin(response);
            } else {                
                setLoginError({errors : response.payload.errors});
            }
           })
           .catch((response: any) => { 
            setEmailError(true);
            setPasswordError(true);
           });
    };

    const onLoginWithRecoverAndChangeTwoFactor = () => {        
        dispatch(loginWithRecovery({ recoveryCode: recoveryAuthenticatorCode, token: authenticatorToken, changeTwoFactor: true }))
        .then((response: any) => 
        {            
            if (response.payload.success) {
                dispatch(getAuthenticatorKeyWithToken({token:response.payload.token}));
                setShowEnableMfa(true);
            } else {                
                setLoginError({errors : response.payload.errors});
            }
        });
    }

    const selectClient = (clientId: string) => {    
        
        dispatch(loginWithClient({ clientId, token: choseClientToken }))
        .then((response: any) => {
           if (response.payload.success) {
                setEmailError(false);
                setPasswordError(false);
                setLoginError(null);
                dispatch(clearFilters());
               dispatch(connectHub());               
               if (originalLocation) {
                    history.push(originalLocation);
               } else {
                   history.push(`/`);
               }
           } else {
               setLoginError(response.payload);
               setPasswordError(true);
           }
          })
          .catch((response: any) => { 
           setEmailError(true);
           setPasswordError(true);
          });
    };
    
    const onHandleAuthenticatorSubmit = () => {
        dispatch(loginWithEnableTwoFactor({authenticatorCode, token: authenticatorToken }));
    }  

    const onContinueTwoFactor = () => {        
        dispatch(loginWithAuthenticatorToken({token: authenticatorToken }))
         .then((response: any) => {    
            if (response.payload.success) {                
                postLogin(response);
            } else {                
                setLoginError({errors : response.payload.errors});
            }
           })
           .catch((response: any) => { 
            setEmailError(true);
            setPasswordError(true);
           });     
    }  
    
    const onSkipTwoFactor = () => {
        setShowEnableMfa(false);
        dispatch(loginWithSkipEnableTwoFactor({token: authenticatorToken}))
        .then((response: any) => {            
            if (response.payload.success) {                
                postLogin(response);
            } else {                
                setLoginError({errors : response.payload.errors});
            }
           })
           .catch((response: any) => { 
            setEmailError(true);
            setPasswordError(true);
           });        
    };



    return (
        <>
            {loading && <Loader/>}
            {!showEnableMfa &&  <LoginForm emailAddress={emailAddress}
                setEmailAddress={onSetEmailAddress}
                password={password}
                setPassword={onSetPassword}
                handleSubmit={onHandleSubmit}
                emailError={emailError}
                passwordError={passwordError}
                loginError={loginError}
                loggedOut={loggedOut}
                showMfa={showMfa}
                showRecoveryMfa={showRecoveryMfa} 
                setShowRecoveryMfa={onsetShowRecoveryMfa}
                showClientList={showClientList}
                clientList={clientList}
                selectClient={selectClient}
                authenticatorCode={authenticatorCode} 
                setAuthenticatorCode={setAuthenticatorCode}
                recoveryAuthenticatorCode={recoveryAuthenticatorCode} 
                setRecoveryAuthenticatorCode={setRecoveryAuthenticatorCode}
                onLoginWithRecoverAndChangeTwoFactor={onLoginWithRecoverAndChangeTwoFactor} 
                remainingRecoveryCodes={remainingRecoveryCodes} />}

        {showEnableMfa && <EnableTwoFactorForm  
                handleSubmit={onHandleAuthenticatorSubmit}                
                authenticatorKey={authenticatorKey} 
                authenticatorKeyUrl={authenticatorKeyUrl}
                authenticatorCode={authenticatorCode}
                setAuthenticatorCode={setAuthenticatorCode}
                recoveryCodes={recoveryCodes}
                error={error}
                canSkip={canSkipTwoFactor}
                onSkip={onSkipTwoFactor}
                showContinue={true}
                onContinue={onContinueTwoFactor}
                twoFactorDeadline={twoFactorDeadline}
                atLogin={true} />}
        </>
    )
    }
    
export default LoginContainer;