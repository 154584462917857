import React from "react";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import CurveOrderItem from "./curve-order-item";

const CurveOrder = ({ curves, setDialogCurves }: any) => {

    const onDragEnd = (result: DropResult) => {
        const { source, destination } = result;
    
        if (!destination) {
          return;
        }
    
        moveCurve(source.index, destination.index);
      };

      const moveCurve = (dragIndex: any, hoverIndex: any) => {  
        if (dragIndex !== null && hoverIndex !== null) {   
          const curvesCopy = reorderCurves(curves, dragIndex, hoverIndex);
          setDialogCurves(curvesCopy);  
        }
      };
      
      const reorderCurves = (list: any[], sourceIndex: number, destinationIndex: number) => {
        const result: any[] = list.map((a: any) => {return {...a}})
        //const result: any[] = Array.from(list);
        result[sourceIndex].displayOrder = destinationIndex + 1;
      
        if (sourceIndex > destinationIndex) {
          for (let i = destinationIndex; i < sourceIndex; i++) {
            result[i].displayOrder++;
          }
        }
      
        if (sourceIndex < destinationIndex) {
          for (let i = sourceIndex + 1; i <= destinationIndex; i++) {
            result[i].displayOrder--;
          }
        }
      
        const [removed] = result.splice(sourceIndex, 1);
        result.splice(destinationIndex, 0, removed);
      
        return result;
      };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={"trackorder"}>
            {(provided: any) => (
            <div ref={provided.innerRef}
            {...provided.droppableProps}>
                {(curves || []).map((curve: any, index: number) => (
                    <CurveOrderItem key={curve.id} curve={curve} index={index} />
                ))}
                {provided.placeholder}
            </div>
            )}
        </Droppable>
        </DragDropContext>      
    )
}

export default CurveOrder;