import Moment from "react-moment"
import { Link } from "react-router-dom"
import moment from 'moment';
import { format } from 'date-fns';
import './BoreholeListView.scss';
import LogViewerIcon from  '../../components/icons/log-viewer-icon';
import ImageViewerIcon from  '../../components/icons/image-viewer-icon';
import FileIcon from  '../../components/icons/file-icon';
import EditIcon from  '../../components/icons/pencil-icon';
import CheckboxField from "../../components/Input/checkbox-field";
import { useState } from "react";
import { forEach } from "lodash";

export default function BoreholesList({boreholes, 
                                       canEdit, 
                                       canAccessFiles, 
                                       clientId, 
                                       tableSort, 
                                       sortColumn, 
                                       sortAscending, 
                                       moreToLoad, 
                                       loadMore,
                                       showSelection,
                                       selectedBoreholeIds, 
                                       setSelectedBoreholeIds,
                                       loading }: any) { 
    const ProjectSortColumn = "ProjectName";
    const BoreholeNameSortColumn = "BoreholeName";
    const BoreholeLengthSortColumn = "BoreholeLength";
    const LastEditedSortColumn = "LastEdited";
    const StatusSortColumn = "Status";
    const DateCreatedSortColumn = "DateCreated";

    const getIcons = (column: string) => {
        return (
        <>
            {sortColumn === column ? sortAscending ? <img src="/images/icons/Sorting Arrow Asc.svg" className="sort-icon" /> : <img src="/images/icons/Sorting Arrow Desc.svg" className="sort-icon" /> : <></>}
        </>
        )
    };
    

    const getAllSelected = () => {
        const keys = Object.keys(selectedBoreholeIds);
        if (keys.length < boreholes?.length) {
            return false;
        }
               
        for (var i = 0; i < keys.length; i ++)  {
            if (!selectedBoreholeIds[keys[i]]) {                
                return false;                
            }
        }       

        return true;
    };

    const selectAllBoreholes = () => {           
        const allSelected = getAllSelected();
        let data = {...selectedBoreholeIds};
        boreholes.forEach((borehole: any) => { data[borehole.id] = !allSelected; });        
        setSelectedBoreholeIds(data);
    };

    const onChangeSelection = (boreholeId: any) => {
        if (selectedBoreholeIds[boreholeId]) {            
            let data = {...selectedBoreholeIds};
            data[boreholeId] = false;
            setSelectedBoreholeIds(data);
        } else {
            let data = {...selectedBoreholeIds};
            data[boreholeId] = true;
            setSelectedBoreholeIds(data);
        }        
    };

    return(
        <div>
            <table className="geotek-table">
                <thead>
                    <tr>
                        {showSelection && <th><CheckboxField onChange={() => selectAllBoreholes()} checked={getAllSelected()}/></th>}
                        <th onClick={() => tableSort(ProjectSortColumn)}><div className="borehole-list-header">Project Name {getIcons(ProjectSortColumn)}</div></th>
                        <th onClick={() => tableSort(BoreholeNameSortColumn)}><div className="borehole-list-header">Borehole Name {getIcons(BoreholeNameSortColumn)}</div></th>
                        <th onClick={() => tableSort(BoreholeLengthSortColumn)}><div className="borehole-list-header">Borehole Length {getIcons(BoreholeLengthSortColumn)}</div></th>
                        <th onClick={() => tableSort(LastEditedSortColumn)}><div className="borehole-list-header">Last Edited {getIcons(LastEditedSortColumn)}</div></th>
                        <th onClick={() => tableSort(StatusSortColumn)}><div className="borehole-list-header">Stage {getIcons(StatusSortColumn)}</div></th>
                        <th onClick={() => tableSort(DateCreatedSortColumn)}><div className="borehole-list-header">Date Created{getIcons(DateCreatedSortColumn)}</div></th>
                        <th className="data-viewer-columns">Data Viewer</th>
                    </tr>
                </thead>
                <tbody>
                {boreholes?.length == 0 && <div className="mt-4">None found</div>}
                {(boreholes || []).map((borehole: any) => { return (                    
                    <tr key={borehole.id}>
                        {showSelection && <td><CheckboxField onChange={() => onChangeSelection(borehole.id)} checked={selectedBoreholeIds[borehole.id]}/></td>}
                        <td className="project-edit">{borehole.projectName} 
                            <Link to={`/client/${clientId}/project/${borehole.projectId}`}><i className="fas fa-pencil-alt data-viewer-column" title="Edit Project" aria-hidden="true"></i></Link>
                        </td>
                        <td>{borehole.name}</td>
                        <td>{borehole.maxBoreholeDepth}{borehole.maxBoreholeDepth ? "m" : <>&nbsp;</>}</td>
                        <td>{borehole.updated && format(new Date(borehole.updated), 'dd/MM/yyyy HH:mm:ss')}</td>
                        <td>{borehole.statusDescription}</td>
                        <td>{borehole.created && format(new Date(borehole.created), 'dd/MM/yyyy HH:mm:ss')}</td>
                        <td>
                            {borehole.movingImages && <div>Moving images</div>}
                            {!borehole.movingImages && <div className="columns data-viewer-columns">                                
                                <div className="column">
                                    {(borehole.deepZoomImagesGenerated >= 1) &&                                         
                                        <Link to={`/client/${clientId}/project/${borehole.projectId}/borehole/${borehole.id}/image-viewer`}><ImageViewerIcon className="list-icon" /></Link>
                                    }   
                                </div>
                                <div className="column">
                                    {(borehole.maxBoreholeDepth && (borehole.scalesGenerated >= 1 || borehole.hasScaledData)) &&                                
                                        <Link to={`/client/${clientId}/project/${borehole.projectId}/borehole/${borehole.id}/log-viewer`}><LogViewerIcon className="list-icon" /></Link>
                                    }
                                </div>
                                <div className="column">
                                    {canEdit &&                                 
                                        <Link to={`/client/${clientId}/project/${borehole.projectId}/borehole/${borehole.id}/edit`}><EditIcon className="list-icon" /></Link>
                                    }
                                </div>
                                <div className="column">
                                    {canAccessFiles &&                                         
                                        <Link to={`/client/${clientId}/project/${borehole.projectId}/borehole/${borehole.id}/files`}><FileIcon className="list-icon" /> </Link>
                                }              
                                </div>
                            </div>}
                        </td>
                    </tr> 
                    )}
                )}
                </tbody>
            </table>
            {moreToLoad && <button className={`button mb-4 ${loading ? 'is-loading' : ''}`} onClick={loadMore}>Load More</button>}
        </div>
    )
}