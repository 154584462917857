import { useState } from "react";
import Panel from "../../../components/panels/panel";
import ProjectNameFilter from "./project-name-filter";
import BoreholeNameFilter from "./borehole-name-filter";
import DepthFilter from "./depth-filter";
import CreatedDateFilter from "./created-date-filter";
import LastEditedDateFilter from "./last-edited-date-filter";
import EquipmentFilter from "./equipment-filter";
import BoreholeStatusesFilter from "./borehole-statuses-filter";

export default function BoreholeFilters({ showLeftSidePanel,                                                                                     
                                          applyFilters, 
                                          clearFilters, 
                                          projectName, 
                                          setProjectName, 
                                          boreholeName, 
                                          setBoreholeName,
                                          minimumBoreholeDepth,
                                          maximumBoreholeDepth,
                                          minimumDepth,
                                          maximumDepth,
                                          setDepth,
                                          setCreatedDate,
                                          dateCreatedStart,
                                          dateCreatedEnd,
                                          setLastEditedDate,
                                          lastEditedStart,
                                          lastEditedEnd,                                          
                                          hasFilters,
                                          equipments,
                                          checkedEquipment,
                                          setCheckedEquipment,
                                          boreholeStatuses,
                                          checkedBoreholeStatuses,
                                          setCheckedBoreholeStatuses } : any) {        

    return (
        
            <Panel title="Filter Borehole">
                <ProjectNameFilter projectName={projectName} setProjectName={setProjectName}/>
                <BoreholeNameFilter boreholeName={boreholeName} setBoreholeName={setBoreholeName}/>
                <DepthFilter minimumBoreholeDepth={minimumBoreholeDepth}
                             maximumBoreholeDepth={maximumBoreholeDepth}
                             minimumDepth={minimumDepth}
                             maximumDepth={maximumDepth} 
                             onChange={setDepth}
                             showHandles={showLeftSidePanel}/>
                <CreatedDateFilter onChange={setCreatedDate} 
                                   dateCreatedStart={dateCreatedStart}
                                   dateCreatedEnd={dateCreatedEnd} />
                <LastEditedDateFilter onChange={setLastEditedDate} 
                                   lastEditedStart={lastEditedStart}
                                   lastEditedEnd={lastEditedEnd}/>
                <EquipmentFilter equipments={equipments} checkedEquipment={checkedEquipment} setCheckedEquipment={setCheckedEquipment} />
                <BoreholeStatusesFilter boreholeStatuses={boreholeStatuses}
                                          checkedBoreholeStatuses={checkedBoreholeStatuses}
                                          setCheckedBoreholeStatuses={setCheckedBoreholeStatuses} />
                <div>
                    <button className="button is-light mr-4 mt-4" onClick={applyFilters}>Apply Filter</button> 
                    <button className="button mt-4" onClick={clearFilters} disabled={!hasFilters}>Clear</button> 
                </div>
            </Panel>        
    )
}