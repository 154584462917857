import React from 'react';

const CheckboxListField = (props: any) => {

    const { 
            label, 
            values,
            checkedValues,
            errors,            
            onChange,
            readOnly
             } = props;

    const onChangeValuesList = (e:any) => {
      const value = e.target.value;
      if (checkedValues.includes(value)) {
        const newCheckValues = [...checkedValues].filter((id) => id !== value);
        onChange(newCheckValues);
      } else {
        const newCheckedValues = [...checkedValues];
        newCheckedValues.push(value);
        onChange(newCheckedValues);
      }
    }

  return (
      <div className="field">
        <label className="label">{label}</label>
        {(values || []).map((item: any, index: number) => (
          <div key={index}>
            <label>
                <input type="checkbox" value={item.value.toString()} onChange={(e) => onChangeValuesList(e)} checked={checkedValues?.includes(item.value.toString()) ? true : false}></input>&nbsp;  {item.name}
            </label>
          </div>
        ))}
      </div>
  )
}

export default CheckboxListField;