import Collapsible from "../../../components/panels/collapsible";

export default function CheckboxFilter({label, list, checkedList, onChangeList, onReset } : any) {    
    return (
        <Collapsible label={label} isSelected={checkedList?.length > 0} showReset={checkedList?.length > 0} reset={onReset}>
            <div>
            {(list || []).map((item: any, index: number) => (
                <div key={index}>
                      <label>
                          <input type="checkbox" value={item.id || item.value} onChange={onChangeList} checked={checkedList.includes((item.id || item.value)) ? true : false}></input>&nbsp;  {item.name}
                      </label>
                    </div>
                ))}
            </div>
        </Collapsible>
    )
}