import React from "react";
import { Switch } from "react-router-dom";
import { PrivateRoute } from "../../components/privateRoute";
import AddUser from './add-user/add-user';
import ListUser from './list-users/list-users';
import EditUser from './edit-user/edit-user';
import EditUserRoles from './roles/edit-user-roles';
import EditUserEmailAddress from './email-address/edit-email-address';
import LoginAudit from './login-audit/login-audit'

const UserRoutes = () => {
  return (    
    <Switch>
      <PrivateRoute exact path="/admin/user/add">
        <AddUser />        
      </PrivateRoute>
      <PrivateRoute exact path="/admin/user/edit/:userId">
        <EditUser />        
      </PrivateRoute>
      <PrivateRoute exact path="/admin/user/roles/:userId">
        <EditUserRoles />        
      </PrivateRoute>
      <PrivateRoute exact path="/admin/user/email-address/:userId">
        <EditUserEmailAddress />        
      </PrivateRoute>   
      <PrivateRoute exact path="/admin/user/list">
        <ListUser />        
      </PrivateRoute>
      <PrivateRoute exact path="/admin/user/logins">
        <LoginAudit />        
      </PrivateRoute>      
    </Switch>            
  );
};

export default UserRoutes;
