import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert'; 
import XScaleProperties from './xscale-properties';

const XScaleComponent = (props: any) => {

    const {        
        dialogShowGridlines,
        dialogScaleType,
        setDialogShowGridlines,
        setDialogScaleType,
        dialogCurves,
        setDialogCurves        
    } = props;

    const [selectedCurveId, setSelectedCurveId] = useState<string>();

    useEffect(() => {
        if (dialogCurves && dialogCurves.length > 0) {
          setSelectedCurveId(dialogCurves[0].id);
        }
      }, 
      [dialogCurves?.length]);

  const onSetCurveProperties = (curveId: any, curveProperties: any) => {
    let curveIndex = dialogCurves.findIndex((curve: any) => curve.id === curveId);
    let curve = dialogCurves[curveIndex];
    let c = dialogCurves.slice();
    c.splice(curveIndex, 1);
    const curvesCopy = [...c, {...curve, ...curveProperties}];
    curvesCopy.sort((a: any, b:any) => a.displayOrder - b.displayOrder);
    setDialogCurves(curvesCopy);
  };


  const onDeleteCurve = (curveId: any, displayName: string) => {
    if (dialogCurves.length > 1) {
      confirmAlert({
        title: 'Confirm Remove Curve',
        message: `Are you sure you want to remove curve ${displayName}?`,
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              deleteCurve(curveId);
            }
          },
          {
            label: 'No',
            onClick: () => {}
          }
        ]
      });
    }
  };
  
  const deleteCurve = (curveId: any) => {
    var curvesCopy = dialogCurves.slice();
    const index = curvesCopy.findIndex((curve: any) => curve.id === curveId);
    curvesCopy.splice(index, 1);
    setDialogCurves(curvesCopy);  
  }

const hasMultipleCurves = dialogCurves.length > 1;

return (

<div>
    <div>
      {(dialogCurves || []).map((curve: any, index: number) =>
      <div className="mb-4">
      <h4>{curve.displayName} {hasMultipleCurves && <span className="material-icons delete-icon" title="Delete" onClick={() => onDeleteCurve(curve.id, curve.displayName)}>delete</span>}</h4>
                <XScaleProperties
                  key={index}
                  id={curve.id}
                  curveProperties={curve}
                  trackTypeId={curve.trackTypeId}
                  setCurveProperties={onSetCurveProperties}
                  visible={true}
                />
                </div>
        )}
    </div>
  
    <label className="checkbox">
        <input type="checkbox" checked={dialogShowGridlines} onChange={(e) => setDialogShowGridlines(e.target.checked)} /> Gridlines
    </label>  
    <div>
    <nav className="level">
      <div className="level-left">
        <div className="level-item white">
          Scale
        </div>
        <div className="level-item">                  
          <div className="select">
            <select value={dialogScaleType} onChange={e => setDialogScaleType(e.target.value)}>
                <option value={0}>Linear</option>
                <option value={1}>Log</option>
            </select>
          </div>          
        </div>   
      </div>       
    </nav>   
    {dialogCurves.some((c: any) => c.logLessThaZero) && <span><span className="material-icons" title="Values less that zero are ignored">warning</span>Values less that zero are ignored</span>}         
    </div>
     
  </div>
)};

export default XScaleComponent;