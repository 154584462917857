import { useState } from 'react';
import { Link } from 'react-router-dom';
import Row from './row';

const List = ({ roles, onDelete, deleteMultiple, canAdd, canEdit, canView, canDelete, canListUsers }:any) => {

    const [checkedRows, setCheckedRows] = useState<any[]>([]);

    const onCheckRow = (id: string) => {
        const checkRowsCopy = Array.from(checkedRows);
        const foundIndex = checkRowsCopy.findIndex((r: any) => r == id);
        if (foundIndex >= 0) {
            checkRowsCopy.splice(foundIndex, 1);
        } else {
            checkRowsCopy.push(id);
        }

        setCheckedRows(checkRowsCopy);
    };

    const onCheckAll = () => {        
        if (checkedRows.length === roles.length) {     
            setCheckedRows([]);
        } else {
            const checkRowsCopy = Array.from(checkedRows);
            roles.forEach((user: any) => {
                const foundIndex = checkRowsCopy.findIndex((r: any) => r == user.id);
                if (foundIndex === -1) {                    
                    checkRowsCopy.push(user.id);
                }
            });

            setCheckedRows(checkRowsCopy);
        }
    };

    const onDeleteMultiple = () => {
        if (deleteMultiple(checkedRows)) {
            setCheckedRows([]);
        }
    }

    return (
            <div>              
                  <table className="geotek-table">
                      <thead>
                          <tr>
                              <th><input type="checkbox" onChange={onCheckAll} checked={checkedRows.length === roles?.length && roles?.length > 0}/></th>
                              <th>Name</th>
                              <th>Actions</th>
                          </tr>
                      </thead>
                      <tbody>
                       {(roles || []).map((role: any, index: number) => (
                           <Row key={index} 
                                            role={role}                             
                                            onDelete={onDelete}
                                            canAdd={canAdd} 
                                            canEdit={canEdit} 
                                            canView={canView} 
                                            canDelete={canDelete} 
                                            canListUsers={canListUsers}
                                            onCheckRow={onCheckRow}
                                            checkedRows={checkedRows} />
                        ))}
                      </tbody>
                  </table>
                  <div className="field is-grouped">
                    {canAdd && <div className="control">
                         <Link className="button" to={`add`}>Add Role</Link>
                    </div>}
                    {canDelete && 
                        <div className="control">
                            <button className="button is-primary" onClick={onDeleteMultiple} disabled={checkedRows.length === 0 || roles?.length === 0}>Delete Roles</button>
                        </div>}                   
                </div>
            </div>
    )    
};

export default List;