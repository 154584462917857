import React from 'react';

const SelectField = (props: any) => {

    const { 
            label,
            values,
            value,
            onChange,
            disabled,
            allowNull,
            readOnly
          } = props;

   const selected = values?.find((v :any) => v.value === value);   

return (
    <div className="field">
        {label && <label className="label">{label}</label>}
        {readOnly && <div>{selected?.name ? selected?.name : "None"}</div>}
        {!readOnly && <div className="select">
            <select value={value} onChange={(e: any) => onChange(e.target.value)} disabled={disabled || readOnly}>
                {allowNull && <option value={undefined}></option>  }
                {(values || []).map((item: any, index: number) => (
                    <option key={index} value={item.value}>{item.name}</option>  
                ))}
            </select>
        </div>}
    </div>
)
}

export default SelectField;