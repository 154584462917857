import Row from './row';
import './list.scss';

const ListTable = ({ lithologyLookups, updateLithologyLookup }: any) => {

    return (            
        <table className="table">
            <thead>
                <tr>
                    <th>Code</th>
                    <th>Color</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
            {(lithologyLookups || []).map((lookup: any, index: number) => (
                <Row key={lookup.id} lookup={lookup} updateLithologyLookup={updateLithologyLookup} />
            ))}
            </tbody>
        </table>
    )    
};

export default ListTable;