import React from 'react';
import { Link } from 'react-router-dom';
import TextField from '../../../components/Input/text-field';

const ForgotPasswordForm = (props: any) => {

    const { emailAddress, 
        setEmailAddress,         
        handleSubmit,
        emailAddressErrors } = props;

        const onHandleSubmit = (e: any) => {
            e.preventDefault();
            handleSubmit();
        }

return (
    <div className="container">
        <div className="columns is-centered">
            <div className="column is-5-tablet is-4-desktop is-4-widescreen">
                <form className="box" onSubmit={onHandleSubmit}>
                    <h4>Forgot Password</h4>
                    <TextField type="text" label="Email Address" errors={emailAddressErrors} value={emailAddress} onChange={setEmailAddress} icon="fa-lock"></TextField>

                    <div className="field is-grouped">
                        <div className="control mt-3">
                            <button type="submit" className="button is-primary">Reset Password</button>
                        </div>
                    </div>
                </form>

                <Link to={"/login"}>Login</Link>
            </div>
        </div>
    </div>        
)}

export default ForgotPasswordForm;