import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useCopyRoleQuery, useAddMutation } from '../../../services/roleApi';

import Container from './container';

const CopyRole = ({}:any) => {
    
    const { roleId } = useParams<any>();    
    const { data: role, isLoading: roleIsLoading } = useCopyRoleQuery({ roleId });
    const [add, { isLoading, error: addError }] = useAddMutation();
    let history = useHistory();

    const onAddRole = (role: any) => {       
        add({...role })
        .unwrap()
        .then((response: any) => {
            toast.success("Successfully copied role");
            history.push(`/admin/role/list`) ;
         })
         .catch((error) => { toast.error("Error copied role") });
      };

    return (
       <Container title="Copy Role" 
                  role={role} 
                  roleIsLoading={roleIsLoading} 
                  onSave={onAddRole}
                  isLoading={isLoading}
                  error={addError}  />
    )    
};

export default CopyRole;