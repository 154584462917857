import React from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Container from './container';
import { useAddMutation } from '../../../services/clientApi';

const AddClient = ({}:any) => {    

    const [add, { isLoading, error: addError }] = useAddMutation();
    let history = useHistory();

    const onAddClient = (client: any) => {        
        add({...client })
        .unwrap()
        .then((response: any) => {
            toast.success("Successfully added client");
            history.push(`/admin/client/list`) ;
         })
         .catch((error) => { toast.error("Error adding client") });
      };

    return (
       <Container title="Add Client" 
                  onSave={onAddClient}
                  isLoading={isLoading}
                  error={addError} />
    )    
};

export default AddClient;