import { useState } from 'react';
import SelectField from '../../../components/Input/select-field';
import Row from './completed-row';
import './list.scss';

const CompletedList = ({ jobs, onGetJobs }:any) => {

    const [pageSize, setPageSize] = useState<any>(25);

    if (!jobs?.length) {
        return null;
    }

    const pageSizes = [{name: "14", value: 14}, {name: "28", value: 28}, {name: "60", value: 60}, {name: "90", value: 90}];
    
    const onChangePageSize = (value: number) => {
        setPageSize(value);
        onGetJobs(value);       
      }

    return (          
        <>            
            <div className='mb-4'>                
                <nav className="level">
                    <div className="level-left">
                        <div className='is-size-5'>Completed</div>                
                    </div>
                    <div className="level-right">                      
                        <div className="level-item">
                            Past Days
                        </div>
                        <div className="level-item">
                            <SelectField values={pageSizes} value={pageSize} onChange={onChangePageSize}/>
                        </div>                      
                    </div>
                </nav>                 
            </div>      
            <table className="geotek-table">
                <thead>
                    <tr>
                        <th>Borehole</th>
                        <th>Name</th>
                        <th>Type</th>                              
                        <th>State</th>
                        <th>Priority</th>
                        <th>Created By</th>
                        <th>Created</th>
                        <th>Started</th>
                        <th>Completed</th>
                    </tr>
                </thead>
                <tbody>
                {(jobs || []).map((job: any, index: number) => (
                    <Row key={job.id} job={job} />
                ))}
                </tbody>
            </table>
        </>
    )    
};

export default CompletedList;