import React from 'react'
import Progress from '../progress';
import './UploadItem.scss'
import formatBytes from '../FormatBytes';

const UploadItem = (props: any) => {
  const { file, progress, status } = props.file

  const progressProgress = { progress: progress, message: null }

  return (
    <div className="wrapperItem">
      <div className="leftSide">
        <Progress progress={{progress:progress, failed: status === 2}} className="upload-progress" />
        
        <label>{file.name} {formatBytes(file.size)}</label>
      </div>
      
    </div>
  )
}

export default UploadItem
