import React, { useEffect, useState } from 'react';

const XScaleProperties = (props: any) => {

    const {        
        id,
        curveProperties,
        setCurveProperties,
        visible        
    } = props;

    const [allManual, setAllManual] = useState(false);
    const [dialogManualScaleXMinimum, setDialogManualScaleXMinimum] = useState<boolean>(curveProperties.manualScaleXMinimum);
    const [dialogManualScaleXMaximum, setDialogManualScaleXMaximum] = useState<boolean>(curveProperties.manualScaleXMaximum);
    const [dialogScaleXMinimum, setDialogScaleXMinimum] = useState<number>(curveProperties.xScaleMinimum);
    const [dialogScaleXMaximum, setDialogScaleXMaximum] = useState<number>(curveProperties.xScaleMaximum);
    const [dialogManualMajorIntervals, setDialogManualMajorIntervals] = useState<boolean>(curveProperties.manualMajorIntervals);
    const [dialogMajorIntervals, setDialogMajorIntervals] = useState<number>(curveProperties.majorIntervals);

    useEffect(() => {
        setDialogScaleXMinimum(curveProperties.xScaleMinimum);
    }, [curveProperties.xScaleMinimum]);

    useEffect(() => {
        setDialogScaleXMaximum(curveProperties.xScaleMaximum);
    }, [curveProperties.xScaleMaximum]);

    useEffect(() => {
        setDialogMajorIntervals(curveProperties.majorIntervals);
    }, [curveProperties.majorIntervals]);

    useEffect(() => {
        setAllManual(!(!dialogManualScaleXMinimum && ! dialogManualScaleXMaximum && !dialogManualMajorIntervals));
    }, 
    [dialogManualScaleXMinimum,dialogManualScaleXMaximum, dialogManualMajorIntervals])
   
    if (!visible) {
        return null;
      }
 
    //   const setDialogManualScaleXMinimum = (value: boolean) => {
    //     setCurveProperties(id, {...curveProperties, manualScaleXMinimum: value });
    //   }

    //   const setDialogManualScaleXMaximum = (value: boolean) => {
    //     setCurveProperties(id, {...curveProperties, manualScaleXMaximum: value });
    //   }

      const setXScaleMinInput = (value: string) => {        
        setDialogScaleXMinimum(+value);
        //setCurveProperties(id, {...curveProperties, xScaleMinimum: +value });
      }

      const setXScaleMaxInput = (value: string) => {
        setDialogScaleXMaximum(+value);
        //setCurveProperties(id, {...curveProperties, xScaleMaximum: +value });
      }

    //   const setDialogManualMajorIntervals = (value: boolean) => {
    //     setCurveProperties(id, {...curveProperties, manualMajorIntervals: value });
    //   }

    //   const setDialogMajorIntervals = (value: string) => {
    //     setCurveProperties(id, {...curveProperties, majorIntervals: +value });
    //   }

    const onSubmit = (e: any) => {
        e.preventDefault();
        ok();
    };

      const ok = () => {
        setCurveProperties(id, {...curveProperties, 
            manualScaleXMinimum: dialogManualScaleXMinimum,
            manualScaleXMaximum: dialogManualScaleXMaximum,
            xScaleMinimum: dialogScaleXMinimum,
            xScaleMaximum: dialogScaleXMaximum,
            manualMajorIntervals: dialogManualMajorIntervals,
            majorIntervals: dialogMajorIntervals
         });
      };

const autoAll = (auto: boolean) => {    
    //setCurveProperties(id, {...curveProperties, manualMajorIntervals: !auto, manualScaleXMinimum: !auto, manualScaleXMaximum: !auto });   
    setDialogManualScaleXMinimum(!auto);
    setDialogManualScaleXMaximum(!auto);
    setDialogManualMajorIntervals(!auto);
};


return(
    <form onSubmit={onSubmit}>
        <table>        
            <thead>
                <tr>
                    <th colSpan={2}>Bounds</th>                
                    <th className="has-text-centered">Auto</th>                
                </tr>
            </thead>
            <tr>
                <td></td>
                <td className="has-text-right">All</td>
                <td className="has-text-centered"><input type="checkbox" checked={!allManual} onChange={(e) => autoAll(e.target.checked)}/></td>
            </tr>
            <tr>
                <td>Minimum</td>
                {/* <td><input className="input" type="number" step="any" value={curveProperties.manualScaleXMinimum ? curveProperties.xScaleMinimum : curveProperties.xScaleMinimumExtent} onChange={(e) => setXScaleMinInput(e.target.value)} disabled={!curveProperties.manualScaleXMinimum}/></td> */}
                <td><input className="input" type="number" step="any" value={dialogScaleXMinimum} onChange={(e) => setXScaleMinInput(e.target.value)} disabled={!dialogManualScaleXMinimum}/></td>
                <td className="has-text-centered"><input type="checkbox" checked={!dialogManualScaleXMinimum} onChange={(e) => setDialogManualScaleXMinimum(!e.target.checked)} /></td>
            </tr>
            <tr>
                <td>Maximum</td>
                <td><input className="input" type="number" step="any" value={dialogScaleXMaximum} onChange={(e) => setXScaleMaxInput(e.target.value)}  disabled={!dialogManualScaleXMaximum}/></td>
                <td className="has-text-centered"><input type="checkbox" checked={!dialogManualScaleXMaximum} onChange={(e) => setDialogManualScaleXMaximum(!e.target.checked)}/></td>
            </tr>
            <tr>
                <th colSpan={3}>
                    Intervals
                </th>
            </tr>
            <tr>
                <td>Major</td>
                <td><input className="input" type="number" step="any" value={!dialogMajorIntervals ? 6 : dialogMajorIntervals} onChange={(e) => setDialogMajorIntervals(+e.target.value)} disabled={!dialogManualMajorIntervals} /></td>
                <td className="has-text-centered"><input type="checkbox" checked={!dialogManualMajorIntervals} onChange={(e) => setDialogManualMajorIntervals(!e.target.checked)}/></td>
            </tr>
            {/* <tr>
                <td>Minor</td>
                <td><input className="input" type="number" step="any" value={curveProperties.minorIntervals} onChange={(e) => setDialogMinorIntervals(e.target.value)}  disabled={!curveProperties.manualMinorIntervals} /></td>
                <td className="has-text-centered"><input type="checkbox" checked={!curveProperties.manualMinorIntervals} onChange={(e) => setDialogManualMinorIntervals(!e.target.checked)} /></td>
            </tr> */}
        </table>  
        <button className="button is-light" onClick={ok}>Ok</button> 
    </form>
)};

export default XScaleProperties;