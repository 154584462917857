
const SideMenuButton = ({
    highlighted, 
    onClick,
    title,
    icon,
    first, 
    disabled }: any) => {    
    return (
     <button className={`button is-tab button-icon ${first ? "" : "mt-1"} ${highlighted ? "highlighted" : ""}`} onClick={onClick} title={title} disabled={disabled}>
                            <img src={`/images/icons/${icon}.svg`} />
                        </button>
    )
}

export default SideMenuButton;