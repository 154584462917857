import _ from 'lodash';
import RightPanel from './right-panel';
import './add-track-panel.scss';
import './panel.scss';
import AddTrack from './add-track';

const AddTrackPanel = (props: any) => {
    const {       
       onAddTrackFormSubmit,       
       selectedTrackType,
       setSelectedTrackType,       
       trackTypeCategories,
       checkedTrackTypes, 
       setCheckedTrackTypes,
       checkedImageTypes,
       setCheckedImageTypes,
       checkedLithologyTypes,
       setCheckedLithologyTypes,
       checkedTextTypes,
       setCheckedTextTypes,
       setSelectedTemplateTrackType,
       selectedTemplateTrackType,
       imageTypes,
       selectedTrackCategory,
       setSelectedTrackCategory,
       allowedTrackCategories,
       onClose
    } = props;    

return(
  
  <RightPanel title="Add Track" icon="Atlas Add Track Icon">
    <div className="add-track-panel-contents">

        <AddTrack
          onAddTrackFormSubmit={onAddTrackFormSubmit}
          selectedTrackType={selectedTrackType} 
          setSelectedTrackType={setSelectedTrackType}
          trackTypeCategories={trackTypeCategories}                                    
          checkedTrackTypes={checkedTrackTypes}
          setCheckedTrackTypes={setCheckedTrackTypes} 
          selectedTemplateTrackType={selectedTemplateTrackType}
          setSelectedTemplateTrackType={setSelectedTemplateTrackType}
          checkedImageTypes={checkedImageTypes}
          setCheckedImageTypes={setCheckedImageTypes}
          checkedLithologyTypes={checkedLithologyTypes}
          setCheckedLithologyTypes={setCheckedLithologyTypes}
          checkedTextTypes={checkedTextTypes}
          setCheckedTextTypes={setCheckedTextTypes}
          imageTypes={imageTypes}
          selectedTrackCategory={selectedTrackCategory}
          setSelectedTrackCategory={setSelectedTrackCategory}
          allowedTrackCategories={allowedTrackCategories}
          onClose={onClose}          
          />
       
    </div>
    </RightPanel>
)};

export default AddTrackPanel;