import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getTrackType, selectTrackType, updateTrackType } from '../../../services/trackTypeSlice';

import Container from './container';

const EditTrackType = ({}:any) => {
    
    const { trackTypeId } = useParams<any>();        

    const dispatch = useAppDispatch();    
    let history = useHistory();  

    useEffect(() => {
        dispatch(getTrackType(trackTypeId))
    }, []);

    const { 
        trackType,
        loading,
        error
       } = useAppSelector(selectTrackType);

    const onSave = (trackType: any) => {
        dispatch(updateTrackType({...trackType, id: trackTypeId})).then((response: any) => {
            if (response.payload.success) {
                //toast.success("Successfully updated track type");
                history.push(`/admin/track-type/list`)
            } else {
                
            }
           })
           .catch((response: any) => { 
            //toast.error("Error updating track type")
           });
    };    

    return (
       <Container title="Edit Track Type" 
                  trackType={trackType}
                  isLoading={loading}
                  onSave={onSave}
                  error={error}  />
    )    
};

export default EditTrackType;