import React from "react";
import { Switch } from "react-router-dom";
import { PrivateRoute } from "../../components/privateRoute";
import AdminMenu from './admin-menu';
import LithologyRoutes from '../lithology/routes';
import RoleRoutes from '../role/routes';
import UserRoutes from '../user/routes';
import TrackTypeRoutes from '../track-type/routes';
import ImageTypeRoutes from '../image-type/routes';
import ClientRoutes from '../client/routes';

import ListAllClientsJobs from '../job/list-all-clients-jobs/list-jobs';
import './admin-menu.scss';

const AdminRoutes = (props: any) => {
  return (    
    <Switch>     
      <div className="admin-menu-container">
        <PrivateRoute path="/admin">
          <AdminMenu />
        </PrivateRoute>

        <LithologyRoutes />
        <RoleRoutes />
        <UserRoutes />
        <TrackTypeRoutes />
        <ImageTypeRoutes />
        <ClientRoutes />
        <PrivateRoute exact path="/admin/job/list-all-clients">
          <ListAllClientsJobs />        
      </PrivateRoute>      
      </div> 
    </Switch>            
  );
};

export default AdminRoutes;
