import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import getConfig from "../app/config";
import { toast } from 'react-toastify';
import auth from '../components/auth';
import { DefaultHttpClient, HttpRequest, HttpResponse, HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import decodeJWT from "jwt-decode";
import fetchWithRefresh from './fetchWithRefresh';
import { RootState } from '../app/store';

const getCurrentUserInfo = () => {    
  const decoded: any = decodeJWT(auth.getToken());
  return {
    name: decoded["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"],
    firstName: decoded["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname"],
    lastName: decoded["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname"],
    emailAddress: decoded["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"],
    isAdmin: decoded["IsAdmin"] === "true"
  };
};


const startSignalRConnection = async (connection: HubConnection) =>
    {
        await connection
        .start()
        .then(() => {                     
            console.info("SignalR Connected");             
          })
        .catch((err: any) => console.error("SignalR Connection Error: ", err))
    };

  class CustomHttpClient extends DefaultHttpClient {
      public send(request: HttpRequest): Promise<HttpResponse> {
        request.headers = { ...request.headers, "authorization": auth.getToken() };
        return super.send(request);
      }
    }

 const connectHubAsync = async (dispatch: any) => {
        const connection = new HubConnectionBuilder()
          .withUrl(getConfig().hubUrl, { accessTokenFactory: () => auth.getTokenForSignalR() })
          .withAutomaticReconnect({
            nextRetryDelayInMilliseconds: retryContext => {
                if (retryContext.elapsedMilliseconds < 600000) {
                    return Math.random() * 10000;
                } else {                  
                    return null;
                }
              }
            })
          .configureLogging(LogLevel.None)
          .build();
        connection.onreconnecting(() => { 
          dispatch(reconnectingHub()); });
        connection.onreconnected(() => { 
          dispatch(reconnectHub()); });
        connection.onclose(async () => {
          console.error("SignalR connection closed");     
            //console.error("SignalR connection closed. Retrying to connect in 5 seconds.");     
            //dispatch(reconnectingHub());
            //setTimeout(() => startSignalRConnection(connection), 5000);
          }
        );
  
        await startSignalRConnection(connection);

        return connection;
      };

const reconnectHub = createAsyncThunk<any, void>(
    "account/reconnectHub",
    async () => {
    }
)

const reconnectingHub = createAsyncThunk<any, void>(
  "account/reconnectingHub",
  async () => {
  }
)

export const connectHub = createAsyncThunk<any, void>(
        "account/connectHub",
        async (dummy, meta : any) => { 
          const loggedIn = meta.getState().account.loggedIn;
          if (loggedIn) {            
            return await connectHubAsync(meta.dispatch);
          }
        }
    )

export const login = createAsyncThunk<any, any>(
    "account/login",
    async (user) => {
      const response = await fetch(getConfig().apiUrl + "account/login", {
        method: 'POST',
        headers: {       
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(user),
      });

      return await response.json();
    }
)

export const loginWithClient = createAsyncThunk<any, any>(
  "account/loginWithClient",
  async (arg) => {    
    const response = await fetch(getConfig().apiUrl + "account/loginWithClient", {
      method: 'POST',
      headers: {       
        'Content-Type': 'application/json',
        Authorization: `Bearer ${arg.token}`,
        Accept: 'application/json',
      },
      body: JSON.stringify({ clientId: arg.clientId }),
    });

    return await response.json();
  }
)

export const loginWithSkipEnableTwoFactor = createAsyncThunk<any, any>(
  "account/loginWithSkipEnableTwoFactor",
  async (arg) => {    
    const response = await fetch(getConfig().apiUrl + "account/loginWithSkipEnableTwoFactor", {
      method: 'POST',
      headers: {       
        'Content-Type': 'application/json',
        Authorization: `Bearer ${arg.token}`,
        Accept: 'application/json',
      },
      body: "{}",
    });

    return await response.json();
  }
)

export const loginWithEnableTwoFactor = createAsyncThunk<any, any>(
  "account/loginWithEnableTwoFactor",
  async (arg) => {    
    const response = await fetch(getConfig().apiUrl + "account/loginWithEnableTwoFactor", {
      method: 'POST',
      headers: {       
        'Content-Type': 'application/json',
        Authorization: `Bearer ${arg.token}`,
        Accept: 'application/json',
      },
      body: JSON.stringify({ authenticatorCode: arg.authenticatorCode }),
    });

    return await response.json();
  }
)

export const loginWithAuthenticatorToken = createAsyncThunk<any, any>(
  "account/loginWithAuthenticatorToken",
  async (arg) => {    
    const response = await fetch(getConfig().apiUrl + "account/loginWithAuthenticatorToken", {
      method: 'POST',
      headers: {       
        'Content-Type': 'application/json',
        Authorization: `Bearer ${arg.token}`,
        Accept: 'application/json',
      },
      body: "{}",
    });

    return await response.json();
  }
)

export const loginWithMfa = createAsyncThunk<any, any>(
  "account/loginWithMfa",
  async (arg) => {    
    const response = await fetch(getConfig().apiUrl + "account/loginWithMfa", {
      method: 'POST',
      headers: {       
        'Content-Type': 'application/json',
        Authorization: `Bearer ${arg.token}`,
        Accept: 'application/json',
      },
      body: JSON.stringify({ authenticatorCode: arg.authenticatorCode }),
    });

    return await response.json();
  }
)

export const loginWithRecovery = createAsyncThunk<any, any>(
  "account/loginWithRecovery",
  async (arg) => {    
    const response = await fetch(getConfig().apiUrl + "account/loginWithRecovery", {
      method: 'POST',
      headers: {       
        'Content-Type': 'application/json',
        Authorization: `Bearer ${arg.token}`,
        Accept: 'application/json',
      },
      body: JSON.stringify({ recoveryCode: arg.recoveryCode, changeTwoFactor: arg.changeTwoFactor }),
    });

    return await response.json();
  }
)

export const changeTwoFactorAuthenticationWithRecoveryCode = createAsyncThunk<any, any>(
  "account/changeTwoFactorAuthenticationWithRecoveryCode",
  async (arg) => {    
    debugger;
    const response = await fetch(getConfig().apiUrl + "account/ChangeTwoFactorAuthenticationWithRecoveryCode", {
      method: 'POST', 
      headers: {       
        'Content-Type': 'application/json',
        Authorization: `Bearer ${arg.token}`,
        Accept: 'application/json',
      },
      body: JSON.stringify({recoveryCode: arg.recoveryCode}),
    });

    return await response.json();
  }
)

export const getUser = createAsyncThunk<any, void>(
  "account/user",
  async (user, thunkApi) => {
    const response = await fetchWithRefresh(getConfig().apiUrl + "account", thunkApi, {
      method: 'Get',
      headers: {       
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    });

    return response;
  }
)

export const getAuthenticatorKeyWithToken = createAsyncThunk<any, any>(
  "account/getAuthenticatorKeyWithToken",
  async (arg: any) => {
    const response = await fetch(getConfig().apiUrl + "account/authenticatorKey", {
      method: 'Get',
      headers: {       
        'Content-Type': 'application/json',
        Authorization: `Bearer ${arg.token}`,
        Accept: 'application/json',
      }
    });

    return response.json();
  }
)


export const getAuthenticatorKey = createAsyncThunk<any, void>(
  "account/authenticatorKey",
  async (user, thunkApi) => {
    const response = await fetchWithRefresh(getConfig().apiUrl + "account/authenticatorKey", thunkApi, {
      method: 'Get',
      headers: {       
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    });

    return response;
  }
)

export const enableTwoFactorAuthentication = createAsyncThunk<any, any>(
  "account/EnableTwoFactorAuthentication",
  async (code, thunkApi) => {    
    const response = await fetchWithRefresh(getConfig().apiUrl + "account/EnableTwoFactorAuthentication", thunkApi, {
      method: 'POST', 
      headers: {       
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({code}),
    });

    return response;
  }
)

export const disableTwoFactorAuthentication = createAsyncThunk<any, any>(
  "account/DisableTwoFactorAuthentication",
  async (code, thunkApi) => {    
    const response = await fetchWithRefresh(getConfig().apiUrl + "account/DisableTwoFactorAuthentication", thunkApi, {
      method: 'POST', 
      headers: {       
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({code}),
    });

    return await response;
  }
)

export const changeTwoFactorAuthentication = createAsyncThunk<any, any>(
  "account/ChangeTwoFactorAuthentication",
  async (code, thunkApi) => {    
    const response = await fetchWithRefresh(getConfig().apiUrl + "account/ChangeTwoFactorAuthentication", thunkApi, {
      method: 'POST', 
      headers: {       
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({code}),
    });

    return await response;
  }
)



export const validateAuthenticatorCode = createAsyncThunk<any, any>(
  "account/validateAuthenticatorCode",
  async (code, thunkApi) => {    
    const response = await fetchWithRefresh(getConfig().apiUrl + "account/ValidateAuthenticatorCode", thunkApi, {
      method: 'POST', 
      headers: {       
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({code}),
    });

    return await response.json();
  }
)

export const changePassword = createAsyncThunk<any, any>(
  "account/changePassword",
  async (user, thunkApi) => {    
    const response = await fetchWithRefresh(getConfig().apiUrl + "account/changePassword", thunkApi, {
      method: 'PUT',
      headers: {       
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(user),
    });

    return response;
  }
)

export const updateDetails = createAsyncThunk<any, any>(
  "account/updateDetails",
  async (user, thunkApi) => {    
    const response = await fetchWithRefresh(getConfig().apiUrl + "account", thunkApi, {
      method: 'PUT',
      headers: {       
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(user),
    });

    return response;
  }
)

export const forgotPassword = createAsyncThunk<any, any>(
  "account/forgotPassword",
  async (user) => {    
    const response = await fetch(getConfig().apiUrl + "account/forgotPassword", {
      method: 'POST',
      headers: {       
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(user),
    });

    return await response.json();
  }
)

export const resetPassword = createAsyncThunk<any, any>(
  "account/resetPassword",
  async (user) => {    
    const response = await fetch(getConfig().apiUrl + "account/resetPassword", {
      method: 'POST',
      headers: {       
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(user),
    });

    return await response.json();
  }
)

interface UserState {  
  name: string | null,
  firstName: string | null,
  lastName: string | null,
  emailAddress: string | null,
  isUserAdmin: boolean,
  isRoleAdmin: boolean,
  isClientAdmin: boolean,
  isTrackTypeAdmin: boolean,
  isImageTypeAdmin: boolean,
  isJobAdmin: boolean,
  canAccessBoreholeFiles: boolean,  
  clientId: string | null,
  clientName: string | null,
  twoFactorEnabled: boolean,  
}

export interface AccountState {
    loading: boolean,
    user: UserState | null,
    connection: HubConnection | null,
    error: any,
    loggedIn: boolean,
    connected: boolean,
    choseClientToken: string | null,
    authenticatorToken: string | null,
    authenticatorKey: string | null,
    authenticatorKeyUrl: string | null,
    recoveryCodes: string[],
    canSkipTwoFactor: boolean;
    twoFactorDeadline: any;
    remainingRecoveryCodes: number | null;
  }
  
const initialState: AccountState = {
    loading: false,
    user: null,
    connection: null,
    error: null,
    loggedIn: false,
    connected: false,
    choseClientToken: null,
    authenticatorToken: null,
    authenticatorKey: null,
    authenticatorKeyUrl: null,
    recoveryCodes: [],
    canSkipTwoFactor: false,
    twoFactorDeadline: null,
    remainingRecoveryCodes: null
  }

const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {           
       logout: (state) => {        
          auth.logout();
          state.loggedIn = false;
          state.connection?.stop();
          state.user = null;
          state.connected = false;
          toast.success("Successfully logged out");
       }
    },
    extraReducers:  (builder) => {
          builder.addCase(login.pending, (state, { payload }) => {   
              state.error = null;               
              state.loading = true;
            });

          builder.addCase(login.fulfilled, (state, { payload }) => {                             
            if (payload?.success || payload.status == 200) {              
              state.canSkipTwoFactor = payload.canSkipTwoFactor;
              state.remainingRecoveryCodes = payload.remainingRecoveryCodes;
              if (payload.choseClient) {
                state.choseClientToken = payload.token;
              } else if (payload.mfa) {
                state.authenticatorToken = payload.token;
              } else {
                toast.success("Successfully logged in");
                auth.setToken(payload);
                state.user = payload;
                state.loggedIn = true;               
              }
            } else {
              toast.error("Error logging in");
            }

            state.loading = false;
          }); 

          builder.addCase(login.rejected, (state, action) => {
            if (action.payload) {
              state.error = action.payload;
              toast.error("Error logging in");
            } else {
              state.error = action.error;
              toast.error("Error logging in");
            }

            state.loading = false;
          });

         
         builder.addCase(loginWithSkipEnableTwoFactor.pending, (state, { payload }) => {
            state.error = null;               
            state.loading = true;
          });

        builder.addCase(loginWithSkipEnableTwoFactor.fulfilled, (state, { payload }) => {
          if (payload?.success || payload.status == 200) {              
            if (payload.choseClient) {
              state.choseClientToken = payload.token;
            } else {
              toast.success("Successfully logged in");
              auth.setToken(payload);
              state.user = payload;
              state.loggedIn = true;               
            }
          } else {
            toast.error("Error logging in");
          }

          state.loading = false;
        }); 

        builder.addCase(loginWithSkipEnableTwoFactor.rejected, (state, action) => {
          if (action.payload) {
            state.error = action.payload;
            toast.error("Error logging in");
          } else {
            state.error = action.error;
            toast.error("Error logging in");
          }

          state.loading = false;
        });

        builder.addCase(loginWithAuthenticatorToken.pending, (state, { payload }) => {
          state.error = null;               
          state.loading = true;
        });

      builder.addCase(loginWithAuthenticatorToken.fulfilled, (state, { payload }) => {
        if (payload?.success || payload.status == 200) {              
          if (payload.choseClient) {
            state.choseClientToken = payload.token;
          } else {
            toast.success("Successfully logged in");
            auth.setToken(payload);
            state.user = payload;
            state.loggedIn = true;               
          }
        } else {
          toast.error("Error logging in");
        }

        state.loading = false;
      }); 

      builder.addCase(loginWithAuthenticatorToken.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          toast.error("Error logging in");
        } else {
          state.error = action.error;
          toast.error("Error logging in");
        }

        state.loading = false;
      });

        builder.addCase(loginWithEnableTwoFactor.pending, (state, { payload }) => {
          state.error = null;               
          state.loading = true;
        });

      builder.addCase(loginWithEnableTwoFactor.fulfilled, (state, { payload }) => {        
        if (payload?.success || payload.status == 200) {
            state.recoveryCodes = payload.recoveryCodes;
            state.choseClientToken = payload.token;
        } else {
          state.error = { errors: payload.errors };
        }

        state.loading = false;
      }); 

      builder.addCase(loginWithEnableTwoFactor.rejected, (state, action) => {        
        if (action.payload) {
          state.error = action.payload;
          toast.error("Error logging in");
        } else {
          state.error = action.error;
          toast.error("Error logging in");
        }

        state.loading = false;
      });

          builder.addCase(loginWithClient.pending, (state, { payload }) => {               
            state.error = null;
            state.loading = true;
          });

          builder.addCase(loginWithClient.fulfilled, (state, { payload }) => {  
            if (payload?.success || payload.status == 200) {
              if (!payload.choseClient) {
                toast.success("Successfully logged in");
                auth.setToken(payload);
                state.user = payload;
                state.loggedIn = true;
                state.recoveryCodes = [];
              } else {
                state.choseClientToken = payload.token;
              }
            } else {
              toast.error("Error logging in");
            }

            state.loading = false;
          }); 

          builder.addCase(loginWithClient.rejected, (state, action) => {
            if (action.payload) {
              state.error = action.payload;
              toast.error("Error logging in");
            } else {
              state.error = action.error;
              toast.error("Error logging in");
            }

            state.loading = false;
          });

          builder.addCase(loginWithMfa.fulfilled, (state, { payload }) => {   
            if (payload?.success || payload.status == 200) {
              if (payload.choseClient) {
                state.choseClientToken = payload.token;
              } else {
                toast.success("Successfully logged in");
                auth.setToken(payload);
                state.user = payload;
                state.loggedIn = true;        
                state.recoveryCodes = [];       
              }
            } else {
              toast.error("Error logging in");
            }

            state.loading = false;
          }); 

          builder.addCase(loginWithMfa.rejected, (state, action) => {
            if (action.payload) {
              state.error = action.payload;
              toast.error("Error logging in");
            } else {
              state.error = action.error;
              toast.error("Error logging in");
            }

            state.loading = false;
          });

          builder.addCase(loginWithRecovery.fulfilled, (state, action) => {   
            const { payload, meta } = action;
            debugger;
            if (!meta.arg.changeTwoFactor) {
              if (payload?.success || payload.status == 200) {
                if (payload.choseClient) {
                  state.choseClientToken = payload.token;
                } else {
                  toast.success("Successfully logged in");
                  auth.setToken(payload);
                  state.user = payload;
                  state.loggedIn = true;      
                  state.recoveryCodes = [];         
                }
              } else {
                toast.error("Error logging in");
              }
            }

            state.loading = false;
          }); 

          builder.addCase(loginWithRecovery.rejected, (state, action) => {
            debugger;
            if (action.payload) {
              state.error = action.payload;
              toast.error("Error logging in");
            } else {
              state.error = action.error;
              toast.error("Error logging in");
            }

            state.loading = false;
          });

          builder.addCase(changeTwoFactorAuthenticationWithRecoveryCode.pending, (state, { payload }) => {
            state.error = null;
            state.loading = true;
          });
          builder.addCase(changeTwoFactorAuthenticationWithRecoveryCode.fulfilled, (state, action) => {
            debugger;
            const { payload } = action;
            if (payload?.success || payload.status == 200) {
              if (payload.choseClient) {
                state.choseClientToken = payload.token;
              } else {
                toast.success("Two factor authentication disabled");
                if (payload.token) {
                  auth.setToken(payload);
                  state.user = payload;
                  state.loggedIn = true;               
                }
              }
            } else {
              state.error = {errors: payload.errors };
              toast.error("Error changing two factor authentication");
            }

            state.loading = false;
          }); 
          builder.addCase(changeTwoFactorAuthenticationWithRecoveryCode.rejected, (state, { payload }: any) => {                                                
            state.error = {errors: payload};
            state.loading = false;
          }); 

          builder.addCase(getUser.pending, (state, { payload }) => {               
            state.loading = true;
          });
          builder.addCase(getUser.fulfilled, (state, { payload }) => {               
            if (payload?.success || payload?.status == 200) {
              state.user = payload;
              state.loggedIn = true;
            } else {
              toast.error("Error getting user");
            }

            state.loading = false;
          }); 

          builder.addCase(getUser.rejected, (state, action) => {
            if (action.payload) {
              state.error = action.payload;
              toast.error("Error getting user");
            } else {
              state.error = action.error;
              toast.error("Error getting user");
            }

            state.loading = false;
          });

          builder.addCase(getAuthenticatorKey.pending, (state, { payload }) => {       
            state.recoveryCodes = [];        
            state.loading = true;
          });
          builder.addCase(getAuthenticatorKey.fulfilled, (state, { payload }) => {                         
            if (payload?.success || payload?.status == 200) {              
              state.authenticatorKey = payload.authenticatorKey;
              state.authenticatorKeyUrl = payload.authenticatorKeyUrl;              
              state.twoFactorDeadline = payload.deadline;
            } else {
              toast.error("Error getting authenticator key");
            }

            state.loading = false;
          }); 

          builder.addCase(getAuthenticatorKeyWithToken.pending, (state, { payload }) => {       
            state.recoveryCodes = [];        
            state.loading = true;
          });
          builder.addCase(getAuthenticatorKeyWithToken.fulfilled, (state, { payload }) => {                             
            if (payload?.success || payload?.status == 200) {
              state.authenticatorKey = payload.authenticatorKey;
              state.authenticatorKeyUrl= payload.authenticatorKeyUrl;
              state.twoFactorDeadline = payload.deadline;
            } else {
              toast.error("Error getting authenticator key");
            }

            state.loading = false;
          }); 

          builder.addCase(validateAuthenticatorCode.pending, (state, { payload }) => {
            state.loading = true;
          });
          builder.addCase(validateAuthenticatorCode.fulfilled, (state, { payload }) => {                 
            
            state.loading = false;
          }); 
          builder.addCase(validateAuthenticatorCode.rejected, (state, { payload }) => {            
            state.loading = false;
          }); 

          builder.addCase(enableTwoFactorAuthentication.pending, (state, { payload }) => {
            state.error = null;
            state.loading = true;
          });
          builder.addCase(enableTwoFactorAuthentication.fulfilled, (state, action) => {            
            const { payload } = action;
            if (payload?.success || payload?.status == 200) {            
              toast.success("Code validated successfully");
              state.recoveryCodes = payload.recoveryCodes;
              if (state.user) {
                state.user.twoFactorEnabled = true;
              }
            } else {
              toast.error("Code error");
              state.error = { errors: payload.errors };
            }

            state.loading = false;
          }); 
          builder.addCase(enableTwoFactorAuthentication.rejected, (state, { payload }: any) => {                        
            state.error = { errors: payload };
            state.loading = false;
          }); 

          builder.addCase(disableTwoFactorAuthentication.pending, (state, { payload }) => {
            state.error = null;
            state.loading = true;
          });
          builder.addCase(disableTwoFactorAuthentication.fulfilled, (state, { payload }) => {            
            toast.success("Two factor authentication disabled");
            state.loading = false;
            if (state.user) {
              state.user.twoFactorEnabled = false;
            }
          }); 
          builder.addCase(disableTwoFactorAuthentication.rejected, (state, { payload }: any) => {                                                
            state.error = {errors: payload};
            state.loading = false;
          }); 

          builder.addCase(changeTwoFactorAuthentication.pending, (state, { payload }) => {
            state.error = null;
            state.loading = true;
          });
          builder.addCase(changeTwoFactorAuthentication.fulfilled, (state, { payload }) => {            
            toast.success("Two factor authentication disabled");
            state.loading = false;
            if (state.user) {
              state.user.twoFactorEnabled = false;
            }
          }); 
          builder.addCase(changeTwoFactorAuthentication.rejected, (state, { payload }: any) => {                                                
            state.error = {errors: payload};
            state.loading = false;
          }); 

         

          builder.addCase(changePassword.pending, (state, { payload }) => {               
            state.loading = true;
          });

          builder.addCase(changePassword.fulfilled, (state, { payload }) => {   
            if (payload?.success || payload.status == 200) {
              toast.success("Successfully changed password");                
            } else {
              toast.error("Error changing password");
            }

            state.loading = false;
          });

          builder.addCase(changePassword.rejected, (state, action) => {
            if (action.payload) {
              state.error = action.payload;
              toast.error("Error changing password");
            } else {
              state.error = action.error;
              toast.error("Error changing password");
            }

            state.loading = false;
          });

          builder.addCase(updateDetails.pending, (state, { payload }) => {               
            state.loading = true;
          });

          builder.addCase(updateDetails.fulfilled, (state, action) => {   
            const { payload, meta } = action;
            if (payload?.success || payload.status == 200) {
              if (state.user) {
                state.user.name = payload.name;
                state.user.firstName = meta.arg.firstName;
                state.user.lastName = meta.arg.lastName;
                state.user.emailAddress = meta.arg.emailAddress;
              }

              auth.updateToken(payload.token, payload.tokenExpires);
              toast.success("Successfully updated user details");                
            } else {
              toast.error("Error updating user details");
            }

            state.loading = false;
          });

          builder.addCase(updateDetails.rejected, (state, action) => {
            if (action.payload) {
              state.error = action.payload;
              toast.error("Error updating user details");
            } else {
              state.error = action.error;
              toast.error("Error updating user details");
            }

            state.loading = false;
          });

          builder.addCase(forgotPassword.pending, (state, { payload }) => {               
            state.loading = true;
          });

          builder.addCase(forgotPassword.fulfilled, (state, { payload }) => {   
            if (payload?.success || payload.status == 200) {
              toast.success("Successfully sent reset password email");
            } else {
              toast.error("Error resetting password");
            }

            state.loading = false;
          });

          builder.addCase(forgotPassword.rejected, (state, action) => {
            if (action.payload) {
              state.error = action.payload;
              toast.error("Error sending password");
            } else {
              state.error = action.error;
              toast.error("Error sending password");
            }

            state.loading = false;
          });

          builder.addCase(resetPassword.pending, (state, { payload }) => {
            state.loading = true;
          });

          builder.addCase(resetPassword.fulfilled, (state, { payload }) => {   
            if (payload?.success || payload.status == 200) {
              toast.success("Successfully changed password");                
            } else {
              toast.error("Error changing password");
            }

            state.loading = false;
          });

          builder.addCase(resetPassword.rejected, (state, action) => {
            if (action.payload) {
              state.error = action.payload;
              toast.error("Error changing password");
            } else {
              state.error = action.error;
              toast.error("Error changing password");
            }

            state.loading = false;
          });

          builder.addCase(connectHub.fulfilled, (state, response) => {          
            const { payload } = response;
            if (payload) {
              state.connection = payload;
              state.connected = true;
            }
          });

          builder.addCase(connectHub.rejected, (state, { payload }) => {             
            state.connected = false;
          });

          builder.addCase(reconnectingHub.fulfilled, (state, { payload }) => {        
            state.connected = false;
          });

          builder.addCase(reconnectHub.fulfilled, (state, { payload }) => {
            state.connected = true;
          });          
    }    
})

export const { logout } = accountSlice.actions

export const selectUser = (state: RootState) => state.account.user;

export const selectAccount = (state: RootState) => state.account;

export default accountSlice.reducer;