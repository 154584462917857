import React from "react";
import { Switch } from "react-router-dom";
import { PrivateRoute } from "../../components/privateRoute";
import ListTrackType from './list/track-type-list';
import EditTrackType from './edit-track-type/edit-track-type';
import AddTrackType from './edit-track-type/add-track-type';

const TrackTypeRoutes = () => {
  return (    
    <Switch>      
      <PrivateRoute exact path="/admin/track-type/list">
        <ListTrackType />        
      </PrivateRoute>   
      <PrivateRoute exact path="/admin/track-type/edit/:trackTypeId">
        <EditTrackType />        
      </PrivateRoute>   
      <PrivateRoute exact path="/admin/track-type/add">
        <AddTrackType />        
      </PrivateRoute>   
    </Switch>            
  );
};

export default TrackTypeRoutes;
